import React, {ReactElement} from "react";
import {Role} from "./Role";
import {Right} from "./Right";
import {UserOldRoles} from "../../../model/UserOldRoles";
import {Organisation} from "./Organisation";
import Selector, {Selectable} from "./Selector";
import {deleteItem, fetchArrayOf, put} from "../../../../infra/BackendService";

interface props {
    role: Role
}

interface state {
    assignedRights: Right[],
    availableRights: Right[],

    users: UserOldRoles[]

    organisations: Organisation[]
}

class ManageRoles extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.assignRight = this.assignRight.bind(this);
        this.removeRight = this.removeRight.bind(this);
        this.state = {
            assignedRights: [],
            availableRights: [],
            users: [],
            organisations: []
        }
    }

    render(): ReactElement {
        const rightSelector = <Selector assigned={this.state.assignedRights}
                                        available={this.state.availableRights}
                                        assign={this.assignRight}
                                        remove={this.removeRight}
                                        type={"Rights"}/>


        const users = this.state.users;
        const userRows = users.map((user: UserOldRoles, index: number) => {
                let className: string = ManageRoles.getClassName("col-5", index);
                const userName = user.userName;
                const fullName = user.firstName + " " + user.lastName;
                return <div key={"userRow " + userName} className={"row"} aria-label={userName + " " + fullName}>
                    <div className={className}>
                        {userName} {fullName}
                    </div>
                </div>
            }
        )

        const organisations = this.state.organisations;
        const organisationRows = organisations.map((organisation: Organisation, index: number) => {
                let className: string = ManageRoles.getClassName("col-5", index);
                const organisationName = organisation.name;
                return <div key={"organisationRow " + organisationName} className={"row"} aria-label={organisationName}>
                    <div className={className}>
                        {organisationName}
                    </div>
                </div>
            }
        )
        const roleName = this.props.role.name;
        return <div>
            <div className={"row mt-3"}>
                <h1>{roleName}</h1>
            </div>
            {rightSelector}
            <div className={"row mt-3"}>
                <h3>Users</h3>
            </div>
            {userRows}
            <div className={"row mt-3"}>
                <h3>Organisations</h3>
            </div>
            {organisationRows}
        </div>;
    }

    componentDidMount() {
        this.getRoleAffiliations();
    }

    private getRoleAffiliations() {
        const role = this.props.role;
        const roleId = role.id;

        this.getAssignedRights(roleId);
        this.getAvailableRights(roleId);

        this.getOrganisations(roleId);
        this.getUsers(roleId);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        if (prevProps.role !== this.props.role) {
            this.getRoleAffiliations();
        }
    }


    private getAssignedRights(roleId: string) {
        const rolesUrl = "/api/v3/backoffice/role/rights/" + roleId;
        fetchArrayOf<Right>(rolesUrl)
            .then((rights) => {
                this.setState({assignedRights: rights});
            });
    }

    private getAvailableRights(roleId: string) {
        const availableUrl = "/api/v3/backoffice/role/rights/available/" + roleId;
        fetchArrayOf<Right>(availableUrl)
            .then((rights: Right[]) => {
                this.setState({availableRights: rights})
            });
    }

    private getOrganisations(roleId: string) {
        const organisationsUrl = "/api/v3/backoffice/role/organisations/" + roleId;
        fetchArrayOf<Organisation>(organisationsUrl)
            .then((organisations: Organisation[]) => {
                this.setState({organisations: organisations});
            });
    }


    private assignRight(selected: Selectable) {
        const role = this.props.role;
        const roleId = role.id;
        const url: string = '/api/v3/backoffice/role/right/' + roleId;

        const payload = {
            "id": selected.id
        }
        put(payload, url)
            .then(() => {
                this.getAvailableRights(roleId);
                this.getAssignedRights(roleId);
            });
    }

    private removeRight(selected: Selectable) {
        const role = this.props.role;
        const roleId = role.id;
        const url: string = '/api/v3/backoffice/role/right/' + roleId + "/" + selected.id

        deleteItem(url)
            .then(() => {
                this.getAvailableRights(roleId);
                this.getAssignedRights(roleId);
            });
    }

    private getUsers(roleId: string) {
        const usersUrl = "/api/v3/backoffice/role/users/" + roleId
        fetchArrayOf<UserOldRoles>(usersUrl).then((users) => {
            this.setState({users: users});
        });
    }

    private static getClassName(src: string, index: number) {
        const rowNumber = index + 1;
        if (rowNumber % 2 === 0) {
            return src + " grayed";
        }

        return src;
    }
}

export default ManageRoles;