import React, {Component} from 'react'
import C3Chart from "react-c3js";
import './SupervisorExperience.css';

class SupervisorExperience extends Component {
    constructor(props) {
        super(props);
        let graphData = {
            columns: [
                ['attendance', 144],
                ['placement', 575],
                ['SoS', 1825]
            ],
            names: {
                attendance: 'Faktisk närvaro',
                placement: 'Socialstyrelsen',
                SoS: 'Socialstyrelsens mål'
            },
            type: 'bar',
            colors: {
                attendance: 'green',
                placement: 'blue',
                SoS: 'grey'
            },
        };
        let graphData1 = {
            columns: [
                ['attendance', 191],
                ['placement', 469],
                ['SoS', 1825]
            ],
            names: {
                attendance: 'Faktisk närvaro',
                placement: 'Socialstyrelsen',
                SoS: 'Socialstyrelsens mål'
            },
            type: 'bar',
            colors: {
                attendance: 'green',
                placement: 'blue',
                SoS: 'grey'
            },
        };
        let graphData2 = {
            columns: [
                ['attendance', 65],
                ['placement', 197],
                ['SoS', 1825]
            ],
            names: {
                attendance: 'Faktisk närvaro',
                placement: 'Socialstyrelsen',
                SoS: 'Socialstyrelsens mål'
            },
            type: 'bar',
            colors: {
                attendance: 'green',
                placement: 'blue',
                SoS: 'grey'
            },
        };
        let graphData4 = {
            columns: [
                ['attendance', 461],
                ['placement', 788],
                ['SoS', 1825]
            ],
            names: {
                attendance: 'Faktisk närvaro',
                placement: 'Socialstyrelsen',
                SoS: 'Socialstyrelsens mål'
            },
            type: 'bar',
            colors: {
                attendance: 'green',
                placement: 'blue',
                SoS: 'grey'
            },
        };

        let graphBar = {
            space: 0.25
        };

        let graphAxis = {
            rotated: true,
            x: {
                show: false
            },
            y: {
                show: true,
                max: 1650,
            }
        };

        let legend = {
            show: false
        };

        let userList = [

            {
                name: "Märta Åberg",
                data: graphData4
            },
            {
                name: "Anja Rosén",
                data: graphData
            },
            {
                name: "Atosa Nejatian",
                data: graphData1
            },
            {
                name: "Martin Hult",
                data: graphData2
            },
        ];

        this.state.userList = userList;
        this.state.graphData = graphData;
        this.state.graphAxis = graphAxis;
        this.state.graphBar = graphBar;
        this.state.legend = legend;


        this.switchStateOfDiv = this.switchStateOfDiv.bind(this);

    }

    state = {
        graphData: "",
        graphAxis: "",
        userList: "",
        graphBar: "",
        legend: "",
    };

    switchStateOfDiv(elementId) {
        let details = document.getElementById(elementId + "hiddenDiv");

        if (details.className.includes("hidden")) {
            details.className = "col-sm text-center";
        } else {
            details.className = "col-sm text-center hidden";
        }
    }

    asdf() {
        return (
            <div>
                <div className="row">

                    <div className="col-sm-2 text-left">
                        <h6>Placering</h6>
                    </div>
                    <div className="col-sm-3 text-left">
                        <h6>Period</h6>
                    </div>
                    <div className="col-sm-1 text-right">
                        <h6>Totalt</h6>
                    </div>
                    <div className="col-sm-1 text-right">
                        <h6> SoS</h6>
                    </div>
                    <div className="col-sm-1 text-right">
                        <h6> Faktisk</h6>
                    </div>
                    <div className="col-sm-2 text-right">
                        <h6> Giltig</h6>
                    </div>
                    <div className="col-sm-2 text-right">
                        <h6>Ogiltig</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-left">
                        E5
                    </div>
                    <div className="col-sm-3 text-left">2019-06-17 - 2019-10-13</div>
                    <div className="col-sm-1 text-right">
                        119
                    </div>
                    <div className="col-sm-1 text-right">
                        88
                    </div>
                    <div className="col-sm-1 text-right">
                        25
                    </div>
                    <div className="col-sm-2 text-right">
                        63
                    </div>
                    <div className="col-sm-2 text-right">
                        31
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-left">
                        ÖNH
                    </div>
                    <div className="col-sm-3 text-left"> 2019-02-25 - 2019-06-16</div>
                    <div className="col-sm-1 text-right">
                        112
                    </div>
                    <div className="col-sm-1 text-right">
                        84
                    </div>
                    <div className="col-sm-1 text-right">
                        33
                    </div>
                    <div className="col-sm-2 text-right">
                        51
                    </div>
                    <div className="col-sm-2 text-right">
                        28
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-left">
                        Kirurgi
                    </div>
                    <div className="col-sm-3 text-left">2019-01-14 - 2019-02-24</div>
                    <div className="col-sm-1 text-right">
                        42
                    </div>
                    <div className="col-sm-1 text-right">
                        36
                    </div>
                    <div className="col-sm-1 text-right">
                        24
                    </div>
                    <div className="col-sm-2 text-right">
                        12
                    </div>
                    <div className="col-sm-2 text-right">
                        6
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-left">
                        Ortopedi
                    </div>
                    <div className="col-sm-3 text-left">2018-08-27 - 2019-01-13</div>
                    <div className="col-sm-1 text-right">
                        140
                    </div>
                    <div className="col-sm-1 text-right">
                        138
                    </div>
                    <div className="col-sm-1 text-right">
                        82
                    </div>
                    <div className="col-sm-2 text-right">
                        56
                    </div>
                    <div className="col-sm-2 text-right">
                        2
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-left">
                        Ortopedi
                    </div>
                    <div className="col-sm-3 text-left">2018-01-15 - 2018-03-28</div>
                    <div className="col-sm-1 text-right">
                        73
                    </div>
                    <div className="col-sm-1 text-right">
                        73
                    </div>
                    <div className="col-sm-1 text-right">
                        53
                    </div>
                    <div className="col-sm-2 text-right">
                        20
                    </div>
                    <div className="col-sm-2 text-right">
                        0
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-left">
                        KLAM
                    </div>
                    <div className="col-sm-3 text-left">2016-11-21 - 2016-12-16</div>
                    <div className="col-sm-1 text-right">
                        26
                    </div>
                    <div className="col-sm-1 text-right">
                        26
                    </div>
                    <div className="col-sm-1 text-right">
                        20
                    </div>
                    <div className="col-sm-2 text-right">
                        6
                    </div>
                    <div className="col-sm-2 text-right">
                        0
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-left">
                        Kirurgi
                    </div>
                    <div className="col-sm-3 text-left">2016-08-29 - 2017-01-15</div>
                    <div className="col-sm-1 text-right">
                        140
                    </div>
                    <div className="col-sm-1 text-right">
                        140
                    </div>
                    <div className="col-sm-1 text-right">
                        80
                    </div>
                    <div className="col-sm-2 text-right">
                        60
                    </div>
                    <div className="col-sm-2 text-right">
                        0
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-left">
                        Urologi
                    </div>
                    <div className="col-sm-3 text-left">2016-03-14 - 2016-07-31</div>
                    <div className="col-sm-1 text-right">
                        140
                    </div>
                    <div className="col-sm-1 text-right">
                        140
                    </div>
                    <div className="col-sm-1 text-right">
                        100
                    </div>
                    <div className="col-sm-2 text-right">
                        40
                    </div>
                    <div className="col-sm-2 text-right">
                        0
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-left">
                        Introduktion
                    </div>
                    <div className="col-sm-3 text-left">2016-01-11 - 2016-03-13</div>
                    <div className="col-sm-1 text-right">
                        63
                    </div>
                    <div className="col-sm-1 text-right">
                        63
                    </div>
                    <div className="col-sm-1 text-right">
                        45
                    </div>
                    <div className="col-sm-2 text-right">
                        18
                    </div>
                    <div className="col-sm-2 text-right">
                        0
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className={"container"}>
                {
                    this.state.userList.map(e => {
                        return (
                            <React.Fragment key={e.name}>
                                <div className={"row"}>
                                    <div className={"col-sm-2"}><h6 className={"pt-5"}>{e.name}</h6></div>
                                    <div className={"col-sm-9"}>
                                        <C3Chart data={e.data} axis={this.state.graphAxis} bar={this.state.graphBar} legend={this.state.legend}/>
                                    </div>
                                    <div className={"col-sm-1 pt-5"}>
                                        <button onClick={() => this.switchStateOfDiv(e.name)}>Detaljer</button>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div id={e.name + "hiddenDiv"} className={"col-sm text-center hidden"}>{this.asdf()}</div>
                                </div>
                            </React.Fragment>
                        )
                    })

                }
            </div>
        );
    }
}

export default SupervisorExperience;