import {Field} from "../v2/Action";
import Event, {hasValue} from "./Event";
import Value from "../v2/Value";
import ValueOld from "../v2/Value";
import {getDuplicationIndex} from "./DuplicationIndex";

export enum CONDITIONALOPERATOR {
    AND = "and",
    OR = "or"
//    NOT = "not"
}

export function shouldBeVisible(field: Field, event: Event): boolean {
    const isConditionalOn: boolean = shouldConditionalOnBeVisible(field, event);
    const isExpanding: boolean = shouldExpandingFieldBeVisible(field, event);

    return isConditionalOn && isExpanding;
}


function anyPredicateTruthy(predicates: any[], event: Event) {
    let truthy = false;
    predicates.forEach((pred) => {
        if (isLeaf(pred)) {
            const leafIsTruthy = isLeafTruthy(pred, CONDITIONALOPERATOR.OR, event);
            if (leafIsTruthy) {
                truthy = true;
            }
        } else {
            const treeIsTruthy = isTreeTruthy(pred, event);
            if (treeIsTruthy) {
                truthy = true;
            }
        }
    })

    return truthy;
}

function isLeaf(pred: any): boolean {
    return pred.fieldName !== undefined;
}

function isTreeTruthy(pred: any, event: Event): boolean {
    const operator: string = pred.operator;
    const predicates: any[] = pred.predicates;

    if (operator === 'or') {
        return anyPredicateTruthy(predicates, event);
    }

    if (operator === 'and') {
        return allPredicatesTruthy(predicates, event);
    }

    if (operator === 'not') {
        return !anyPredicateTruthy(predicates, event);
    }

    return false; //should hedge for more operators(?)
}

function isLeafTruthy(pred: any, operator: CONDITIONALOPERATOR, event: Event): boolean {
    const wantedFieldName: string = pred.fieldName;
    let duplicationIndex: string;

    if (pred.duplicationIndex !== undefined) {
        duplicationIndex = pred.duplicationIndex;
    } else {
        duplicationIndex = '0'
    }

    let someAreTrue: boolean = false;
    let allTrue: boolean = true;

    for (const wantedValue of pred.values) {
        if (hasValue(wantedFieldName, wantedValue, duplicationIndex, event)) {
            someAreTrue = true;
        } else {
            allTrue = false;
        }
    }

    if (operator === CONDITIONALOPERATOR.AND) {
        return allTrue;
    }
    if (operator === CONDITIONALOPERATOR.OR) {
        return someAreTrue;
    }

    return false;
}

function allPredicatesTruthy(predicates: any[], event: Event) {
    let truthy = true;
    predicates.forEach((pred) => {
        if (isLeaf(pred)) {
            const leafIsTruthy = isLeafTruthy(pred, CONDITIONALOPERATOR.AND, event);
            if (!leafIsTruthy) {
                truthy = false;
            }
        } else {
            const treeIsTruthy = isTreeTruthy(pred, event);
            if (!treeIsTruthy) {
                truthy = false;
            }
        }
    })

    return truthy;
}

export function shouldConditionalOnBeVisible(field: Field, event: Event): boolean {
    let conditionalOn = field.conditionalOn;
    if (conditionalOn !== undefined) {

        //This is very similar to isTreeTruthy, and could probably be merged

        const operator: string = conditionalOn.operator;
        const predicates: any[] = conditionalOn.predicates;

        if (operator === 'or') {
            return anyPredicateTruthy(predicates, event);
        }

        if (operator === 'and') {
            return allPredicatesTruthy(predicates, event);
        }

        if (operator === 'not') {
            return !anyPredicateTruthy(predicates, event);
        }

        return true;

    } else {
        return true;
    }
}

export function shouldExpandingFieldBeVisible(field: Field, event: Event): boolean {
    if (field.parent !== undefined) {
        const parent: Field = field.parent;

        if (parent.type !== undefined) {
            if (parent.type === 'radio' || parent.type === 'checkbox') {

                if (field.type !== undefined) {
                    if (field.type === 'radio' || field.type === 'checkbox') {
                        const wantedFieldName: string = parent.name;
                        const wantedValue: string = field.name;
                        const duplicationIndex = getDuplicationIndex(field);

                        return hasValue(wantedFieldName, wantedValue, duplicationIndex, event);
                    } else {
                        if (parent.parent !== undefined) {
                            const wantedFieldName: string = parent.parent.name;
                            const wantedValue: string = parent.name;
                            const duplicationIndex = getDuplicationIndex(field);

                            return hasValue(wantedFieldName, wantedValue, duplicationIndex, event);
                        } else {
                            const wantedFieldName: string = parent.name;
                            const wantedValue: string = field.name;
                            const duplicationIndex = getDuplicationIndex(field);

                            return hasValue(wantedFieldName, wantedValue, duplicationIndex, event);
                        }
                    }
                }
            }

            return true;
        }
    }

    return true;
}


export function addDuplicationIndexToConditionalOnPredicates(field: Field) {
    const parent = field.parent;
    if (parent === undefined) {
        // if field has no parent, assign 0 to predicate duplication field. why??
        // what if it is dependent on a duplicable field that fulfills the predicate on its second copy?
        // this will never show up!
        // what am I missing here?
        const conditionalOn = field.conditionalOn;
        if (conditionalOn !== undefined) {
            const predicates = conditionalOn.predicates as Value[];  //cast to not screw things up // Mika
            predicates.forEach((predicate: ValueOld) => predicate.duplicationIndex = '0')
        }
    } else {
        // conditional on field has a parent
        const conditionalOn = field.conditionalOn;
        if (conditionalOn !== undefined) {
            const predicates = conditionalOn.predicates as Value[]; //cast to not screw things up // Mika
            predicates.forEach((predicate: ValueOld) => {
                // for each predicate, if the predicate fieldname is a sibling, set the duplication index as the sibling's
                const wanted: string = predicate.fieldName;
                let siblings = parent.fields;
                if (siblings !== undefined) {
                    siblings.forEach((sibling: Field) => {
                        const duplicationIndex: string | undefined = calculateDuplicationIndex(wanted, sibling);
                        if (duplicationIndex !== undefined) {
                            predicate.duplicationIndex = duplicationIndex;
                            return;
                        }
                    });
                    if (predicate.duplicationIndex !== undefined) {
                        return;
                    }
                }
                // if there are no siblings, calculate based on the parent's field
                predicate.duplicationIndex = calculateDuplicationIndex(wanted, parent);
            });
        }
    }
}

export function calculateDuplicationIndex(predicateFieldName: string, field: Field): string {
    if (field.name === predicateFieldName) {
        return getDuplicationIndex(field);
    } else {
        if (field.fields !== undefined) {
            field.fields.forEach((child: Field) => {
                return calculateDuplicationIndex(predicateFieldName, child);
            });
        }

        if (field.options !== undefined) {
            field.options.forEach((option: string | Field) => {
                if (typeof option !== 'string') {
                    return calculateDuplicationIndex(predicateFieldName, option);
                }
            });
        }

        if (field.parent !== undefined) {
            return getDuplicationIndex(field.parent);
        } else {
            return getDuplicationIndex(field);
        }
    }
}