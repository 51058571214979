import Event, {getValue, Value} from "./Event";
import {Field} from "../v2/Action";
import {getDuplicationIndex} from "./DuplicationIndex";

export default interface FieldError {
    name: string
}

export function getErrors(name: string, valid: boolean, currentErrors: FieldError[]): FieldError[] {
    if (valid) {
        return currentErrors.filter((value: FieldError) => value.name !== name);
    } else {
        const error: FieldError = {
            name: name
        };
        const found: FieldError | undefined = currentErrors.find((value: FieldError) => value.name === name);
        if (found === undefined) {
            currentErrors.push(error);
        }

        return currentErrors;
    }
}

export function rebuildErrorsForCurrentState(event: Event, previousStateErrors: FieldError[], mandatoryFields: Field[]): FieldError[] {
    const currentErrors: FieldError[] = [];

    if (previousStateErrors.length > 0) {
        const values: Map<string, Value> = event.values;
        let fields: string[] = [];
        values.forEach((value: Value) => {
            let field = value.field;
            fields.push(field.name);
        });
        previousStateErrors.forEach((error) => {
            if (fields.includes(error.name)) {
                currentErrors.push(error);
            }
        });
    }

    if (mandatoryFields.length > 0) {
        mandatoryFields.forEach((field) => {
            let name = field.name;
            const duplicationIndex: string = getDuplicationIndex(field);
            const value: string | string[] | undefined = getValue(name, event, duplicationIndex);
            if (value === undefined) {
                getErrors(name, false, currentErrors);
            }
            if (value === '') {
                getErrors(name, false, currentErrors);
            }

            if (Array.isArray(value) && value.length === 0) {
                getErrors(name, false, currentErrors);
            }
        });
    }

    return currentErrors;
}

