import React, {ChangeEvent, ReactElement} from "react";
import {postObjectOf} from "../../../../infra/BackendService";
import moment from "moment-timezone";

interface props {
}

interface state {
    firstName: string,
    lastName: string
    email: string,
    password: string,
    phone: string | undefined,
    userCreated: string,
    scheduledDate: string,
    scheduledTime: string,
    locale: string
    hasErrors: boolean,
    errorMessages: string[]
}

export type UserPayload = {
    firstName: string,
    lastName: string
    email: string,
    password: string
    phone: string,
    locale: string,
    scheduleEmail: string | undefined
}


class CreateUserForm extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            phone: "",
            locale: "swedish",
            userCreated: "",
            scheduledDate: "",
            scheduledTime: "",
            hasErrors: false,
            errorMessages: []
        };
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleScheduledDate = this.handleScheduledDate.bind(this);
        this.handleScheduledTime = this.handleScheduledTime.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleFirstNameChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({firstName: event.target.value, hasErrors: false, errorMessages: []});
    }

    handleLastNameChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({lastName: event.target.value, hasErrors: false, errorMessages: []});
    }

    handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({email: event.target.value, hasErrors: false, errorMessages: []});
    }

    handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({password: event.target.value, hasErrors: false, errorMessages: []})
    }

    handlePhoneChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({phone: event.target.value})
    }

    handleLocaleChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({locale: event.target.value})
    }

    private handleScheduledDate(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({scheduledDate: event.target.value, hasErrors: false, errorMessages: []})
    }

    private handleScheduledTime(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({scheduledTime: event.target.value, hasErrors: false, errorMessages: []})
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const url: string = "/api/v3/backoffice/user";
        let firstName = this.state.firstName;
        let lastName = this.state.lastName;
        let email = this.state.email;
        let password = this.state.password;
        let phone = this.state.phone;
        let locale = this.state.locale
        let scheduleEmail = undefined;
        let hasErrors = false;
        let errorMessages = this.state.errorMessages;
        if (!firstName) {
            errorMessages.push("First name is mandatory");
            hasErrors = true;
        }
        if (!lastName) {
            errorMessages.push("Last name is mandatory");
            hasErrors = true;
        }
        if (!email) {
            errorMessages.push("Email is mandatory");
            hasErrors = true;
        }
        if (!password) {
            errorMessages.push("Password is mandatory");
            hasErrors = true;
        }

        if (this.state.scheduledDate !== "") {
            if (this.state.scheduledTime === "") {
                errorMessages.push("Time field is mandatory if date field is filled in");
                hasErrors = true;
            } else {
                const [year, month, day] = this.state.scheduledDate.split('-').map(Number);
                const [hours, minutes] = this.state.scheduledTime.split(':').map(Number);
                const dateTimeInCest = moment.tz({year, month: month - 1, day, hours, minutes}, "Europe/Berlin");


                const now = moment.tz("Europe/Berlin");

                const diff = dateTimeInCest.diff(now);
                const diffHours = diff / (1000 * 60 * 60);

                if (diffHours > 71) {
                    errorMessages.push("You cannot schedule more than 71 hours in advance");
                    hasErrors = true;
                }

                scheduleEmail = dateTimeInCest.toISOString();
            }
        }

        if (hasErrors) {
            this.setState({hasErrors: true, errorMessages: errorMessages})
        } else {
            const payload: UserPayload = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
                phone: phone ? phone : "",
                locale: locale,
                scheduleEmail: scheduleEmail
            }


            postObjectOf(payload, url).then(() => this.setState({
                userCreated: firstName + " " + lastName,
            }));

            this.clearForm()
        }

    }

    render(): ReactElement {
        let label = <label className={"row m-0 pb-1"} htmlFor="createUser">Create user</label>
        const form = <form id={"createUser"} onSubmit={this.handleSubmit}>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.firstName}
                    onChange={(e) => this.handleFirstNameChange(e)}
                    type="text"
                    placeholder="First name"
                    aria-label="First name"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.lastName}
                    onChange={(e) => this.handleLastNameChange(e)}
                    type="text"
                    placeholder="Last name"
                    aria-label="Last name"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.email}
                    onChange={(e) => this.handleEmailChange(e)}
                    type="text"
                    placeholder="Email"
                    aria-label="Email"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.password}
                    onChange={(e) => this.handlePasswordChange(e)}
                    type="text"
                    placeholder="Password"
                    aria-label="Password"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.phone}
                    onChange={(e) => this.handlePhoneChange(e)}
                    type="text"
                    placeholder="Phone"
                    aria-label="Phone"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <select
                    onChange={(e) => this.handleLocaleChange(e)}
                    defaultValue={"swedish"}
                >
                    <option value={"swedish"}>Swedish</option>
                    <option value={"english"}>English</option>
                </select>
            </div>

            <div className={"row m-0 pb-2"}>
                Schedule activate account email (not more than 71 hours in advance). Timezone is CEST.
            </div>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.scheduledDate}
                    onChange={(e) => this.handleScheduledDate(e)}
                    type="date"
                    placeholder="Schedule date CET"
                    aria-label="Date"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.scheduledTime}
                    onChange={(e) => this.handleScheduledTime(e)}
                    type="time"
                    placeholder="Schedule time CEST"
                    aria-label="Time"
                />
            </div>
            <div>
                {
                    this.state.errorMessages.map((err: string, index: number) => {
                        return <div key={"error" + index} className="errorFont row m-0 pb-2">{err}</div>
                    })
                }
            </div>
            <div className={"row m-0 pb-2"}>
                <button type="submit" className="btn btn-tage-default" disabled={this.state.hasErrors}
                        aria-label="Save">Save
                </button>
            </div>
        </form>

        const userCreated = this.state.userCreated !== "" ?
            <div>User {this.state.userCreated} created</div> : <div/>;
        return <div>
            {label}
            {form}
            {userCreated}
        </div>

    };

    private clearForm() {
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
        })
    }


}

export default CreateUserForm;