import React from 'react';

class SupervisorStart extends React.Component {
    render() {
        return (
            <div className={"col text-center mt-3"}>
                <h3>Välkommen</h3>
                <p>Välj vad du vill se i menyn till vänster</p>
            </div>
        );
    }
}

export default SupervisorStart;