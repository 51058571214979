import {UserOldRoles} from "../../../model/UserOldRoles";
import React, {ReactElement} from "react";
import {deleteItem, fetchArrayOf, put} from "../../../../infra/BackendService";
import {Role} from "./Role";
import Selector, {Selectable} from "./Selector";
import {Organisation} from "./Organisation";
import {Right} from "./Right";

interface props {
    user: UserOldRoles
}

interface state {
    assignedRoles: Role[],
    availableRoles: Role[],

    assignedOrganisations: Organisation[],
    availableOrganisations: Organisation[],

    rights: Right[]
}

class ManageUsers extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.assignRole = this.assignRole.bind(this);
        this.removeRole = this.removeRole.bind(this);
        this.assignOrganisations = this.assignOrganisations.bind(this);
        this.removeOrganisations = this.removeOrganisations.bind(this);
        this.state = {
            assignedRoles: [],
            availableRoles: [],
            assignedOrganisations: [],
            availableOrganisations: [],
            rights: []
        }
    }

    render(): ReactElement {

        const roleSelector = <Selector assigned={this.state.assignedRoles}
                                       available={this.state.availableRoles}
                                       assign={this.assignRole}
                                       remove={this.removeRole}
                                       type={"Roles"}/>

        const organisationSelector = <Selector assigned={this.state.assignedOrganisations}
                                               available={this.state.availableOrganisations}
                                               assign={this.assignOrganisations}
                                               remove={this.removeOrganisations}
                                               type={"Organisations"}/>

        const rights = this.state.rights;
        const rightRows = rights.map((right: Right, index: number) => {
                let className: string = ManageUsers.getClassName("col-5", index);
                let rightId = right.id;
                const rightName = right.name;
                const rightType = right.type;
                return <div key={"rightRow " + rightId} className={"row"} aria-label={rightName + " " + rightType}>
                    <div className={className}>
                        {rightName}
                    </div>
                </div>
            }
        )
        const userName = this.props.user.firstName + " " + this.props.user.lastName
        return <div>
            <div className={"row mt-3"}>
                <h1>{userName}</h1>
            </div>
            {roleSelector}
            {organisationSelector}
            <div className={"row mt-3"}>
                <h3>Rights</h3>
            </div>
            {rightRows}
        </div>;
    }

    componentDidMount() {
        this.getUserAffiliations();
    }

    private getUserAffiliations() {
        const user = this.props.user;
        const userName = user.userName;

        this.getAssignedRoles(userName);
        this.getAvailableRoles(userName);

        this.getAssignedOrganisations(userName);
        this.getAvailableOrganisations(userName);

        this.rights(userName);
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        if (prevProps.user !== this.props.user) {
            this.getUserAffiliations();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    private getAssignedRoles(userName: string) {
        const rolesUrl = "/api/v3/backoffice/user/roles/" + userName
        fetchArrayOf<Role>(rolesUrl)
            .then((roles) => {
                this.setState({assignedRoles: roles});
            });
    }

    private getAvailableRoles(userName: string) {
        const potentialRoleUrl = "/api/v3/backoffice/user/roles/available/" + userName
        fetchArrayOf<Role>(potentialRoleUrl)
            .then((roles: Role[]) => {
                this.setState({availableRoles: roles})
            });
    }

    private getAssignedOrganisations(userName: string) {
        const organisationsUrl = "/api/v3/backoffice/user/organisations/" + userName
        fetchArrayOf<Organisation>(organisationsUrl)
            .then((organisations: Organisation[]) => {
                this.setState({assignedOrganisations: organisations});
            });
    }

    private getAvailableOrganisations(userName: string) {
        const organisationsUrl = "/api/v3/backoffice/user/organisations/available/" + userName
        fetchArrayOf<Organisation>(organisationsUrl)
            .then((organisations: Organisation[]) => {
                this.setState({availableOrganisations: organisations})
            });
    }

    private assignRole(selected: Selectable) {
        const user = this.props.user;
        const userName = user.userName;
        const url: string = '/api/v3/backoffice/user/role/' + userName;

        const payload = {
            "id": selected.id
        }
        put(payload, url)
            .then(() => {
                this.getAvailableRoles(userName);
                this.getAssignedRoles(userName);
                this.rights(userName)
            });
    }

    private removeRole(selected: Selectable) {
        const user = this.props.user;
        const userName = user.userName;
        const url: string = '/api/v3/backoffice/user/role/' + userName + "/" + selected.id

        deleteItem(url)
            .then(() => {
                this.getAvailableRoles(userName);
                this.getAssignedRoles(userName);
                this.rights(userName)
            });
    }

    private assignOrganisations(selected: Selectable) {
        const user = this.props.user;
        const userName = user.userName;
        const url: string = '/api/v3/backoffice/user/organisation/' + userName;

        const payload = {
            "id": selected.id
        }
        put(payload, url)
            .then(() => {
                this.getAvailableOrganisations(userName);
                this.getAssignedOrganisations(userName);
            });
    }

    private removeOrganisations(selected: Selectable) {
        const user = this.props.user;
        const userName = user.userName;
        const url: string = '/api/v3/backoffice/user/organisation/' + userName + "/" + selected.id

        deleteItem(url)
            .then(() => {
                this.getAvailableOrganisations(userName);
                this.getAssignedOrganisations(userName);
            });
    }

    private rights(userName: string) {
        const rightsUrl = "/api/v3/backoffice/user/rights/" + userName
        fetchArrayOf<Right>(rightsUrl).then((rights) => {
            this.setState({rights: rights});
        });
    }

    private static getClassName(src: string, index: number) {
        const rowNumber = index + 1;
        if (rowNumber % 2 === 0) {
            return src + " grayed";
        }

        return src;
    }
}

export default ManageUsers;