import {User} from "../components/model/User";

export function hasRight(user: User, rightName: string, rightType: string) {
    let rights = user.rights;
    if (user.rights === undefined) return false;
    for (let right of rights) {
        if ((right.type === rightType && right.name === rightName)) {
            return true;
        }
    }
    return false;
}
