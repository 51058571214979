import React, {Component} from 'react';
import TranslationService from "../../infra/TranslationService";
import "./UserProfile.css"
import {Organisation} from "./Organisation";
import CreatableSelect from "react-select/creatable";
import {OptionType} from "../model/SelectOption";
import {MultiValue} from "react-select";

interface props {
    emailErrorMessage?: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    dateFieldAsDates: boolean;
    dateFormat: string;
    localChanges: boolean;
    language: string;
    availableOrganisations: Organisation[];
    selectedOrganisations: Organisation[];
    handleFirstName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleLastName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handlePhone: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleLanguage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    handleOrganisations: (organisations: Organisation[]) => void;
    handleDateFieldOption: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setLocalChanges: (localChanges: boolean) => void;
}

interface state {
}


class UserProfile extends Component<props, state> {
    constructor(props: props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    render() {
        const options: OptionType[] = this.transform(this.props.availableOrganisations);
        const firstName = TranslationService.translation("userprofileFirstname");
        const profile = TranslationService.translation("userprofileProfile");
        const calendar = TranslationService.translation("userprofileCalendar");
        const dateformat = TranslationService.translation("userprofileDateformat");
        const save = TranslationService.translation("save");

        let disabled = !this.props.localChanges;
        let emailErrorMessage = this.props.emailErrorMessage;
        if (emailErrorMessage !== undefined && emailErrorMessage !== "") {
            disabled = true;
        }
        const saveButton = <button disabled={disabled} type={"submit"}
                                   className={"btn btn-save btn-tage-default"}>{save}
        </button>;

        let languageSelect = this.getLanguageSelect();

        let organisations = this.props.availableOrganisations && this.props.availableOrganisations.length > 0 ?
            <div className={"row pt-1"}>
                <div className={"col-sm-3"}>
                    {TranslationService.translation("userprofileWorkplace")}
                </div>
                <div className={"col-sm-9"}>
                    <CreatableSelect options={options}
                                     isMulti={true}
                                     onChange={this.onChange}
                                     value={this.getCurrentOrganisations()}
                                     aria-label={"select.label"}

                    />
                </div>
            </div> : <div/>;
        return (
            <React.Fragment>
                <div className={"row pt-4"}>
                    <div className={"col text-right"}><b>{profile}</b></div>
                    <div className={"col text-left"}>

                        <div className={"row pt-1"}>
                            <div className={"col-sm-3"}>
                            </div>
                            <div className={"col-sm-9"}>
                                {TranslationService.translation("mandatoryFieldsInForm")}
                            </div>
                        </div>

                        <div className={"row pt-1"}>
                            <div className={"col-sm-3"}>
                                {firstName}
                            </div>
                            <div className={"col-sm-9"}>
                                <input
                                    type={"text"}
                                    size={36}
                                    readOnly={false}
                                    required={true}
                                    value={this.props.firstName}
                                    onChange={(event) => this.props.handleFirstName(event)}
                                />
                            </div>
                        </div>

                        <div className={"row pt-1"}>
                            <div className={"col-sm-3"}>
                                {TranslationService.translation("userprofileLastname")}
                            </div>
                            <div className={"col-sm-9"}>
                                <input
                                    type={"text"}
                                    size={36}
                                    readOnly={false}
                                    required={true}
                                    value={this.props.lastName}
                                    onChange={(event) => this.props.handleLastName(event)}
                                />
                            </div>
                        </div>

                        <div className={"row pt-1"}>
                            <div className={"col-sm-3"}>
                                {TranslationService.translation("userprofilePhone")}
                            </div>
                            <div className={"col-sm-9"}>
                                <input
                                    type={"text"}
                                    size={36}
                                    readOnly={false}
                                    value={this.props.phone}
                                    onChange={(event) => this.props.handlePhone(event)}
                                />
                            </div>
                        </div>

                        <div className={"row pt-1"}>
                            <div className={"col-sm-3"}>
                                {TranslationService.translation("userprofileEmail")}
                            </div>
                            <div className={"col-sm-9"}>
                                <input
                                    type={"text"}
                                    size={36}
                                    readOnly={false}
                                    value={this.props.email}
                                    onChange={(event) => this.props.handleEmail(event)}
                                />
                                <p className={"mb-0"}>
                                    <sub>{emailErrorMessage}</sub>
                                </p>
                            </div>
                        </div>
                        {organisations}
                        {languageSelect}

                        <div className={"row pt-1"}>
                            <div className={"col-sm-3"}>
                                {calendar}
                            </div>
                            <div className={"col-sm-9"}>
                                <input className={"mt-3"}
                                       aria-label={"dateFieldAsDates"}
                                       type={"checkbox"}
                                       checked={this.props.dateFieldAsDates}
                                       onChange={(event) => this.props.handleDateFieldOption(event)}/>
                            </div>
                        </div>

                        <div className={"row pt-1"}>
                            <div className={"col-sm-3"}>
                                {dateformat}
                            </div>
                            <div className={"col-sm-9"}>
                                <div>{this.props.dateFormat}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col"}/>
                    <div className={"col"}>
                        <div className={"row"}>
                            <div className={"col-sm-10 text-right"}>
                            </div>
                            <div className={"col-sm-2"}>
                                {saveButton}
                            </div>
                        </div>
                    </div>
                </div>


            </React.Fragment>

        );
    }

    private onChange(selectedOptions: MultiValue<OptionType>) {
        let selectedOrganisations: Organisation[] = this.transformToOrganisations(selectedOptions);
        this.props.handleOrganisations(selectedOrganisations);

    }

    private getCurrentOrganisations(): OptionType[] {
        return this.transform(this.props.selectedOrganisations);
    }

    private transform(organisations: Organisation[]): OptionType[] {
        let currentOptions: OptionType[] = [];
        for (let organisation of organisations) {
            let currentOption = {label: organisation.organisationName, value: organisation.organisationId};
            currentOptions.push(currentOption);
        }
        return currentOptions;
    }

    private transformToOrganisations(options: MultiValue<OptionType>): Organisation[] {
        let organisations: Organisation[] = [];
        options.forEach(option => {
            let organisation: Organisation = {organisationId: option.value, organisationName: option.label};
            organisations.push(organisation);
        })
        return organisations;
    }


    private getLanguageSelect() {
        const currentLanguage = this.props.language;
        const translatedLabel = TranslationService.translation("userprofileLanguage");

        return <div className={"row pt-1"}>
            <div className={"col-sm-3"}>
                {translatedLabel}
            </div>
            <div className={"col-sm-9"}>
                <select
                    onChange={(event) => this.props.handleLanguage(event)}
                    defaultValue={currentLanguage} aria-label={"languages"}
                >
                    <option value={"swedish"}>Svenska</option>
                    <option value={"english"}>English</option>
                </select>
            </div>
        </div>;
    }
}

export default UserProfile;
