import React from 'react';
import "./CapioDeviation.css"
import CopyImage from "../../../images/copy-225-225.png";
import CapioDeviationEmailModal from "./CapioDeviationEmailModal";
import CapioEvaluationModal from "./CapioEvaluationModal";
import {isLocalhost} from "../../../infra/Utils";
import EditHeadlineElement from "./EditHeadlineElement";
import {getSecurityToken} from "../../../infra/SecurityToken";
// import CapioForwardEmailModal from "./CapioForwardEmailModal";

const action = "capio-deviation";
const version = "1";
const limit = "100";
const GET_DEVIATIONS_API = process.env.REACT_APP_LOCALHOST + "/api/v1/supervisor/deviations/" + action + "/" + version + "/" + limit + "/"
const POST_DEVIATION_API = process.env.REACT_APP_LOCALHOST + "/api/v1/supervisor/deviation"

const emptyStatusSelect = "-- Välj status --";

export interface Deviation {
    sequenceNumber: string,
    eventId: string,
    displayName: string,
    displayDate: string,
    displayType: string,
    displayStatus: string,
    latestComment: string,
    status: string,
    details: DeviationDetails[],
    events: DeviationEvent[]
}

interface DeviationDetails {
    headline: string,
    value: string
}

export interface DeviationMaintenancePayload {
    eventId: string;
    field: string;
    value: string
}

export interface DeviationEvent {
    timeStamp: string,
    who: string,
    type: string,
    typeTranslated: string,
    value: string
}

export const sortByChosenField = (deviations: Deviation[], sortBy: string, orderFlip: number) => {
    let eventArray = deviations;

    switch (sortBy) {
        case "sequenceNumber" :
            eventArray.sort(compareSequenceNumber);
            break;
        case "date" :
            eventArray.sort(compareDate);
            break;
        case "reporter" :
            eventArray.sort(compareName);
            break;
        case "type" :
            eventArray.sort(compareType);
            break;
        case "status" :
            eventArray.sort(compareStatus);
            break;
        default :
            break;

    }
    eventArray.sort()
    return deviations;

    function compare(a: string, b: string) {
        let comparison = 0;
        if (orderFlip === 0 ? (a > b) : (a < b)) {
            comparison = 1;
        } else if (orderFlip === 0 ? (a < b) : (a > b)) {
            comparison = -1;
        }
        return comparison;
    }

    function compareSequenceNumber(deviationA: Deviation, deviationB: Deviation) {
        const sequenceNumberA = deviationA.sequenceNumber;
        const sequenceNumberB = deviationB.sequenceNumber;

        return compare(sequenceNumberA, sequenceNumberB);
    }

    function compareType(deviationA: Deviation, deviationB: Deviation) {
        const deviationTypeA = deviationA.displayType;
        const deviationTypeB = deviationB.displayType;

        return compare(deviationTypeA, deviationTypeB);
    }

    function compareName(deviationA: Deviation, deviationB: Deviation) {
        const nameA = deviationA.displayName;
        const nameB = deviationB.displayName;

        return compare(nameA, nameB);
    }

    function compareStatus(deviationA: Deviation, deviationB: Deviation) {
        const statusA = deviationA.status;
        const statusB = deviationB.status;

        return compare(statusA, statusB);
    }

    function compareDate(deviationA: Deviation, deviationB: Deviation) {
        const dateA = deviationA.displayDate;
        const dateB = deviationB.displayDate;

        let comparison = 0;
        if (orderFlip === 0 ? (dateA < dateB) : (dateA > dateB)) {
            comparison = 1;
        } else if (orderFlip === 0 ? (dateA > dateB) : (dateA < dateB)) {
            comparison = -1;
        }
        return comparison;
    }
}

interface props {
}

interface state {
    deviations: Deviation[],
    currentEventId: string,
    sortButtonType: number,
    sortButtonStatus: number,
    sortButtonDate: number,
    sortButtonName: number,
    sortButtonSequence: number,
    showManipulateEvent: boolean,
    commentField: string,
    sortReceived: boolean,
    sortPending: boolean,
    sortRedirected: boolean,
    sortAnswered: boolean,
    sortCompleted: boolean,
    sortAll: boolean,
    showSendEmailDialog: boolean,
    showEvaluationDialog: boolean,
    showForwardDialog: boolean
}

class CapioDeviation extends React.Component <props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.appendEvent = this.appendEvent.bind(this);
        this.updateType = this.updateType.bind(this);
        this.flipEmailModalVisibility = this.flipEmailModalVisibility.bind(this);
        this.updateDeviations = this.updateDeviations.bind(this);

        this.state = {
            deviations: [],
            currentEventId: "",
            sortButtonType: 0,
            sortButtonStatus: 0,
            sortButtonDate: 0,
            sortButtonName: 0,
            sortButtonSequence: 0,
            showManipulateEvent: false,
            commentField: "",
            sortReceived: false,
            sortPending: false,
            sortRedirected: false,
            sortAnswered: false,
            sortCompleted: false,
            sortAll: true,
            showSendEmailDialog: false,
            showEvaluationDialog: false,
            showForwardDialog: false
        }
    }

    componentDidMount() {
        this.updateState();
    }

    private updateState() {
        let filterString = this.getFilterString();

        fetch(GET_DEVIATIONS_API + "?" + filterString, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({deviations: data});
            })
            .catch(console.log);
    }

    render() {
        return (
            <div className={"col pr-5 pt-3"}>
                <div className={"row"}>
                    <div className={"col-4"}>
                        <div className={"row mb-3"}>
                            <div className={"col-6"}>
                                <h6 className={"sort-header pt-1 pb-1"}>Visa</h6>
                                <div className={"row m-0 p-0"}>
                                    <div className={"col-10 pl-0"}>
                                        Mottagna
                                    </div>
                                    <div className={"col-2"}>
                                        <input value={"received"}
                                               checked={this.state.sortReceived}
                                               onChange={(event => this.handleViewChange(event))}
                                               type={"checkbox"}/>
                                    </div>
                                </div>
                                <div className={"row m-0 p-0"}>
                                    <div className={"col-10 pl-0"}>
                                        Påbörjade
                                    </div>
                                    <div className={"col-2"}>
                                        <input value={"pending"}
                                               checked={this.state.sortPending}
                                               onChange={(event => this.handleViewChange(event))}
                                               type={"checkbox"}/>
                                    </div>
                                </div>
                                <div className={"row m-0 p-0"}>
                                    <div className={"col-10 pl-0"}>
                                        Väntar på svar
                                    </div>
                                    <div className={"col-2"}>
                                        <input value={"redirected"}
                                               checked={this.state.sortRedirected}
                                               onChange={(event => this.handleViewChange(event))}
                                               type={"checkbox"}/>
                                    </div>
                                </div>
                                <div className={"row m-0 p-0"}>
                                    <div className={"col-10 pl-0"}>
                                        Besvarade
                                    </div>
                                    <div className={"col-2"}>
                                        <input value={"answered"}
                                               checked={this.state.sortAnswered}
                                               onChange={(event => this.handleViewChange(event))}
                                               type={"checkbox"}/>
                                    </div>
                                </div>
                                <div className={"row m-0 p-0"}>
                                    <div className={"col-10 pl-0"}>
                                        Avslutade
                                    </div>
                                    <div className={"col-2"}>
                                        <input value={"completed"}
                                               checked={this.state.sortCompleted}
                                               onChange={(event => this.handleViewChange(event))}
                                               type={"checkbox"}/>
                                    </div>
                                </div>
                                <div className={"row m-0 p-0"}>
                                    <div className={"col-10 pl-0"}>
                                        Alla
                                    </div>
                                    <div className={"col-2"}>
                                        <input value={"all"}
                                               checked={this.state.sortAll}
                                               onChange={(event => this.handleViewChange(event))}
                                               type={"checkbox"}/>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-6"}>
                            </div>
                        </div>
                        <div className={"deviation-selector"}>
                            <div className={"row m-0 sort-header"}>
                                <div className={"col-3 m-0 p-0"}>
                                    <h6 className={"mb-1"}>Löpnummer
                                        <button id={"sort-sequenceNumber"}
                                                className={"btn btn-deviation-sort ml-1"}
                                                value="sequenceNumber"
                                                onClick={e => this.sortBy(e, this.state.sortButtonSequence)}>{this.state.sortButtonSequence === 0 ? "▲" : "▼"}
                                        </button>
                                    </h6>
                                </div>
                                <div className={"col-2 m-0 p-0"}>
                                    <h6 className={"mb-1"}>Datum
                                        <button id={"sort-date"}
                                                className={"btn btn-deviation-sort ml-1"}
                                                value="date"
                                                onClick={e => this.sortBy(e, this.state.sortButtonDate)}>{this.state.sortButtonDate === 0 ? "▲" : "▼"}
                                        </button>
                                    </h6>
                                </div>
                                <div className={"col-3 m-0 p-0"}>
                                    <h6 className={"mb-1"}>Rapportör
                                        <button id={"sort-reporter"}
                                                className={"btn btn-deviation-sort ml-1"}
                                                value="reporter"
                                                onClick={e => this.sortBy(e, this.state.sortButtonName)}>{this.state.sortButtonName === 0 ? "▲" : "▼"}
                                        </button>
                                    </h6>
                                </div>
                                <div className={"col-2 m-0 p-0"}>
                                    <h6 className={"mb-1"}>Typ
                                        <button id={"sort-type"}
                                                className={"btn btn-deviation-sort ml-1"}
                                                value="type"
                                                onClick={e => this.sortBy(e, this.state.sortButtonType)}>{this.state.sortButtonType === 0 ? "▲" : "▼"}
                                        </button>
                                    </h6>
                                </div>
                                <div className={"col-2 m-0 p-0"}>
                                    <h6 className={"mb-1 ml-2"}>Status
                                        <button id={"sort-status"}
                                                className={"btn btn-deviation-sort ml-1"}
                                                value="status"
                                                onClick={e => this.sortBy(e, this.state.sortButtonStatus)}>{this.state.sortButtonStatus === 0 ? "▲" : "▼"}
                                        </button>
                                    </h6>
                                </div>
                            </div>
                            {

                                this.state.deviations.map(deviation => {
                                    return <div className={"pl-1 deviation-item row ml-0"}
                                                id={deviation.eventId}
                                                key={deviation.eventId}
                                                onClick={() => this.onDeviationClick(deviation.eventId)}>
                                        <div className={"col-2 p-0"}>{deviation.sequenceNumber}</div>
                                        <div className={"col-2 p-0"}>{deviation.displayDate}</div>
                                        <div className={"col-3 p-0"}>{deviation.displayName}</div>
                                        <div
                                            className={"col-3 p-0"}>{CapioDeviation.stripParenthesis(deviation.displayType)}</div>
                                        <div className={"col-2 p-0"}>
                                            <div className={"ml-2"}>{deviation.displayStatus}</div>
                                        </div>
                                    </div>;
                                })

                            }
                        </div>
                        {
                            this.state.showManipulateEvent ?
                                <React.Fragment>
                                    <div className={"row m-0 pt-2"}>
                                        <div className={"col p-0 m-0"}>
                                            <div className={"d-flex justify-content-end"}>
                                                Status:
                                                <select id={"status-selector"}
                                                        onChange={event => this.handleStatusUpdate(event)}
                                                        className={"ml-1"}>
                                                    <option value={"none"}>{emptyStatusSelect}</option>
                                                    <option value={"received"}>Mottagen</option>
                                                    <option value={"pending"}>Påbörjad</option>
                                                    <option value={"redirected"}>Väntar på svar</option>
                                                    <option value={"answered"}>Besvarad</option>
                                                    <option value={"completed"}>Avslutad</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"comment-container mt-2"}>
                                        <textarea className={"form-control comment-textarea"}
                                                  value={this.state.commentField}
                                                  onChange={e => this.updateCommentArea(e)}
                                                  name={"comment"}
                                                  rows={4}/>
                                    </div>

                                    <div className={"m-0 p-0 row"}>
                                        <div className={"col mt-2 p-0"}>
                                            <div className={"d-flex justify-content-end"}>
                                                <button className={"btn btn-comment"}
                                                        onClick={(event) => this.handleSubmitComment(event)}>Kommentera
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                                :
                                ""
                        }
                    </div>
                    <div className={"col-8"}>
                        <div className={"deviation-panel"}>
                            {
                                this.drawWantedElement()
                            }
                        </div>
                        <h6 className={"mt-2"}>Händelse logg</h6>
                        <div className={"deviation-events"}>
                            {
                                this.drawEvents()
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private drawWantedElement() {
        let currentEventId = this.state.currentEventId;
        let deviation: Deviation | undefined = this.currentDeviation(currentEventId);
        if (deviation !== undefined) {
            /*
            {
                this.state.showForwardDialog ? <CapioForwardEmailModal/> : ""
            }
            */
            let sendEmailModal: React.ReactFragment = "";
            let showSendEmailDialog = this.state.showSendEmailDialog;
            if (showSendEmailDialog) {
                sendEmailModal = <CapioDeviationEmailModal updateParent={this.updateDeviations}
                                                           flipVisibility={this.flipEmailModalVisibility}
                                                           deviation={deviation}
                                                           eventId={deviation.eventId}
                                                           displayName={deviation.displayName}/>
            }

            let evaluateDeviation: React.ReactFragment = "";
            if (this.state.showEvaluationDialog) {
                evaluateDeviation =
                    <CapioEvaluationModal parentId={deviation.eventId}
                                          flipVisibility={() => this.flipEvaluationVisibility()}
                                          appendEvent={this.appendEvent}
                    />
            }

            let divList = [];
            const details = deviation.details;
            for (let i = 0; i < details.length; i++) {
                const headline = details[i].headline;
                const value = details[i].value;
                divList.push(<div key={headline} className={"ml-1 container p-0"}>
                    <div className={"row m-0 p-0"}>
                        <div>
                            <h5 className={"mt-0"}>{headline}</h5>
                        </div>
                        <EditHeadlineElement updateParent={this.updateDeviations}
                                             sequenceNumber={deviation.sequenceNumber}
                                             updateType={this.updateType}
                                             eventId={currentEventId}
                                             headline={headline}/>
                    </div>

                    <div className={"row m-0 pb-3"}>
                        {value}
                    </div>

                </div>)
            }

            let replyDeviationAuthorButton = <div/>
            if (isLocalhost()) {
                replyDeviationAuthorButton = <button className={"btn btn-send-mail-deviation"}
                                                     onClick={() => this.sendEmailToDeviationAuthor()}>
                    Svara avsändaren
                </button>;
            }

            return (<div className={"pt-2 pl-1"}>
                <div id={"deviation-panel-events"}>
                    {sendEmailModal}
                    {replyDeviationAuthorButton}
                    <button className={"btn btn-copy-deviation"} onClick={this.copyDeviationToClipboard}>
                        <img alt={"Kopiera rapporten till klippminne"} height={25} src={CopyImage}/>
                    </button>
                    <button className={"btn btn-evaluate-deviation"} onClick={() => this.flipEvaluationVisibility()}>
                        Riskbedömning
                    </button>
                    {evaluateDeviation}

                    {divList}
                </div>
            </div>);
        } else {
            return <div/>
        }
    }

    private drawEvents() {
        let deviation = this.state.deviations.find(deviation => deviation.eventId === this.state.currentEventId)
        let events: DeviationEvent[] = [];
        if (deviation !== undefined && deviation.events !== undefined) {
            events = deviation.events;
        }

        let timeStampColumn = "col-2";
        let nameColumn = "col-2";
        let typeColumn = "col-2"
        let valueColumn = "col-6";
        return <div>
            {
                events.map((e, i) => {
                    let flipper = "";
                    if (i % 2 !== 0) {
                        flipper = " deviation-event-grayed"
                    }

                    return (
                        <div key={e.timeStamp + e.value + i} className={"row m-0" + flipper}>
                            <div className={timeStampColumn + " m-0 p-0"}>
                                <div className={"col m-0 p-0"}>{e.timeStamp}</div>
                            </div>
                            <div className={nameColumn + " m-0 p-0"}>
                                <div className={"col m-0 p-0"}>{e.who}</div>
                            </div>
                            <div className={typeColumn + " m-0 p-0"}>
                                <div className={"col m-0 p-0"}>{e.typeTranslated}</div>
                            </div>
                            <div className={valueColumn + " pl-1"}>
                                {e.value}
                            </div>
                        </div>
                    );
                })
            }
        </div>
    }

    private onDeviationClick(eventId: string) {
        let allDeviations = document.getElementsByClassName("deviation-item");

        for (let i = 0; i < allDeviations.length; i++) {
            allDeviations[i].classList.remove("active-deviation");
        }

        let current = document.getElementById(eventId);
        if (current !== null) {
            current.classList.add("active-deviation");
        }

        let latestComment = this.getLatestComment(eventId);

        this.setState({currentEventId: eventId});
        this.setState({showManipulateEvent: true});
        if (latestComment !== undefined) {
            this.setState({commentField: latestComment});
        }
    }

    private sortBy = (e: React.MouseEvent<HTMLButtonElement>, buttonState: number) => {
        let deviations = sortByChosenField(this.state.deviations, e.currentTarget.value, buttonState)
        this.setState({deviations})


        CapioDeviation.setSortButtonActive(e.currentTarget.value);
        this.setSortingOrderFlip(e.currentTarget.value, buttonState);
    }

    private static setSortButtonActive(e: string) {
        let allSortButtons = document.getElementsByClassName("btn-deviation-sort");
        for (let i = 0; i < allSortButtons.length; i++) {
            allSortButtons[i].classList.remove("sort-active")
        }

        let elementId = "sort-" + e;
        let wanted = document.getElementById(elementId);
        if (wanted !== null) {
            wanted.classList.add("sort-active")
        }
    }

    private setSortingOrderFlip(value: string, buttonState: number) {
        switch (value) {
            case "date" :
                this.setState({sortButtonType: 0, sortButtonStatus: 0, sortButtonName: 0, sortButtonSequence: 0})
                buttonState === 1 ? this.setState({sortButtonDate: 0}) : this.setState({sortButtonDate: 1})
                break;
            case "reporter" :
                this.setState({sortButtonType: 0, sortButtonStatus: 0, sortButtonDate: 0, sortButtonSequence: 0})
                buttonState === 1 ? this.setState({sortButtonName: 0}) : this.setState({sortButtonName: 1})
                break;
            case "type" :
                this.setState({sortButtonName: 0, sortButtonStatus: 0, sortButtonDate: 0, sortButtonSequence: 0})
                buttonState === 1 ? this.setState({sortButtonType: 0}) : this.setState({sortButtonType: 1})
                break;
            case "status" :
                this.setState({sortButtonName: 0, sortButtonType: 0, sortButtonDate: 0, sortButtonSequence: 0})
                buttonState === 1 ? this.setState({sortButtonStatus: 0}) : this.setState({sortButtonStatus: 1})
                break;
            case "sequenceNumber" :
                this.setState({sortButtonName: 0, sortButtonType: 0, sortButtonDate: 0, sortButtonStatus: 0})
                buttonState === 1 ? this.setState({sortButtonSequence: 0}) : this.setState({sortButtonSequence: 1})
                break;
            default :
                break;

        }
    }

    private static stripParenthesis(displayType: string) {
        let firstParenthesis = displayType.indexOf("(");
        if (firstParenthesis === -1) {
            return displayType
        }
        return displayType.substring(0, firstParenthesis);
    }

    private handleViewChange(event: React.ChangeEvent<HTMLInputElement>) {
        switch (event.currentTarget.value) {
            case "all" :
                this.setState({sortAll: !this.state.sortAll})
                break;
            case "received" :
                this.setState({sortReceived: !this.state.sortReceived})
                break;
            case "pending" :
                this.setState({sortPending: !this.state.sortPending})
                break;
            case "redirected" :
                this.setState({sortRedirected: !this.state.sortRedirected})
                break;
            case "completed" :
                this.setState({sortCompleted: !this.state.sortCompleted})
                break;
            case "answered" :
                this.setState({sortAnswered: !this.state.sortAnswered})
                break;
            default :
                break;
        }

        setTimeout(() => { //setstate i switchen är async..... gör promise?
            this.updateState();
        }, 50);
    }

    private handleStatusUpdate(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        let payload: DeviationMaintenancePayload = {
            "eventId": this.state.currentEventId,
            "field": "capio-deviation-status",
            "value": event.currentTarget.value
        };
        this.postAndUpdateState(payload);
        this.resetSelect();
    }

    resetSelect() {
        const statusSelector = document.getElementById("status-selector");
        if (statusSelector instanceof HTMLSelectElement) {
            statusSelector.value = "none";
        }
    }

    private handleSubmitComment(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        let payload: DeviationMaintenancePayload = {
            "eventId": this.state.currentEventId,
            "field": "capio-deviation-comment",
            "value": this.state.commentField
        };
        this.postAndUpdateState(payload);

        this.setState({commentField: ""})
    }

    private updateCommentArea(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({commentField: e.currentTarget.value});
    }

    private postAndUpdateState(payload: DeviationMaintenancePayload) {
        fetch(POST_DEVIATION_API, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include',
            method: "POST",
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then((data: Deviation) => {
                let updated = this.state.deviations;
                const wantedIndex = updated.findIndex(deviation => deviation.eventId === data.eventId)
                updated[wantedIndex] = data;
                this.setState({deviations: updated});
                this.updateState();
            })
            .catch(console.log);
    }

    private getFilterString() {
        let filterString = "";
        filterString += this.state.sortReceived ? filterString.length === 0 ? "filter=received" : "&filter=received" : "";
        filterString += this.state.sortPending ? filterString.length === 0 ? "filter=pending" : "&filter=pending" : "";
        filterString += this.state.sortRedirected ? filterString.length === 0 ? "filter=redirected" : "&filter=redirected" : "";
        filterString += this.state.sortCompleted ? filterString.length === 0 ? "filter=completed" : "&filter=completed" : "";
        filterString += this.state.sortAnswered ? filterString.length === 0 ? "filter=answered" : "&filter=answered" : "";
        filterString += this.state.sortAll ? filterString.length === 0 ? "filter=all" : "&filter=all" : "";

        return filterString;
    }

    copyDeviationToClipboard = () => {
        let element = document.getElementById("deviation-panel-events")
        if (element !== null) {
            let elementText = element.textContent;
            if (elementText !== null) {
                navigator.clipboard.writeText(elementText).then(r => (console.log("copied to clipboard:", r)));
            }
        }
    }

    private appendEvent(parentId: string, event: DeviationEvent): void {
        const updated = this.state.deviations;

        const wantedIndex = updated.findIndex(deviation => deviation.eventId === parentId)
        const deviationEvents = updated[wantedIndex].events;
        deviationEvents.push(event);

        this.setState({
            deviations: updated
        });
    }

    private updateDeviations() {
        this.updateState();
    }

    private flipEmailModalVisibility() {
        this.setState({showSendEmailDialog: !this.state.showSendEmailDialog});

    }

    private getLatestComment(eventId: string) {
        let wanted = this.state.deviations.find(e => e.eventId === eventId);
        if (wanted !== null && wanted !== undefined) {
            return wanted.latestComment;
        }
    }

    private currentDeviation(currentEventId: string): Deviation | undefined {
        return this.state.deviations.find(dev => dev.eventId === currentEventId);
    }

    private sendEmailToDeviationAuthor() {
        this.flipEmailModalVisibility();
    }

    private flipEvaluationVisibility() {
        this.setState({showEvaluationDialog: !this.state.showEvaluationDialog});
    }

    private updateType(sequenceNumber: string, updatedValue: string) {
        let deviation = this.state.deviations.find((dev) => dev.sequenceNumber === sequenceNumber);
        let detail = deviation?.details.find(det => det.headline === "Typ av avvikelse");
        if (detail) {
            detail.value = updatedValue;
        }

        this.setState({deviations: this.state.deviations});
    }
}

export default CapioDeviation;
