import React from 'react';
import "./PharmacyOrder.css";
import {Organisation} from "../model/Organisation";
import {EventBackendService} from "../register/v2/EventBackendService";
import {BackendContext} from "../../infra/BackendContext";
import {Drug} from "./models/Models";
import {uuidv4} from "../register/v2/Uuid";

interface Order {
    name: string,
    amount: number
}

interface props {
    organisation: Organisation
}

interface state {
    drugs: Drug[]
    order: Order[],
    currentAmount: number,
    currentDrug: string,
    oldOrders: { ordId: string, order: Order[], timestamp: string }[],
    filledOrders: any[],
    showFilledOrders: boolean
}

class PharmacyOrder extends React.Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {
            drugs: [],
            order: [],
            currentAmount: 0,
            currentDrug: "-- Välj Läkemedel --",
            oldOrders: [],
            filledOrders: [
                {name: "fentanyl @ 50 mikrog/ml, 2 ml", amount: "12"},
                {name: "oxazepam 10 mg, tablett", amount: "11"},
                {name: "fentanyl @ 50 mikrog/ml, 2 ml", amount: "5"},
                {name: "morfin @ 10 mg/ml, 1 ml", amount: "39"},
            ],
            showFilledOrders: false
        }
    }

    async componentDidMount() {
        let organisation: string = this.props.organisation.organisationId;

        const drugsPath: string = `/api/v1/knarkbok/drugs/${organisation}`
        const drugUrl: string = EventBackendService.getUrl2(drugsPath);
        const drugsResponse: any = await this.context.get(drugUrl, {
            success: "",
            failure: ""
        }) as any;

        if (drugsResponse !== undefined && drugsResponse.data !== undefined) {
            this.setState({
                drugs: drugsResponse.data,
            });
        }
    }

    render() {
        return <div aria-label={'pharmacy-order'}
                    className={"container"}>
            <div className={"row"}>
                <div className={"col"}>
                    <div className={"pharmacy-container pt-3"}>
                        <div className={"row pb-4"}>
                            <h1>Detta fungerar inte för tillfället, enbart för visualisering</h1>
                        </div>
                        <div className={"row"}>
                            <div className={"col pl-4"}>
                                <h3>
                                    Lägg till beställning från apoteket:
                                </h3>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col"}>
                                <div className={"pharmacy-container-row pl-2 pt-2 pb-2"}>
                                    <div className={"row m-0 pl-2 pt-2"}>
                                        <select defaultValue={"-- Välj Läkemedel --"} value={this.state.currentDrug}
                                                onChange={(e) => this.changeThing(e)}>
                                            <option disabled={true}>-- Välj Läkemedel --</option>
                                            {
                                                this.state.drugs.map((drug) => {
                                                    return <option value={drug.id?.id}>
                                                        {drug.name}
                                                    </option>
                                                })
                                            }
                                        </select>
                                        <input className={"ml-4"} type={"text"} size={2}
                                               value={this.state.currentAmount}
                                               onChange={(e) => this.updateCurrentAmount(e)}/>
                                        <div className={"col"}>
                                            <button className={"btn btn-tage-default"}
                                                    disabled={!this.state.currentAmount || (!this.state.currentDrug || this.state.currentDrug === "--välj--")}
                                                    onClick={() => this.incrementOrderRows()}>+
                                            </button>
                                        </div>
                                    </div>

                                    {
                                        this.state.order.map((row) => {
                                            return <div className={"row m-0 pl-2 pt-1"}>
                                                {row.name} : {row.amount}
                                            </div>
                                        })
                                    }

                                    <div className={"pharmacy-container-bottom-row pl-2 pt-2 pb-2"}>
                                        <div className={"row"}>
                                            <div className={"col"}>
                                            </div>

                                            <div className={"col text-right pr-4"}>
                                                <button onClick={() => this.makeOrder()}
                                                        className={"btn btn-tage-default"}>Beställ
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={"row pt-5"}>
                            <div className={"col pl-4"}>
                                <h3>
                                    Olevererade beställningar:
                                </h3>
                            </div>
                        </div>


                        {
                            this.state.oldOrders.map((ordr) => {
                                return <div>
                                    <div className={"row pl-4 pt-4"}>
                                        <h6>Beställning: {ordr.timestamp}</h6>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <div className={"pharmacy-container-row pl-2 pt-2 pb-2"}>
                                                {
                                                    ordr.order.map((order) => {
                                                        return <div className={"row m-0 pl-2 pt-1"}>
                                                            {order.name} : {order.amount}
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                        <hr className={"pt-5"}/>

                        <div className={"row pt-5"}>
                            <div className={"col pl-4"}>
                                <button onClick={() => this.flipShowFilledOrders()}
                                        className={"btn btn-tage-default"}>Se samtliga beställningar
                                </button>
                            </div>
                        </div>

                        {
                            this.state.showFilledOrders ? this.state.filledOrders.map((filled) => {
                                return <div className={"pharmacy-container-row pl-2 pt-2 pb-2 mt-3"}>
                                    <div className={"row m-0 pl-2 pt-1"}>
                                        {filled.name} : {filled.amount}
                                    </div>
                                </div>
                            }) : ""

                        }

                    </div>
                </div>
            </div>
        </div>;
    }

    private changeThing(e: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({currentDrug: e.currentTarget.value})
    }

    private incrementOrderRows() {
        const currentDrugName = this.state.drugs.find((d) => d.id?.id === +this.state.currentDrug)?.name

        const orderEntry: Order = {
            name: currentDrugName ? currentDrugName : " ",
            amount: this.state.currentAmount
        }

        this.state.order.push(orderEntry);

        this.setState({order: this.state.order, currentDrug: "-- Välj Läkemedel --", currentAmount: 0})
    }

    private updateCurrentAmount(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({currentAmount: +e.currentTarget.value})
    }

    private makeOrder() {
        let timestamp: Date = new Date();

        const order = {
            ordId: uuidv4(),
            timestamp: timestamp.toLocaleDateString() + ' ' + timestamp.toLocaleTimeString(),
            order: this.state.order
        }
        this.state.oldOrders.push(order);

        this.setState({
            order: [],
            currentDrug: "-- Välj Läkemedel --",
            currentAmount: 0,
            oldOrders: this.state.oldOrders
        })
    }

    private flipShowFilledOrders() {
        this.setState({showFilledOrders: !this.state.showFilledOrders});
    }
}

export default PharmacyOrder;
