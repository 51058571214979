import React from 'react';
import {ArchivedDrugBook, BinderId} from "./models/Models";
import "jspdf-autotable";
import {BackendContext, BackendInterface} from "../../infra/BackendContext";
import {EventBackendService} from "../register/v2/EventBackendService";
import {jsPDF} from "jspdf";
import autoTable from "jspdf-autotable";

interface Event {
    drug: string
    user: string,
    event: string,
    date: string,
    specification: string,
    amount: string,
    balance: string,
    comment: string
}

interface props {
    archivedDrugBook: ArchivedDrugBook,
    closeFocus: () => void,
}

interface state {
    events: Event[]
}

class ArchivedDrugBookEventFocus extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: any) {
        super(props);

        this.state = {
            events: []
        }
    }

    async componentDidMount() {
        const binderId: BinderId = this.props.archivedDrugBook.binderId;
        const path: string = `/api/v1/knarkbok/druglord/archiveed/details/${binderId.id}`
        const url: string = EventBackendService.getUrl2(path);
        const response: any = await this.context.get(url, {
            success: "",
            failure: ""
        }) as any;

        if (response !== undefined && response.data !== undefined) {
            const events: Event[] = response.data as Event[];
            this.setState({
                events: events
            });
        }
    }

    render(): React.JSX.Element {
        const grid: React.JSX.Element[] = this.getGrid();
        const modalContent: React.JSX.Element = this.getModalContent(grid);

        return <div>
            <aside className="knarkbok-modal-background2"
                   onClick={() => this.props.closeFocus()}/>
            <div className={"knarkbok-modal2 pr-3 justify-content-center pb-5"}>
                {modalContent}
            </div>
        </div>;
    }

    private getGrid(): React.JSX.Element[] {
        const grid: React.JSX.Element[] = [];
        const events: Event[] = this.state.events;
        if (events) {
            grid.push(<div className={"row m-0 p-0"}
                           key={"header"}>
                <div className={"col-2"}>Läkemedel</div>
                <div className={"col-2"}>Datum</div>
                <div className={"col-2"}>Användare</div>
                <div className={"col-2"}>Händelse</div>
                <div className={"col-1"}>Specifikation</div>
                <div className={"col-1"}>Antal</div>
                <div className={"col-1"}>Nytt saldo</div>
                <div className={"col-1"}>Kommentar</div>
            </div>)
        }

        events.forEach((event: Event, index: number) => {
            let grayed: string;
            if (index % 2 === 0) {
                grayed = " alert-secondary ";
            } else {
                grayed = "";
            }

            let comment: string = event.comment;
            if (comment !== undefined) {
                comment = comment.substr(0, 10);
            }

            grid.push(<div className={"row m-0 p-0 " + grayed}
                           key={"header"}>
                <div className={"col-2"}>{event.drug}</div>
                <div className={"col-2"}>{event.date}</div>
                <div className={"col-2"}>{event.user}</div>
                <div className={"col-2"}>{event.event}</div>
                <div className={"col-1"}>{event.specification}</div>
                <div className={"col-1"}>{event.amount}</div>
                <div className={"col-1"}>{event.balance}</div>
                <div className={"col-1"}>{comment}</div>
            </div>)
        });

        return grid;
    }

    private getModalContent(grid: React.JSX.Element[]) {
        const headLine: string = this.getHeadline();

        return <div className={"container2 m-0 pl-0 pr-0"}>
            <div className={"row m-0 pt-2 pb-2"}>
                <div>
                    <h5 aria-label={'headline'}
                        className={"mt-0"}>
                        {headLine}
                    </h5></div>
                <div className={"col m-0 pr-3 justify-content-end text-right"}>
                    <button aria-label={'close'}
                            className={"btn btn-close-focus btn-tage-default-cancel"}
                            onClick={() => this.props.closeFocus()}>
                        X
                    </button>
                </div>
            </div>
            <div className={"container2 m-0 pt-3 pl-0 kb-modal-scrollable"}>
                {grid}
            </div>
            <div className={"row m-0 pt-4 pr-3"}>
                <div className={"col m-0 p-0  justify-content-end text-right"}>
                    <button aria-label={'print'}
                            onClick={() => this.createKbReport()}
                            className={"btn btn-tage-default-cancel"}>
                        Skriv ut
                    </button>
                </div>
            </div>
        </div>;
    }

    private createKbReport() {
        const doc = new jsPDF('l');

        const headLine: string = this.getHeadline();
        doc.text(headLine, 14, 10);

        const body: string[][] = [];
        let events = this.state.events;
        events.forEach((event) => {
            body.push([event.drug, event.date, event.user, event.event, event.specification, event.amount, event.balance, event.comment])
        });

        autoTable(doc, {
            headStyles: {fillColor: [82, 206, 255]},
            head: [['Läkemedel', 'Datum', 'Användare', 'Händelse', 'Specifikation', 'Antal', 'Nytt saldo', 'Kommentar']],
            body: body,
        });


        let cleanedHeadLine: string = headLine.replace(', Aktiv: ', '-');
        cleanedHeadLine = cleanedHeadLine.replace('Förbrukningsjournal: ', 'Förbrukningsjournal-');
        cleanedHeadLine = cleanedHeadLine.replace(' -- ', '-');
        cleanedHeadLine = cleanedHeadLine.replace(/\s/g, '-');
        const date = new Date().toISOString();

        doc.save(`${cleanedHeadLine}-${date}.pdf`);
    }

    private getHeadline(): string {
        const archivedDrugBook: ArchivedDrugBook = this.props.archivedDrugBook;
        const name = archivedDrugBook.name;
        const from = archivedDrugBook.activeFrom;
        const to = archivedDrugBook.activeTo;

        return 'Förbrukningsjournal: ' + name + ', Aktiv: ' + from + ' -- ' + to;
    }
}

export default ArchivedDrugBookEventFocus;
