import React, {Component} from 'react';
import HelpModalDialog from "../help/HelpModalDialog";
import './UserPassword.css'
import TranslationService from "../../infra/TranslationService";
import {getSecurityToken} from "../../infra/SecurityToken";

const CHANGE_PASSWORD_API = process.env.REACT_APP_LOCALHOST + "/api/v1/change-password";
const youtubeLink = "https://player.vimeo.com/video/485024100";

class UserPassword extends Component {
    constructor(props) {
        super(props);
        this.state.showSentMessageText = false;
        this.state.showHelpDialog = false;
    }

    state = {
        showSentMessageText: false,
        showHelpDialog: false
    };

    onChangePassword(event) {
        event.preventDefault();

        this.props.refreshExpiredToken();
        fetch(CHANGE_PASSWORD_API, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include',
            method: "POST"
        })
            .then(res => res.json())
            .then(data => {
                //sätta texten från datat ? för att visa errormessages (?)
                if (data["o"] === "k") {
                    this.setState({showSentMessageText: true})
                }
            })
            .catch(console.log);
    }

    showHelp() {
        this.setState({showHelpDialog: true});
    }

    hideHelp = () => {
        this.setState({showHelpDialog: false});
    }

    render() {
        const aLinkHasBeenSent = TranslationService.translation("aLinkToChange");
        const logoutPrompt = TranslationService.translation("logoutCheckMail");
        const SENT_PASSWORD_TEXT = aLinkHasBeenSent + "\n" +
            logoutPrompt;
        const headLine = TranslationService.translation("changePass");
        const onlyReset = TranslationService.translation("neverRecover");
        const clickToReset = TranslationService.translation("clickLinkToReset");
        const helpBody = [
            onlyReset,
            clickToReset
        ];

        let messageSent;
        if (this.state.showSentMessageText) {
            messageSent = SENT_PASSWORD_TEXT;
        } else {
            messageSent = "";
        }

        let helpDialog;
        if (this.state.showHelpDialog) {
            helpDialog = <div>
                <HelpModalDialog
                    hide={this.hideHelp}
                    headline={headLine}
                    body={helpBody}
                    youtubeLink={youtubeLink}
                />
            </div>;
        } else {
            helpDialog = <div/>;
        }

        const CHANGE_PASSWORD_LABEL = TranslationService.translation("Byt lösenord:");
        const CHANGE_PASSWORD_BUTTON_TEXT = TranslationService.translation("Byt lösenord");
        const INSTRUCTIONS_TEXT = TranslationService.translation("You will recieve a mail with further instructions");

        return (
            <div>
                <div className={"row"}>
                    <div className={"col text-right pt-1"}>
                        <b>{CHANGE_PASSWORD_LABEL} </b>
                        <sup className={"btn-link"}
                             onClick={() => this.showHelp()}
                        >
                            [?]
                        </sup>
                    </div>
                    <div className={"col text-right"}>
                        <div className={"row"}>
                            <div className={"col-sm-10 text-left"}>
                                {messageSent}
                            </div>
                            <div className={"col-sm-2"}>
                                <button aria-label={"change password"}
                                        className={"btn btn-save btn-tage-default"}
                                        title={INSTRUCTIONS_TEXT}
                                        onClick={(event) => this.onChangePassword(event)}>
                                    {CHANGE_PASSWORD_BUTTON_TEXT}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    {helpDialog}
                </div>
            </div>
        );
    }
}

export default UserPassword;
