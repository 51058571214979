import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import Public from "./Public";
import React from "react";

let root = document.getElementById('root');

if (!(root === undefined || root === null)) {
    ReactDOM.render( <HashRouter>
    <HttpsRedirect>
        <Public/>
    </HttpsRedirect>
    </HashRouter>, root);
}