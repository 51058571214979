import React, {Component} from 'react';

const VERSIONAPI = process.env.REACT_APP_LOCALHOST + '/api/v1/version';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state.footerData.version = 10;
        this.state.footerData.buildDate = '09-06-1987';
    }

    state = {
        footerData: []
    };

    componentDidMount() {
        fetch(VERSIONAPI)
            .then(res => res.json())
            .then((data) => {
                this.setState({footerData: data})
            })
            .catch(console.log)
    }

    render() {
        return (
            <nav className={"navbar-nav footerText"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col"}/>
                        <div className={"col"}/>
                        <div className={"col"}>
                            <span>Build: {this.state.footerData.version} ({this.state.footerData.buildDate})</span>
                        </div>
                    </div>
                </div>

            </nav>
        );
    }
}

export default Footer;