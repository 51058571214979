import React, {Component} from 'react'
import './ActionStatistics.css';
import 'c3/c3.css';
import C3Wrapper from "../graph/C3Wrapper";
import HSFGrouping from "../fields/v1/HSFGrouping";
import {getSecurityToken} from "../../infra/SecurityToken";

const STATSAPI = process.env.REACT_APP_LOCALHOST + "/api/v1/stats";


//TODO Kolla saker som har "hasChildren" ersätt med hasAchild metoden

class ActionStatistics extends Component {
    constructor(props) {
        super(props);

        this.updateGraph = this.updateGraph.bind(this);
        this.onChangeGroup = this.onChangeGroup.bind(this);
    }

    state = {
        groupBy: [],
        formData: {},
        graphDataONE: {},
        graphDataTWO: {},
        action: {},
    };

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.updateGraph();
        }
    }

    updateGraph() {

        let groupBy = this.findSelectedGrouping();
        let filterUsers = this.getSelectedUsers();
        let filterFields = this.getFilterFields();
        let action = this.getActionField();

        let submitForm = {
            "action": action,
            "groupBy": groupBy,
            "filter": {
                "users": filterUsers,
                "fields": filterFields
            }
        };

        if (groupBy !== undefined) {
            if (groupBy.length > 0) {
                //our graph (+ "/user")
                fetch(STATSAPI + "/user", {
                    headers: {
                        'X-Custom-header': getSecurityToken()
                    },
                    credentials: 'include',
                    method: "POST",
                    body: JSON.stringify(submitForm)
                })
                    .then(res => res.json())
                    .then((data) => {
                        data.data["unload"] = true;
                        this.setState({graphDataONE: data});
                    }).catch(console.log);

                fetch(STATSAPI + "/world", {
                    headers: {
                        'X-Custom-header': getSecurityToken()
                    },
                    credentials: 'include',
                    method: "POST",
                    body: JSON.stringify(submitForm)
                })
                    .then(res => res.json())
                    .then((data) => {
                        data.data["unload"] = true;
                        this.setState({graphDataTWO: data});
                    }).catch(console.log);
            }
        }

    }

    //TODO detta är möjligtvis deprecated, flytta den här till getDerivedStateFromProps()
    // https://stackoverflow.com/questions/32414308/updating-state-on-props-change-in-react-form
    // https://reactjs.org/docs/react-component.html#static-getderivedstatefromprops

    componentWillReceiveProps(nextProps) {
        if (nextProps.groupBy !== this.state.groupBy) {
            this.setState({groupBy: nextProps.groupBy});
        }
        if (nextProps.formData !== this.state.formData) {
            this.setState({formData: nextProps.formData});
        }
        if (nextProps.action !== this.state.action) {
            this.setState({action: nextProps.action});
        }
    }

    componentDidMount() {
        this.setState({groupBy: this.props.groupBy});
        this.setState({formData: this.props.formData});
        this.setState({action: this.props.action});
    }

    onSelectAll(name) {
        let caller = document.getElementById(name + "All");

        if (!caller.checked) {
            let newFormData = this.state.formData;
            let elem = ActionStatistics.search(newFormData, name);
            if (elem.length === 0) {
                elem = this.getFromSection(newFormData, name)
            }
            for (let box of elem.fields) {
                box.selected = true;
            }
            this.setState({formData: newFormData});
            this.updateGraph();

        } else {
            let newFormData = this.state.formData;
            let elem = ActionStatistics.search(newFormData, name);
            if (elem.length === 0) {
                elem = this.getFromSection(newFormData, name)
            }
            for (let box of elem.fields) {
                box.selected = false;
            }
            this.setState({formData: newFormData});
            this.updateGraph();
        }
    }

    static search(structure, name) {
        const truthAdding = (acc, cur) => !!acc + !!cur;

        //todo has A child och childarray kan göras snyggare
        //todo flytta ut hasAChild, kommer att användas på andra ställen

        function hasAChild(structure) {
            let childArray;
            if (structure.fields !== undefined) {
                structure.fields.forEach(e => {
                    if (e.fields !== undefined) {
                        if (e.fields.length > 0) {
                            if (childArray === undefined) {
                                childArray = [];
                            }
                            childArray.push(true);
                        }
                    }
                });
            }

            if (childArray === undefined) {
                return false;
            }

            return childArray.reduce(truthAdding) > 0;
        }

        function recursiveSearch(structure, name) {
            if (structure.name === name) {
                return structure;
            } else {
                if (hasAChild(structure)) {
                    return structure.fields.map(e => recursiveSearch(e, name))
                }
            }
        }

        function reduction(acc, cur) {
            if (typeof cur === "undefined") {
                return acc;
            } else if (Array.isArray(cur)) {
                return cur.reduce(reduction, [])
            } else {
                if (typeof cur === "object") {
                    acc = cur;
                }
                return acc;
            }
        }

        return structure.map(e => recursiveSearch(e, name)).reduce(reduction, []);
    }

    onBoxSelect(boxKey, sectionKey, currentState) {
        //TODO byt från key till name i namnen
        //TODO sätta till statechange
        let newFormData = this.state.formData;

        let element = ActionStatistics.search(newFormData, sectionKey);
        if (element.length === 0) {
            element = this.getFromSection(newFormData, sectionKey);
        }

        let box;
        if (!!element.fields) {
            box = element.fields.find(searched => searched.name === boxKey);
        } else {
            box = element.sections.find(searched => searched.name === boxKey);
        }
        box.selected = !currentState;
        this.setState({formData: newFormData});

        let selectAllCheckbox = document.getElementById(sectionKey + "All");
        if (selectAllCheckbox.checked && !currentState) {
            selectAllCheckbox.checked = false;
        }
        this.updateGraph();
    }

    onTextChange(name) {
        console.log("date: ", name);
    }

    render() {
        function stateIsEmpty(state) {
            return state.formData[0] === undefined;

        }

        function drawJustCheckboxes(section, par) {
            return <ul key={section.name} className="noPoints pl-0">
                {
                    section.fields.map((elem) => (
                        <li key={elem.name}>
                            <label className="mb-0">
                                <input type="checkbox"
                                       checked={elem.selected === true}
                                       onChange={() => par.onBoxSelect(elem.name, section.name, elem.selected)}>

                                </input>{elem.translatedName}
                            </label>
                        </li>
                    ))
                }
                <li key={section.name + "All"}>
                    <label className="mb-0">
                        <input id={section.name + "All"}
                               type="checkbox"
                               defaultChecked={true}
                               onChange={() => par.onSelectAll(section.name)}>
                        </input>Ingen filtrering
                    </label>
                </li>
            </ul>;
        }

        function drawCheckboxSection(section, isChildNode, par) {
            return <div>
                {
                    isChildNode ? <h6 className={"pl-5"}>{section.translatedName}</h6> :
                        <h4>{section.translatedName}</h4>
                }

                <div className={isChildNode ? "col" : "container container-section"}>
                    <div className={isChildNode ? "" : "row justify-content-center"}>
                        <div className={isChildNode ? "" : "col-sm"}>
                            {drawJustCheckboxes(section, par)}
                        </div>

                    </div>
                </div>
            </div>;
        }

        function drawForm(parent) {

            function renderCheckboxSection(section, isChildNode, par) {
                return <div key={section.name}>
                    {/**
                     //TODO flytta det här in när det kommer att finnas rekursiva box selections, gör en egen rekursiveCheckBoxSection? fundera varför de här krashar annars?
                     section.hasChildren ? recursiveDig(section, true, par) :


                     **/}
                    {drawCheckboxSection(section, isChildNode, par)}
                </div>;
            }

            function renderParent(section, isChildNode, par) {
                if (!!section.sections) {
                    return <div key={section.name}>
                        <h4>{section.translatedName}</h4>
                        <div className={"row m-0"}>
                            {
                                section.sections.map(innerSection => <div key={innerSection.name} className={"col"}>
                                    <h6>
                                        {innerSection.translatedName}
                                    </h6>
                                    {drawJustCheckboxes(innerSection, par)}</div>)
                            }
                        </div>
                    </div>;
                } else {
                    return <div/>;
                }
            }

            function renderMixedSection(section, isChildNode, parent) {
                return <div>
                    {
                        section.fields.forEach(e => {
                            switch (e.inputType) {
                                case "select":
                                    return <div key={e.name}>{renderCheckboxSection(e, false, parent)}</div>;
                                case "radio":
                                    return <div key={e.name}>{renderCheckboxSection(e, false, parent)}</div>;
                                case "checkbox":
                                    return <div
                                        key={e.name}>{parent.renderJustACheckBox(e, false, parent, section)}</div>;
                                default:
                                    break;
                            }
                        })

                    }
                </div>
            }

            return (
                <div className={"container"}>
                    {parent.state.formData.map((section) => {
                            switch (section.inputType) {
                                case "checkbox":
                                    return <div key={section.name + "Column"} className="col-sm text-left">
                                        <hr/>
                                        {renderCheckboxSection(section, false, parent)}</div>;
                                case "select":
                                    return <div key={section.name + "Column"} className="col-sm text-left">
                                        <hr/>
                                        {renderCheckboxSection(section, false, parent)}</div>;
                                case "radio":
                                    return <div key={section.name + "Column"} className="col-sm text-left">
                                        <hr/>
                                        {renderCheckboxSection(section, false, parent)}</div>;
                                case "parent":
                                    return <div key={section.name + "Column"} className="col-sm text-left">
                                        {
                                            !!section.sections ?
                                                <hr/> : ""
                                        }
                                        {renderParent(section, false, parent)}</div>;
                                case "mixed":
                                    return <div key={section.name + "Column"} className="col-sm text-left">
                                        <hr/>
                                        <div>
                                            <h4>{section.translatedName}</h4>
                                            <div className={"container container-section"}>
                                                <div className={"row justify-content-center"}>
                                                    <div className={"col-sm"}>
                                                        <ul className="noPoints pl-0">
                                                            {
                                                                renderMixedSection(section, false, parent)
                                                            }
                                                            <li key={section.name + "All"}>
                                                                <label className="mb-0">
                                                                    <input id={section.name + "All"}
                                                                           type="checkbox"
                                                                           defaultChecked={true}
                                                                           onChange={() => parent.onSelectAll(section.name)}>
                                                                    </input>Ingen filtrering
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>;
                                default:
                                    break;
                            }
                            return "";
                        }
                    )}
                </div>

            );
        }

        return (

            <React.Fragment>
                <div className={"container pb-5"}>
                    <div className={"row"}>
                        <div className={"col-sm-4"}><HSFGrouping groupBy={this.filterGrouping(this.state.groupBy)}
                                                                 onChangeGroup={this.onChangeGroup}/></div>


                        <div className="col-sm-4 text-center"><h4>OUR GRAPH</h4>
                            <div id={"react-c3jsUs"}>
                                {
                                    this.state.graphDataONE.data === undefined ? "" :
                                        <C3Wrapper id={"chart_us"} data={this.state.graphDataONE.data}
                                                   axis={this.state.graphDataONE.axis}/>
                                }
                            </div>
                        </div>
                        <div className="col-sm-4 text-center"><h4>WORLD GRAPH</h4>
                            <div id={"react-c3jsWorld"}>
                                {
                                    this.state.graphDataTWO.data === undefined ? "" :
                                        <C3Wrapper id={"chart_world"} data={this.state.graphDataTWO.data}
                                                   axis={this.state.graphDataTWO.axis}/>
                                }
                            </div>
                        </div>
                    </div>

                    {/**
                     </div>

                     <div className="container pb-5">
                     **/}

                    <div className="row">
                        {stateIsEmpty(this.state) ? "" : drawForm(this)}
                    </div>
                </div>
            </React.Fragment>
        )
            ;
    }

    renderJustACheckBox = (e, b, parent, section) => {
        return (
            <li key={e.name}>
                <label className="mb-0">
                    <input type="checkbox"
                           checked={e.selected === true}
                           onChange={() => parent.onBoxSelect(e.name, section.name, e.selected)}>

                    </input>{e.translatedName}
                </label>
            </li>
        );
    }

    findSelectedGrouping() {
        let selectedGrouping = this.state.groupBy.find(e => e.selected);
        let useDefaultIfNoneSelected = this.state.groupBy.find(e => e.defaultGroupBy);

        if (selectedGrouping !== undefined) {
            return selectedGrouping.name;
        }
        if (useDefaultIfNoneSelected !== undefined) {
            return useDefaultIfNoneSelected.name;
        }
        return "";
    }

    onChangeGroup(selectedElement) {
        this.state.groupBy.forEach(e => {
            e.selected = e === selectedElement;
        });

        this.setState({groupBy: this.state.groupBy});
        this.updateGraph();
    }

    getSelectedUsers() {
        let returnUserList = [];

        this.props.userList.forEach(e => {
            if (e.selected) returnUserList.push({"userName": e.userName});
        });

        return returnUserList;
    }

    getFilterFields() {
        let returnFilterField = [];

        //TODO släng in den rekursiva söken för att söka igenom alla fält som har selected.
        //TODO inte en switch, nån annan logik?

        if (this.state.formData.length > 0) {
            this.state.formData.forEach(section => {

                    switch (section.inputType) {
                        case 'radio':
                            section.fields.forEach(e => {
                                if (e.selected) returnFilterField.push({"field": e.name})
                            });
                            break;
                        case 'checkbox':
                            section.fields.forEach(e => {
                                if (e.selected) returnFilterField.push({"field": e.name})
                            });
                            break;
                        case 'select':
                            section.fields.forEach(e => {
                                if (e.selected) returnFilterField.push({"field": e.name})
                            });
                            break;
                        default:
                    }

                }
            );
        }
        return returnFilterField;
    }

    getActionField() {
        if (this.state.action !== undefined) {
            return this.state.action;
        }
        return "cvk";
    }

    filterGrouping = (groupBy) => {
        let filteredList = [];
        //ersätt detta med nåt mera stabilt... när man i frontend kan säga vad som skall finnas i grupperings listan och vad som inte skall
        groupBy.forEach(e => {
            if (!e.name.includes("comment") && !e.name.includes("date")) {
                filteredList.push(e);
            }
        });
        return filteredList;
    }

    getFromSection(newFormData, name) {
        let wantedElement;
        newFormData.forEach(outer => {
            if (!!outer.sections) {
                let temp = outer.sections.find(innerSection => innerSection.name === name)
                if (temp) {
                    wantedElement = temp;
                }
            }
        })
        if (wantedElement) {
            return wantedElement;
        }
        return null;
    }
}

export default ActionStatistics;