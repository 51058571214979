import React from 'react';
import "./CountersignMailbox.css";
import {getSecurityToken} from "../../../infra/SecurityToken";

interface props {
    refreshExpiredToken: () => void
    securityToken: string
}

export interface CountersignResponse {
    eventId: string;
    countersignId: string;
    from: string;
    toEmail: string;
    received: boolean;
    closed: boolean;
}

interface state {
    mail: CountersignResponse[]
    currentEvent: csEvent
    currentEventId: string
    details: string
}

interface csEvent {
    actionName: string
    details: csDetails[]
}

interface csDetails {
    headline: string
    value: string[]
}

const COUNTERSIGNAPI = process.env.REACT_APP_LOCALHOST + "/api/v1/countersignmailbox";
const GETEVENTAPI = process.env.REACT_APP_LOCALHOST + "/api/v1/event";
const VERDICTAPI = process.env.REACT_APP_LOCALHOST + "/api/v1/verdict";

class CountersignMailbox extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            mail: [],
            currentEvent: {actionName: "", details: []},
            currentEventId: "",
            details: ""
        }
    }

    componentDidMount() {
        this.refreshMailbox();
    }

    render() {
        return (
            <div>
                <button className={"btn csRefreshBtn ml-2 mt-2"} onClick={() => this.refreshMailbox()}>Refresh</button>
                <div>
                    {this.drawMail()}
                    {this.drawResponseBox()}
                    {this.drawVerdictConfirmation()}
                </div>
            </div>
        );
    };

    private drawMail() {
        return <div className={"row"}>
            <div className={"col text-center"}>
                {
                    this.state !== null ?
                        this.drawPerMail()
                        :
                        ""
                }
            </div>
        </div>;
    }

    private drawPerMail() {
        return this.state.mail.map(csEvent => <div key={csEvent.countersignId} className={"pt-1"}>
            Från: {csEvent.from + " "}
            {
                csEvent.closed ? "" : <button className={"btn ml-2 mr-2"} onClick={() => this.goToPage(csEvent.eventId, csEvent.countersignId)}>
                    Öppna
                </button>
            }
            {csEvent.received ? "läst/mottagen" : ""} {csEvent.closed ? "stängd" : ""}
            <button className={"btn ml-2"} onClick={() => this.fetchVerdict(csEvent.countersignId)}>
                +
            </button>
        </div>);
    }

    private drawResponseBox() {
        return <div className={"container pt-3"}>
            <div hidden={this.state.currentEventId === ""} className={"countersignbox"}>
                {
                    this.drawCurrentEvent()
                }
            </div>
        </div>;
    }

    private drawCurrentEvent() {
        let detailsArray: string[] = [];

        if (this.state.currentEvent !== undefined) {
            this.state.currentEvent.details.forEach(det => {
                let values = "";
                det.value.forEach(val => values = values + " " + val)
                let stringBuild = det.headline + " : " + values + "\n";
                detailsArray.push(stringBuild);
            });
        }

        return <div>{this.state.currentEvent.actionName + "\n"}{detailsArray}</div>
    }

    private drawVerdictConfirmation() {
        let verdictButtons;

        if (this.state.currentEventId === "") {
            verdictButtons = <div/>
        } else {
            verdictButtons = <div className={"container pt-5"}>
                <div className={"row"}>
                    <div className={"col-8"}>
                        <h5>
                            Beskrivning
                        </h5>
                    </div>
                    <div className={"col-4"}>
                        <h5>
                            Beslut
                        </h5>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-8"}>
                        <textarea className={"form-control"} rows={4} value={this.state.details} onChange={(event) => this.handleDetails(event)}/>
                    </div>
                    <div className={"col-4"}>
                        <button className={"mr-4 btn csOkButton"} onClick={() => this.handleVerdict(true)}>OK</button>
                        <button className={"btn csNoButton"} onClick={() => this.handleVerdict(false)}>ICKE</button>
                    </div>
                </div>
            </div>;
        }

        return verdictButtons
    }

    goToPage(eventId: string, countersignId: string) {
        this.fetchWantedEvent(eventId, countersignId);
        this.setCountersignToReceived(countersignId);
    }

    private fetchWantedEvent(eventId: string, countersignId: string) {

        this.props.refreshExpiredToken();
        fetch(GETEVENTAPI + "/" + eventId, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({currentEvent: data, currentEventId: countersignId});
            })
            .catch(console.log);
    }

    private setCountersignToReceived(countersignId: string) {
        const payload = {
            "countersignId": {"id": countersignId},
            "eventType": "RECEIVED"
        }

        this.post(payload);
    }

    private refreshMailbox() {
        this.props.refreshExpiredToken();
        fetch(COUNTERSIGNAPI, { //TODO gör paginering innan nån människa har > 600 meddelanden
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({mail: data});
            })
            .catch(console.log);
    }

    private post(payload: { eventType: string; countersignId: { id: string } }) {
        this.props.refreshExpiredToken();
        fetch(COUNTERSIGNAPI, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include',
            method: "POST",
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({mail: data});
            })
            .catch(console.log);
    }


    private handleVerdict(passed: boolean) {
        const payload = {
            "countersignId": {"id": this.state.currentEventId},
            "eventType": "VERDICT",
            "passed": passed,
            "details": this.state.details
        }

        this.post(payload);
        this.emptyAllValues();
    }

    private emptyAllValues() {
        this.setState({currentEvent: {actionName: "", details: []}, currentEventId: "", details: ""})
    }

    private handleDetails(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({details: event.currentTarget.value});
    }

    private fetchVerdict(countersignId: string) {
        fetch(VERDICTAPI + "/" + countersignId, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then((data) => {
                alert(JSON.stringify(data));
            })
            .catch(console.log);
    }
}

export default CountersignMailbox;

/*
            <button className={"btn mr-2"} onClick={() => this.handleUnsee(csEvent.countersignId)}>
                Gör osedd
            </button>
            <button className={"btn mr-2"} onClick={() => this.handleClose(csEvent.countersignId)}>
                Stäng
            </button>
            <button className={"btn"} onClick={() => this.handleUnClose(csEvent.countersignId)}>
                Gör ostängd
            </button>
 */