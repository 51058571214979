import React from "react";
import {ActionService} from "../register/v2/ActionService";
import {StatisticsService} from "./StatisticsService";
import {Action, Error} from "../register/v2/Action";
import {STATISTICS, V2} from "../../infra/Constants";
import StatisticsV2 from "./v2/Statistics";
import StatisticsV3 from "./v3/Statistics";
import {User} from "../model/User";
import {Organisation} from "../model/Organisation";

interface props {
    actionName: string,
    actionVersion: number,
    dateFormat: string,
    actionService: ActionService,
    statisticsService: StatisticsService,
    user: User,
    currentOrganisation?: Organisation
}

interface state {
    action: Action | undefined,
    actionError: Error | undefined,
}

class StatisticsSelector extends React.Component<props, state> {
    amIMounted: boolean = false;

    constructor(props: Readonly<props>) {
        super(props);

        this.state = {
            action: undefined,
            actionError: undefined
        };
    }

    render(): React.JSX.Element {
        const action: Action | undefined = this.state.action;
        const actionError: Error | undefined = this.state.actionError;

        if (actionError === undefined && action === undefined) {
            return <div data-testid={"no-action-spinner"} className={"spinner"}/>
        }

        if (actionError !== undefined) {
            return <div>
                {actionError.message}
            </div>
        } else {
            if (action !== undefined) {

                const actionName: string = this.props.actionName;
                const actionVersion: number = this.props.actionVersion;
                const key: string = actionName + "." + actionVersion + "." + STATISTICS;
                const user: User = this.props.user
                const currentOrganisation: Organisation | undefined = this.props.currentOrganisation;
                const dateFormat: string = this.props.dateFormat;
                const actionServiceBackend: ActionService = this.props.actionService;
                const statisticsService: StatisticsService = this.props.statisticsService;

                if (action.frontendVersion === V2) {
                    return <StatisticsV2 key={key}
                                         actionName={actionName}
                                         actionVersion={actionVersion}
                                         dateFormat={dateFormat}
                                         actionService={actionServiceBackend}
                                         statisticsService={statisticsService}
                                         user={user}
                                         currentOrganisation={currentOrganisation}
                    />
                } else {
                    return <StatisticsV3 key={key}
                                         action={action}
                                         actionService={actionServiceBackend}
                                         statisticsService={statisticsService}
                                         user={user}
                                         currentOrganisation={currentOrganisation}
                    />
                }
            } else {
                return <div>
                    It's not you, it's us
                </div>
            }
        }
    }

    componentDidMount() {
        this.amIMounted = true;
        this.setAction();
    }

    private setAction() {
        const name: string = this.props.actionName;
        const version: number = this.props.actionVersion;
        const type: string = STATISTICS;
        const actionService: ActionService = this.props.actionService;

        const actionPromise: Promise<Action | Error> = actionService.getAction(name, version, type);
        actionPromise.then((action: Action | Error) => {
            if (this.amIMounted) {
                if (!('reason' in action)) {
                    this.setState({
                        action: action
                    });
                } else {
                    this.setState({actionError: action});
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }
}

export default StatisticsSelector