import React, {ReactNode} from "react";
import {uuidv4} from "../../register/v2/Uuid";
import TranslationService from "../../../infra/TranslationService";
import NumberField from "../../fields/v2/NumberField";
import {Field} from "../../register/v2/Action";
import "./Analysis.css"
import HelpModalDialog from "../../help/HelpModalDialog";
import {
    DEFAULT_MOVING_AVERAGE,
    GRAPH_TYPE_DISTRIBUTION,
    GRAPH_TYPE_MOVING_AVERAGE,
    GRAPH_TYPE_TOTAL_AVERAGE,
    MOVING_AVERAGE
} from "../Constants";
import {V3} from "../../../infra/Constants";

interface props {
    graphType: string,
    movingAverage: number | undefined,
    onGraphTypeChange: (graphType: string) => void,
    onMovingAverageChange: (movingAverage: number | undefined) => void
}

interface state {
    showHelpDialog: boolean,
    helpHeadLine: string,
    helpBody: string[],
    helpYoutubeLink: string
}

class Analysis extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.onMovingAverageChange = this.onMovingAverageChange.bind(this);
        this.hideHelp = this.hideHelp.bind(this);

        this.state = {
            showHelpDialog: false,
            helpHeadLine: "",
            helpBody: [],
            helpYoutubeLink: ""
        }
    }

    render(): ReactNode {
        const headline = TranslationService.translation("analysis");
        const graphTypePie = this.getRadioButton(GRAPH_TYPE_DISTRIBUTION);
        const graphTypePieHelp = this.getHelpLink(GRAPH_TYPE_DISTRIBUTION);
        const graphTypeTotalAverage = this.getRadioButton(GRAPH_TYPE_TOTAL_AVERAGE);
        const graphTypeTotalAverageHelp = this.getHelpLink(GRAPH_TYPE_TOTAL_AVERAGE);
        const graphTypeMovingAverage = this.getRadioButton(GRAPH_TYPE_MOVING_AVERAGE);
        const graphTypeMovingAverageHelp = this.getHelpLink(GRAPH_TYPE_MOVING_AVERAGE);
        const movingAverage = this.getMovingAverageField();
        const helpDialog = this.getHelpDialog();

        return <div>
            <h4 aria-label={"analysis headline"}>{headline}</h4>
            <div>
                <div className={"row ml-0"}>
                    {graphTypePie} {graphTypePieHelp}
                </div>
                <div className={"row ml-0"}>
                    {graphTypeTotalAverage} {graphTypeTotalAverageHelp}
                </div>
                <div className={"row ml-0"}>
                    {graphTypeMovingAverage}
                    {movingAverage} {graphTypeMovingAverageHelp}
                    {helpDialog}
                </div>
            </div>
        </div>;
    }

    private getRadioButton(optionName: string): React.ReactFragment {
        const name: string = "analysisType";
        const id: string = uuidv4();
        const labelText: string = TranslationService.translation(optionName);

        const graphType: string = this.props.graphType;
        const checked: boolean = optionName === graphType;

        const onGraphTypeChange = this.props.onGraphTypeChange;

        return <div key={optionName}
                    onChange={() => onGraphTypeChange(optionName)}
        >
            <input type={"radio"}
                   name={name}
                   id={id}
                   readOnly={true}
                   checked={checked}
                   aria-label={optionName}
            />
            <label htmlFor={id}>{labelText}</label>
        </div>;
    }

    private getHelpLink(optionName: string): React.ReactFragment {
        const label: string = optionName + " help";
        return <div aria-label={label}
                    onClick={() => this.showHelp(optionName)}
        >
            <sup className={"btn-link"}>[?]</sup>
        </div>
    }

    private getMovingAverageField(): React.ReactFragment {
        const movingAverage: number | undefined = this.props.movingAverage;
        let defaultValue: string;
        if (movingAverage !== undefined) {
            defaultValue = '' + movingAverage;
        } else {
            defaultValue = '';
        }

        const field: Field = {
            name: MOVING_AVERAGE,
            fieldLabel: false,
            showLabel: false,
            duplicationIndex: 0,
            defaultValue: defaultValue,
            fieldSize: 2,
            decimalPlaces: 0
        };

        const duplicationIndex = '0';
        const onChange = this.onMovingAverageChange;

        return <div className={"pl-2"}>
            <NumberField field={field}
                         frontendVersion={V3}
                         value={defaultValue}
                         duplicationIndex={duplicationIndex}
                         onChange={onChange}
            />
        </div>;
    }

    private onMovingAverageChange(name: string, value: string | string[], duplicationIndex: string, valid: boolean): void {
        if (name === duplicationIndex === valid) {
            // Trick Idea to think these arguments are used
        }

        let movingAverage: number | undefined = DEFAULT_MOVING_AVERAGE;
        if (typeof value === 'string') {
            movingAverage = parseInt(value);
        }
        if (Array.isArray(value)) {
            movingAverage = parseInt(value[0]);
        }
        if (isNaN(movingAverage)) {
            movingAverage = undefined
        }

        const onMovingAverageChange = this.props.onMovingAverageChange;
        onMovingAverageChange(movingAverage);

        const onGraphTypeChange = this.props.onGraphTypeChange;
        onGraphTypeChange(GRAPH_TYPE_MOVING_AVERAGE);
    }

    private showHelp(dialog: string): void {
        let headLine: string = "";
        let helpBody: string[] = [];
        let videoLink: string = "";

        if (GRAPH_TYPE_TOTAL_AVERAGE === dialog) {
            headLine = "Fördelning som funktion av antal";

            helpBody = [
                "Här kan du se din och andras utveckling när man jämför allas första värde, andra värde etc.",
                "Det är som att titta på pajgraferna efter din första registrering, din andra osv."
            ];

            videoLink = "https://www.youtube.com/embed/bf2m7HV6Ff4";
        }

        if (GRAPH_TYPE_DISTRIBUTION === dialog) {
            headLine = "Fördelning per gruppering";

            helpBody = [
                "Pajgraf som visar fördelning enligt din valda gruppering."
            ];
        }

        if (GRAPH_TYPE_MOVING_AVERAGE === dialog) {
            headLine = "Glidande medelvärde";

            helpBody = [
                "Glidande medelvärde med den period du valt över tid."
            ];
        }

        this.setState({
            showHelpDialog: true,
            helpHeadLine: headLine,
            helpBody: helpBody,
            helpYoutubeLink: videoLink
        })
    }

    private getHelpDialog() {
        if (this.state.showHelpDialog) {
            const headLine = this.state.helpHeadLine;
            const helpBody = this.state.helpBody;
            const youtubeLink = this.state.helpYoutubeLink;

            return <div>
                <HelpModalDialog
                    hide={this.hideHelp}
                    headline={headLine}
                    body={helpBody}
                    youtubeLink={youtubeLink}
                />
            </div>;
        } else {
            return <div/>;
        }
    }

    private hideHelp() {
        this.setState({showHelpDialog: false});
    }
}

export default Analysis;
