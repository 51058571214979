import React from 'react';
import "./PrehospitalMissionReportModal.css"
import {MissionField} from "./PrehospitalMissionReport";
import TranslationService from "../../infra/TranslationService";
import {UserOldRoles} from "../model/UserOldRoles";

interface props {
    data: MissionField[] | null;
    flipVisibility: () => void;
    owner?: UserOldRoles,
    actionName: string
}

interface state {
}

class EventDetailModal extends React.Component<props, state> {
    constructor(props: any) {
        super(props);

        this.state = {}
    }

    render() {
        let data = this.props.data;
        let missionDetailFragment = data !== null ? data.map((missionDetail, index) => {
            let value = TranslationService.translation(missionDetail.value);
            let duplicationIndexStr = missionDetail.duplicationIndex.substr(missionDetail.duplicationIndex.length - 1);
            if(missionDetail.level === 0 && duplicationIndexStr !== "0") {
                let duplicationIndex : number = +duplicationIndexStr + 1;
                value = value + " #" + duplicationIndex;
            }
            let className = "";
                let divider = <div/>;
                let level = missionDetail.level;
                if (level > 1) {
                    while (level > 1) {
                        value = "\u00A0\u00A0" + value;
                        level = level - 1;
                    }
                } else if (level === 0) {
                    className = "strong";
                    divider = <hr/>;
                }
                return (
                    <div className={"row m-0 pb-1"} key={index}>
                        <div className={"col"}>
                            {divider}
                            <div className={className}>{value}</div>
                        </div>
                    </div>)
            }
        ) : <div/>;

        let owner = this.props.owner;
        let title = owner ? <>Detaljer för {TranslationService.translation(this.props.actionName) + "\n"}
            ({owner?.firstName} {owner?.lastName})</> : <>Detaljer
            för {TranslationService.translation(this.props.actionName)}</>;
        return (
            <div>
                <aside className="temporary-dummy-modal-background" onClick={() => this.props.flipVisibility()}/>
                <div className={"temporary-dummy-modal"}>
                    <div className={"row m-0"}>
                        <div className={"col"}>
                            <h4 className={"strong"}>{title}</h4>
                        </div>
                        <div className={"col"}>
                            <button className="close-button"
                                    aria-label="Close Modal"
                                    onClick={() => this.props.flipVisibility()}
                            >
                                <span className="u-hide-visually">Close</span>
                                <svg className="c-modal__close-icon"
                                     viewBox="0 0 40 40">
                                    <path d="M 10,10 L 30,30 M 30,10 L 10,30"/>
                                </svg>
                            </button>

                        </div>
                    </div>
                    {missionDetailFragment}
                </div>
            </div>
        );
    }

}

export default EventDetailModal;
