import React, {Component} from 'react';
import "./SendNewPassword.css";
import PasswordInputField from "../fields/v2/PasswordInputField";

const RESETPASSWORDAPI = process.env.REACT_APP_LOCALHOST + "/api/v1/new-password";
const RESETPASSWORDBUTTONTEXT = "Uppdatera";

const ADD_PASSWORD = "Ditt nya lösenord";

class SendNewPassword extends Component {
    constructor(props) {
        super(props);

        this.state.passwordToken = this.props.token;
    }

    state = {
        passwordInput: "",
        passwordToken: "",
        errors: [],
        displayErrors: false
    };

    onSendPassword() {
        const passwordInput = this.state.passwordInput;
        let payload = {
            "passwordToken": this.state.passwordToken,
            "password": passwordInput
        };

        fetch(RESETPASSWORDAPI, {
            method: "POST",
            body: JSON.stringify(payload)
        }).then(res => res.json())
            .then((data) => {
                localStorage.setItem("email", data.email);
                localStorage.setItem("password", data.password);
                window.location = "/";
            })
            .catch(console.log);
    }

    updatePassword(inc) {
        inc.preventDefault();
        const incoming = inc.currentTarget.value
        const validation = PasswordInputField.adheresToPasswordRestrictions(incoming);
        this.setState({errors: validation.errors});
        this.setState({passwordInput: incoming})
    }

    verifyPassword(e) {
        e.preventDefault();
        const validation = PasswordInputField.adheresToPasswordRestrictions(this.state.passwordInput)
        if (!validation.valid) {
            this.setState({displayErrors: true, errors: validation.errors});
        } else {
            this.onSendPassword(e);
        }
    }

    render() {
        return (
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-1"}/>
                    <div className={"col-10"}>
                        <div className={"row justify-content-center"}>
                            <PasswordInputField
                                errors={this.state.errors}
                                displayErrors={this.state.displayErrors}
                                mandatory={true}
                                placeholder={ADD_PASSWORD}
                                password={this.state.passwordInput}
                                update={(value) => this.updatePassword(value)}/>

                            <button
                                id={"changePasswordButton"}
                                className={"btn btn-signup btn-newPassword"}
                                onClick={(e) => this.verifyPassword(e)}>
                                {RESETPASSWORDBUTTONTEXT}
                            </button>
                        </div>
                    </div>
                    <div className={"col-1"}/>

                </div>
            </div>
        );
    }
}


export default SendNewPassword;
