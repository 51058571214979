import React, {ChangeEvent, ReactElement} from "react";
import {postObjectOf} from "../../../../infra/BackendService";

interface props {
}

interface state {
    name: string,
    description: string,
    organisationCreated: string
}

export type OrganisationPayload = {
    name: string,
    description: string,
}


class CreateOrganisationForm extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            name: "",
            description: "",
            organisationCreated: ""
        };
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleNameChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({name: event.target.value});
    }

    handleDescriptionChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({description: event.target.value});
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const url: string = "/api/v3/backoffice/organisation";
        let name = this.state.name;
        let description = this.state.description;
        const payload: OrganisationPayload = {
            name: name,
            description: description,
        }

        postObjectOf(payload, url).then(() => this.setState({
            organisationCreated: name
        }));

        this.clearForm()
    }

    render(): ReactElement {
        let label = <label className={"row m-0 pb-1"} htmlFor="createOrganisation">Create organisation</label>
        const form = <form id={"createOrganisation"} onSubmit={this.handleSubmit}>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.name}
                    onChange={(e) => this.handleNameChange(e)}
                    type="text"
                    placeholder="Name"
                    aria-label="Name"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.description}
                    onChange={(e) => this.handleDescriptionChange(e)}
                    type="text"
                    placeholder="Description"
                    aria-label="Description"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <button type="submit" className="btn" aria-label="Save">Save</button>
            </div>
        </form>

        const organisationCreated = this.state.organisationCreated !== "" ?
            <div>Organisation {this.state.organisationCreated} created</div> : <div/>;
        return <div>
            {label}
            {form}
            {organisationCreated}
        </div>

    };

    private clearForm() {
        this.setState({name: "", description: ""})
    }
}

export default CreateOrganisationForm;