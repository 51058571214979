import React, {ReactNode} from "react";
import TranslationService from "../../../infra/TranslationService";
import {Field} from "../../register/v2/Action";
import NumberField from "../../fields/v2/NumberField";
import CustomSelect from "../../fields/v2/CustomSelect";
import Value from "../../register/v2/Value";
import {FieldComponent, FieldError} from "../../fields/v2/FieldComponent";
import {getValid} from "../../fields/v2/FieldUtil";
import {AgeFilterSelection} from "./GraphOptions";
import {V3} from "../../../infra/Constants";

interface props {
    field: Field,
    name: string,
    device: string,
    frontendVersion: string,
    ageSelection: AgeFilterSelection | undefined,
    onAgeSelectionChange: (ageSelection: AgeFilterSelection) => void
}

interface state {
}

class AgeFilter extends React.Component<props, state> implements FieldComponent {
    private fromRef: any;
    private toRef: any;
    private unitRef: any;

    private readonly defaultUnit: string = "years";

    constructor(props: props) {
        super(props);

        this.onFromChange = this.onFromChange.bind(this);
        this.onToChange = this.onToChange.bind(this);
        this.onUnitChange = this.onUnitChange.bind(this);

        this.state = {}
    }

    render(): ReactNode {
        const name: string = this.props.name;
        let headlineLabel = name + ".age-filter-headline";
        const headline: string = TranslationService.translation(headlineLabel);
        const from: React.ReactFragment = this.getFromField(name);
        const to: React.ReactFragment = this.getToField(name);
        const unit: React.ReactFragment = this.getUnitField(name);

        return <div className={"container"}>
            <h4 aria-label={headlineLabel}>{headline}</h4>
            <div className={"row"}>
                <div className={"col"}>
                    {from}
                </div>
                <div className={"col"}>
                    {to}
                </div>
                <div className={"col mt-2"}>
                    {unit}
                </div>
            </div>
        </div>
    }

    private getFromField(fieldName: string): React.ReactFragment {
        this.fromRef = React.createRef();
        let name = fieldName + ".from";

        let currentValue: string | undefined;
        let ageSelection = this.props.ageSelection;
        if (ageSelection !== undefined) {
            let from = ageSelection.from;
            if (from !== undefined) {
                currentValue = '' + from;
            }
        }
        const onFromChange = this.onFromChange;

        return this.createNumberField(name, this.fromRef, currentValue, onFromChange);
    }

    private getToField(fieldName: string): React.ReactFragment {
        this.toRef = React.createRef();
        let name = fieldName + ".to";

        let currentValue: string | undefined;
        let ageSelection = this.props.ageSelection;
        if (ageSelection !== undefined) {
            let to = ageSelection.to;
            if (to !== undefined) {
                currentValue = '' + to;
            }
        }
        const onTOChange = this.onToChange;

        return this.createNumberField(name, this.toRef, currentValue, onTOChange);
    }

    private createNumberField(name: string,
                              ref: any,
                              currentValue: string | undefined,
                              onChange: (name: string, value: (string | string[]), duplicationIndex: string, valid: boolean) => void): React.ReactFragment {
        const field: Field = {
            name: name,
            fieldLabel: true,
            duplicationIndex: 0,
            min: this.getMin(),
            max: this.getMax(),
            decimalPlaces: this.getDeciamlPlaces(),
            fieldSize: this.getFieldSize()
        };

        let frontendVersion = this.props.frontendVersion;
        if (frontendVersion === V3) {
            const duplicationIndex: string = '0';

            return <NumberField field={field}
                                frontendVersion={frontendVersion}
                                value={currentValue}
                                duplicationIndex={duplicationIndex}
                                onChange={onChange}
            />;
        } else {
            return <NumberField ref={ref}
                                field={field}/>;
        }
    }

    private getUnitField(fieldName: string): React.ReactFragment {
        this.unitRef = React.createRef();
        const field: Field = {
            name: fieldName + ".unit",
            duplicationIndex: 0,
            fieldLabel: true,
            showDefaultOption: false,
            options: this.getOptions()
        };
        let defaultSelection = this.defaultUnit;

        let frontendVersion = this.props.frontendVersion;
        if (frontendVersion === V3) {
            const duplicationIndex: string = '';
            let currentAgeSelection: AgeFilterSelection | undefined = this.props.ageSelection;
            let currentValue: string | undefined = undefined;
            if (currentAgeSelection !== undefined) {
                currentValue = currentAgeSelection.unit;
            }
            const onChange = this.onUnitChange;

            return <CustomSelect field={field}
                                 frontendVersion={frontendVersion}
                                 value={currentValue}
                                 duplicationIndex={duplicationIndex}
                                 onChange={onChange}
            />;
        } else {
            return <CustomSelect ref={this.unitRef}
                                 field={field}
                                 defaultSelection={defaultSelection}/>
        }
    }

    private onFromChange(name: string, value: string | string[], duplicationIndex: string, valid: boolean): void {
        if (name === duplicationIndex === valid) {
            // Trick Idea to think these arguments are used
        }

        let currentAgeSelection: AgeFilterSelection | undefined = this.props.ageSelection;

        if (currentAgeSelection === undefined) {
            currentAgeSelection = this.getDefaultAgeFilterSelection();
        }
        currentAgeSelection.from = this.parseNumber(value, this.getMin());


        const onAgeSelectionChange = this.props.onAgeSelectionChange;
        onAgeSelectionChange(currentAgeSelection);
    }

    private onToChange(name: string, value: string | string[], duplicationIndex: string, valid: boolean): void {
        if (name === duplicationIndex === valid) {
            // Trick Idea to think these arguments are used
        }

        let currentAgeSelection: AgeFilterSelection | undefined = this.props.ageSelection;

        if (currentAgeSelection === undefined) {
            currentAgeSelection = this.getDefaultAgeFilterSelection();
        }
        currentAgeSelection.to = this.parseNumber(value, this.getMax());

        const onAgeSelectionChange = this.props.onAgeSelectionChange;
        onAgeSelectionChange(currentAgeSelection);
    }

    private onUnitChange(name: string, value: string | string[], duplicationIndex: string, valid: boolean): void {
        if (name === duplicationIndex === valid) {
            // Trick Idea to think these arguments are used
        }

        let currentAgeSelection: AgeFilterSelection | undefined = this.props.ageSelection;

        if (currentAgeSelection === undefined) {
            currentAgeSelection = this.getDefaultAgeFilterSelection();
        }
        currentAgeSelection.unit = this.getUnit(name, value, this.defaultUnit);

        const onAgeSelectionChange = this.props.onAgeSelectionChange;
        onAgeSelectionChange(currentAgeSelection);
    }

    private getDefaultAgeFilterSelection(): AgeFilterSelection {
        const fieldName = this.props.field.name;
        const unit = this.defaultUnit;

        return {
            name: fieldName,
            unit: unit
        };
    }

    private parseNumber(value: string | string[], defaultValue: number): number | undefined {
        let parsedValue: number | undefined = defaultValue;

        if (typeof value === 'string') {
            parsedValue = parseInt(value);
        }
        if (Array.isArray(value)) {
            parsedValue = parseInt(value[0]);
        }
        if (isNaN(parsedValue)) {
            parsedValue = undefined
        }

        return parsedValue;
    }

    private getUnit(name: string, value: string | string[], defaultUnit: string) {
        let completeValue: string = '';

        if (typeof value === 'string') {
            completeValue = value;
        }

        if (Array.isArray(value)) {
            completeValue = value[0];
        }

        const length: number = name.length + 1;
        const cleaned: string = completeValue.substring(length);
        if (cleaned.length > 0) {
            return cleaned;
        }

        return defaultUnit;
    }

    getMin(): number {
        let min: number | undefined = this.props.field.min;
        if (!!min) {
            return min;
        }

        return 1;
    }

    getMax(): number {
        let max: number | undefined = this.props.field.max;
        if (!!max) {
            return max;
        }

        return 200;
    }

    getDeciamlPlaces(): number {
        let decimalPlaces: number | undefined = this.props.field.decimalPlaces;
        if (!!decimalPlaces) {
            return decimalPlaces;
        }

        return 0;
    }

    getFieldSize(): number {
        let fieldSize: number | undefined = this.props.field.fieldSize;
        if (!!fieldSize) {
            return fieldSize;
        }

        return 3;
    }

    getOptions(): string[] {
        let options: (string | Field) [] | undefined = this.props.field.options;
        if (!!options) {
            return options as string[];
        }

        return [
            "years",
            "months",
            "weeks",
            "days"
        ];
    }

    selection(): AgeFilterSelection {
        const result: any = {};
        const fromField: NumberField = this.fromRef.current;
        const toField: NumberField = this.toRef.current;
        const unitField: CustomSelect = this.unitRef.current;

        const name: string = this.props.name;
        const from: number = AgeFilter.extractNumber(fromField);
        const to: number = AgeFilter.extractNumber(toField);
        const unit: string = AgeFilter.getUnit(unitField, this.defaultUnit);

        if (from !== -1) {
            result['name'] = name;
            result['from'] = from;
            result['unit'] = unit;
        }

        if (to !== -1) {
            result['name'] = name;
            result['to'] = to;
            result['unit'] = unit;
        }

        return result;
    }

    private static extractNumber(field: NumberField) {
        if (field.values().length === 1) {
            const valueObject: Value = field.values()[0];
            if (valueObject.values.length === 1) {
                let valueStr: string = valueObject.values[0];
                return parseInt(valueStr);
            }
        }

        return -1;
    }

    private static getUnit(unitField: CustomSelect, defaultUnit: string) {
        if (unitField.values().length === 1) {
            const valueObject: Value = unitField.values()[0];
            if (valueObject.values.length === 1) {
                const valueStr: string = valueObject.values[0];
                const parts: string[] = valueStr.split(".");
                let lastValue: number = parts.length - 1;

                return parts[lastValue];
            }
        }

        return defaultUnit;
    }

    clear(): void {
    }

    isValid(): FieldError {
        const from: NumberField = this.fromRef.current;
        const to: NumberField = this.toRef.current;
        const unit: CustomSelect = this.unitRef.current;

        if (AgeFilter.extractNumber(from) === -1) {
            const fromValidation = from.isValid();
            if (!fromValidation.valid) {
                return fromValidation;
            }
        }

        if (AgeFilter.extractNumber(to) === -1) {
            const toValidation = to.isValid();
            if (!toValidation.valid) {
                return toValidation;
            }
        }

        const unitValidation = unit.isValid();
        if (!unitValidation.valid) {
            return unitValidation;
        }

        let name = this.props.name;
        return getValid(name);
    }

    set(values: Value[]): void {
        // only to fool idea to think that values are being used
        if (values === undefined) {
            console.log(values);
        }
    }

    values(): Value[] {
        return [];
    }
}

export function getAgeSelections(fieldReferences: Map<string, any>): AgeFilterSelection[] {
    const ageSelection: AgeFilterSelection [] = []

    fieldReferences.forEach((value: any) => {
        const candidate: any = value.current;
        if (candidate instanceof AgeFilter) {
            const selection: AgeFilterSelection = candidate.selection();
            ageSelection.push(selection);
        }
    });

    return ageSelection;
}

export default AgeFilter;
