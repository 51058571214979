import React from 'react';
import parse from 'html-react-parser';
import TranslationService from "../../infra/TranslationService";
import {User} from "../model/User";
import {BackendContext, BackendInterface} from "../../infra/BackendContext";


interface state {
    splashData: SplashResponse | undefined,
}

interface SplashResponse {
    userRegistrations: number;
    userLastRegistration: string;
    userLastRegistrationDate: string;
    worldsRegistrations: string;
    worldsLastRegistration: string;
    worldsLastRegistrationDate: string;
    worldsUserCount: string;
    currentAnnouncements: Announcement[];
    allAnnouncements: Announcement[];
}

interface Announcement {
    from: string;
    to: string;
    language: string;
    announcement: string;
    message: Paragraph[];
}

interface Paragraph {
    paragraph: string
}

interface props {
    setSplashData: any,
    refreshExpiredToken: any,
    user: User,
    securityToken: string
}

class Home extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: any) {
        super(props);

        this.state = {
            splashData: undefined,
        }
    }

    async componentDidMount() {
        document.title = "LogEze";
        await this.getSplash();
    }

    render() {
        const welcome = TranslationService.translation("welcome")
        const registrations = TranslationService.translation("registrations_capital_r")
        const you = TranslationService.translation("you")
        const world = TranslationService.translation("world")
        const nr_users = TranslationService.translation("number of users")
        const about_you = TranslationService.translation("about you")
        const latest_reg = TranslationService.translation("latest registrations")

        function undefOrSpace(userLastRegistration: string) {
            if (userLastRegistration === "") {
                return true;
            }

            return userLastRegistration === undefined;
        }


        let splashData = this.state.splashData;
        let user = this.props.user;
        let splashDataDiv = splashData !== undefined ? <div>
            <div className={"row"}>
                <div className={"col grayed"}>{registrations}:</div>
                <div className={"col grayed"}>{splashData.userRegistrations}</div>
                <div className={"col grayed"}>{splashData.worldsRegistrations}</div>
            </div>
            <div className={"row"}>
                <div className={"col"}>{nr_users}:</div>
                <div className={"col"}/>
                <div className={"col"}>{splashData.worldsUserCount}</div>
            </div>
            <div className={"row"}>
                <div className={"col grayed"}>{latest_reg}:</div>
                <div
                    className={"col grayed"}>{splashData.userLastRegistration}{undefOrSpace(splashData.userLastRegistration) ? " " : ", "}{splashData.userLastRegistrationDate}</div>
                <div
                    className={"col grayed"}>{splashData.worldsLastRegistration}{undefOrSpace(splashData.worldsLastRegistration) ? " " : ","} {splashData.worldsLastRegistrationDate}</div>
            </div>
            <div className={"row"}>
                <div className={"col"}>{about_you}:</div>
                <div
                    className={"col"}>{user === undefined ? "" : user.email}, {user === undefined ? "" : user.phone}</div>
                <div className={"col"}/>
            </div>
            <div className={"container daily-message-container pt-5"}>
                <div className={"row grayed"}>
                    <div className={"col text-center"}>

                        {
                            splashData.currentAnnouncements !== undefined ?

                                splashData.currentAnnouncements.map(e => {
                                    return (

                                        <div className={"row"} key={e.announcement}>
                                            <div className={"col-sm-1"}/>
                                            <div className={"col-sm-2 text-left"}>
                                                <h6 className={"pt-1"}>{e.from}</h6>
                                            </div>
                                            <div className={"col-sm-9 text-left"}>
                                                <div className={"container"}>
                                                    <div className={"row"}>
                                                        <h4>{e.announcement}</h4>
                                                    </div>
                                                    <div className={"row"}>
                                                        <div className={"container"}>
                                                            {
                                                                e.message !== undefined ?
                                                                    e.message.map(message => {
                                                                        return (
                                                                            <div className={"row"}
                                                                                 key={message.paragraph}>
                                                                                <p className={"mb-1"}>{parse(message.paragraph)}</p>
                                                                            </div>)
                                                                    })
                                                                    :
                                                                    ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                ""
                        }
                    </div>
                </div>
            </div>
        </div> : <div/>;
        return (
            <div>
                <div aria-label={'home-page'}
                     className={"container"}>
                    <h2>{welcome} {user === undefined ? "" : user.firstName}!</h2>
                    <div className={"row"}>
                        <div className={"col"}>

                        </div>
                        <div className={"col"}><h5>{you}</h5></div>
                        <div className={"col"}><h5>{world}</h5></div>
                    </div>
                    {splashDataDiv}
                </div>
            </div>
        );
    }

    async getSplash() {
        const SPLASHAPI = '/api/v1/splash';

        let host;
        if (process.env.REACT_APP_LOCALHOST === undefined) {
            host = '';
        } else {
            host = process.env.REACT_APP_LOCALHOST;
        }
        const url = host + SPLASHAPI;

       const splash = await this.context.get(url, {
            success: "",
            failure: ""
        })

        if (splash.data) {
            this.setState({splashData: splash.data});
            this.props.setSplashData(splash.data);
        }
    }
}

export default Home;
