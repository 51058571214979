import React, {ReactElement} from "react";
import ReportTable, {Report} from "./ReportTable";
import TranslationService from "../../../infra/TranslationService";
import {FilterReport, SelectFilter} from "./FilterReport";
import {fetchArrayOf} from "../../../infra/BackendService";


interface props {
}

interface state {
    report: Report | undefined,
    kvaCodes: string[] | undefined
}


class DOPSReport extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            report: undefined,
            kvaCodes: undefined
        }
    }

    populateReport = (reportValue: Report) => {
        this.setState({report: reportValue});
    }

    render(): ReactElement {
        let kvaCodes = this.state.kvaCodes;
        if (kvaCodes === undefined) {
            return <div
                className={"spinner"}
                aria-label={"waiting for selectValues"}
            />
        }
        const selection = this.selectPeriod();
        let report = this.getReport();

        return <div>
            {selection}
            <hr/>
            {report}
        </div>;
    }

    private getReport() {
        if (this.state.report === undefined) {
            return <div/>
        }
        const legend = DOPSReport.getLegend();


        return <ReportTable report={this.state.report}
                            horizontalScrolling={true} legend={legend}/>
    }

    componentDidMount() {
        let url = "/api/v1/reports/dops-surgery/kva-codes";

        const kvaCodes: Promise<string[]> = fetchArrayOf<string>(url);
        kvaCodes.then((kvaCodes: string[]) => {
            if (kvaCodes.length > 1) {
                let blankValue = TranslationService.translation("dops-surgery.select.kva-code");
                kvaCodes.splice(0, 0, blankValue);
            }
            this.setState({kvaCodes: kvaCodes});

        });

    }

    private selectPeriod() {
        let url = "/api/v1/reports/dops-surgery/"
        let kvaCodes = this.state.kvaCodes;
        if (kvaCodes === undefined) {
            return <div
                className={"spinner"}
                aria-label={"waiting for selectValues"}
            />
        }

        if (kvaCodes?.length > 0) {
            let blankValue = "-- select a kvå code --";
            let selectFilter: SelectFilter = {
                values: kvaCodes,
                blankValue: blankValue,
                defaultValue: blankValue
            };
            return <div>
                <FilterReport onViewReport={this.populateReport} fetchReportsUrl={url} selectFilter={selectFilter}/>
            </div>
        }

        return <div>
            <FilterReport onViewReport={this.populateReport} fetchReportsUrl={url}/>
        </div>
    }

    private static getLegend() {
        const q1 = TranslationService.translation("dops-surgery.evaluation.report.legend.q1");
        const q2 = TranslationService.translation("dops-surgery.evaluation.report.legend.q2");
        const q3 = TranslationService.translation("dops-surgery.evaluation.report.legend.q3");
        const q4 = TranslationService.translation("dops-surgery.evaluation.report.legend.q4");
        const q5 = TranslationService.translation("dops-surgery.evaluation.report.legend.q5");
        const q6 = TranslationService.translation("dops-surgery.evaluation.report.legend.q6");
        const q7 = TranslationService.translation("dops-surgery.evaluation.report.legend.q7");
        const q8 = TranslationService.translation("dops-surgery.evaluation.report.legend.q8");
        const q9 = TranslationService.translation("dops-surgery.evaluation.report.legend.q9");
        const q10 = TranslationService.translation("dops-surgery.evaluation.report.legend.q10");


        const grade1 = "1 : " + TranslationService.translation("dops-surgery.evaluation.comprehension.a");
        const grade2 = "2 : " + TranslationService.translation("dops-surgery.evaluation.comprehension.b");
        const grade3 = "3 : " + TranslationService.translation("dops-surgery.evaluation.comprehension.c");
        const grade4 = "4 : " + TranslationService.translation("dops-surgery.evaluation.comprehension.d");
        const grade5 = "5 : " + TranslationService.translation("dops-surgery.evaluation.comprehension.e");

        const legendHeader = TranslationService.translation("dops-surgery.evaluation.report.legend.header");
        const gradingSystem = TranslationService.translation("dops-surgery.evaluation.report.legend.grading.system");
        return <div>
            <hr/>
            <div className="strong" aria-label="legendHeader">{legendHeader}</div>
            <div>{q1}</div>
            <div>{q2}</div>
            <div>{q3}</div>
            <div>{q4}</div>
            <div>{q5}</div>
            <div>{q6}</div>
            <div>{q7}</div>
            <div>{q8}</div>
            <div>{q9}</div>
            <div>{q10}</div>
            <hr/>
            <div className="strong" aria-label="gradingSystem">{gradingSystem}</div>
            <div>{grade1}</div>
            <div>{grade2}</div>
            <div>{grade3}</div>
            <div>{grade4}</div>
            <div>{grade5}</div>
        </div>

    }
}

export default DOPSReport;
