import React, {ChangeEvent, ReactElement} from 'react';
import {deleteItem, fetchArrayOf} from "../../../../infra/BackendService";
import {UserOldRoles} from "../../../model/UserOldRoles";
import DeleteLogo from "../../../../images/delete-512.png";

interface props {
    selectUser: (user: UserOldRoles | undefined) => void;
}

interface state {
    wantedName: string,
    users: UserOldRoles[],
    selected: UserOldRoles | undefined
}

class SearchUsers extends React.Component<props, state> {
    state: state = {
        wantedName: "",
        users: [],
        selected: undefined
    }

    render(): ReactElement {
        const users: UserOldRoles[] = this.state.users;
        const wantedName: string = this.state.wantedName;
        const searchField = this.getSearchField(wantedName);
        const foundUsers = this.getUsers(users);

        return <div>
            {searchField}
            {foundUsers}
        </div>
    }

    private getSearchField(wantedName: string) {
        return <div>
            <label className={"row m-0 pb-1"} htmlFor="user">Search for users</label>
            <input className={"row m-0 pb-1 mb-4"}
                   type={"text"}
                   id={"user"}
                   value={wantedName}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => this.onChange(e)}
            />
        </div>;
    }

    private getUsers(users: UserOldRoles[]) {
        return users.map((user: UserOldRoles, index: number) => {
            let className = "row m-0 pb-1 no-line-break";
            if (user === this.state.selected) {
                className = className + " selected";
            } else {
                className = SearchUsers.getClassName(className, index);
            }
            const userName = user.userName;
            const fullName = user.firstName + " " + user.lastName;

            const deleteUser = <img
                aria-label={"delete " + userName}
                height={16}
                src={DeleteLogo}
                alt={"Delete user"}
                onClick={() => this.deleteUser(userName, fullName)}
            />;

            return <div className={className} key={user.email}>
                <div className={"col-1"}>
                    {deleteUser}
                </div>
                <div aria-label={user.firstName + " " + user.lastName} className={"col-11"}
                     onClick={() => this.selectUser(user)}>
                    {fullName} {user.email}
                </div>

            </div>
        });
    }

    private onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const wantedName = e.currentTarget.value;
        this.setState({wantedName: wantedName});

        if (wantedName.length > 2) {
            this.searchFor(wantedName);
        } else {
            this.setState({users: []});
            this.selectUser(undefined);
        }
    }

    private searchFor(wantedName: string) {
        const url = "/api/v3/backoffice/user/search/" + wantedName;
        fetchArrayOf<UserOldRoles>(url).then((resolvedUsers: UserOldRoles[]) => {
            this.setState({users: resolvedUsers});
        });
    }

    private selectUser(user: UserOldRoles | undefined) {
        this.props.selectUser(user);
        this.setState({selected: user})
    }

    private static getClassName(src: string, index: number) {
        const rowNumber = index + 1;
        if (rowNumber % 2 === 0) {
            return src + " grayed";
        }

        return src;
    }

    private deleteUser(userName: string, fullName: string) {
        let confirm = window.confirm("Do you want to forget " + fullName + " according to GDPR?");
        if (confirm) {
            const url = "/api/v3/backoffice/user/" + userName
            deleteItem(url).then(() => {
                const wantedName = this.state.wantedName;
                this.searchFor(wantedName);
            })
        }
    }
}

export default SearchUsers;