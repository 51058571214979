import React from "react";
import {Action, Field} from "../../register/v2/Action";
import {AgeFilterSelection, GraphOptions} from "../v2/GraphOptions";
import Checkbox from "../../fields/v2/Checkbox";
import {ALL_ALTERNATIVES, DESKTOP} from "../../../infra/Constants";
import Event, {getValue} from "../../register/v3/Event";
import StatisticsNumberField from "./StatisticsNumberField";
import {FilterValue} from "./FilterValue";
import StatisticsDateField from "./StatisticsDateField";
import StatisticsAgeField from "./StatisticsAgeField";
import StatisticsPeriodField from "./StatisticsPeriodField";

export default function getField(field: Field,
                                 graphOptions: GraphOptions,
                                 event: Event,
                                 duplicationIndex: string,
                                 action: Action,
                                 user: any,
                                 onValueChange: (name: string, value: (string | string[]), duplicationIndex: string, valid: boolean, field: Field) => void,
                                 onValueChangeNG: (filterValue: FilterValue) => void,
                                 onAgeSelectionChange?: (ageSelection: AgeFilterSelection) => void,
): React.ReactFragment {

    const fieldName = field.name;
    const fieldType = field.type;
    const frontendVersion = action.frontendVersion;
    const key = fieldName + "-" + duplicationIndex;

    if(graphOptions && onAgeSelectionChange){
        //linter
    }

    if (fieldType === "number") {
        if (onValueChangeNG !== undefined) {
            return <StatisticsNumberField field={field}
                                          onChange={onValueChange}
                                          onValueChangeNG={onValueChangeNG}
            />
        }
    }

    if (fieldType === "date") {
        if (onValueChangeNG !== undefined) {
            return <StatisticsDateField field={field}
                                        onChange={onValueChange}
                                        onValueChangeNG={onValueChangeNG}
            />
        }
    }

    if (fieldType === "age") {
        if (onValueChangeNG !== undefined) {
            return <StatisticsAgeField field={field}
                                       onChange={onValueChange}
                                       onValueChangeNG={onValueChangeNG}
            />
        }
    }

    if (fieldType === "period") {
        if (onValueChangeNG !== undefined) {
            return <StatisticsPeriodField field={field}
                                          onChange={onValueChange}
                                          onValueChangeNG={onValueChangeNG}
            />
        }
    }

    if (field.options === undefined) {
        return <div aria-label={'empty label for ' + fieldName}/>;
    }

    const value: string | string[] | undefined = getValue(fieldName, event, '0');
    let currentValue: string[] = [];
    if (value === undefined) {
        currentValue.push(fieldName + '.' + ALL_ALTERNATIVES);
    } else if (typeof value === 'string') {
        currentValue.push(value);
    } else if (Array.isArray(value)) {
        currentValue = value;
    }

    return <Checkbox key={key}
                     field={field}
                     device={DESKTOP}
                     frontendVersion={frontendVersion}
                     value={currentValue}
                     duplicationIndex={duplicationIndex}
                     onChange={onValueChange}
                     onValueChangeNG={onValueChangeNG}
                     event={event}
                     enableAllOptions={true}
                     action={action}
                     user={user}
    />
}
