import React, {ReactElement} from 'react';
import "../register/v2/Register.css"
import {StatisticsService} from "../statistics/StatisticsService";
import {UpdateAble} from "../register/v2/UpdateAble";
import C3Wrapper from "./C3Wrapper";
import TranslationService from "../../infra/TranslationService";
import {GraphOptions} from "../statistics/v2/GraphOptions";
import "./Graphs.css"
import HelpModalDialog from "../help/HelpModalDialog";
import {Organisation} from "../model/Organisation";
import {Graph} from "./Graph";
import {isPlaceholderOrganisation} from "../organisation/SelectOrganisation";

interface props {
    actionName: string,
    actionVersion: number,
    showDescription: boolean,
    graphOptions: GraphOptions,
    statisticsService: StatisticsService,
    graphPlaceholder?: any
}

interface state {
    userGraph: Graph | undefined,
    organisationGraph: Graph | undefined,
    worldGraph: Graph | undefined,
    userSpinner: boolean,
    organisationSpinner: boolean,
    worldSpinner: boolean,
    showHelp: boolean
}

class Graphs extends React.Component<props, state> implements UpdateAble {
    scopeUser: string = 'user';
    scopeOrganisation: string = 'organisation';
    scopeWorld: string = 'world';
    amIMounted: boolean = false;

    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            userGraph: undefined,
            organisationGraph: undefined,
            worldGraph: undefined,
            userSpinner: true,
            organisationSpinner: this.shouldShowOrgGraph(this.props.graphOptions),
            worldSpinner: true,
            showHelp: false
        }
    }

    render(): ReactElement {
        const currentOrganisation: Organisation | undefined = this.props.graphOptions.currentOrganisation;
        const headlines: React.JSX.Element = this.getHeadlines(currentOrganisation);
        const graphs: React.JSX.Element = this.getGraphs(currentOrganisation);
        const descriptions: React.JSX.Element = this.getDescriptions(currentOrganisation);
        const helpDialog: React.JSX.Element = this.getHelp();

        return <div aria-label={"graphs"}>
            <div>
                <div className={"row"}>
                    {headlines}
                </div>
                <div className={"row"}>
                    {graphs}
                </div>
                <div className={"row"}>
                    {descriptions}
                </div>
            </div>
            <div>
                {helpDialog}
            </div>
        </div>
    }

    private getHeadlines(currentOrganisation: Organisation | undefined): React.JSX.Element {
        const myStatsLabel: string = TranslationService.translation("my_stats");
        const worldStatsLabel: string = TranslationService.translation("everyones_stats");

        if (currentOrganisation === undefined || isPlaceholderOrganisation(currentOrganisation)) {
            return <>
                <div className={"col text-center"}
                     aria-label={this.scopeUser + " graph headline"}
                >
                    <h4>
                        {myStatsLabel}
                    </h4>
                </div>
                <div className={"col text-center"}
                     aria-label={this.scopeWorld + " graph headline"}
                >
                    <h4>
                        {worldStatsLabel}
                    </h4>
                </div>
            </>;
        } else {
            let organisationStatsLabel: string = '';
            let currentOrganisation = this.props.graphOptions.currentOrganisation;
            if (currentOrganisation !== undefined) {
                organisationStatsLabel = currentOrganisation.organisationName;
            }
            return <>
                <div className={"col text-center"}
                     aria-label={this.scopeUser + " graph headline"}
                >
                    <h4>
                        {myStatsLabel}
                    </h4>
                </div>
                <div className={"col text-center"}
                     aria-label={this.scopeOrganisation + " graph headline"}
                >
                    <h4>
                        {organisationStatsLabel}
                    </h4>
                </div>
                <div className={"col text-center"}
                     aria-label={this.scopeWorld + " graph headline"}
                >
                    <h4>
                        {worldStatsLabel}
                    </h4>
                </div>
            </>;
        }
    }

    private getGraphs(currentOrganisation: Organisation | undefined): React.JSX.Element {
        let userGraph;
        if (this.state.userSpinner) {
            userGraph = <div
                className={"spinner"}
                aria-label={"waiting for " + this.scopeUser + " graph"}
            />
        } else {
            userGraph = this.getGraph(this.scopeUser);
        }

        let organisationGraph;
        if (this.state.organisationSpinner) {
            organisationGraph = <div
                className={'spinner'}
                aria-label={'waiting for ' + this.scopeOrganisation + ' graph'}
            />
        } else {
            if (this.shouldShowOrgGraph(this.props.graphOptions)) {
                organisationGraph = this.getGraph(this.scopeOrganisation);
            }
        }

        let worldGraph;
        if (this.state.worldSpinner) {
            worldGraph = <div
                className={"spinner"}
                aria-label={"waiting for " + this.scopeWorld + " graph"}
            />
        } else {
            worldGraph = this.getGraph(this.scopeWorld);
        }

        if (currentOrganisation === undefined) {
            return <>
                <div className={"col"}>
                    {userGraph}
                </div>
                <div className={"col"}>
                    {worldGraph}
                </div>
            </>;
        } else {
            return <>
                <div className={"col"}>
                    {userGraph}
                </div>
                <div className={"col"}>
                    {organisationGraph}
                </div>
                <div className={"col"}>
                    {worldGraph}
                </div>
            </>;
        }
    }

    private getDescriptions(currentOrganisation: Organisation | undefined): React.JSX.Element {
        const userDescription: React.JSX.Element = this.getUserDescription();
        const worldDescription: React.JSX.Element = this.getWorldDescription();

        if (currentOrganisation === undefined || isPlaceholderOrganisation(currentOrganisation)) {
            return <>
                <div className={"col text-center"}>
                    {userDescription}
                </div>
                <div className={"col text-center"}>
                    {worldDescription}
                </div>
            </>;
        } else {
            const organisationDescription: React.JSX.Element = this.getOrganisationDescription();
            return <>
                <div className={"col text-center"}>
                    {userDescription}
                </div>
                <div className={"col text-center"}>
                    {organisationDescription}
                </div>
                <div className={"col text-center"}>
                    {worldDescription}
                </div>
            </>;
        }
    }

    componentDidUpdate(prevProps: Readonly<props>): void {
        const graphOptions: GraphOptions = this.props.graphOptions;
        const prevGraphOptions: GraphOptions = prevProps.graphOptions;

        //strong arguments could be made that this should reside in "shouldComponentUpdate" hook
        if (JSON.stringify(graphOptions) !== JSON.stringify(prevGraphOptions)) {
            this.update();
        }
    }

    componentDidMount() {
        this.amIMounted = true;
        this.update();
    }

    componentWillUnmount() {
        this.amIMounted = false;
    }

    update(): void {
        if (this.updateGraphs()) {
            this.setState({
                userSpinner: true,
                worldSpinner: true,
                organisationSpinner: this.shouldShowOrgGraph(this.props.graphOptions)
            });

            const backend: StatisticsService = this.props.statisticsService;
            const actionName: string = this.props.actionName;
            const actionVersion: number = this.props.actionVersion;
            const graphOptions: GraphOptions = this.props.graphOptions;

            backend.getGraph(actionName, actionVersion, graphOptions, this.scopeUser)
                .then((graph: Graph) => {
                    if (this.validateGraph(graph)) {
                        if (this.amIMounted) {
                            this.setState({
                                userGraph: graph,
                                userSpinner: false
                            });
                        }
                    }
                });

            if (this.shouldShowOrgGraph(graphOptions)) {
                backend.getGraph(actionName, actionVersion, graphOptions, this.scopeOrganisation)
                    .then((graph: Graph) => {
                        if (this.validateGraph(graph)) {
                            if (this.amIMounted) {
                                this.setState({
                                    organisationGraph: graph,
                                    organisationSpinner: false
                                });
                            }
                        }
                    });
            }

            backend.getGraph(actionName, actionVersion, graphOptions, this.scopeWorld)
                .then((graph: Graph) => {
                    if (this.validateGraph(graph)) {
                        if (this.amIMounted) {
                            this.setState({
                                worldGraph: graph,
                                worldSpinner: false
                            });
                        }
                    }
                });
        }
    }

    private updateGraphs(): boolean {
        const graphPlaceholder = this.props.graphPlaceholder;
        if (graphPlaceholder !== undefined) {
            const graph = this.props.graphPlaceholder;
            const graphOptions: GraphOptions = this.props.graphOptions;
            this.setState({
                userGraph: graph,
                worldGraph: graph,
                userSpinner: false,
                worldSpinner: false
            });

            if(this.shouldShowOrgGraph(graphOptions)){
                this.setState({
                    organisationGraph: graph,
                    organisationSpinner: false
                });
            }

            return false;
        }

        return true;
    }

    private shouldShowOrgGraph(graphOptions: GraphOptions) {
        return graphOptions.currentOrganisation !== undefined && !isPlaceholderOrganisation(graphOptions.currentOrganisation);
    }

    validateGraph(graph: Graph | undefined): boolean {
        return graph !== undefined &&
            graph.data !== undefined;
    }

    private getGraph(scope: string): React.JSX.Element {
        let graph: Graph | undefined;
        if (scope === this.scopeUser) {
            graph = this.state.userGraph;
        } else if (scope === this.scopeOrganisation) {
            graph = this.state.organisationGraph;
        } else {
            graph = this.state.worldGraph;
        }

        const ariaLabel = scope + " graph";
        const id = "chart_" + scope;

        if (graph !== undefined) {
            return <div aria-label={ariaLabel}>
                <C3Wrapper
                    id={id}
                    data={graph.data}
                    axis={graph.axis}
                />
            </div>;
        } else {
            return <div/>;
        }
    }

    private getUserDescription(): React.JSX.Element {
        const showDescription: boolean = this.props.showDescription;
        const graph = this.state.userGraph;

        if (showDescription &&
            graph !== undefined &&
            graph.emptyGraphReason === undefined) {
            const filteredRegistrations = graph.filteredRegistrations;
            const groupRegistrations = graph.groupRegistrations;
            const totalRegistrations = graph.totalRegistrations;

            let registrationsOf: string = TranslationService.translation('registrations of');
            let groupOf: string = TranslationService.translation('in the group of');
            let forYou: string = TranslationService.translation('for you');

            return <div aria-label={this.scopeUser + " graph description"}>
                {filteredRegistrations} {registrationsOf} {groupRegistrations} {groupOf} {totalRegistrations} {forYou}
            </div>;
        } else {
            return <div/>
        }
    }

    private getOrganisationDescription(): React.JSX.Element {
        const showDescription: boolean = this.props.showDescription;
        const graph = this.state.organisationGraph;

        if (showDescription &&
            graph !== undefined &&
            graph.emptyGraphReason === undefined) {
            const filteredRegistrations = graph.filteredRegistrations;
            const groupRegistrations = graph.groupRegistrations;
            const totalRegistrations = graph.totalRegistrations;

            let registrationsOf: string = TranslationService.translation('registrations of');
            let groupOf: string = TranslationService.translation('in the group of');
            let forYourOrganisation: string = TranslationService.translation('for your organisation');

            return <div aria-label={this.scopeOrganisation + " graph description"}>
                {filteredRegistrations} {registrationsOf} {groupRegistrations} {groupOf} {totalRegistrations} {forYourOrganisation}
            </div>;
        } else {
            return <div/>
        }
    }

    private getWorldDescription(): React.JSX.Element {
        const showDescription: boolean = this.props.showDescription;
        const graph = this.state.worldGraph;

        if (showDescription &&
            graph !== undefined &&
            graph.emptyGraphReason === undefined) {
            const filteredRegistrations = graph.filteredRegistrations;
            const groupRegistrations = graph.groupRegistrations;
            const totalRegistrations = graph.totalRegistrations;

            let registrationsOf: string = TranslationService.translation('registrations of');
            let groupOf: string = TranslationService.translation('in the group of');
            let forWorld: string = TranslationService.translation('for the world');

            /*
            With graph help dialogue
            return <div aria-label={this.scopeWorld + " graph help"}
                        onClick={() => this.showHelp()}>
                <div aria-label={this.scopeWorld + " graph description"}>
                    {filteredRegistrations} {registrationsOf} {groupRegistrations} {groupOf} {totalRegistrations} {forWorld}
                    <sup className={"btn-link"}>[?]</sup>
                </div>
            </div>;
             */
            return <div aria-label={this.scopeWorld + " graph description"}>
                {filteredRegistrations} {registrationsOf} {groupRegistrations} {groupOf} {totalRegistrations} {forWorld}
            </div>;
        } else {
            return <div/>
        }
    }

    private getHelp(): React.JSX.Element {
        const showHelp = this.state.showHelp;
        if (showHelp) {
            const headLine = "Världens statistik";
            const helpBody = [
                "Det kan synas märkligt hur statistiken ser ut för världen.",
                "Kolla på videon så får du en bättre förklaring."
            ];
            const youtubeLink = "https://www.youtube.com/embed/bf2m7HV6Ff4";

            return <div>
                <HelpModalDialog
                    hide={this.hideHelp}
                    headline={headLine}
                    body={helpBody}
                    youtubeLink={youtubeLink}
                />
            </div>;
        } else {
            return <div/>;
        }
    }

    hideHelp = () => {
        this.setState({showHelp: false});
    }
}

export default Graphs;
