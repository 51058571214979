import {ModalDisplayBinder} from "./KnarkbokModal";
import {
    Binder,
    BinderId,
    Book,
    BookId,
    Drug,
    DrugEvent,
    DrugEventSummary,
    DrugOrganisation,
    KnarkbokType
} from "./models/Models";
import {EventBackendService} from "../register/v2/EventBackendService";


export class KnarkbokService {

    /**
     * Get inventory for a station / car / place
     * @param binderId which station / car / place to fetch
     */
    async getBooksPerBinderId(binderId: BinderId): Promise<Book[]> {
        const path = `/api/v1/knarkbok/books/binder/${binderId.id}`
        const url = EventBackendService.getUrl2(path);

        return await EventBackendService.get(url) as Book[];
    }

    /**
     * sadf
     * @param par parameter from url
     */
    async getBooksFromPathParam(par: string): Promise<{
        books: Book[],
        binder: Binder,
        acceptDelivery: boolean,
        requireWitness: boolean,
        canMoveDrugs: boolean
    }> {
        const path: string = `/api/v1/knarkbok/books/binder/path/${par}`
        const url: string = EventBackendService.getUrl2(path);

        return await EventBackendService.get(url)
    }


    /**
     * For a binder get drug location for display, and inventory as hidden value
     * @param {BookId} id
     */
    async getModalDisplayBinder(id: BookId): Promise<ModalDisplayBinder | null> {
        const path: string = `/api/v1/knarkbok/books/${id.id}`
        const url: string = EventBackendService.getUrl2(path);

        return await EventBackendService.get(url) as ModalDisplayBinder;
    }

    /**
     * returns the book for a drug and a location, undef for not found
     * @param binderId
     * @param drug
     */
    async getBookByDrugBinder(binderId: number, drug: Drug): Promise<Book> {
        //TODO should we allow movement from places that have no book?
        const path = `/api/v1/knarkbok/books/binder/${binderId}/drug/${drug.id?.id}`
        const url = EventBackendService.getUrl2(path);

        let book: Book;
        book = await EventBackendService.get(url) as Book;
        return book;
    }

    /**
     * checks if new reported values match what is expected when values change. There could be a random component to this aswell, depending on what is specified in the future
     * @param oldInventory
     * @param newBalance
     * @param type
     * @param amount
     */
    aboutToSubmitCheck(oldInventory: number, newBalance: string | undefined, type: KnarkbokType, amount: number = 0): boolean {
        if (!newBalance) {
            return false;
        }

        if (!oldInventory) {
            oldInventory = 0;
        }

        let expected: number;
        if (type === KnarkbokType.move_neg || type === KnarkbokType.discard || type === KnarkbokType.administer) {
            expected = oldInventory - amount;
            return +newBalance === expected;
        } else if (type === (KnarkbokType.move_pos)) {
            expected = oldInventory + amount;
            return +newBalance === expected;
        } else {
            //defaults to KnarkbokType.check
            return oldInventory === +newBalance;
        }
    }

    /**
     * Fetch summary for all stations a user is allowed to see.
     * @param orgId
     */
    async getDrugEventSummaryStationForOrg(orgId: string): Promise<DrugEventSummary[]> {
        const path = `/api/v1/knarkbok/druglord/overview/${orgId}`
        const url = EventBackendService.getUrl2(path);

        return await EventBackendService.get(url) as DrugEventSummary[];
    }

    async getEventLogForDrug(id: BookId): Promise<DrugEvent[]> {
        const path = `/api/v1/knarkbok/druglord/details/${id.id}`
        const url = EventBackendService.getUrl2(path);

        return await EventBackendService.get(url) as DrugEvent[];
    }

    async getOrganisation(drugBook: string): Promise<DrugOrganisation> {
        const path: string = `/api/v1/knarkbok/organisations/${drugBook}`
        const url: string = EventBackendService.getUrl2(path);

        return await EventBackendService.get(url) as DrugOrganisation;
    }
}
