import React from 'react';
import EventDetailModal from "./EventDetailModal";
import {getLegendClass} from "../../infra/Utils";
import PrehospitalHome from "./PrehospitalHome";
import {fetchArrayOf, fetchObjectOf} from "../../infra/BackendService";
import {UserOldRoles} from "../model/UserOldRoles";
import InfiniteScroll from "react-infinite-scroll-component";
import {Field} from "../register/v2/Action";
import TranslationService from "../../infra/TranslationService";
import PrehospitalFiltersDynamicSelect from "../fields/v2/PrehospitalFiltersDynamicSelect";
import {Organisation} from "../model/Organisation";

interface state {
    displayModal: boolean,
    modalData: MissionField[] | null
    owner?: UserOldRoles,
    hasMore: boolean,
    totalDisplayed: number,
    displayedReports: Mission[],
    currentIndex: number,
    currentFilters: string[] | undefined,
    missionCount: number
}

interface props {
    currentOrganisation?: Organisation | undefined
}

export interface Mission {
    "date": string | null,
    "time": string | null,
    "base": string | null,
    "naca": string | null,
    "diagnosis": string | null,
    "keywords": string | null,
    "flags": (string []) | null,
    "eventId": string,
    "user": UserOldRoles
}

export interface MissionDetailReport {
    missionFields: MissionField[],
    shouldSee: boolean
}

export interface MissionField {
    value: string,
    duplicationIndex: string
    level: number
}

class PrehospitalMissionReport extends React.Component<props, state> {
    constructor(props: any) {
        super(props);
        this.onFilterSelect = this.onFilterSelect.bind(this);

        this.state = {
            displayModal: false,
            modalData: null,
            displayedReports: [],
            hasMore: true,
            totalDisplayed: 0,
            currentIndex: 0,
            missionCount: 0,
            currentFilters: []
        }
    }

    componentDidMount() {
        this.fetchMissions();
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        if (prevProps.currentOrganisation !== this.props.currentOrganisation) {
            this.fetchMissions();
        }
    }

    private fetchMissions() {
        let missionCountUrl: string = "/api/v2/reports/prehospital/filtered/count";
        const currentOrganisation = this.props.currentOrganisation;
        let missionsAsRowsUrl: string = "/api/v2/reports/prehospital/filtered/missions"
        if (currentOrganisation !== undefined) {
            missionCountUrl = missionCountUrl + "/" + currentOrganisation.organisationId;
            missionsAsRowsUrl = missionsAsRowsUrl + "/" + currentOrganisation.organisationId;
        }
        missionsAsRowsUrl = missionsAsRowsUrl + "/0/20";
        fetchObjectOf<number>(missionCountUrl).then((count) => {
            if (count !== undefined) {
                this.setState({missionCount: count})
            }
        });
        fetchArrayOf<Mission>(missionsAsRowsUrl).then((missionsAsRows) => {
            this.setState({displayedReports: missionsAsRows})
        });
    }

    fetchData = () => {
        const currentOrganisation = this.props.currentOrganisation;
        let index = this.state.currentIndex + 20;
        if (index >= this.state.missionCount) {
            this.setState({hasMore: false})
        } else {
            let filterUrlSuffix = "";
            if (this.state.currentFilters !== undefined) {
                filterUrlSuffix = this.getFilterUrlSuffix(this.state.currentFilters);
            }

            let missionsUrl = "/api/v2/reports/prehospital/filtered/missions/";
            if (currentOrganisation !== undefined) {
                missionsUrl = missionsUrl + currentOrganisation.organisationId + "/";
            }
            let missionsAsRowsUrl: string = missionsUrl + index + "/20" + filterUrlSuffix;
            fetchArrayOf<Mission>(missionsAsRowsUrl).then((missionsAsRows) => {
                if (missionsAsRows.length === 0) {
                    this.setState({hasMore: false})
                } else {
                    this.setState({
                        displayedReports: [...this.state.displayedReports, ...missionsAsRows],
                        currentIndex: index
                    })
                }
            });

        }

    }


    render() {
        let modal = <EventDetailModal data={this.state.modalData} owner={this.state.owner}
                                      flipVisibility={this.flipVisibility} actionName={"prehospital-response-unit"}/>
        const tableStyle = {
            border: "none",
            boxShadow: "none"
        };

        const filterSelectionField: Field = {
            "name": "prehospital-response-unit.report.select.filter",
            "fieldLabel": true,
            "type": "dynamicSelectWithoutTranslation",
            "optionsSource": "allPrehospitalFilters",
            "duplicable": false,
            "multiple": true,
            "duplicationIndex": 0,
            "showDefaultOption": false,
        }

        let filtersDynamicSelect = <PrehospitalFiltersDynamicSelect field={filterSelectionField}
                                                                    frontendVersion={"v3"}
                                                                    value={this.state.currentFilters}
                                                                    duplicationIndex={"0"}
                                                                    onChange={this.onFilterSelect}
                                                                    optionsSource={"allPrehospitalFilters"}
                                                                    currentOrganisation={this.props.currentOrganisation}
                                                                    actionName={"prehospital-response-unit"}
                                                                    version={0}
        />
        let body = this.state.displayedReports.map((row: Mission, i: number) => {
            return (
                <tr key={row.eventId} className={"pt-1 pl-0 pr-0" + (i % 2 === 1 ? " grayed" : "")}
                    onClick={() => this.flipVisibility(row.eventId, row.user)} style={tableStyle}>
                    <td className={"col-nowrap"} style={tableStyle}>{row.date}</td>
                    <td className={"col-nowrap"} style={tableStyle}>{row.time}</td>
                    <td className={"col-nowrap"} style={tableStyle}>{row.base}</td>
                    <td className={"col-nowrap"} style={tableStyle}>{row.naca}</td>
                    <td className={"col-nowrap"} style={tableStyle}>{row.diagnosis}</td>
                    <td className={"col-nowrap"}
                        style={tableStyle}>{PrehospitalMissionReport.addClassesToFlags(row.flags)}</td>
                    <td className={"col-nowrap"} style={tableStyle}>{row.keywords}</td>
                </tr>);
        })
        let legend = <div className={"row m-0 p-0"}>
            <div className={"col-sm-12 col-md-12 col-lg-3 col-xl-3 m-0 pl-3"}>
                {PrehospitalHome.getLegend(true)}
            </div>
        </div>;
        let table = <div className={"table-responsive"}>
            <table className="table" style={tableStyle}>
                <thead>
                <tr style={tableStyle} className={"m-0 p-0"}>
                    <th scope="col" style={tableStyle}>Datum</th>
                    <th scope="col" style={tableStyle}>Tid</th>
                    <th scope="col" style={tableStyle}>Bas</th>
                    <th scope="col" style={tableStyle}>NACA</th>
                    <th scope="col" style={tableStyle}>Diagnos</th>
                    <th scope="col" style={tableStyle}>Flaggor</th>
                    <th scope="col" style={tableStyle}>Nyckelord</th>
                </tr>
                </thead>
                <tbody>
                {body}
                {
                    this.state.displayModal ? modal : null
                }
                </tbody>
            </table>
        </div>
        let loadMoreMissions = TranslationService.translation("prehospital-response-unit.report.scroll.down.for.more.missions");
        let noMoreMissions = TranslationService.translation("prehospital-response-unit.report.no.more.missions");
        return <div>
            <div aria-label={"last missions report"}>
                <h3 className={"strong pb-2"}>Senaste uppdragen</h3>
            </div>
            {legend}
            {filtersDynamicSelect}
            <h5 className={"strong pb-2"}>Antal uppdrag med nuvarande filter: {this.state.missionCount} </h5>
            <InfiniteScroll
                dataLength={this.state.displayedReports.length}
                next={this.fetchData}
                hasMore={this.state.hasMore}
                loader={<p style={{textAlign: 'center'}}>
                    <b>{loadMoreMissions}</b>
                </p>}
                endMessage={
                    <p style={{textAlign: 'center'}}>
                        <b>{noMoreMissions}</b>
                    </p>
                }
            >
                {table}
            </InfiniteScroll>
            <hr/>
        </div>
    }

    private onFilterSelect(_name: string, value: string | string[], _duplicationIndex: string, _valid: boolean, _field: Field) {
        let filters: string[] = [];
        if (value !== undefined && value !== null) {
            if (typeof value === "string") {
                filters.push(value);
            } else {
                filters.push(...value);
            }
        }
        let basesUrlSuffix = this.getFilterUrlSuffix(value);
        let missionCountUrl: string = "/api/v2/reports/prehospital/filtered/count";
        const currentOrganisation = this.props.currentOrganisation;
        if (currentOrganisation !== undefined) {
            missionCountUrl = missionCountUrl + "/" + currentOrganisation.organisationId;
        }
        missionCountUrl = missionCountUrl + basesUrlSuffix;

        fetchObjectOf<number>(missionCountUrl).then((count) => {
            let missionsAsRowsUrl: string = "/api/v2/reports/prehospital/filtered/missions/";
            if (currentOrganisation !== undefined) {
                missionsAsRowsUrl = missionsAsRowsUrl + currentOrganisation.organisationId + "/";
            }
            missionsAsRowsUrl = missionsAsRowsUrl + "0/20" + basesUrlSuffix;
            fetchArrayOf<Mission>(missionsAsRowsUrl).then((missionsAsRows) => {
                if (count !== undefined) {
                    let hasMore = count > 20;
                    this.setState({
                        missionCount: count, hasMore: hasMore,
                        displayedReports: missionsAsRows, currentIndex: 0, currentFilters: filters
                    })
                }

            });
        });

    }


    private getFilterUrlSuffix(value: string | string[]) {
        let filterUrlSuffix = "";
        if (value !== undefined && value !== null) {
            if (typeof value === "string") {
                filterUrlSuffix = "?filterBy=" + value;
            } else {
                if (value.length > 0) {
                    filterUrlSuffix = "?filterBy=" + value[0];
                    if (value.length > 1) {
                        for (let i = 1; i < value.length; i++) {
                            filterUrlSuffix = filterUrlSuffix + "&filterBy=" + value[i];
                        }
                    }
                }
            }
        }
        return filterUrlSuffix;
    }

    flipVisibility = (id?: string, user?: UserOldRoles) => {
        if (id) {
            let missionDetailUrl: string = "/api/v1/reports/prehospital/mission/" + id;
            fetchObjectOf<MissionDetailReport>(missionDetailUrl).then((missionDetailReport) => {
                if (missionDetailReport !== undefined && missionDetailReport.shouldSee) {
                    this.setState({
                        modalData: missionDetailReport.missionFields,
                        displayModal: !this.state.displayModal
                    })
                }
            });
        } else {
            this.setState({displayModal: !this.state.displayModal});
        }
        if (user) {
            this.setState({owner: user})
        }

    }

    private static addClassesToFlags(flags: string[] | null): React.JSX.Element {
        const classedFlags = [];
        if (flags) {
            let index = 0;
            for (const flag of flags) {

                let className: string;
                if (index === 0) {
                    className = getLegendClass(flag);
                } else {
                    className = "pl-2 " + getLegendClass(flag);
                }

                const classifiedFlag = <div key={index + "--" + flag}
                                            className={className}>
                    {flag}
                </div>;

                classedFlags.push(classifiedFlag)

                index++;
            }
        }

        return <div className={"row m-0 p-0"}>{classedFlags}</div>
    }
}

export default PrehospitalMissionReport;
