import React from "react";
import {Link, NavLink} from "react-router-dom";
import "./EmptyNavBar.css";
import logo from "../../images/logo-inverted.png"
import LoginNavbar from "../public/LoginNavbar";
import {changeLayoutWidth, DESKTOP, MOBILE} from "../../infra/Constants";
import {UserData} from "../model/UserData";
import TranslationService from "../../infra/TranslationService";

interface props {
    loggedIn: () => void
    setUserData: (userData: UserData) => void
    setLoading: (state: boolean) => void
    layout: string,
    domain: string
}

interface state {
    layout: string
}

class EmptyNavBar extends React.Component<props, state> {

    constructor(props: props) {
        super(props);

        this.onResize = this.onResize.bind(this);

        this.state = {
            layout: DESKTOP
        };
    }

    async componentDidMount() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        const width: number = window.innerWidth;
        const layout: string = this.state.layout;

        if (width > changeLayoutWidth && layout === MOBILE) {
            this.setState({
                layout: DESKTOP
            });
        }

        if (width <= changeLayoutWidth && layout === DESKTOP) {
            this.setState({
                layout: MOBILE
            });
        }
    }

    render() {
        const layout: string = this.state.layout;
        let loginNavbar = <div/>;
        if (layout === DESKTOP) {
            loginNavbar = <LoginNavbar loggedIn={this.props.loggedIn}
                                       domain={this.props.domain}
                                       setUserData={this.props.setUserData}
                                       setLoading={this.props.setLoading}/>;
        }

        let createAccountText = TranslationService.translatePerDomain("Skapa konto", this.props.domain);

        return (
            <React.Fragment>
                <nav className={"nav navbar navbar-expand-sm navBarStyle"}>
                    <div className={"container"}>
                        <Link className={"btn-custom-tage-primary navbar-brand"} to="/">
                            <img src={logo} alt={"logo"} height={42}/>
                        </Link>

                        <ul className={"navbar-nav ml-auto"}>
                            <li className={"nav-item"}>
                                {loginNavbar}
                            </li>
                            <li className={"nav-item"}>
                                <NavLink to={"/signup"}
                                         className={"btn btn-register-empty btn-custom-tage-primary nav-link m-1 rootNavLink"}
                                         aria-label={"Signup for an account"}
                                >
                                    {createAccountText}
                                </NavLink>
                            </li>
                        </ul>

                    </div>
                </nav>
            </React.Fragment>
        );
    }
}

export default EmptyNavBar;
