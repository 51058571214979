import {getSecurityToken} from "./SecurityToken";

export function logoutWithBackup() {
    let host: string;
    if (process.env.REACT_APP_LOCALHOST === undefined) {
        host = '';
    } else {
        host = process.env.REACT_APP_LOCALHOST;
    }

    const LOGOUTURI: string = "/api/v1/logout";
    const url: string = host + LOGOUTURI;

    fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            'X-Custom-header': getSecurityToken()
        },
        body: JSON.stringify({"log": "out"})
    }).then(() => {
        logout();
    }).catch(console.log);

    // Allow the frontend some time to actually send the logout post before logout
    setTimeout(function () {
        logout();
    }, 200);
}

export function logout() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.assign("/");
}
