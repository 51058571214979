import React from 'react';
import TranslationService from "../../infra/TranslationService";
import {uuidv4} from "../register/v2/Uuid";
import {BackendContext, BackendInterface} from "../../infra/BackendContext";
import {EventBackendService} from "../register/v2/EventBackendService";

interface state {
    additionalErrorInfo: string[],
    showSpinner: boolean
}

export interface ExportResponse {
    rows: string
    fileName: string,
    additionalErrorInfo: string[],

}

interface props {
    showFailureMessage: (show: boolean) => void,
    hasExportRight: boolean,
    buttonText: string
    actionName: string,
    version: number,
    scope: string,
    organisationId?: string
}

class ExportEventsButton extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: any) {
        super(props);

        this.state = {
            additionalErrorInfo: [],
            showSpinner: false
        }
    }

    render() {
        const hasErrors: boolean = this.getHasErrors();

        let snackbar: React.JSX.Element = <div/>;
        if (hasErrors) {
            snackbar = <div>
                <div>
                    {TranslationService.translation("some data could not be exported")}
                </div>
                <div>{TranslationService.translation("prehospital-response-unit.missing.events.details")}</div>
            </div>;
        }

        let additionalErrors: React.JSX.Element[] = [];
        if (hasErrors) {
            const additionalErrorInfo: string[] = this.state.additionalErrorInfo;
            additionalErrors = additionalErrorInfo.map((error) =>
                <li key={uuidv4()}>{error}</li>
            );
        }

        let spinner: React.JSX.Element = <div/>;
        let showSpinner: boolean = this.state.showSpinner;
        if (showSpinner) {
            spinner = <div
                className={"spinner"}
                aria-label={"waiting for export events"}
            />;
        }

        let directExportButton: React.JSX.Element = <div/>;
        const hasExportRight: boolean = this.props.hasExportRight;
        if (hasExportRight) {
            const buttonText: string = TranslationService.translation(this.props.buttonText);

            const action: string = this.props.actionName;
            const version: number = this.props.version;
            const scope: string = this.props.scope;
            let url: string = '/api/v2/export/events/' + action + '/' + version + '/' + scope;

            let organisationId: string | undefined = this.props.organisationId;
            if (organisationId !== undefined) {
                url = url + '/' + organisationId;
            }

            directExportButton = <div>
                <button className={"btn btn-tage-default"}
                        onClick={() => this.export(url)}>
                    {buttonText}
                </button>
            </div>;
        }

        let errors: React.JSX.Element = <div/>;
        if (hasErrors) {
            errors = <ol type="1">{additionalErrors}</ol>;
        }

        return <div>
            {directExportButton}
            {spinner}
            {snackbar}
            {errors}
        </div>
    }

    private getHasErrors() {
        return this.state.additionalErrorInfo !== undefined && this.state.additionalErrorInfo.length > 0;
    }

    async export(path: string) {
        const url = EventBackendService.getUrl2(path);
        this.showSpinner();
        let payload = {};

        await this.context.post(url, JSON.stringify(payload), {
            success: 'Vi förbereder en export. Du kommer strax få en länk i din mail där du kan ladda ner den.',
            failure: "Oops, det var oväntat. Prova igen om några minuter. Om felet kvarstår, kontakta oss på support@logee.com"
        });

        this.hideSpinner();
    }

    private showSpinner() {
        this.setState({showSpinner: true});
    }

    private hideSpinner() {
        this.setState({showSpinner: false});
    }
}

export function downloadFile(blob: Blob, fileName: string) {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName + '.tsv';
    a.click();
}


export default ExportEventsButton;
