import React, {createRef} from "react";
import {Action} from "../../register/v2/Action";
import Analysis from "./Analysis";
import PeriodNumberOf from "./PeriodNumberOf";
import GroupBy from "./GroupBy";
import {GraphOptions} from "./GraphOptions";

interface props {
    action: Action,
    graphOptions: GraphOptions,
    device: string,
    user: any,
    dateFormat: string,
    onGroupByChange: (groupBy: string) => void,
    onGraphTypeChange: (graphType: string) => void,
    onMovingAverageChange: (movingAverage: number | undefined) => void,
    onPeriodChange: (from: string, to: string) => void,
    onFirstEventsChange: (firstEvents: number | undefined) => void,
    onLastEventsChange: (lastEvents: number | undefined) => void,
    onAllEventsChange: () => void,
}

interface state {
}

class Selection extends React.Component<props, state> {
    groupByRef: any
    analysisRef: any
    periodNumberOfRef: any

    constructor(props: props) {
        super(props);
        this.state = {}
    }

    render(): React.ReactNode {
        this.groupByRef = createRef();
        this.analysisRef = createRef();
        this.periodNumberOfRef = createRef();

        const action: Action = this.props.action;
        const dateFormat = this.props.dateFormat;
        const device = this.props.device;

        const groupBy = this.getGroupBy(action);
        const analysis = this.getAnalysis();
        const periodNumberOf = this.getPeriodNumberOf(device, dateFormat);

        return <div className={"container m-0 b-0 p-0"}>
            <hr/>
            <div className={"row"}>
                <div className={"col"}>
                    {groupBy}
                </div>
                <div className={"col"}>
                    {analysis}
                </div>
            </div>
            <hr/>
            <div className={"row"}>
                <div className={"col"}>
                    {periodNumberOf}
                </div>
            </div>
        </div>;
    }

    private getGroupBy(action: Action): React.ReactFragment {
        const groupByRef: any = this.groupByRef;
        const graphOptions: GraphOptions = this.props.graphOptions;
        const device: string = this.props.device;
        const user: any = this.props.user;
        const onGroupChange = this.props.onGroupByChange;

        return <GroupBy ref={groupByRef}
                        action={action}
                        graphOptions={graphOptions}
                        device={device}
                        user={user}
                        onGroupByChange={onGroupChange}
        />;
    }

    private getAnalysis() {
        const frontendVersion: string = this.props.action.frontendVersion;
        const graphOptions: GraphOptions = this.props.graphOptions;
        const onGraphTypeChange = this.props.onGraphTypeChange;
        const onMovingAverageChange = this.props.onMovingAverageChange;

        return <Analysis ref={this.analysisRef}
                         frontendVersion={frontendVersion}
                         graphOptions={graphOptions}
                         onGraphTypeChange={onGraphTypeChange}
                         onMovingAverageChange={onMovingAverageChange}
        />;
    }

    private getPeriodNumberOf(device: string, dateFormat: string) {
        const action: Action = this.props.action;
        const graphOptions: GraphOptions = this.props.graphOptions;
        const user: any = this.props.user;
        const onPeriodChange = this.props.onPeriodChange;
        const onFirstEventsChange = this.props.onFirstEventsChange;
        const onLastEventsChange = this.props.onLastEventsChange;
        const onAllEventsChange = this.props.onAllEventsChange;

        return <PeriodNumberOf ref={this.periodNumberOfRef}
                               action={action}
                               user={user}
                               graphOptions={graphOptions}
                               onPeriodChange={onPeriodChange}
                               onFirstEventsChange={onFirstEventsChange}
                               onLastEventsChange={onLastEventsChange}
                               onAllEventsChange={onAllEventsChange}
                               device={device}
                               dateFormat={dateFormat}
        />;
    }

    groupBy(): string {
        const groupBy: GroupBy = this.groupByRef.current;

        return groupBy.groupBy();
    }

    graphType(): string {
        const analysis: Analysis = this.analysisRef.current;

        return analysis.getGraphtype();
    };

    movingAveragePeriod(): number {
        const analysis: Analysis = this.analysisRef.current;

        return analysis.getMovingAverage();
    };

    periodNumberOf(): any {
        const periodNumberOf: PeriodNumberOf = this.periodNumberOfRef.current;

        return periodNumberOf.selection();
    }
}

export default Selection;
