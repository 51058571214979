export interface Mode {
    unique: string, // in order to be able to click edit for the same thing twice, a unique value is needed.
    edit: boolean,
    copy: boolean,
    view: boolean,
    register: boolean
}

export function copy(): Mode {
    const uuid = require('uuid');
    return {
        unique: uuid.v4(),
        copy: true,
        edit: false,
        view: false,
        register: false
    }
}

export function edit(): Mode {
    const uuid = require('uuid');
    return {
        unique: uuid.v4(),
        copy: false,
        edit: true,
        view: false,
        register: false
    }
}

export function view(): Mode {
    const uuid = require('uuid');
    return {
        unique: uuid.v4(),
        copy: false,
        edit: false,
        view: true,
        register: false
    }
}

export function register(): Mode {
    const uuid = require('uuid');
    return {
        unique: uuid.v4(),
        copy: false,
        edit: false,
        view: false,
        register: true
    }
}

export function isCopy(mode: Mode): boolean {
    return mode.copy;
}

export function isEdit(mode: Mode): boolean {
    return mode.edit;
}

export function isView(mode: Mode): boolean {
    return mode.view;
}

export function isRegister(mode: Mode): boolean {
    return mode.register;
}
