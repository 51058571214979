import React from 'react';
import "./CapioDeviationEmailModal.css"
import {postObjectOf} from "../../../infra/BackendService";
import UserSessionUtilService from "../../model/UserSessionUtilService";

interface Props {
    deviation: any
    eventId: string,
    displayName: string
    flipVisibility: () => void;
    updateParent: () => void;
}

interface State {
    subject: string,
    body: string
}

const capioDeviationEmailUri = "/api/v1/supervisor/deviation/response-email"

class CapioDeviationEmailModal extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            subject: "",
            body: ""
        }
    }

    render() {
        return (
            <div className={"deviation-email-modal"}>
                <button className={"floating-right-cross btn"} onClick={() => this.props.flipVisibility()}>X</button>
                <h4 className={"pb-3"}>Skicka svar till avvikelsen</h4>
                <p>Till: {this.props.displayName}</p>
                <div className={"container m-0 p-0"}>
                    <div className={"row m-0 p-0"}>
                        <label className={"label-CDEM"}>Subject:
                            <input className={"input-CDEM"} type={"text"} width={30} value={this.state.subject}
                                   onChange={(e) => this.onChangeSubject(e)}/>
                        </label>
                    </div>
                    <div className={"row m-0 p-0"}>
                        <label className={"label-CDEM"}>Body:
                            <textarea className={"input-CDEM"} rows={5} cols={50} value={this.state.body}
                                      onChange={(e) => this.onChangeBody(e)}/>
                        </label>
                    </div>
                </div>
                <div className={"row m-0 pr-3 pt-2 justify-content-end"}>
                    <button className={"btn btn-send-mail-deviation-confirm"} onClick={() => this.sendMail()}>Skicka
                    </button>
                </div>
            </div>
        );
    }

    async sendMailToBE() {
        const payload = {
            from: UserSessionUtilService.getUserEmail(),
            subject: this.state.subject,
            body: this.state.body,
            eventId: this.props.eventId
        }

        await postObjectOf(payload, capioDeviationEmailUri);
    }

    async sendMail() {
        await this.sendMailToBE();
        this.props.updateParent();
        this.props.flipVisibility();
    }

    private onChangeSubject(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({subject: e.currentTarget.value});
    }

    private onChangeBody(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({body: e.currentTarget.value});
    }
}

export default CapioDeviationEmailModal;
