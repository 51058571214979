import TranslationService from "../../../../infra/TranslationService";

export function cleanNumberInput(dirtyStr: string, min: number, decimalPlaces: number): string {
    if (dirtyStr === '') {
        return '';
    }

    if (dirtyStr === '-') {
        if (min != null && min >= 0) {
            return '';
        }
    }

    let preCleaned = "";
    if (dirtyStr.lastIndexOf("-") > 0) {
        let size = dirtyStr.length;
        preCleaned += dirtyStr[0];
        for (let i = 1; i < size; i++) {
            let character = dirtyStr[i];
            if (character !== '-') {
                preCleaned += character;
            }
        }
    } else {
        preCleaned = dirtyStr;
    }

    // check for legal characters
    const legalCharacters = new Set(['-', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', ',']);
    let size = preCleaned.length;
    let cleanStr = "";
    let decimal = false;
    for (let i = 0; i < size; i++) {
        let character = preCleaned[i];
        if (legalCharacters.has(character)) {
            if (isDecimal(character)) {
                if (decimal) {
                    return cleanStr;
                }
                decimal = true;
            }
            cleanStr += character;
        }
    }

    return cutSurplusDecimals(cleanStr, decimalPlaces);
}

export function cutSurplusDecimals(cleanStr: string, decimalPlaces: number): string {
    if (decimalPlaces === null) {
        return cleanStr;
    }

    if (decimalPlaces === 0) {
        while (containsDecimal(cleanStr)) {
            cleanStr = removeLast(cleanStr);
        }

        return cleanStr;
    }

    let currentDecimals = numberOfDecimals(cleanStr);
    while (currentDecimals > decimalPlaces) {
        cleanStr = removeLast(cleanStr);
        currentDecimals = numberOfDecimals(cleanStr);
    }

    return cleanStr;
}

export interface NumberValidationResult {
    valid: boolean,
    message: string,
    translatedMessage: string
}

export function validateNumber(sample: string, min: number, max: number, decimalPlaces: number): NumberValidationResult {
    let res: NumberValidationResult = {
        valid: true,
        message: "",
        translatedMessage: ""
    };

    if (sample === '') {
        return res;
    }

    sample = sample.replace(",", ".");

    let number = parseFloat(sample);

    if (isNaN(number)) {
        res.valid = false;
        res.message = "number input field NaN";
        res.translatedMessage = TranslationService.translation("number input field NaN");
        return res;
    }

    if (min !== null) {
        res.valid = number >= min;
        if (!res.valid) {
            res.message = "number input field min";
            const translation = TranslationService.translation("number input field min");
            res.translatedMessage = translation + min;

            return res;
        }
    }

    if (max !== null) {
        res.valid = number <= max;
        if (!res.valid) {
            res.message = "number input field max";
            res.translatedMessage = TranslationService.translation("number input field max") + max;

            return res;
        }
    }

    if (decimalPlaces !== null) {
        const parts: string[] = number.toString().split(".");
        if (parts.length === 2) {
            const decimals: number = parts[1].length;
            if (decimals > decimalPlaces) {
                res.valid = false;
                res.message = "number input field decimalPlaces";
                res.translatedMessage = TranslationService.translation("number input field decimalPlaces") + decimalPlaces;
                return res;
            }
        }
    }

    return res;
}

function isDecimal(character: string): boolean {
    return character === ',' || character === '.';
}

function removeLast(cleanStr: string): string {
    let length = cleanStr.length - 1;
    return cleanStr.substr(0, length);
}

function containsDecimal(sample: string): boolean {
    return sample.includes(',') || sample.includes('.');
}

function numberOfDecimals(sample: string): number {
    let length = sample.length;
    let decimalPos = 0;

    if (sample.includes('.') || sample.includes(',')) {
        if (sample.includes('.')) {
            decimalPos = sample.indexOf('.');
        }

        if (sample.includes(',')) {
            decimalPos = sample.indexOf(',');
        }
        decimalPos = decimalPos + 1;

        return length - decimalPos;
    }

    return 0;
}