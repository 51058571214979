import React, {Component} from 'react';
import "./QrPaint.css"
import {EventBackendService} from "../../register/v2/EventBackendService";

interface props {

}

interface state {
    qrstring: string;
    black: boolean
}

const black = "rgb(0,0,0)";
const white = "rgb(255,255,255)";


class QrPaint extends Component<props, state> {

    constructor(props: any) {
        super(props);
        this.state = {
            qrstring: "",
            black: true
        }
    }

    xOffset = 0;
    yOffset = 0;

    componentDidMount() {
        const canvas = document.querySelector("#qrCanvas");
        if (canvas) {
            document.addEventListener('mousemove', (e) => this.draw(e));
            document.addEventListener('mousedown', (e) => this.draw(e));
        }

        const canvasBounding = document.getElementById("qrCanvas")?.getBoundingClientRect();
        this.xOffset = Math.round(canvasBounding ? canvasBounding.x : 0);
        this.yOffset = Math.round(canvasBounding ? canvasBounding.y : 0);

        this.clear();
    }


    draw(e: any) {
        const canvas = document.querySelector("#qrCanvas");
        if (canvas) {
            // @ts-ignore
            const ctx = canvas.getContext("2d");
            if (e.buttons !== 1) return;

            const x = Math.floor((e.clientX - this.xOffset) / 16)
            const y = Math.floor((e.clientY - this.yOffset) / 16)

            ctx.fillStyle = this.state.black ? black : white;
            ctx.fillRect(x, y, 1, 1);
        }
    }

    drawDefaults() {
        const canvas = document.querySelector("#qrCanvas");
        if (canvas) {
            // @ts-ignore
            const ctx = canvas.getContext("2d");

            this.drawPositionCube(0, 0, ctx)
            this.drawPositionCube(30, 0, ctx)
            this.drawPositionCube(0, 30, ctx)
            this.drawAlignmentCube(30, 30, ctx)
            this.drawTimingPatternSouth(6, 6, ctx, 25);
            this.drawTimingPatternEast(6, 6, ctx, 25);
        }
    }


    render() {
        const buttonClass = this.state.black ? "ml-2" : "ml-2 inverted";

        return (
            <div className={"col text-center"}>
                <div className={"row m-0 p-0 justify-content-center"}>
                    <canvas id="qrCanvas" width="37" height="37">
                    </canvas>
                </div>
                <div className={"row m-0 pt-4 justify-content-center"}>
                    <button className={"mr-2"} onClick={() => this.submitQrString()}>OK</button>
                    <button className={buttonClass} onClick={() => this.switchColor()}>Eraser</button>
                    <button className={"ml-3"} onClick={() => this.clear()}>Clear</button>
                    <button className={"ml-3"} onClick={() => this.savePicture()}>Save picture</button>
                </div>
                <div className={"row m-0 pt-4 justify-content-center"}>
                    <p>string: {this.state.qrstring}</p>
                </div>
            </div>
        );
    }

    private clear() {
        const canvas = document.querySelector("#qrCanvas");
        if (canvas) {
            // @ts-ignore
            const ctx = canvas.getContext("2d");
            ctx.fillStyle = white;
            ctx.fillRect(0, 0, 592, 592);
        }

        this.drawDefaults();
    }

    private switchColor() {
        this.setState({black: !this.state.black})
    }

    private async submitQrString() {
        const canvas = document.querySelector("#qrCanvas");
        const qrArray = Array(37);
        for (let x = 0; x < 37; x++) {
            qrArray[x] = Array(37).fill(false);
        }

        if (canvas) {
            // @ts-ignore
            const ctx = canvas.getContext("2d");
            for (let x = 0; x < 37; x++) {
                for (let y = 0; y < 37; y++) {
                    qrArray[x][y] = this.isPixelbitSet(ctx, x, y);
                    //qrArray[x][y] = this.isPixelbitSet(ctx, x, y) ? 1 : 0;
                }
            }
        }

        const path = `/api/v1/qr/decode`
        const url = EventBackendService.getUrl2(path);
        const payload = JSON.stringify({bitMatrix:qrArray, size:37});
        const responseString = await EventBackendService.post(payload, url) as string;

        this.setState({qrstring: responseString})
    }


    private isPixelbitSet(ctx: any, x: number, y: number): boolean {
        const pixel = ctx.getImageData(x, y, 1, 1);
        const data = pixel.data;

        return data[0] === 0;
    }

    private drawPositionCube(x: number, y: number, ctx: any) {
        ctx.fillStyle = black;

        //top
        ctx.fillRect(x, y, 1, 1);
        ctx.fillRect(x + 1, y, 1, 1);
        ctx.fillRect(x + 2, y, 1, 1);
        ctx.fillRect(x + 3, y, 1, 1);
        ctx.fillRect(x + 4, y, 1, 1);
        ctx.fillRect(x + 5, y, 1, 1);
        ctx.fillRect(x + 6, y, 1, 1);

        ctx.fillRect(x, y + 1, 1, 1);
        ctx.fillRect(x + 6, y + 1, 1, 1);

        //middle cube top
        ctx.fillRect(x, y + 2, 1, 1);
        ctx.fillRect(x + 6, y + 2, 1, 1);
        ctx.fillRect(x + 2, y + 2, 1, 1);
        ctx.fillRect(x + 3, y + 2, 1, 1);
        ctx.fillRect(x + 4, y + 2, 1, 1);

        ctx.fillRect(x, y + 3, 1, 1);
        ctx.fillRect(x + 6, y + 3, 1, 1);
        ctx.fillRect(x + 2, y + 3, 1, 1);
        ctx.fillRect(x + 3, y + 3, 1, 1);
        ctx.fillRect(x + 4, y + 3, 1, 1);

        ctx.fillRect(x, y + 4, 1, 1);
        ctx.fillRect(x + 6, y + 4, 1, 1);
        ctx.fillRect(x + 2, y + 4, 1, 1);
        ctx.fillRect(x + 3, y + 4, 1, 1);
        ctx.fillRect(x + 4, y + 4, 1, 1);
        //cube ended

        ctx.fillRect(x, y + 5, 1, 1);
        ctx.fillRect(x + 6, y + 5, 1, 1);

        //bottom row
        ctx.fillRect(x, y + 6, 1, 1);
        ctx.fillRect(x + 1, y + 6, 1, 1);
        ctx.fillRect(x + 2, y + 6, 1, 1);
        ctx.fillRect(x + 3, y + 6, 1, 1);
        ctx.fillRect(x + 4, y + 6, 1, 1);
        ctx.fillRect(x + 5, y + 6, 1, 1);
        ctx.fillRect(x + 6, y + 6, 1, 1);
    }

    private drawAlignmentCube(x: number, y: number, ctx: any) {
        ctx.fillStyle = black;

        //top
        ctx.fillRect(x, y, 1, 1);
        ctx.fillRect(x - 2, y, 1, 1);
        ctx.fillRect(x + 2, y, 1, 1);

        ctx.fillRect(x - 2, y + 1, 1, 1);
        ctx.fillRect(x + 2, y + 1, 1, 1);

        ctx.fillRect(x - 2, y - 1, 1, 1);
        ctx.fillRect(x + 2, y - 1, 1, 1);

        ctx.fillRect(x - 2, y - 2, 1, 1);
        ctx.fillRect(x - 1, y - 2, 1, 1);
        ctx.fillRect(x, y - 2, 1, 1);
        ctx.fillRect(x + 1, y - 2, 1, 1);
        ctx.fillRect(x + 2, y - 2, 1, 1);

        ctx.fillRect(x - 2, y + 2, 1, 1);
        ctx.fillRect(x - 1, y + 2, 1, 1);
        ctx.fillRect(x, y + 2, 1, 1);
        ctx.fillRect(x + 1, y + 2, 1, 1);
        ctx.fillRect(x + 2, y + 2, 1, 1);
    }

    private drawTimingPatternSouth(x: number, y: number, ctx: any, steps: number) {
        for (let i = 0; i < steps; i++) {
            ctx.fillStyle = i % 2 === 0 ? black : white;
            ctx.fillRect(x, y + i, 1, 1);
        }
    }

    private drawTimingPatternEast(x: number, y: number, ctx: any, steps: number) {
        for (let i = 0; i < steps; i++) {
            ctx.fillStyle = i % 2 === 0 ? black : white;
            ctx.fillRect(x + i, y, 1, 1);
        }
    }

    private savePicture() {
        alert("This does nothing currently")
    }
}

export default QrPaint;
