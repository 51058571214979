import React from 'react';
import "./GoalPage.css";
import GoalGraph from "../../graph/GoalGraph";
import {GOAL} from "../../../infra/Constants";
import {getSecurityToken} from "../../../infra/SecurityToken";
import TranslationService from "../../../infra/TranslationService";

const EXPERIENCE_GOAL_API = process.env.REACT_APP_LOCALHOST + "/api/v1/experience/goal";

interface Props {
    actionName: string;
    actionVersion: number;
    showDetails: () => void;
    refreshExpiredToken: () => void;
}

class Graph {
    id: string;
    data: {};
    axis: {};

    constructor(id: string, data: {}, axis: {}) {
        this.id = id;
        this.data = data;
        this.axis = axis;
    }
}

interface State {
    graph: Graph;
}

export default class GoalPage extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            graph: {
                id: 'none',
                data: {
                    columns: [],
                },
                axis: {}
            }
        }
    }

    static defaultProps = {
        showDetails: () => {
            console.log("A parent has not set a call back for showDetails()")
        },

        refreshExpiredToken: () => {
            console.log("A parent has not set a call back for refreshExpiredToken()")
        }
    };

    componentDidMount() {
        const action: string = this.props.actionName;
        const version: number = this.props.actionVersion;
        getGoals(action, version, this);
    }

    render() {
        const graph = this.state.graph;

        const showDetails = TranslationService.translation("show details");
        const showFulFilament = TranslationService.translation("goal overview");
        return (
            <div className={"container"}>
                <div className={"row pt-2"}>
                    <h1>{showFulFilament}</h1>
                </div>

                <div className={"row pt-2"}>
                    <div className={"col"}>
                        <GoalGraph id={graph.id}
                                   data={graph.data}
                                   axis={graph.axis}
                        />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col text-right"}>
                        <button onClick={this.props.showDetails}>
                            {showDetails}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

function getGoals(action: string, version: number, parent: any): any {
    const url = EXPERIENCE_GOAL_API + "/" + action + "/" + version;
    fetch(url, {
        headers: {
            'X-Custom-header': getSecurityToken()
        },
        credentials: 'include'
    })
        .then(res => res.json())
        .then((response) => {
            console.log("getGoals", response);

            const data = {
                columns: [
                    response.goal,
                    response.accomplishment
                ],
                colors: {
                    goal: '#0000ff',
                    accomplishment: '#00ff00',
                },
                names: {
                    goal: response.translatedGoal,
                    accomplishment: response.translatedAccomplishment
                },
                type: 'bar'
            };

            const axis = {
                x: {
                    type: 'category',
                    categories: response.categories
                },

                y: {
                    show: true,
                    label: {
                        text: response.translatedUnit,
                        position: 'outer-top'
                    },
                    padding: {top: 0, bottom: 0}
                }
            };

            const graph = new Graph(GOAL, data, axis);
            parent.setState({graph: graph})
        }).catch(console.log);
}
