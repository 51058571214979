import React from "react";
import {withRouter} from 'react-router-dom';
import "./FullNavBar.css";
import {UserData} from "../model/UserData";
import {NavBar} from "../model/NavBar";
import {User} from "../model/User";
import {Organisation} from "../model/Organisation";
import {BackendContext, BackendInterface} from "../../infra/BackendContext";
import FullNavBarMobile from "./FullNavBarMobile";
import FullNavBarDesktop from "./FullNavBarDesktop";
import TranslationService from "../../infra/TranslationService";

interface Position {
    id: string,
    yPos: number
}

interface props {
    user: User
    updateNavBar: (navBar: NavBar) => void
    setCurrentOrganisation: (organisation: Organisation | undefined) => void,
    testing: boolean,
    userData: UserData,
    refreshExpiredToken: () => void,
    securityToken: string,
    setAction: (action: string) => void,
    setStatsAction: (statsAction: string) => void,
    history: string[],
    setPath: (path: string) => void,
    nav: any //TODO make interface
}

interface state {
    nav: any,
    rootLinksInvisible: boolean
    ypos: number,
    hasScrolled: boolean,
    userData: UserData,
    sublistYpos: Position[]
    width: number,
    height: number
}

class FullNavBarHub extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: props) {
        super(props);

        this.state = {
            ypos: 0,
            hasScrolled: false,
            userData: this.props.userData,
            nav: this.props.nav,
            rootLinksInvisible: false,
            sublistYpos: [],
            width: 0,
            height: 0
        };


        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    async componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        if (this.props.userData.navBar === undefined) {
            if (!this.props.testing) {
                await TranslationService.init();
            }
            if (this.props.userData.navBar === undefined) {
                let nav = {
                    brand: {
                        translatedName: "LogEze",
                        route: "/",
                        subList: []

                    },
                    items: [
                        {
                            route: "/register",
                            translatedName: TranslationService.translation("/register"),
                            subList: [
                                {
                                    name: "feedback",
                                    version: "0",
                                    nameTranslated: TranslationService.translation("feedback"),
                                    modelVersion: "v2"
                                }
                            ]
                        },
                        {
                            route: "/user",
                            translatedName: this.props.user.firstName,
                            subList: []

                        },
                        {
                            route: "/about",
                            translatedName: TranslationService.translation("/about"),
                            subList: []
                        },
                        {
                            route: "/logout",
                            translatedName: TranslationService.translation("/logout"),
                            subList: []
                        },

                    ],
                    organisations: []
                }
                let userData = this.state.userData;
                userData.navBar = nav;
                this.setState({nav: nav, userData: userData})
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps: Readonly<props>, _prevState: Readonly<state>, _snapshot?: any) {
        if (prevProps.userData !== this.props.userData || prevProps.nav !== this.props.nav) {
            this.setState({nav: this.props.nav, userData: this.props.userData});
        }
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    render(): React.JSX.Element {
        let {
            user,
            updateNavBar,
            setCurrentOrganisation,
            refreshExpiredToken,
            securityToken,
            setAction,
            setPath,
            setStatsAction
        } = this.props;
        let nav = this.state.nav;
        let userData = this.state.userData;

        if (this.state.width < 992) {
            return <FullNavBarMobile user={user}
                                     nav={nav}
                                     updateNavBar={updateNavBar}
                                     setCurrentOrganisation={setCurrentOrganisation}
                                     userData={userData}
                                     refreshExpiredToken={refreshExpiredToken}
                                     securityToken={securityToken}
                                     setAction={setAction}
                                     setPath={setPath}
                                     setStatsAction={setStatsAction}
            />
        } else {
            return <FullNavBarDesktop user={user}
                                      nav={nav}
                                      updateNavBar={updateNavBar}
                                      setCurrentOrganisation={setCurrentOrganisation}
                                      userData={userData}
                                      refreshExpiredToken={refreshExpiredToken}
                                      securityToken={securityToken}
                                      setAction={setAction}
                                      setPath={setPath}
                                      setStatsAction={setStatsAction}
            />
        }
    }

    //TODO move org parts up to hub
}

export default withRouter(FullNavBarHub);
