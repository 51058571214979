import {Field} from "../../register/v2/Action";
import React, {ChangeEvent, createRef, ReactElement} from "react";
import Period from "../../fields/v2/Period";
import {DESKTOP} from "../../../infra/Constants";
import TranslationService from "../../../infra/TranslationService";
import {Report} from "./ReportTable";
import {fetchObjectOf} from "../../../infra/BackendService";
import {FieldError} from "../../fields/v2/FieldComponent";
import {uuidv4} from "../../register/v2/Uuid";


interface props {
    onViewReport: any
    fetchReportsUrl: string
    selectFilter?: SelectFilter

}

export interface SelectFilter {
    values: string [],
    defaultValue?: string,
    blankValue?: string
}

interface state {
    report: Report | undefined,
    selectValues: string[] | undefined
    selectedValue: string | undefined
    errors: FieldError[]
}


export class FilterReport extends React.Component<props, state> {
    periodRef: any;

    constructor(props: props) {
        super(props);

        this.state = {
            report: undefined,
            selectValues: undefined,
            selectedValue: undefined,
            errors: []
        }
    }

    render() {
        let filterSelect: ReactElement = <div/>;
        const selectValues = this.state.selectValues;

        if (this.props.selectFilter !== undefined) {
            if (selectValues === undefined) {
                return <div
                    className={"spinner"}
                    aria-label={"waiting for selectValues"}
                />
            }

            const selectedValue = this.state.selectedValue;
            if (selectValues.length > 2) {
                const name: string = "kva.code.select";

                const labelText = TranslationService.translation("dops-surgery.select.kva.code");
                const label: ReactElement = <div className={"row"}>
                    <div className={"col"}>
                        <label aria-label={name + ".label"}
                               htmlFor={name}>{labelText}</label>
                    </div>
                </div>;
                const options: ReactElement[] = selectValues.map((code) => {
                    return <option key={code}
                                   value={code}
                                   data-testid={"select-option"}
                    >
                        {code}
                    </option>;
                });

                const onChange = (e: ChangeEvent<HTMLSelectElement>) => this.onChange(e);
                const field: ReactElement = <div className={"row"}>
                    <div className={"col"}>
                        <select aria-label={"kva.code.selection"}
                                name={name}
                                value={selectedValue}
                                onChange={onChange}>
                            {options}
                        </select>
                    </div>
                </div>;

                filterSelect = <div>
                    {label}
                    {field}
                </div>;
            }
        }


        const reportPeriod: Field = {
            name: "reportPeriod",
            fieldLabel: true,
            mandatory: true,
            defaultValue: "firstDayOfMonth",
            showChildLabel: false,
            duplicationIndex: 0
        };
        this.periodRef = createRef();

        const periodField = <div>
            <Period ref={this.periodRef} field={reportPeriod} device={DESKTOP} dateFormat={"yyyy-MM-dd"}/>
        </div>;

        const view: string = TranslationService.translation("view");
        const viewButton = <div className={"pt-3"}>
            <button id={"viewButton"}
                    aria-label={"view"}
                    className={"btn"}
                    onClick={() => this.view()}>
                {view}
            </button>
        </div>;
        let errors = this.getErrors();
        return <div>
            {filterSelect}
            {periodField}
            {viewButton}
            {errors}
        </div>
    }

    componentDidMount() {
        let selectFilter = this.props.selectFilter;
        if (selectFilter) {
            let selectedValue;
            let selectValues = selectFilter.values;
            if (selectValues.length === 1) {
                selectedValue = selectValues[0];
            } else {
                selectedValue = selectFilter.defaultValue;
            }


            this.setState({selectValues: selectValues, selectedValue: selectedValue})
        }
    }


    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    private view() {
        let currentPeriod: Period = this.periodRef.current;
        let from: string | undefined = currentPeriod.getFrom();

        let to: string | undefined = currentPeriod.getTo();
        if (to === undefined) {
            to = "9999-12-31";
        }

        const error: FieldError = currentPeriod.isValid();
        let errors = [];
        if (!error.valid) {
            errors.push(error);
            this.setState({errors: errors});
        } else {
            let url = this.props.fetchReportsUrl + from + "/" + to;
            let selectedKvaCode = this.state.selectedValue;
            let blankValue = this.props.selectFilter?.blankValue;
            if (selectedKvaCode !== undefined && selectedKvaCode !== blankValue) {
                url = url + "/" + selectedKvaCode;
            }
            fetchObjectOf<Report>(url).then((report) => {
                    this.props.onViewReport(report);
                }
            );
        }
    }

    private getErrors(): React.ReactFragment {
        const errors = this.state.errors;
        if (errors.length > 0) {
            return <div>
                {errors.map((error: FieldError) => {
                        const name = error.name;
                        const errorDescription = error.error;
                        const ariaLabel = name + " " + errorDescription;
                        const message = TranslationService.translation(name) + " " + TranslationService.translation(errorDescription);
                        return <div className="errorFont" key={uuidv4()}
                                    aria-label={ariaLabel}>
                            {message}
                        </div>
                    }
                )}
            </div>
        } else {
            return <div/>
        }
    }

    private onChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const selectedKvaCode = e.target.value;
        this.setState({selectedValue: selectedKvaCode});
    }
}