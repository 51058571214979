import React from 'react';

class CapioOvertime extends React.Component {
    render() {
        return (
            <div className={"col text-center"}>
                <div id="capio-overtime">
                    <h1>Capio Overtime</h1>
                </div>
            </div>
        );
    }
}

export default CapioOvertime;