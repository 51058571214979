import React from "react";
import {fetchArrayOf} from "../../../infra/BackendService";
import {DeliveryStudentReport, ProcedureCount} from "./StudentDeliveryReport";
import TranslationService from "../../../infra/TranslationService";
import "./DeliveryTutorReport.css"
import OrganisationDynamicSelect from "./OrganisationDynamicSelect";
import {OptionType} from "../../model/SelectOption";
import {User} from "../../model/User";
import RegisterResultFailureMessage from "../../register/v2/RegisterResult";
import ExportEventsButton from "../../splashpage/ExportEventsButton";
import {hasRight} from "../../../utils/HasRight";

interface props {
    isPrincipal: boolean,
    user: User
}

interface state {
    reportRows: DeliveryStudentReport[] | undefined,
    selectedCohort: OptionType | undefined,
    showFailureMessage: boolean,
}


class TutorDeliveryReport extends React.Component<props, state> {
    constructor(props: props) {
        super(props);
        this.onChangeCohort = this.onChangeCohort.bind(this);
        this.state = {
            reportRows: undefined,
            selectedCohort: undefined,
            showFailureMessage: false,
        }
    }

    componentDidMount() {
    }

    render() {
        let reportRows = this.state.reportRows;
        let reportTable;
        if (reportRows !== undefined) {
            let selectedCohort = this.state.selectedCohort;
            if (reportRows.length === 0 || selectedCohort === undefined) {
                reportTable = <div>No report to display right now</div>
            } else {
                let failureModal: React.JSX.Element = <div/>;
                const failureMessage = ["Något gick tokigt! Vi jobbar på att lösa det."];

                if (this.state.showFailureMessage) {
                    failureModal =
                        <RegisterResultFailureMessage message={failureMessage}
                                                      hideFailureMessage={this.hideFailureMessage}/>;
                }
                const action: string = 'delivery';
                const version: number = 1;
                const organisationId: string = selectedCohort.value;
                reportTable = <div>
                    <h1 className={"pb-3"}>{TranslationService.translation("delivery.midterm-report.for") + " " + selectedCohort.label} </h1>
                    <div>
                        <ExportEventsButton
                            hasExportRight={this.props.isPrincipal && hasRight(this.props.user, "delivery-" + selectedCohort.label, "export-organisation-events")}
                            buttonText={"Exportera " + selectedCohort.label + "s registreringar"}
                            showFailureMessage={this.showFailureMessage}
                            actionName={action}
                            version={version}
                            scope={'organisation'}
                            organisationId={organisationId}
                        />
                    </div>
                    {this.drawTable(reportRows)}
                    {failureModal}
                </div>;
            }

        } else {
            reportTable = <div className={"spinner"} aria-label={"waiting for table"}/>
        }
        return <>
            <div className={"pt-5"}>
                {this.selectOrganisation()}
            </div>
            {reportTable}
        </>
    }

    private selectOrganisation() {
        return <div className={"mb-5"}>
            <OrganisationDynamicSelect onOrganisationSelect={this.onChangeCohort}/>
        </div>
    }

    private drawTable(tutorReportRows: DeliveryStudentReport[]) {
        let noStudentsEnrolledInClass = TranslationService.translation("delivery.report.no.students");
        if (tutorReportRows.length === 0) {
            return <div>{noStudentsEnrolledInClass}</div>;
        }

        const rowStyle = {
            border: "none",
            boxShadow: "none",
            textAlign: "center" as "center"
        };

        const headerStyle = {
            borderLeft: "1px solid #D6D6D6",
            borderRight: "1px solid #D6D6D6",
        }

        let body = this.getTableBody(tutorReportRows, rowStyle);
        let row: DeliveryStudentReport = tutorReportRows[0];
        if (row.procedureCountMap === undefined || row.procedureCountMap.size === 0 || row.positionCountList.length === 0)
            return <div>{noStudentsEnrolledInClass}</div>;
        const procedureMap = new Map(Object.entries(row.procedureCountMap));
        let evaluationHeaderCols = Array.from(procedureMap.entries()).map(([evaluation, procedures], index) => (

            <th style={headerStyle} className={"col-nowrap"} key={"evaluation " + index}
                colSpan={procedures.length}>{evaluation}</th>
        ));
        if (row.positionCountList === undefined || row.positionCountList.length === 0) return <div/>;
        const procedureCount = row.positionCountList;
        let procedureCountCols = procedureCount.map((procedure, index) => (

            <th style={headerStyle} className={"col-nowrap"} key={"procedure " + index}
                colSpan={1}>{TranslationService.translation(procedure.name)}</th>
        ));


        const rows = Array.from(procedureMap.entries()).map(([evaluation, procedures], index) => (
            <React.Fragment key={index}>
                {procedures.map((procedure: ProcedureCount, idx: number) => (
                    <th style={headerStyle} className={"col-nowrap"}
                        key={"procedure" + idx}>{TranslationService.translation(procedure.name)}</th>
                ))}
            </React.Fragment>
        ));
        const legend = this.props.isPrincipal ? TutorDeliveryReport.getPrincipalLegend() : TutorDeliveryReport.getTutorLegend();
        return <div>
            <div className={"scroll-sideways"}>
                <table className="table" style={rowStyle}>
                    <thead>
                    <tr style={rowStyle} className={"m-0 p-0 evaluation-header scrolling-header"}>
                        <th colSpan={1}></th>
                        <th colSpan={2}></th>
                        {evaluationHeaderCols}
                        <th colSpan={7}>Position</th>
                    </tr>
                    <tr style={rowStyle} className={"procedure-header sticky-header"}>
                        <th style={{textAlign: 'center'}}>{TranslationService.translation("delivery.midterm-report.name")}</th>
                        <th style={{textAlign: 'center'}}>{TranslationService.translation("delivery.midterm-report.beforeOrAfterParticipation")}</th>
                        <th style={{textAlign: 'center'}}>{TranslationService.translation("delivery.midterm-report.deliveryParticipation")}</th>
                        {rows}
                        {procedureCountCols}
                    </tr>
                    </thead>
                    <tbody>
                    {body}
                    </tbody>
                </table>
            </div>
            <div>
                {legend}
            </div>
        </div>

    }

    private getTableBody(tutorReportRows: DeliveryStudentReport[], tableStyle: { border: string; boxShadow: string }) {
        return tutorReportRows.map((row: DeliveryStudentReport, i: number) => {
            const procedureMap: Map<string, ProcedureCount[]> = new Map(Object.entries(row.procedureCountMap));
            const procedures: ProcedureCount[] = row.positionCountList;
            let isPrincipal = this.props.isPrincipal;
            let keySuffix = isPrincipal ? "principal" : "";
            return (
                <tr key={"student row for" + keySuffix + i}
                    className={"pt-1 pl-0 pr-0" + (i % 2 === 1 ? " grayed" : "")}
                    style={tableStyle}>
                    <td key={"name" + i}
                        className={"col-nowrap sticky-column"}>{TranslationService.translation(row.name)}</td>
                    <td key={"beforeOrAfterParticipation" + i}
                        className={"col-nowrap"}>{row.beforeOrAfterParticipation}</td>
                    <td key={"deliveryParticipation" + i} className={"col-nowrap"}>{row.deliveryParticipation}</td>
                    {isPrincipal ? this.getPrincipalSpecificRows(procedureMap, i) : this.getClinicTutorSpecificRows(procedureMap, i)}
                    {procedures.map((procedure, index) => (
                        <td className={"col-nowrap"} key={"position" + index + i}>{procedure.totalCount}</td>
                    ))}
                </tr>)

        });
    }

    private getClinicTutorSpecificRows(procedureMap: Map<string, ProcedureCount[]>, i: number) {
        return <>
            {Array.from(procedureMap.entries()).map(([evaluation, procedures], index) => (
                procedures.map((procedure: ProcedureCount, idx: number) => (
                    <React.Fragment key={index}>
                        <td className={"col-nowrap"} key={"position" + i + idx}>
                            <div className="inline-container">
                                <div className={"center-wrapper"}>
                                    <div className={"observed"}>{procedure.observed}</div>
                                    <div className={"participated"}>{procedure.participated}</div>
                                    <div className={"self"}>{procedure.self}</div>
                                </div>
                            </div>
                        </td>
                    </React.Fragment>
                ))))}
        </>;
    }

    private getPrincipalSpecificRows(procedureMap: Map<string, ProcedureCount[]>, i: number) {
        return <>
            {Array.from(procedureMap.entries()).map(([evaluation, procedures], index) => (
                procedures.map((procedure: ProcedureCount, idx: number) => (
                    <React.Fragment key={index}>
                        <td className={"col-nowrap"} key={"position" + i + idx}>
                            <div className="inline-container">
                                <div className={"center-wrapper"}>
                                    <div className={"total-count"}>{procedure.totalCount}</div>
                                    <div className={"weighted-sum"}>{procedure.weightedSum}</div>
                                </div>
                            </div>
                        </td>
                    </React.Fragment>
                ))))}
        </>;
    }

    static getTutorLegend() {
        const legendHeader = TranslationService.translation("delivery.midterm-report.legend.header");
        return <div className={"mt-3"}>
            <div className="strong" aria-label="legendHeader">{legendHeader}</div>
            <div
                className={"mt-2 observed"}>{TranslationService.translation("delivery.midterm-report.purple.observed")}</div>
            <div
                className={"participated mt-2"}>{TranslationService.translation("delivery.midterm-report.blue.participated")}
            </div>
            <div
                className={"self mt-2"}>{TranslationService.translation("delivery.midterm-report.green.self")}
            </div>
        </div>
    }

    static getPrincipalLegend() {
        const legendHeader = TranslationService.translation("delivery.midterm-report.legend.header");
        return <div className={"mt-3"}>
            <div className="strong" aria-label="legendHeader">{legendHeader}</div>
            <div
                className={"total-count mt-2"}>{TranslationService.translation("delivery.midterm-report.blue.number")}</div>
            <div
                className={"weighted-sum mt-2"}>{TranslationService.translation("delivery.midterm-report.brown.number")}
            </div>
        </div>
    }

    private onChangeCohort(selectedCohort: OptionType) {
        if (selectedCohort !== undefined && selectedCohort.value !== undefined) {
            this.setState({selectedCohort: selectedCohort})
            let url = "/api/v1/reports/tutor/delivery/" + selectedCohort.value;
            fetchArrayOf<DeliveryStudentReport>(url).then((rows) => {
                if (rows !== undefined) {
                    this.setState({reportRows: rows})
                }
            });
        } else {
            this.setState({selectedCohort: undefined, reportRows: []})
        }

    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    hideFailureMessage = () => {
        this.setState({showFailureMessage: false})
    }

    showFailureMessage = (showFailureMessage: boolean) => {
        this.setState({showFailureMessage: showFailureMessage})
    }
}

export default TutorDeliveryReport