import React from 'react';
import CustomSelect from "./CustomSelect";
import {DependingOption} from "../../register/v2/Action";

class DependingSelect extends CustomSelect {

    componentDidMount() {
        if (this.props.field.dependsOn !== undefined) {
            const dependsOn: string = this.props.field.dependsOn;
            const elementById = document.getElementById(dependsOn);
            if (elementById !== null) {
                elementById.addEventListener("change", this.dependeeChanged());
            }
        }
    }

    componentWillUnmount() {
        if (this.props.field.dependsOn !== undefined) {
            const dependsOn: string = this.props.field.dependsOn;
            const elementById = document.getElementById(dependsOn);
            if (elementById !== null) {
                elementById.removeEventListener("change", this.dependeeChanged());
            }
        }
    }

    getOptions(): React.ReactFragment {
        const key: string = this.state.key;
        const dependingOptions = this.props.field.dependingOptions;
        if (dependingOptions !== undefined) {
            const newOptions: DependingOption | undefined =
                dependingOptions.find((depending) => depending.dependsOn === key);

            if (newOptions !== undefined) {
                this.props.field.options = newOptions.options;
                return super.getOptions();
            }
        }

        return <></>
    }

    dependeeChanged(): any {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            const key = event.currentTarget.value;
            this.setState({key: key});
        }
    }
}

export default DependingSelect;