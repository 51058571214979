import React from 'react';
import {KnarkbokService} from "./Knarkbokservice";
import {BinderId, BookId, Drug, KnarkbokType} from "./models/Models";
import "./KnarkbokModal.css"
import Administer from "./innermodals/Administer";
import Discard from "./innermodals/Discard";
import Check from "./innermodals/Check";
import Move from "./innermodals/Move";
import {BackendContext} from "../../infra/BackendContext";
import {Organisation} from "../model/Organisation";
import PartialAdminister from "./innermodals/PartialAdminister";

interface props {
    bookId: BookId | null
    type: KnarkbokType;
    flipModal: (bookId?: BookId, sentEmailWarning?: boolean) => void;
    KBService: KnarkbokService
    isDruglord: boolean,
    organisation: Organisation,
    canReturnDrugFromPatient?: boolean
}

interface state {
    book: ModalDisplayBinder | undefined
}

export interface ModalDisplayBinder {
    drug: Drug,
    location: string,
    inventory: number,
    lastChecked: string,
    bookId: BookId,
    binderId: BinderId
}

class KnarkbokModal extends React.Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {
            book: undefined
        }
    }

    async componentDidMount() {
        let bookId = this.props.bookId;
        if (bookId) {
            const fetched: ModalDisplayBinder | null = await this.props.KBService.getModalDisplayBinder(bookId);
            if (fetched !== null) {
                this.setState({book: fetched})
            }
        }
    }

    render() {
        const type: KnarkbokType = this.props.type;
        const organisation: Organisation = this.props.organisation;
        const closeModal: () => void = this.props.flipModal;
        const isDrugLord: boolean = this.props.isDruglord;
        const kbService: KnarkbokService = this.props.KBService;

        let movePrefill;
        if (this.props.canReturnDrugFromPatient) {
            movePrefill = {
                operation: KnarkbokType.move_pos,
                showAdditionalDetails: true,
                closeOtherOptions: true
            }
        }

        let headline: React.JSX.Element = <div></div>;
        let innerModal: React.JSX.Element = <div></div>;
        const book: ModalDisplayBinder | undefined = this.state.book;
        if (book !== undefined) {
            if (type === KnarkbokType.administer) {
                innerModal = <Administer isDruglord={isDrugLord}
                                         KBService={kbService}
                                         book={book}
                                         closeModal={closeModal}
                />
            }

            if (type === KnarkbokType.move) {
                innerModal = <Move book={book}
                                   prefill={movePrefill}
                                   KBService={kbService}
                                   organisation={organisation}
                                   closeModal={closeModal}
                />
            }

            if (type === KnarkbokType.discard) {
                innerModal = <Discard isDruglord={isDrugLord}
                                      KBService={kbService}
                                      book={book}
                                      closeModal={closeModal}
                />
            }

            if (type === KnarkbokType.partial_administer) {
                innerModal = <PartialAdminister isDruglord={isDrugLord}
                                                KBService={kbService}
                                                book={book}
                                                closeModal={closeModal}
                />
            }


            if (type === KnarkbokType.check) {
                innerModal = <Check isDruglord={isDrugLord}
                                    KBService={kbService}
                                    book={book}
                                    closeModal={closeModal}
                />
            }

            const name: string = book.drug.name;
            const location: string = book.location;
            const inventory: number = book.inventory;
            const lastChecked: string = book.lastChecked;

            const firstRow: React.JSX.Element = <div className={"row pl-5 justify-content-left"}>
                Du har valt: {name} @ {location}
            </div>;
            const secondRow: React.JSX.Element = <div className={"row pl-5 pb-3 justify-content-left"}>
                Nuvarande saldo: {inventory} ({lastChecked})
            </div>;

            headline = <div>{firstRow} {secondRow}</div>;
        }

        return <div aria-label={'drug book modal'}>
            <aside className="knarkbok-modal-background"
                   onClick={closeModal}
            />
            <div className={"knarkbok-modal"}>
                <div className={"container p-0 m-0"}>
                    <div>
                        {headline}
                    </div>
                    {innerModal}
                </div>
            </div>
        </div>;
    }
}

export default KnarkbokModal;
