import React, {createRef, ReactNode} from "react";
import {Action, Field} from "../../register/v2/Action";
import {GraphOptions} from "./GraphOptions";
import TranslationService from "../../../infra/TranslationService";
import Radio from "../../fields/v2/Radio";
import {DESKTOP, V3} from "../../../infra/Constants";
import {FieldComponent} from "../../fields/v2/FieldComponent";
import Value from "../../register/v2/Value";
import getField from "../../register/v3/FieldFactory";
import Event, {createEvent, setValue} from "../../register/v3/Event";

interface props {
    action: Action,
    graphOptions: GraphOptions,
    device: string,
    user: any,
    onGroupByChange: (groupBy: string) => void
}

interface state {
}

class GroupBy extends React.Component<props, state> {
    groupByRef: any;

    constructor(props: props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    render(): ReactNode {
        this.groupByRef = createRef();
        const action: Action = this.props.action;
        const graphOptions: GraphOptions = this.props.graphOptions;

        const groupBy = this.getGroupBy(action, graphOptions);

        return <div>
            {groupBy}
        </div>;
    }

    private getGroupBy(action: Action, graphOptions: GraphOptions): React.ReactFragment {
        const groupByRef: any = this.groupByRef;
        let groupBy = <div/>;
        if (action.fields !== undefined) {
            const headline: string = TranslationService.translation("group_by");
            const name: string = action.name;
            const defaultValue: string | undefined = graphOptions.groupBy;

            const options: string[] = [];
            action.fields.forEach((field: Field) => {
                this.getFieldsWithGraphs(field, name, options);
            });

            const field: Field = {
                name: name,
                type: 'radio',
                fieldLabel: false,
                showLabel: false,
                defaultValue: defaultValue,
                mandatory: true,
                duplicationIndex: 0,
                options: options,
                optionsLayout: {
                    desktop: {columns: 2},
                    mobile: {columns: 1}
                }
            }

            let radio;
            if (action.frontendVersion === V3) {
                const duplicationIndex: string = '0';
                const event: Event = setValue(name, defaultValue, duplicationIndex, true, field, createEvent());
                const user = this.props.user;
                const onChange = this.onChange;

                radio = getField(field, event, onChange, action, user);
            } else {
                radio = <Radio ref={groupByRef}
                               field={field}
                               device={DESKTOP}/>;
            }

            groupBy = <div>
                <h4>{headline}</h4>
                {radio}
            </div>
        }

        return groupBy;
    }

    private getFieldsWithGraphs(field: Field, actionName: string, options: string[]): void {
        if (field.fields === undefined) {
            const graphType = field.graphType;
            if (graphType !== undefined) {
                // The action name will be added by the radio field.
                // Unfortunately, this leads to a need to remove the action name
                // when finding the fields one should be able to group by
                const prefix: number = actionName.length + 1;
                const cleaned = field.name.substr(prefix);
                options.push(cleaned);
            }
        } else {
            field.fields.forEach((field: Field) => {
                this.getFieldsWithGraphs(field, actionName, options);
            });
        }
    }

    groupBy(): string {
        const field: FieldComponent = this.groupByRef.current;
        const values: Value[] = field.values();

        return values[0].values[0];
    }

    private onChange(name: string, value: (string | string[]), duplicationIndex: string, valid: boolean) {
        if (name === duplicationIndex === valid) {
            // Trick Idea to think these arguments are used
        }
        let groupBy: string = this.props.graphOptions.groupBy;
        if (Array.isArray(value) && value.length === 1) {
            groupBy = value[0];
        }

        if (!Array.isArray(value)) {
            groupBy = value;
        }

        const onGroupByChange = this.props.onGroupByChange;

        onGroupByChange(groupBy);
    }
}

export default GroupBy;
