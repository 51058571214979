import {ActionService} from "./ActionService";
import {Action, Error} from "./Action";
import {fetchArrayOf} from "../../../infra/BackendService";


class ActionServiceBackend implements ActionService {
    private actions: Map<string, Action> = new Map<string, Action>();
    private ready: boolean = false;

    async init() {
        if (!this.ready) {
            const uri = '/api/v2/actions';

            const actions: Action[] = await fetchArrayOf<Action>(uri);

            actions.forEach((action: Action) => {
                const name: string = action.name;
                const version: number = action.version;
                const type: string = action.type;

                const key: string = ActionServiceBackend.getKey(name, version, type);

                this.actions.set(key, action);
            });

            this.ready = true;
        }
    }

    async getAction(name: string, version: number, type: string): Promise<Action | Error> {
        await this.init();
        const key: string = ActionServiceBackend.getKey(name, version, type);

        const action: Action | undefined = this.actions.get(key);

        if (action !== undefined) {
            return action;
        }

        return {reason: "unknown action", message: "action could not be found"};
    }

    private static getKey(name: string, version: number, type: string): string {
        return name + version + type;
    }
}

export default ActionServiceBackend;
