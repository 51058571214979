import React, {ReactElement} from "react";
import {Action, Field, FieldCount, Layout} from "../../register/v2/Action";
import Checkbox from "../../fields/v2/Checkbox";
import {DESKTOP, V2} from "../../../infra/Constants";
import AgeFilter from "./AgeFilter";
import {AgeFilterSelection} from "./GraphOptions";

interface props {
    action: Action,
    fieldReferences: Map<string, any>
    dateFormat: string,
}

interface state {
}

class Filter extends React.Component<props, state> {
    render(): ReactElement {
        const actionName = this.props.action.name;
        const fields = this.props.action.fields;
        const fieldReferences = this.props.fieldReferences;
        const device: string = DESKTOP;

        return <div>
            <div key={actionName}>
                {
                    fields.map(field => {
                        return Filter.drawForm(field, fieldReferences, device);
                    })
                }
            </div>
        </div>
    }

    private static drawForm(field: Field, fieldReferences: Map<string, any>, device: string): React.ReactFragment {
        const rows: FieldCount[] = Filter.getRows(field, device);
        let fieldIndex = 0;
        let rowCount = 0;
        const key: string = field.name;

        return <div key={key}>
            {
                rows.map((row: FieldCount) => {
                    const rowKey = key + "." + rowCount++;
                    if (field.fields !== undefined) {
                        const columns: Field[] = [];
                        for (let k = 0; k < row.fields; k++) {
                            let f: Field = field.fields[fieldIndex];
                            if (f !== undefined) {
                                fieldIndex++;
                                columns.push(f);
                            }
                        }

                        return <div key={rowKey} className={"row pb-4"} aria-label={rowKey}>
                            {Filter.drawColumns(columns, fieldReferences, device)}
                        </div>
                    } else {
                        return <div key={rowKey} className={"row pb-4"} aria-label={rowKey}>
                            {Filter.drawColumn(field, fieldReferences, device)}
                        </div>;
                    }
                })
            }
        </div>;
    }

    private static drawColumn(field: Field, fieldReferences: Map<string, any>, device: string) {
        const key = field.name;
        return <div key={key} className={"col"}>
            {getFields(field, fieldReferences, device)}
        </div>;
    }

    private static drawColumns(fields: Field[], fieldReferences: Map<string, any>, device: string): React.ReactFragment[] {
        const columns: React.ReactFragment[] = [];
        fields.forEach((field) => {
            const column = Filter.drawColumn(field, fieldReferences, device);
            columns.push(column)
        });

        return columns;
    }

    private static getRows(field: Field, device: string): FieldCount[] {
        let rows: FieldCount[] = [];

        if (field.layout !== undefined) {
            const layout: Layout = field.layout;

            if (device === DESKTOP) {
                rows = layout.desktop.rows;
            }

            if (field.fields !== undefined) {
                let sum = rows.reduce((partial_sum, a) => partial_sum + a.fields, 0);

                while (sum < field.fields.length) {
                    rows.push({fields: 1});
                    sum++;
                }
            }
        }

        if (rows.length === 0) {
            if (field.fields === undefined) {
                rows.push({fields: 1});
            } else {
                const fields = field.fields.length;
                rows.push({fields: fields});
            }
        }

        return rows;
    }
}

export default Filter;

function getFields(field: Field, fieldReferences: Map<string, any>, device: string): React.ReactFragment {
    const fields = field.fields;
    if (fields === undefined) {
        const key: string = field.name;
        return <div key={key}>
            {getField(field, fieldReferences, device)}
        </div>
    } else {
        const key: string = field.name;
        return <React.Fragment key={key}>
            {
                fields.map(field => {
                    return getFields(field, fieldReferences, device);
                })
            }
        </React.Fragment>;
    }
}

function getField(field: Field, fieldReferences: Map<string, any>, device: string): React.ReactFragment {
    const name = field.name;
    const ref: any = React.createRef();

    if (!!field.type) {
        if (field.type === "age") {
            fieldReferences.set(name, ref);

            const onAgeSelectionChange = (ageSelection: AgeFilterSelection | undefined) => {
                throw new Error('Should not be called! Not even with ' + ageSelection)
            }

            return <AgeFilter ref={ref}
                              field={field}
                              name={name}
                              device={device}
                              frontendVersion={V2}
                              ageSelection={undefined}
                              onAgeSelectionChange={onAgeSelectionChange}
            />
        }
    }

    if (field.options === undefined) {
        return <div/>;
    }

    fieldReferences.set(name, ref);
    return <Checkbox ref={ref}
                     key={name}
                     field={field}
                     enableAllOptions={true}
                     device={device}
    />
}
