import React from "react";
import "./StatisticsNumberFields.css"
import {Field} from "../../register/v2/Action";
import {FilterValue, FilterValueType, FilterValueTypedNumber} from "./FilterValue";
import TranslationService from "../../../infra/TranslationService";
import {uuidv4} from "../../register/v2/Uuid";
import "./StatisticsAgeField.css";
import X from "../../../images/X_2.png";


interface props {
    field: Field
    onChange: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
    onValueChangeNG: (filterValue: FilterValue) => void;
}

interface state {
    lowerBound: number | undefined,
    lowerBoundType: string | undefined,
    upperBound: number | undefined,
    upperBoundType: string | undefined,
    warningText: string,
    minKey: string,
    maxKey: string,
    showWarning: boolean,
    dontShowWarning: boolean
}

class StatisticsAgeField extends React.Component<props, state> {
    defaultValue = "";

    constructor(props: Readonly<props>) {
        super(props);
        const {options} = this.props.field;

        if (options) {
            const firstValue = options[0]
            if (typeof firstValue === "string") {
                this.defaultValue = firstValue;
            } else {
                this.defaultValue = firstValue.name
            }
        }

        this.state = {
            lowerBound: undefined,
            lowerBoundType: this.defaultValue,
            upperBound: undefined,
            upperBoundType: this.defaultValue,
            warningText: "",
            minKey: uuidv4(),
            maxKey: uuidv4(),
            showWarning: false,
            dontShowWarning: false
        }


    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        const {lowerBound, upperBound} = this.state;
        if (prevState.lowerBound && lowerBound === undefined) {
            //values erased, set new key to reset input and prevent dangling values
            this.setState({minKey: uuidv4()})
        }

        if (prevState.upperBound && upperBound === undefined) {
            //values erased, set new key to reset input and prevent dangling values
            this.setState({maxKey: uuidv4()})
        }

    }

    render() {
        const state = this.state;
        const {min, max, options} = this.props.field;
        const lowerBoundTypeWidth = Math.max(3, TranslationService.translation(state.lowerBoundType ? state.lowerBoundType : " ").length);
        const upperBoundTypeWidth = Math.max(3, TranslationService.translation(state.upperBoundType ? state.upperBoundType : " ").length);

        let warning = <div/>;

        if (state.showWarning) {
            warning = <div className={"row differing-age-units-warning"}>
                {TranslationService.translation(this.state.warningText)}
                <button id={"dont-show-warnings-id"} onClick={() => this.dontShowWarning()}
                        className={"dismiss-warning toast-btn-red"}><img className={"toast-image"} height={12} src={X}
                                                                         alt={"close toast"}/></button>
            </div>;
        }

        return <div className={"container m-0 p-0"}>
            <div className={"col m-0 p-0"}>
                <div className={"row m-0 pt-2"}>
                    <div className={"col m-0 p-0"}>
                        {TranslationService.translation(this.props.field.name) + ":"}
                    </div>
                </div>
                <div className={"row m-0 pt-1"}>
                    <div className={"col-12 m-0 p-0"}>
                        <div className={"row m-0 p-0"}>
                            <input key={state.minKey}
                                   aria-label={this.props.field.name + ".fitymi_min_boundary"}
                                   type={"text"} size={3}
                                   placeholder={min ? min + "" : 0 + ""}
                                   value={state.lowerBound || ""}
                                   onChange={(e) => this.updateLowerBound(e)}/>
                            <select value={this.state.lowerBoundType}
                                    aria-label={this.props.field.name + ".fitymi_min_boundary.type"}
                                    className={"mr-2"}
                                    style={{width: lowerBoundTypeWidth + "rem"}}
                                    onChange={(e) => this.handleLowerBoundTypeChange(e)}>
                                {
                                    options?.map((op) => {
                                        if (typeof op === "string") {
                                            return <option key={op}
                                                           value={"" + op}>{TranslationService.translation(op)}</option>
                                        } else {
                                            return <option key={op.name}
                                                           value={"" + op.name}>{TranslationService.translation(op.name)}</option>
                                        }
                                    })
                                }
                            </select>
                            <div className={"pt-1 pr-2 middle-explanation-box"}>{'≤'} x {'≤'}</div>
                            <input key={state.maxKey}
                                   type={"text"}
                                   aria-label={this.props.field.name + ".fitymi_max_boundary"}
                                   placeholder={max ? max + "" : 0 + ""}
                                   size={3}
                                   value={state.upperBound || ""}
                                   onChange={(e) => this.updateUpperBound(e)}/>
                            <select value={this.state.upperBoundType}
                                    className={"mr-2"}
                                    aria-label={this.props.field.name + ".fitymi_max_boundary.type"}
                                    style={{width: upperBoundTypeWidth + "rem"}}
                                    onChange={(e) => this.handleUpperBoundTypeChange(e)}>
                                {
                                    options?.map((op) => {
                                        if (typeof op === "string") {
                                            return <option key={op}
                                                           value={"" + op}>{TranslationService.translation(op)}</option>
                                        } else {
                                            return <option key={op.name}
                                                           value={"" + op.name}>{TranslationService.translation(op.name)}</option>

                                        }
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className={"col-12"}>
                        {warning}

                    </div>
                </div>

            </div>
        </div>;
    }

    private updateProps() {
        const {lowerBound, upperBound, lowerBoundType, upperBoundType} = this.state;
        const {onValueChangeNG, field} = this.props;

        this.checkForTypeMisMatch()

        const filterValue: FilterValueTypedNumber = {
            fieldName: field.name,
            type: FilterValueType.Age,
            min: lowerBound,
            max: upperBound,
            erase: this.erase,
            maxType: upperBoundType,
            minType: lowerBoundType
        };

        onValueChangeNG(filterValue);
    }

    erase = () => {
        this.setState({
            lowerBound: undefined,
            upperBound: undefined,
            warningText: "",
            lowerBoundType: this.defaultValue,
            upperBoundType: this.defaultValue
        });
    }

    private updateLowerBound(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = +e.currentTarget.value;
        this.setState({lowerBound: newValue}, () => this.updateProps());
    }

    private updateUpperBound(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = +e.currentTarget.value;
        this.setState({upperBound: newValue}, () => this.updateProps());
    }

    private handleLowerBoundTypeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({lowerBoundType: e.currentTarget.value}, this.state.lowerBound ? () => this.updateProps() : undefined)
    }

    private handleUpperBoundTypeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({upperBoundType: e.currentTarget.value}, this.state.upperBound ? () => this.updateProps() : undefined)
    }

    private checkForTypeMisMatch() {
        const {lowerBound, upperBound, upperBoundType, lowerBoundType, dontShowWarning} = this.state;
        if ((upperBound && lowerBound) && (upperBoundType !== lowerBoundType) && !dontShowWarning) {
            this.setState({showWarning: true, warningText: "differing-age-units-warning"})
        } else {
            this.setState({showWarning: false})
        }
    }

    private dontShowWarning() {
        this.setState({dontShowWarning: true}, () => this.checkForTypeMisMatch());
    }
}

export default StatisticsAgeField;
