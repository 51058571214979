import React, {ReactElement} from "react";
import SearchUsers from "./SearchUsers";
import {UserOldRoles} from "../../../model/UserOldRoles";
import ManageUsers from "./ManageUsers";
import CreateUserForm from "./CreateUserForm";

interface props {
}

interface state {
    selectedUser: UserOldRoles | undefined,
}

class SearchUsersV3 extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            selectedUser: undefined,
        }
    }

    render(): ReactElement {
        const selectedUser = this.state.selectedUser;

        let manageUsers = <div/>;

        if (selectedUser !== undefined) {
            manageUsers = <ManageUsers user={selectedUser}/>;
        }

        return <div className={"row"}>
            <div className={"col-4"}>
                <div aria-label={"search users"}>
                    <SearchUsers selectUser={this.selectedUser()}/>
                </div>
                <div>
                    <div aria-label={"create users"} className={"mt-4"}>
                        <CreateUserForm/>
                    </div>
                </div>
            </div>
            <div className={"col-8"}>
                {manageUsers}
            </div>
        </div>
    }

    private selectedUser() {
        return (selectUser: UserOldRoles | undefined) => this.setState({selectedUser: selectUser});
    }
}

export default SearchUsersV3;
