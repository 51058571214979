import React, {ChangeEvent, ReactElement} from 'react';
import {FieldComponent, FieldError, FieldProps} from "./FieldComponent";
import Value from "../../register/v2/Value";
import {createSingleValue, isSingleValueValid} from "./FieldUtil";
import {V3} from "../../../infra/Constants";
import {Field} from "../../register/v2/Action";

interface props extends FieldProps {
    frontendVersion?: string,
    value?: string,
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
}

interface state {
    value: string
}

class Comment extends React.Component<props, state> implements FieldComponent {
    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            value: ""
        }
    }

    render(): ReactElement {
        const name = this.props.field.name;
        const ariaLabel = this.props.field.name;
        const value = this.getValue();

        const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => this.onChange(e);

        let field = this.getField(ariaLabel, name, value, onChange);

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            return <div>
                {field}
            </div>
        } else {
            return field
        }
    }

    private getField(ariaLabel: string, name: string, value: string, onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void) {
        const rows = 4;
        const cols = 35;

        return <textarea aria-label={ariaLabel}
                         className={"form-control"}
                         id={name}
                         rows={rows}
                         cols={cols}
                         value={value}
                         onChange={onChange}
        />;
    }

    private getValue() {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            let value = this.props.value;
            if (value !== undefined) {
                return value;
            } else {
                return '';
            }
        } else {
            return this.state.value;
        }
    }

    private onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        let currentValue = e.currentTarget.value;

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            if (this.props.onChange) {
                let field = this.props.field;
                const name: string = field.name;
                let duplicationIndex: string = '0';
                if (this.props.duplicationIndex !== undefined) {
                    duplicationIndex = this.props.duplicationIndex;
                }

                this.props.onChange(name, currentValue, duplicationIndex, true, field);
            }
        } else {
            this.setState({value: currentValue});
        }
    }

    values(): Value[] {
        const name = this.props.field.name;
        const value = this.getValue();

        if (this.isValid().valid) {
            if (value !== "") {
                return createSingleValue(name, value);
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        const value = this.getValue();
        const mandatory = this.props.field.mandatory;

        return isSingleValueValid(name, value, mandatory);
    }

    clear(): void {
        this.setState({value: ""})
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        values.forEach((value: Value) => {
            if (value.fieldName === name) {
                const newValue: string = value.values[0];
                this.setState({value: newValue});
            }
        });
    }
}

export default Comment;