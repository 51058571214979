import {Field} from "../v2/Action";

export function getDuplicationIndex(field: Field): string {
    const index = '';
    return getDuplicationIndexRecursive(field, 0, index);
}

function getDuplicationIndexRecursive(field: Field, duplicationCount: number, index: string): string {
    const parent = field.parent;

    if (parent === undefined) {
        if (index === '') {
            return '' + field.duplicationIndex;
        }

        if (field.duplicable !== undefined && field.duplicable) {
            if (duplicationCount === 0) {
                return '' + field.duplicationIndex;
            } else {
                return '' + field.duplicationIndex + '-' + index;
            }
        }

        return index;
    } else {
        if (field.duplicable !== undefined && field.duplicable) {
            if (duplicationCount === 0) {
                const newDuplicationIndex: string = '' + field.duplicationIndex;
                let nextDuplication = duplicationCount + 1;

                return getDuplicationIndexRecursive(parent, nextDuplication, newDuplicationIndex);
            } else {
                const newDuplicationIndex: string = '' + field.duplicationIndex + '-' + index;
                let nextDuplication = duplicationCount + 1;

                return getDuplicationIndexRecursive(parent, nextDuplication, newDuplicationIndex);
            }
        }

        return getDuplicationIndexRecursive(parent, duplicationCount, index);
    }
}
