import React from 'react';
import Home from './splashpage/Home';
import About from "./about/About";
import UserPage from "./user/UserPage";
import Backoffice from "./backoffice/Backoffice";
import Supervisor from "./supervisor/Supervisor";
import {Route, Switch} from "react-router";
import RegisterV2 from "./register/v2/Register";
import NewNew from "./prototype/NewNew";
import CountersignMailbox from "./countersign/v1/CountersignMailbox";
import ActionServiceBackend from "./register/v2/ActionServiceBackend";
import {EventBackendService} from "./register/v2/EventBackendService";
import {StatisticsBackendService} from "./statistics/StatisticsBackendService";
import {EVENT, GOAL, STATISTICS} from "../infra/Constants";
import Reports from "./reports/v1/Reports";
import CountersignForm from "./countersign/v2/CountersignForm";
import CountersignRequests from "./countersign/v2/CountersignRequests";
import PrehospitalHome from "./splashpage/PrehospitalHome";
import {KnarkbokService} from "./knarkbok/Knarkbokservice";
import StatisticsSelector from "./statistics/StatisticsSelector";
import KnarkbokLanding from "./knarkbok/KnarkbokLanding";
import {UserData} from "./model/UserData";
import {User} from './model/User';
import {Right} from "./model/Right";
import {ActionMenuItem} from "./model/ActionMenuItem";
import {NavBarItem} from "./model/NavBarItem";
import {Organisation} from "./model/Organisation";
import UniversalDashboard from "./splashpage/UniversalDashboard";
import DrugBookRouter from "./knarkbok/DrugBookRouter";
import OrganisationSelector from "./organisation/OrganisationSelector";
import {NavBar} from "./model/NavBar";
import {hasRight} from "../utils/HasRight";
import KnarkbokOverview from "./knarkbok/KnarkbokOverview";
import UserAdministration from "../pages/administration/user/UserAdministration";


interface props {
    userData: UserData,
    currentOrganisation?: Organisation,
    refreshExpiredToken: () => void,
    securityToken: string,
    action: string,
    statsAction: string,
    hasSelectedOrganisation: boolean,
    updateNavBar: (navBar: NavBar) => void,
    setCurrentOrganisation: (organisation: Organisation | undefined) => void,
    testing: boolean,
    updateUserData: (newUserData: UserData) => void
}

interface state {
    splashData: any,
}

class Content extends React.Component<props, state> {
    actionServiceBackend: ActionServiceBackend;
    eventBackend: EventBackendService;
    statisticsService: StatisticsBackendService;

    constructor(props: props) {
        super(props);

        this.setSplashData = this.setSplashData.bind(this);
        this.updateUser = this.updateUser.bind(this);


        this.actionServiceBackend = new ActionServiceBackend();
        this.eventBackend = new EventBackendService();
        this.statisticsService = new StatisticsBackendService();

        this.state = {
            splashData: {},
        }
    }

    setSplashData(splashData: any) {
        this.setState({splashData: splashData});
    }

    render() {
        //TODO this should be changed to something real, dont supply this with dummydata
        //TODO move elsewhere?
        const kbservice = new KnarkbokService();

        const {user} = this.props.userData;

        function isSupervisor(user: User) {
            let listOfRights: Right[] = user.rights;
            for (let right of listOfRights) {
                if (right.type === 'supervisor') {
                    return true;
                }
            }

            return false;
        }

        function hasExportRight(user: User): boolean {
            let listOfRights: Right[] = user.rights;
            for (let right of listOfRights) {
                if ((right.type === 'export-events' && right.name === 'prehospital-response-unit')) {
                    return true;
                }
            }

            return false;
        }

        function hasWorldAirwayExportRight(user: User) {
            let listOfRights: Right[] = user.rights;
            for (let right of listOfRights) {
                if ((right.type === 'export-world-events' && right.name === 'airway')) {
                    return true;
                }
            }

            return false;
        }

        function hasWorldPrehospitalExportRight(user: User) {
            let listOfRights: Right[] = user.rights;
            for (let right of listOfRights) {
                if ((right.type === 'export-world-events' && right.name === 'prehospital-response-unit')) {
                    return true;
                }
            }

            return false;
        }

        function hasExportOrganisationEventsRight(data: User) {
            let listOfRights = data.rights;
            for (let right of listOfRights) {
                if ((right.type === 'export-organisation-events' && right.name.startsWith('prehospital-response-unit'))) {
                    return true;
                }
            }

            return false;
        }

        const currentOrganisation: Organisation | undefined = this.props.currentOrganisation;
        let dashBoard: React.JSX.Element;
        if (!this.props.hasSelectedOrganisation) {
            dashBoard = <OrganisationSelector user={user}
                                              updateNavBar={this.props.updateNavBar}
                                              setCurrentOrganisation={this.props.setCurrentOrganisation}
                                              updateUser={this.updateUser}
                                              testing={this.props.testing}
            />;
        } else {

            let hasSpecialRight: boolean = false;
            //this could use some cleaning up, move logic in UniversalDashboard
            for (const right of user.rights) {
                if ((right.type === 'interface access' && right.name === 'prehospital-home-page') || right.type === 'prehospital-dashboard-access') {
                    dashBoard = <PrehospitalHome
                        user={user}
                        currentOrganisation={currentOrganisation}
                        hasExportRight={hasExportRight(user)}
                        hasExportOrganisationEventsRight={hasExportOrganisationEventsRight(user)}
                        hasWorldAirwayExportRight={hasWorldAirwayExportRight(user)}
                        hasWorldPrehospitalExportRight={hasWorldPrehospitalExportRight(user)}
                    />
                    hasSpecialRight = true;
                    break;
                }
            }
            if (!hasSpecialRight) {
                for (const right of user.rights) {
                    if (right.type === 'dashboard') {
                        dashBoard = <UniversalDashboard user={user}/>
                        hasSpecialRight = true;
                        break;
                    }
                }
            }
            if (!hasSpecialRight && currentOrganisation !== undefined) {
                if (hasRight(user, "drug-journal-overview", "drug-administrator")) {
                    dashBoard = <KnarkbokOverview KBService={kbservice} organisation={currentOrganisation}/>
                    hasSpecialRight = true;
                }
            }

            if (!hasSpecialRight) {
                dashBoard = this.renderHome();
            }


        }

        return (
            <div id={"mainContainer"}
                 className={"mainContainer"}
                 aria-label={"mainContainer"}
            >
                <Switch>
                    <Route path="/" exact render={() => dashBoard}/>

                    <Route path="/about" exact render={() => <About splashData={this.state.splashData}
                                                                    refreshExpiredToken={this.props.refreshExpiredToken}
                                                                    securityToken={this.props.securityToken}/>}/>

                    <Route path="/user" render={() => <UserPage userData={this.props.userData}
                                                                refreshExpiredToken={this.props.refreshExpiredToken}
                                                                securityToken={this.props.securityToken}/>}/>

                    <Route path="/new" render={() => <NewNew refreshExpiredToken={this.props.refreshExpiredToken}
                                                             userData={this.props.userData}
                                                             securityToken={this.props.securityToken}/>}/>

                    <Route path="/backoffice"
                           render={() => {
                               const userData: UserData = this.props.userData;
                               const user: User = userData.user;
                               return <Backoffice refreshExpiredToken={this.props.refreshExpiredToken}
                                                  securityToken={this.props.securityToken}
                                                  user={user}
                               />;
                           }}/>

                    <Route path="/countersignmailbox"
                           render={() => <CountersignMailbox refreshExpiredToken={this.props.refreshExpiredToken}
                                                             securityToken={this.props.securityToken}/>}/>

                    <Route path="/countersign/:countersignId"
                           component={CountersignForm}
                    />

                    <Route path="/countersignrequests"
                           render={() => <CountersignRequests refreshExpiredToken={this.props.refreshExpiredToken}
                                                              securityToken={this.props.securityToken}/>}/>

                    <Route path="/register" render={() => this.renderForm("register", EVENT)}/>
                    <Route path="/goal" render={() => this.renderForm("goal", GOAL)}/>

                    <Route path="/statistics" render={() => this.renderStatistics("statistics")}/>

                    <Route path="/reports" render={() => this.renderReports()}/>

                    <Route path="/fjn/:drugBook" render={() => {


                        return <DrugBookRouter
                            KBService={kbservice}
                        />;
                    }}/>

                    <Route path="/forbrukningsjournal" render={() => {
                        if (currentOrganisation !== undefined) {
                            return <div>
                                <KnarkbokLanding KBService={kbservice}
                                                 organisation={currentOrganisation}
                                                 action={this.props.action}
                                />
                            </div>;
                        } else {
                            return <div>
                                No organisation selected. This message should never be shown...
                                But you see it so please talk to the devs and make it disappear.
                            </div>;
                        }
                    }}/>

                    <Route path="/universal-dashboard" render={() => <UniversalDashboard user={user}/>}/>
                    <Route path="/administration" render={() => <UserAdministration organisation={currentOrganisation}/>}/>


                    {
                        //This section is for supervisor routing
                    }
                    {this.props.userData === undefined ? "" : (isSupervisor(this.getUser()) ?
                        <Route path="/supervisor" render={() => <Supervisor userData={this.props.userData}
                                                                            refreshExpiredToken={this.props.refreshExpiredToken}
                                                                            securityToken={this.props.securityToken}/>}/>

                        : "")}
                </Switch>
            </div>
        );
    }

    private renderHome() {
        return <Home setSplashData={this.setSplashData}
                     refreshExpiredToken={this.props.refreshExpiredToken}
                     user={this.props.userData.user}
                     securityToken={this.props.securityToken}/>;
    }

    renderForm(route: string, actionType: string) {
        const action = this.findAction(route);
        const actionName = action.name;
        const actionVersionStr: string = action.version;
        let actionVersion: number = Number(actionVersionStr);
        if (isNaN(actionVersion)) {
            actionVersion = 0;
        }

        const key = actionName + "." + actionVersion + "." + actionType;
        const dateFormat = this.getDateFormat();

        const user = this.getUser();
        const currentOrganisation: Organisation | undefined = this.props.currentOrganisation;

        let userEmail: string;
        if (user !== undefined) {
            userEmail = user.email;
        } else {
            userEmail = "";
        }

        return <RegisterV2 key={key}
                           actionName={actionName}
                           actionVersion={actionVersion}
                           actionType={actionType}
                           dateFormat={dateFormat}
                           eventBackend={this.eventBackend}
                           actionService={this.actionServiceBackend}
                           statisticsService={this.statisticsService}
                           user={user}
                           currentOrganisation={currentOrganisation}
                           userEmail={userEmail}
        />
    }

    renderStatistics(route: string) {
        const action = this.findAction(route);

        const actionName = action.name;
        const actionVersionStr = action.version;
        let actionVersion: number = Number(actionVersionStr);
        if (isNaN(actionVersion)) {
            actionVersion = 0;
        }
        const key: string = actionName + "." + actionVersion + "." + STATISTICS;
        const user: User = this.getUser();
        const currentOrganisation: Organisation | undefined = this.props.currentOrganisation;
        const dateFormat: string = this.getDateFormat();

        return <StatisticsSelector key={key}
                                   actionName={actionName}
                                   actionVersion={actionVersion}
                                   dateFormat={dateFormat}
                                   actionService={this.actionServiceBackend}
                                   statisticsService={this.statisticsService}
                                   user={user}
                                   currentOrganisation={currentOrganisation}
        />
    }

    renderReports() {
        const actionName = this.props.action;
        const user = this.getUser();
        return <Reports actionName={actionName} user={user}/>
    }

    findAction(route: string): ActionMenuItem {
        const data = this.props.userData;
        const navBar = data.navBar;
        let action: ActionMenuItem | undefined = undefined;
        if (navBar !== undefined && navBar.items !== undefined) {
            if (route === 'statistics') {
                const navBarItem: NavBarItem | undefined = navBar.items
                    .find((item: NavBarItem) => item.route === "/" + route);
                if (navBarItem !== undefined) {
                    action = navBarItem.subList
                        .find(action => action.name === this.props.statsAction);
                }
            } else {

                const navBarItem: NavBarItem | undefined = navBar.items
                    .find((item: NavBarItem) => item.route === "/" + route);
                if (navBarItem !== undefined) {
                    action = navBarItem.subList
                        .find(action => action.name === this.props.action);
                }
            }
        }


        if (action === undefined) {
            return {
                name: '',
                version: '',
                nameTranslated: '',
                modelVersion: "v1"
            };
        } else {
            return action;
        }
    }

    getDateFormat(): string {
        const user = this.getUser();
        if (user !== undefined && user.dateFormat !== undefined) {
            return user.dateFormat;
        }

        return "yyyy-MM-dd";
    }

    getUser(): User {
        return this.props.userData.user;
    }

    getSecurityToken() {
        return this.props.userData.securityToken;
    }

    private updateUser(user: User): void {
        let userData = {...this.props.userData};
        userData.user = user;
        this.props.updateUserData(userData);
    }
}

export default Content;
