import React from "react";
import {fetchArrayOf} from "../../../infra/BackendService";
import Select from "react-select";
import {Organisation} from "../../backoffice/roles/v3/Organisation";
import {OptionType} from "../../model/SelectOption";
import TranslationService from "../../../infra/TranslationService";


interface props {
    onOrganisationSelect: any
}

interface state {
    options: OptionType[],
    currentClass: OptionType | undefined
}


class OrganisationDynamicSelect extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);

        this.onChangeSelect = this.onChangeSelect.bind(this);

        this.state = {
            options: [],
            currentClass: undefined
        }
    }

    componentDidMount() {
        this.retrieveOptions();
    }

    private retrieveOptions() {
        let url: string = '/api/v1/reports/delivery/classes';
        fetchArrayOf<Organisation>(url).then((items: Organisation[] | undefined) => {
            if (items !== undefined) {
                let options: OptionType[] = [];
                items.forEach((item: Organisation) => {
                    let option: OptionType = {
                        value: item.id,
                        label: item.name
                    }
                    options.push(option);
                });
                let currentValue: OptionType | undefined = undefined;
                if (options.length > 0) {
                    currentValue = options[0];
                }
                this.props.onOrganisationSelect(currentValue);
                this.setState({options: options, currentClass: currentValue});
            }
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        let options = this.state.options;
        return <div className={"row"}>
            <div className={"col newSelectContainer select-in-foreground"}>
                <h4>{TranslationService.translation("delivery.report.select.class")}</h4>
                <Select options={options}
                        isMulti={false}
                        onChange={this.onChangeSelect}
                        value={this.state.currentClass}
                        aria-label={"organisation.select.label"}
                />
            </div>
        </div>;
    }

    private onChangeSelect(selectedOption: any) {
        if (this.props.onOrganisationSelect !== undefined && selectedOption !== undefined && selectedOption.value !== undefined) {
            this.setState({currentClass: selectedOption})
            this.props.onOrganisationSelect(selectedOption);
        }
    }

}

export default OrganisationDynamicSelect;
