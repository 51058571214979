import React, {Component} from 'react';

class ErrorPage extends Component {
    render() {
        return (
            <div className={"container"}>
                <h1>Ajdå, något har gått snett...</h1>
                <p>Prova att:</p>
                <ol>
                    <li>Ladda om din webbläsare</li>
                    <li>Stäng fönstret och starta om</li>
                    <li>Om du kommit in via en genväg eller ett bokmärke kan du prova att gå till <a href={"https://www.logeze.se"}>https://www.logeze.se</a></li>
                </ol>
            </div>
        );
    }
}

export default ErrorPage;