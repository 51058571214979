export const EVENT: string = "event";
export const GOAL: string = "goal";
export const STATISTICS: string = "statistics";
export const REPORT: string = "REPORT";
export const NOT_REGISTERED: string = "NOT_REGISTERED";
export const ALL_ALTERNATIVES: string = "ALL_ALTERNATIVES";
export const DELETE_ALT_TEXT = "Delete";
export const EDIT_ALT_TEXT = "Edit";
export const COPY_ALT_TEXT = "Copy";
export const VIEW_ALT_TEXT = "View";
export const V2: string = 'v2';
export const V3: string = 'v3';

export const changeLayoutWidth: number = 1090;
export const DESKTOP: string = "desktop";
export const MOBILE: string = "mobile";

export enum Resolution {
    "Desktop" = "DESKTOP",
    "Mobile" = "MOBILE"
}
