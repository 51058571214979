import React, {ChangeEvent, ReactElement} from "react";
import ReportTable, {Report} from "./ReportTable";
import {fetchObjectOf} from "../../../infra/BackendService";
import {UserOld} from "../../model/UserOld";
import TranslationService from "../../../infra/TranslationService";
import {SharingServiceBackend} from "../../sharing/SharingService";
import {REPORT} from "../../../infra/Constants";


interface props {
    reportName: string,
    type: string,
    questionsNumber: number
}

interface state {
    report: Report | undefined,
    selectedReporter: string | undefined,
    reporters: UserOld[] | undefined
}

class LiaReport extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            report: undefined,
            selectedReporter: undefined,
            reporters: undefined
        }
    }

    render(): ReactElement {
        const type: string = this.props.type;
        if (this.state.reporters !== undefined && this.state.reporters?.length === 0) {
            let key = type + ".no.reports";
            return <div>{TranslationService.translation(key)}</div>;
        }
        const selection = this.getSelection();
        let report = this.getReport();

        return <div>
            {selection}
            {report}
        </div>;
    }

    private getReport() {
        let report = this.state.report;
        if (report === undefined) {
            return <div/>
        }

        report.reporterSchoolClass = "TCC Boden YH8"; // todo should be fetched from the backend

        const legend = this.getLegend();
        const reportName = this.props.reportName;
        return <ReportTable reportName={reportName}
                            report={report}
                            horizontalScrolling={true} legend={legend}/>
    }


    componentDidMount() {
        const type: string = this.props.type;
        const sharingService: SharingServiceBackend = new SharingServiceBackend();
        const sharers = sharingService.getSharers(type, REPORT);

        sharers.then((liaReporters: UserOld[]) => {
            const selectedReporter = liaReporters.length > 0 ? liaReporters[0].userName : undefined
            this.setState({
                reporters: liaReporters,
                selectedReporter: selectedReporter
            });
            if (selectedReporter !== undefined) {
                this.loadReport(selectedReporter);
            }
        });

    }

    private loadReport(selectedReporter: string) {
        const type: string = this.props.type;
        let url = "/api/v1/reports/" + type + "/" + selectedReporter;
        fetchObjectOf<Report>(url)
            .then((report) => {
                    this.setState({report: report});
                }
            );
    }

    private getSelection(): ReactElement {
        const type: string = this.props.type;
        let reporterSelect: ReactElement;
        const reporters = this.state.reporters;
        if (reporters === undefined || reporters.length < 2) {
            reporterSelect = <div/>;
        } else {
            const selectedReporter = this.state.selectedReporter;
            const name: string = "reporter.select";

            let key = type + ".select.reporter";
            const labelText = TranslationService.translation(key);
            const label: ReactElement = <div className={"row"}>
                <div className={"col"}>
                    <label aria-label={name + ".label"}
                           htmlFor={name}>{labelText}</label>
                </div>
            </div>;

            const options: ReactElement[] = reporters.map((u: UserOld) => {
                return <option key={u.userName}
                               value={u.userName}
                               data-testid={"select-option"}>
                    {u.firstName} {u.lastName}
                </option>;
            });

            const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
                this.onChange(e);
            }

            const field: ReactElement = <div className={"row"}>
                <div className={"col"}>
                    <select aria-label={"reporter.selection"}
                            name={name}
                            value={selectedReporter}
                            onChange={onChange}>
                        {options}
                    </select>
                </div>
            </div>;

            reporterSelect = <div>
                {label}
                {field}
                <hr/>
            </div>;
        }

        return <div>
            {reporterSelect}
        </div>;
    }

    private onChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const selectedReporter = e.target.value;
        this.setState({selectedReporter: selectedReporter});
        this.loadReport(selectedReporter);
    }

    private getLegend() {
        const type: string = this.props.type;
        let key = type + ".legend.header";
        const legendHeader = TranslationService.translation(key);
        const questionNumber: number = this.props.questionsNumber;

        let legend = [];
        for (let i = 1; i <= questionNumber; i++) {
            let qKey = type + ".legend.q" + i;
            let qLegend = TranslationService.translation(qKey)
            legend.push(<div key={"q" + i}> {qLegend} </div>);
        }

        return <div>
            <hr/>
            <div className="strong" aria-label="legendHeader">{legendHeader}</div>
            {legend}
        </div>
    }
}


export default LiaReport;
