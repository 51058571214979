import {Report} from "./ReportTable";
import React from "react";
import overview from "../../../images/overviewReport.png"

interface props {
}

interface state {
    report: Report | undefined,
}


class OverviewReport extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            report: undefined,
        }
    }

    render() {
        return <div><img alt="Overviewl" src={overview}/></div>
    }
}

export default OverviewReport