import React, {Component} from "react";
import TranslationService from "../../infra/TranslationService";

interface props {
    onClick: (value: string, group: string, duplicationIndex?: number) => void;
    radioGroup: string,
    value: string
    checked: boolean,
    duplicationIndex?: number,
    cssRules?: string
}

interface state {
}

export default class RadioV4Render extends Component<props, state> {

    render(): React.JSX.Element {
        const {radioGroup, value, onClick, checked, duplicationIndex} = this.props
        let translation = TranslationService.translation(value);
        return <div onClick={() => onClick(value, radioGroup, duplicationIndex)}
                    className={this.props.cssRules || "default-radio-button-v4"}>
            <input type={"radio"}
                   name={radioGroup}
                   id={value}
                   readOnly={true}
                   checked={checked}
                   data-testid={value}
                   aria-label={value}
            />
            {translation}
        </div>
    }
}
