import {UserOld} from "../model/UserOld";
import {fetchArrayOf} from "../../infra/BackendService";

export class SharingServiceBackend {
    private sharersCache: Map<string, UserOld[]> = new Map<string, UserOld[]>();

    async getSharers(actionName: string, type: string): Promise<UserOld[]> {
        const key: string = SharingServiceBackend.getKey(actionName, type);
        const sharers: UserOld[] | undefined = this.sharersCache.get(key);
        if (sharers === undefined) {
            const url = "/api/v1/sharing/" + actionName + "/" + type;
            let retrievedSharers: UserOld[] = await fetchArrayOf<UserOld>(url);

            this.sharersCache.set(key, retrievedSharers);

            return retrievedSharers;
        } else {
            return sharers;
        }
    }

    private static getKey(actionName: string, type: string): string {
        return actionName + type;
    }
}
