import React, {ChangeEvent, ReactElement} from 'react';
import {DependingOption, Field} from "../../register/v2/Action";
import {FieldComponent, FieldError, FieldProps} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import Value from "../../register/v2/Value";
import {createSingleValue, isSingleValueValid} from "./FieldUtil";
import {V3} from "../../../infra/Constants";

interface props extends FieldProps {
    defaultSelection?: string
    frontendVersion?: string,
    value?: string,
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
}

interface state {
    value: string,
    key: string    // depending select must be able to understand what key they should use when preparing their options
}

const DEFAULT_NOT_SELECTED: string = "defaultNotSelected";

class CustomSelect extends React.Component<props, state> implements FieldComponent {
    constructor(props: Readonly<props>) {
        super(props);
        let defaultValue = this.getDefaultValue();

        this.state = {
            key: "",
            value: defaultValue
        }
    }

    render(): ReactElement {
        const name: string = this.props.field.name;
        const currentValue = this.getCurrentValue();
        const label: string = TranslationService.translation(this.props.field.name);
        const onChange = (e: ChangeEvent<HTMLSelectElement>) => this.onChange(e);

        let showLabel: boolean = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        let field = this.getField(label, showLabel, name, currentValue, onChange);

        return <div>
            {field}
        </div>
    }

    private getCurrentValue(): string {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            if (this.props.value !== undefined) {
                return this.props.value;
            } else {
                return '';
            }
        } else {
            return this.state.value;
        }
    }

    private getField(labelText: string,
                     showLabel: boolean,
                     name: string,
                     selectedOption: string,
                     onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void): React.ReactFragment {

        const label = <h5>
            <div className={"row"}>
                <div className={"col"}>
                    <label aria-label={name + ".label"}
                           htmlFor={name}>{labelText}</label>
                </div>
            </div>
        </h5>;

        const field = <div className={"row"}>
            <div className={"col"}>
                <select id={name}
                        name={name}
                        aria-label={name}
                        value={selectedOption}
                        onChange={onChange}
                >
                    {this.getDefault()}
                    {this.getOptions()}
                </select>
            </div>
        </div>;

        if (!showLabel) {
            return <>
                {field}
            </>;
        }

        return <div>
            {label}
            {field}
        </div>;
    }

    private getDefault(): React.ReactFragment {
        let showDefaultOption: boolean = true;
        if (this.props.field.showDefaultOption !== undefined) {
            showDefaultOption = this.props.field.showDefaultOption;
        }

        if (showDefaultOption) {
            const name: string = this.getOptionName(DEFAULT_NOT_SELECTED);
            const translatedOption = TranslationService.translation(name);

            return <option key={name}
                           data-testid="select-option"
                           value={name}
            >
                {translatedOption}
            </option>
        } else {
            return <></>
        }
    }

    getOptions(): React.ReactFragment {
        let options: string[] = [];
        const src = this.props.field.options;
        if (src !== undefined) {
            src.forEach((option: string | Field) => {
                if (typeof option === 'string') {
                    const optionName: string = this.getOptionName(option);
                    options.push(optionName);
                }
            })
        }
        let fieldName = this.props.field.name;
        return options.map((option: string) => {
            let translatedOption = TranslationService.translation(option);
            if (translatedOption.startsWith(fieldName)) {
                let index = translatedOption.lastIndexOf('.');
                translatedOption = translatedOption.substring(index + 1);
            }

            return <option key={option}
                           data-testid="select-option"
                           value={option}
            >
                {translatedOption}
            </option>
        });
    }

    private getOptionName(option: string): string {
        const name: string = this.props.field.name;

        return name + "." + option;
    }

    private onChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const currentValue = e.target.value;

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            if (this.props.onChange) {
                let field = this.props.field;
                const name: string = field.name;
                let duplicationIndex: string = '0';
                if (this.props.duplicationIndex !== undefined) {
                    duplicationIndex = this.props.duplicationIndex;
                }

                this.props.onChange(name, currentValue, duplicationIndex, true, field);
            }
        } else {
            this.setState({value: currentValue});
        }
    }

    values(): Value[] {
        const name = this.props.field.name;
        const value = this.getCurrentValue();

        if (this.isValid().valid) {
            if (value !== "") {
                if (value.endsWith(DEFAULT_NOT_SELECTED)) {
                    return [];
                } else {
                    return createSingleValue(name, value);
                }
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        let value = this.getCurrentValue();
        if (value.endsWith(DEFAULT_NOT_SELECTED)) {
            value = "";
        }
        const mandatory = this.props.field.mandatory;

        return isSingleValueValid(name, value, mandatory);
    }

    clear(): void {
        const defaultValue = this.getDefaultValue();

        this.setState({value: defaultValue})
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        values.forEach((value: Value) => {
            if (value.fieldName === name) {
                const newValue: string = value.values[0];
                let key = this.getDependingOptionsKey(newValue);

                this.setState({value: newValue, key: key});
            }
        });
    }

    private getDependingOptionsKey(value: string) {
        let key = '';
        if (this.props.field.dependingOptions !== undefined) {
            this.props.field.dependingOptions.forEach((dependingOption: DependingOption) => {
                dependingOption.options.forEach((option: string) => {
                    if (value.endsWith(option)) {
                        key = dependingOption.dependsOn;
                    }
                });
            });
        }

        return key;
    }

    private getDefaultValue() {
        const defaultSelection = this.props.defaultSelection;
        if (defaultSelection !== undefined) {
            const options = this.props.field.options;
            if (options !== undefined) {
                const candidate: string | Field | undefined = options.find((option: string | Field) => {
                    if (typeof option === 'string') {
                        return option.endsWith(defaultSelection)
                    } else {
                        return undefined;
                    }
                })

                if (candidate !== undefined) {
                    if (typeof candidate === "string") {
                        return this.getOptionName(candidate);
                    }
                }
            }
        }

        const showDefaultOption: boolean | undefined = this.props.field.showDefaultOption;
        if (!showDefaultOption) {
            const options = this.props.field.options;
            if (options !== undefined) {
                const option: string | Field = options[0];
                if (typeof option === "string") {
                    return this.getOptionName(option);
                }
            }
        }

        return "";
    }
}

export default CustomSelect;
