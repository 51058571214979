import React, {ReactElement} from 'react';
import FormHeader from "../../register/v2/FormHeader";
import FitymiTimeReport from "./FitymiTimeReport";
import {REPORT} from "../../../infra/Constants";
import SurgeryReport from "./SurgeryReport";
import DOPSReport from "./DOPSReport";
import LiaReport from "./LiaReport";
import ResidentTrainingReport from "./ResidentTrainingReport";
import ResidentTrainingWithGoalsReport from "./ResidentTrainingWithGoalsReport";
import OverviewReport from "./OverviewReport";
import TraindriverLiaStudentReport from "./TraindriverLiaStudentReport";
import TraindriverLiaTutorReport from "./TraindriverLiaTutorReport";
import StudentDeliveryReport from "./StudentDeliveryReport";
import TutorDeliveryReport from "./TutorDeliveryReport";
import {User} from "../../model/User";

interface props {
    actionName: string,
    user: User
}

interface state {
}


class Reports extends React.Component<props, state> {
    render(): ReactElement {
        const actionName = this.props.actionName;
        const formHeader = <FormHeader actionName={actionName} actionType={REPORT}/>;

        let reportToBeRendered = <div>No reports to display right now</div>;
        if (actionName === "fitymi-time-report") {
            reportToBeRendered = <FitymiTimeReport actionName={actionName}/>;
        } else if (actionName === "traindriver-lia-2" || actionName === "traindriver-tutor-lia-2") {
            reportToBeRendered = <LiaReport reportName={"LIA 2-Bok"}
                                            type={"traindriver-lia-2"}
                                            questionsNumber={7}/>;
        } else if (actionName === 'traindriver-lia-2-student-report') {
            reportToBeRendered = <TraindriverLiaStudentReport/>
        } else if (actionName === 'traindriver-lia-2-tutor-report') {
            reportToBeRendered = <TraindriverLiaTutorReport/>
        } else if (actionName === 'surgery') {
            reportToBeRendered = <SurgeryReport/>
        } else if (actionName === 'dops-surgery') {
            reportToBeRendered = <DOPSReport/>
        } else if (actionName === 'traindriver-lia-3') {
            reportToBeRendered = <LiaReport reportName={"LIA 3-Bok"}
                                            type={"traindriver-lia-3"}
                                            questionsNumber={6}/>
        } else if (actionName === 'resident-training') {
            reportToBeRendered = <ResidentTrainingReport/>
        } else if (actionName === 'resident-training-with-goals') {
            reportToBeRendered = <ResidentTrainingWithGoalsReport/>
        } else if (actionName === 'overview') {
            reportToBeRendered = <OverviewReport/>
        } else if (actionName === 'delivery-clinic-tutor') {
            reportToBeRendered = <TutorDeliveryReport isPrincipal={false} user={this.props.user}/>
        } else if (actionName === 'delivery-principal') {
            reportToBeRendered = <TutorDeliveryReport isPrincipal={true} user={this.props.user}/>
        } else if (actionName === 'delivery-student') {
            reportToBeRendered = <StudentDeliveryReport user={this.props.user}/>
        }

        return <div>
            {formHeader}
            <div className={"container"}>
                {reportToBeRendered}
            </div>
        </div>
    }

}

export default Reports;
