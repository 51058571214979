import {UserOldRoles} from "../../../model/UserOldRoles";
import React, {ReactElement} from "react";
import {deleteItem, fetchArrayOf, put} from "../../../../infra/BackendService";
import {Role} from "./Role";
import Selector, {Selectable} from "./Selector";
import {Organisation} from "./Organisation";

interface props {
    organisation: Organisation
}

interface state {
    assignedRoles: Role[],
    availableRoles: Role[],

    users: UserOldRoles[]
}

class ManageOrganisations extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.assignRole = this.assignRole.bind(this);
        this.removeRole = this.removeRole.bind(this);

        this.state = {
            assignedRoles: [],
            availableRoles: [],
            users: []
        }
    }

    render(): ReactElement {

        const roleSelector = <Selector assigned={this.state.assignedRoles}
                                       available={this.state.availableRoles}
                                       assign={this.assignRole}
                                       remove={this.removeRole}
                                       type={"Roles"}/>

        const users = this.state.users;
        const userRows = users.map((user: UserOldRoles, index: number) => {
                let className: string = ManageOrganisations.getClassName("col-5", index);
                const userName = user.userName;
                const fullName = user.firstName + " " + user.lastName;
                return <div key={"userRow " + userName} className={"row"} aria-label={userName + " " + fullName}>
                    <div className={className}>
                        {userName} {fullName}
                    </div>
                </div>
            }
        )
        const organisationName = this.props.organisation.name;
        return <div>
            <div className={"row mt-3"}>
                <h1>{organisationName}</h1>
            </div>
            {roleSelector}
            <div className={"row mt-3"}>
                <h3>Users</h3>
            </div>
            {userRows}
        </div>;
    }

    componentDidMount() {
        this.getOrganisationAffiliations();
    }

    private getOrganisationAffiliations() {
        const organisation = this.props.organisation;
        const id = organisation.id;

        this.getAssignedRoles(id);
        this.getAvailableRoles(id);

        this.users(id);
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        if (prevProps.organisation !== this.props.organisation) {
            this.getOrganisationAffiliations();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    private getAssignedRoles(id: string) {
        const rolesUrl = "/api/v3/backoffice/organisation/roles/" + id
        fetchArrayOf<Role>(rolesUrl)
            .then((roles) => {
                this.setState({assignedRoles: roles});
            });
    }

    private getAvailableRoles(id: string) {
        const availableRoleUrl = "/api/v3/backoffice/organisation/roles/available/" + id
        fetchArrayOf<Role>(availableRoleUrl)
            .then((roles: Role[]) => {
                this.setState({availableRoles: roles})
            });
    }


    private assignRole(role: Selectable) {
        const organisation = this.props.organisation;
        const id = organisation.id;
        const url: string = '/api/v3/backoffice/organisation/role/' + id;

        const payload = {
            "id": role.id
        }
        put(payload, url)
            .then(() => {
                this.getAvailableRoles(id);
                this.getAssignedRoles(id);
                this.users(id)
            });
    }

    private removeRole(role: Selectable) {
        const organisation = this.props.organisation;
        const id = organisation.id;
        const url: string = '/api/v3/backoffice/organisation/role/' + id + "/" + role.id

        deleteItem(url)
            .then(() => {
                this.getAvailableRoles(id);
                this.getAssignedRoles(id);
                this.users(id)
            });
    }

    private users(id: string) {
        const rightsUrl = "/api/v3/backoffice/organisation/users/" + id
        fetchArrayOf<UserOldRoles>(rightsUrl).then((users) => {
            this.setState({users: users});
        });
    }

    private static getClassName(src: string, index: number) {
        const rowNumber = index + 1;
        if (rowNumber % 2 === 0) {
            return src + " grayed";
        }

        return src;
    }
}

export default ManageOrganisations;