import React, {ReactElement} from "react";
import {Organisation} from "./Organisation";
import ManageOrganisations from "./ManageOrganisations";
import SearchItems from "./SearchItems";
import {Selectable} from "./Selector";
import {fetchObjectOf} from "../../../../infra/BackendService";
import {Right} from "./Right";
import CreateOrganisationForm from "./CreateOrganisationForm";

interface props {
}

interface state {
    selectedOrganisation: Organisation | undefined,
}

class SearchOrganisationsV3 extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            selectedOrganisation: undefined,
        }
    }

    render(): ReactElement {
        const selectedOrganisation = this.state.selectedOrganisation;

        let manageOrganisations = <div/>;

        if (selectedOrganisation !== undefined) {
            manageOrganisations = <ManageOrganisations organisation={selectedOrganisation}/>;
        }

        return <div>
            <div className={"row"}>
                <div className={"col-3"}>
                    <div aria-label={"search organisations"}>
                        <SearchItems selectItem={this.selectedOrganisation()} type={"organisation"}/>
                    </div>
                    <div>
                        <div aria-label={"create organisations"} className={"mt-4"}>
                            <CreateOrganisationForm/>
                        </div>
                    </div>
                </div>
                <div className={"col-9"}>
                    {manageOrganisations}
                </div>
            </div>
        </div>
    }

    private selectedOrganisation() {
        return (selectOrganisation: Selectable | undefined) => {
            if (selectOrganisation !== undefined) {
                fetchObjectOf<Right>("/api/v3/backoffice/organisation/" + selectOrganisation.id)
                    .then((organisation: Organisation | undefined) => {
                        this.setState({selectedOrganisation: organisation})
                    })
            } else {
                this.setState({selectedOrganisation: undefined})
            }

        };
    }
}

export default SearchOrganisationsV3;
