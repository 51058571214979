import React, {Component} from 'react';
import ActionStatistics from "./ActionStatistics";
import SupervisorExperience from "./SupervisorExperience";
import CapioDeviation from "./capio/CapioDeviation";
import CapioOvertime from "./capio/CapioOvertime";
import SupervisorStart from "./SupervisorStart";

class SupervisorDelegator extends Component {
    render() {
        if (this.props.currentContent === "") {
            return <SupervisorStart/>;
        } else {
            switch (this.props.currentContent) {
                case "anaesthesia-experience" :
                    return <SupervisorExperience/>

                case "surgery-experience" :
                    return <SupervisorExperience/>

                case "intern-se-experience" :
                    return <SupervisorExperience/>

                case "capio-deviation" :
                    return <CapioDeviation securityToken={this.props.securityToken}/>;

                case "capio-overtime-supervisor" :
                    return <CapioOvertime/>;

                default :
                    return <ActionStatistics
                        refreshExpiredToken={this.props.refreshExpiredToken}
                        securityToken={this.props.securityToken}
                        userList={this.props.userList}
                        currentContent={this.props.currentContent}
                    />
            }
        }
    }
}

export default SupervisorDelegator;