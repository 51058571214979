import React, {ReactElement} from 'react';
import "./CommentText.css";
import CommentModal from "./CommentModal";
import "./LiaReport.css"
import "./PrehospitalHome.css"

export const MAXIMUM_TEXT_LENGHT = 40;

interface props {
    text: string | undefined | null
    background: string,
    label: string
    headline?: string
    fontColor: string
}

interface state {
    displayText: string,
    displayFull: boolean,
    textShortened: boolean
}

class TableCell extends React.Component<props, state> {

    constructor(props: props) {
        super(props);
        const nonBreakingSpace: string = "\u00a0";
        let c = this.props.text ? this.props.text : nonBreakingSpace
        c = c.length >= MAXIMUM_TEXT_LENGHT ? c.substr(0, MAXIMUM_TEXT_LENGHT - 1) : c;

        this.state = {
            displayText: c,
            displayFull: false,
            textShortened: this.props.text !== undefined && this.props.text !== null && this.props.text.length >= MAXIMUM_TEXT_LENGHT
        }
    }

    private flipVisibility(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        this.setState({displayFull: !this.state.displayFull})
    }

    hideHelp = () => {
        this.setState({displayFull: false});
    }

    render(): ReactElement {
        let fullTextModal;

        if (this.state.displayFull) {
            const headline = this.props.headline;
            const helpBody = [this.props.text ? this.props.text : " "];

            fullTextModal = <div>
                <CommentModal
                    hide={this.hideHelp}
                    headline={headline}
                    body={helpBody}
                />
            </div>;
        } else {
            fullTextModal = <div/>;
        }

        let expanderLink = <div/>;

        const displayFull = this.state.displayFull;
        const commentShortened = this.state.textShortened;
        if (commentShortened && !displayFull) {
            expanderLink = <a href={"#/"}
                              className={"expanderLink"}
                              onClick={(e) => this.flipVisibility(e)}>&nbsp;...</a>
        }

        const label = this.props.label;
        const displayComment = this.state.displayText;

        const background = this.props.background;
        const fontColor = this.props.fontColor;
        const className = "row m-0 pb-1 text-nowrap" + background + " " + fontColor;

        return <div
            className={className} aria-label={label}>
            <span className={"pr-4 pl-1"}>
                {displayComment}
                {expanderLink}
            </span>
            <div>
                {fullTextModal}
            </div>
        </div>;
    }
}

export default TableCell;
