import React, {ReactElement} from 'react';
import "./FormHeader.css"
import TranslationService from "../../../infra/TranslationService";
import {EVENT, GOAL, REPORT, STATISTICS} from "../../../infra/Constants";

interface props {
    actionName: string,
    actionType: string,
}

class FormHeader extends React.Component<props> {
    render(): ReactElement {
        const actionName: string = this.props.actionName;
        const actionType: string = this.props.actionType;

        const task = FormHeader.getTask(actionType);
        const name = FormHeader.getName(actionName);

        return <div className={"row m-0 pl-2 pr-2 justify-content-center actionHeader pt-1"}>
            <h4>{task} {name}</h4>
        </div>;
    }

    private static getTask(actionType: string) {
        const typeMapping = new Map([
            [EVENT, "create_event"],
            [GOAL, "create_goal"],
            [STATISTICS, "statistics"],
            [REPORT, "show report"]
        ]);

        const key = typeMapping.get(actionType);
        if (key !== undefined) {
            return TranslationService.translation(key);
        } else {
            return "unknown task";
        }
    }

    private static getName(actionName: string) {
        return TranslationService.translation(actionName);
    }
}

export default FormHeader;
