export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function isLocalhost(): boolean {
    return window.location.hostname === 'localhost';
}

const legendClassMap = new Map([
    ["int", "intubationFlag"],
    ["ca", "cardiacArrestFlag"],
    ["r", "roscFlag"],
    ["cvk", "cvkFlag"],
    ["pt", "penetratingTraumaFlag"],
    ["ts", "thoracostomyFlag"],
    ["avv!", "deviationFlag"]
]);

export function getLegendClass(val: string): string {
    return legendClassMap.get(val.toLowerCase()) || "";
}

/**
 * Self Or Empty String
 * returns itself or an empty string, to visually clean up tertiary logic in render
 * @param value anything
 */
export function SOES(value: any) {
    return value ? value : "";
}

/**
 * Self Or Zero
 * returns itself or a zero, to visually clean up tertiary logic in render
 * @param value anything
 */
export function SOZ(value: any) {
    return value ? value : 0;
}