import React, {Component} from 'react';

let c3;

class GoalGraph extends Component {
    componentDidMount() {
        c3 = require('c3');
        this.initChart();
    }

    componentDidUpdate() {
        this.initChart();
    };

    initChart() {
        this.localC3 = c3.generate({
            bindto: '#' + this.props.id,
            data: this.props.data,
            axis: this.props.axis,
            bar: {
                width: 30
            },
            legend: {
                show: true
            }
        });
    }

    render() {
        return (
            <div id={this.props.id}/>
        );
    }
}

export default GoalGraph;