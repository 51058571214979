import React from "react";
import {Link, NavLink, withRouter} from 'react-router-dom';
import "./FullNavBar.css";
import "./FullNavBarDesktop.css";
import logo from "../../images/logo-inverted.png";
import {UserData} from "../model/UserData";
import {NavBar, NavBarOrganisation} from "../model/NavBar";
import {User} from "../model/User";
import {selectOrganisation} from "../organisation/SelectOrganisation";
import {Organisation} from "../model/Organisation";
import {BackendContext, BackendInterface} from "../../infra/BackendContext";
import {NavbarData, NavBarItem} from "./FullNavBarMobile";
import {logoutWithBackup} from "../../infra/Logout";
import TranslationService from "../../infra/TranslationService";

const headerId = "navbarHeaderContainer";
const navPadding = "nav-up";
const NAVBAR_HEIGHT = 66;
const menuItemWidth = 40;

interface Position {
    id: string,
    yPos: number
}

interface props {
    user: User
    updateNavBar: (navBar: NavBar) => void
    setCurrentOrganisation: (organisation: Organisation | undefined) => void
    userData: UserData,
    refreshExpiredToken: () => void,
    securityToken: string,
    setAction: (action: string) => void,
    setStatsAction: (statsAction: string) => void,
    history: string[],
    setPath: (path: string) => void,
    nav: NavbarData
}

interface state {
    rootLinksInvisible: boolean
    ypos: number,
    hasScrolled: boolean,
    sublistYpos: Position[]
}

function nop() {
}

class FullNavBarDesktop extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: props) {
        super(props);

        this.state = {
            ypos: 0,
            hasScrolled: false,
            rootLinksInvisible: false,
            sublistYpos: []
        };
    }

    componentDidMount() {
        document.addEventListener("scroll", this.handleScrolling);

        setInterval(() => {
            if (this.state.hasScrolled) {
                this.hasScrolled();
                this.hasScrolledMenu();
                this.setState({hasScrolled: false});
            }
        }, 250);
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.handleScrolling);
    }


    render(): React.JSX.Element {
        if (this.props.userData.navBar !== undefined) {
            const organisations: NavBarOrganisation[] | undefined = this.props.userData.navBar.organisations;
            const organisationSelector: React.JSX.Element = this.getOrganisationSelector(organisations);

            //navbarHeaderContainer
            //TODO revert
            const {userData} = this.props;
            const nav = userData.navBar as NavbarData;

            const navBarElements = this.createNavlinkButtons(nav);

            navBarElements.splice(Math.max(navBarElements.length - 1, 0), 0, organisationSelector);

            return <div id={headerId} className={"navbarHeaderContainer"}>

                <nav className={"nav navbar navbar-expand-lg navBarStyle"}>
                    <Link
                        className={"btn-custom-tage-primary navbar-brand"}
                        to={userData === undefined ? "/" : this.props.userData.navBar.brand.route}>
                        <img src={logo} alt={"logo"} height={42}/>
                    </Link>

                    <ul className={"navbar-nav ml-auto"}>
                        {navBarElements}
                    </ul>
                </nav>

            </div>
        }
        return <div/>;

    }

    private getOrganisationSelector(organisations: NavBarOrganisation[] | undefined): React.JSX.Element {
        if (organisations && organisations.length >= 1) {
            const options: React.JSX.Element[] = organisations.map((value: NavBarOrganisation) => {
                return <option key={value.id}
                               value={value.id}
                               className={"navbar-org-select-option"}>
                    {TranslationService.translation(value.name)}
                </option>
            });

            let defaultValue: string = '';
            for (const item of organisations) {
                if (item.selected) {
                    defaultValue = item.id;
                    break;
                }
            }

            return <select key={'4711'}
                           className={"navbar-org-select"}
                           aria-label={"Organisation selector"}
                           defaultValue={defaultValue}
                           onChange={(event: React.SyntheticEvent<HTMLSelectElement>) => this.changeOrganisation(event)}>
                {options}
            </select>;
        }

        return <div key={'4711'}/>;
    }

    private fetchingOrganisation(fetchingOrganisation: boolean): void {
        console.log('Maybe display the dancing balls while changing the organisation? It was just set to ' + fetchingOrganisation);
    }

    private changeOrganisation(event: React.SyntheticEvent<HTMLSelectElement>) {
        const organisationId: string = event.currentTarget.value;
        const user: User = this.props.user;
        const updateNavBar = this.props.updateNavBar;
        const setCurrentOrganisation = this.props.setCurrentOrganisation;
        const post = this.context.post;
        const fetchingOrganisation = this.fetchingOrganisation;

        selectOrganisation(organisationId, user, updateNavBar, setCurrentOrganisation, post, fetchingOrganisation);
    }

    private createNavlinkButtons(nav: NavbarData) {
        //This implicitly knows that "/logout" does not have children, and thus does not check in createNavlinkButtonWithDropdownSublist

        return nav.items.map((item: NavBarItem) => {
            if (item.subList && item.subList.length > 0) {
                return this.createNavlinkButtonWithDropdownSublist(item);
            } else {
                const isLogoutButton = item.route === "/logout"
                return <li className={"nav-item"} key={item.route}>
                    <NavLink
                        className={"btn btn-custom-tage-primary nav-link m-1 rootNavLink"}
                        to={item.route}
                        onClick={isLogoutButton ? () => this.logout() : () => nop()}
                        activeClassName={"active"}>{item.translatedName}
                    </NavLink>
                </li>
            }
        })
    }

    private createNavlinkButtonWithDropdownSublist(item: NavBarItem) {
        return <li className={"nav-item visibleBig"} key={item.route}>
            <div id={item.route}
                     className="dropdown2">
                <div className={"btn btn-custom-tage-primary"}
                     onClick={() => this.setPath(item.route)}>
                    {item.translatedName}
                </div>
                <div className="dropdown-content2">
                    {
                        item.subList.map(action => {
                            const id = action.name;
                            const actionName = action.nameTranslated;
                            const length = actionName.length;
                            let displayActionName;
                            const menuItemWidthReduced = menuItemWidth - 3;
                            if (length < menuItemWidth) {
                                displayActionName = actionName;
                            } else {
                                displayActionName = actionName.slice(0, menuItemWidthReduced) + "...";
                            }

                            return (
                                <button id={id}
                                        key={item.route + "_" + action.name + "_button"}
                                        onClick={() => this.submenuButton(action.name, item.route)}
                                        className={"dropDownButton2 text-left pl-1 m-0"}>
                                    {displayActionName}
                                </button>
                            );
                        })
                    }

                </div>
            </div>
        </li>
    }

    setPath = (path: string) => {
        setTimeout(() => {
            this.props.setPath(path); //this is for errorhandling
        }, 25); //wait for update otherwise it will report old url
    }

    private submenuButton(actionName: string, route: string) {
        //TODO this needs to change, move to react based navlink routing
        const path = route + "/" + actionName;
        this.setPath(path);

        if (route === "/statistics") {
            this.props.setStatsAction(actionName);
        } else {
            this.props.setAction(actionName);
        }
        this.props.history.push(route);

        this.closeSubmenuButtons();
    }

    private logout() {
        logoutWithBackup();
    }

    closeSubmenuButtons(): void {
        let dropdowns: HTMLCollectionOf<Element> = document.getElementsByClassName("dropDownButton2");

        function scheduleCall() {
            setTimeout(() => {
                const drop: HTMLCollectionOf<Element> = document.getElementsByClassName("dropDownButton2");
                for (let j = 0; j < drop.length; j++) {
                    const dropElement: Element = drop[j];
                    (dropElement as HTMLElement)["style"].display = "block";
                }
            }, 100);
        }

        for (let i = 0; i < dropdowns.length; i++) {
            const dropdown: Element = dropdowns[i];
            (dropdown as HTMLElement)["style"].display = "none"
            scheduleCall();
        }
    }

    hasScrolled() {
        const currentYpos = window.scrollY;
        const oldPos = this.state.ypos;
        let delta = 5;
        if (Math.abs(oldPos - currentYpos) <= delta) { //minimal change, do nothing
            return;
        }

        let header = document.getElementById(headerId);
        if (oldPos >= currentYpos) {
            if (header !== null) {
                header.classList.remove(navPadding)
            }
        } else if (currentYpos >= NAVBAR_HEIGHT) {
            if (header !== null) {
                header.classList.add(navPadding)
            }
        }

        this.setState({ypos: currentYpos});
    }

    hasScrolledMenu() {
        let element: HTMLCollectionOf<Element> = document.getElementsByClassName("sublistScrollable");
        let lastYPositions: Position[] = this.state.sublistYpos;
        let header: HTMLElement | null = document.getElementById(headerId);

        if (element !== undefined) {
            for (let i = 0; i < element.length; i++) {
                const sublist: Element = element[i];
                const sublistId: string = sublist.id;
                const currentYpos: number = sublist.scrollTop;
                let wantedSublistPos: Position | undefined = lastYPositions.find(e => e.id === sublistId);

                if (wantedSublistPos === undefined) {
                    let newSublistPos = {
                        "id": sublistId,
                        "yPos": 0
                    }
                    lastYPositions.push(newSublistPos);
                    break;
                }

                if (wantedSublistPos.yPos > currentYpos) {
                    if (header !== null) {
                        header.classList.remove(navPadding)
                    }
                } else if (wantedSublistPos.yPos < currentYpos) {
                    if (header !== null) {
                        header.classList.add(navPadding)
                    }
                }

                wantedSublistPos.yPos = currentYpos;
            }
        }
    }

    handleScrolling = () => {
        this.setState({hasScrolled: true});
    }
}

export default withRouter(FullNavBarDesktop);
