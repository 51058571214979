import Event from "../components/register/v2/Event";
import {Goal} from "../components/register/v2/Goal";
import {Binder, Book, Drug, DrugEvent, DrugEventSummary} from "../components/knarkbok/models/Models";
import {ModalDisplayBinder} from "../components/knarkbok/KnarkbokModal";
import {getSecurityToken} from "./SecurityToken";

export class BackendService2 {
    public static get(url: string): Promise<Event[]> | Promise<Goal[]> | Promise<Drug[]>| Promise<Binder[]> | Promise<Book[]> | Promise<ModalDisplayBinder> | Promise<Book> | Promise<DrugEventSummary[]> | Promise<DrugEvent[]> | Promise<any[]> | Promise<any> {
        // Why not use a generic here?
        //  the signature could be
        //
        //   public static get<T>(url: string): Promise<T | T[]> {
        //
        // Why is this one static?
        // How do you create a stub to use instead of the static method?
        //

        //TODO make a more elegant solution for all of these? make it return any and just have subversions handle types?
        //TODO add right
        return fetch(url, {
            method: "GET",
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }

            return response.json();
        }).catch(error => {
            console.log(error);
        });
    }

    public static post(payload: string, url: string): Promise<string> {
        return fetch(url, {
            method: "POST",
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include',
            body: payload
        }).then(response => (
            response.json()
                .then((data) => {
                    if (response.status >= 300) {
                        throw new Error(data.translatedMessage);
                    }

                    return data;
                })

        )).then();
    }

    public static put(url: string, payload: string): Promise<string> {
        return fetch(url, {
            method: "PUT",
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include',
            body: payload
        }).then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
        }).then();
    }

    public static delete(url: string): Promise<void> {
        return fetch(url, {
            method: "DELETE",
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
        }).then()
            .catch(error => {
                console.log(error);
            });
    }

    public static getUrl2(path: string): string {
        let host: string;
        if (process.env.REACT_APP_LOCALHOST === undefined) {
            host = '';
        } else {
            host = process.env.REACT_APP_LOCALHOST;
        }

        return host + path;
    }
}
