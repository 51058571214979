import React, {createRef, ReactElement} from 'react';
import {Action, Field} from "../../register/v2/Action";
import {FieldComponent, FieldError, FieldProps} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import Value from "../../register/v2/Value";
import DateField from "./DateField";
import {getValid, invalidDate, wrongOrderBetweenDates} from "./FieldUtil";
import {DESKTOP, V3} from "../../../infra/Constants";
import getField from "../../register/v3/FieldFactory";
import Event from "../../register/v3/Event";

interface props extends FieldProps {
    device: string,
    dateFormat: string
    action?: Action;
    frontendVersion?: string,
    event?: Event,
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void,
    user?: any
}

interface state {
}

class Period extends React.Component<props, state> implements FieldComponent {
    fromRef: any;
    toRef: any;

    constructor(props: Readonly<props>) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state = {}
    }

    render(): ReactElement {
        const name: string = this.props.field.name;
        const device: string = this.props.device;

        let showLabel: boolean = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        let showField: boolean = true;
        if (this.props.field.showChildLabel !== undefined) {
            showField = this.props.field.showChildLabel;
        }

        let mandatory: boolean = false;
        if (this.props.field.mandatory !== undefined) {
            mandatory = this.props.field.mandatory;
        }

        let defaultValue: string = "";
        if (this.props.field.defaultValue !== undefined) {
            defaultValue = this.props.field.defaultValue;
        }

        let fieldSize = 12;
        if (this.props.field.fieldSize !== undefined) {
            fieldSize = this.props.field.fieldSize;
        }

        const fromField: Field = {
            name: name + ".from",
            type: 'date',
            fieldLabel: false,
            showLabel: showField,
            mandatory: mandatory,
            fieldSize: fieldSize,
            defaultValue: defaultValue,
            duplicationIndex: 0
        };

        const toField: Field = {
            name: name + ".to",
            type: 'date',
            fieldLabel: false,
            fieldSize: fieldSize,
            showLabel: showField,
            duplicationIndex: 0
        };

        this.fromRef = createRef();
        this.toRef = createRef();

        const label = Period.getLabel(name, showLabel);
        const fields = this.getFields(fromField, toField, device);

        return <div>
            {label}
            <div id={name}>
                {fields}
            </div>
        </div>
    }

    private getFields(fromField: Field, toField: Field, device: string): React.ReactFragment {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion === V3) {
            const action: Action | undefined = this.props.action;
            const event: Event | undefined = this.props.event;
            const duplicationIndex: string | undefined = this.props.duplicationIndex;
            const user: any | undefined = this.props.user;
            if (action !== undefined && duplicationIndex !== undefined && user !== undefined && event !== undefined) {

                const from = getField(fromField, event, this.onChange, action, user);
                const to = getField(toField, event, this.onChange, action, user);

                if (device === DESKTOP) {
                    let divider = this.getDivider();

                    return <div className={"row m-0"}>
                        <div>
                            {from}
                        </div>
                        {divider}
                        <div>
                            {to}
                        </div>
                    </div>;
                } else {
                    return <div>
                        {from}
                        {to}
                    </div>;
                }
            } else {
                return <div>Mandatory props are missing</div>;
            }
        } else {
            const dateFormat = this.props.dateFormat;

            if (device === DESKTOP) {
                let divider = this.getDivider();

                return <div className={"row m-0"}>
                    <div>
                        <DateField ref={this.fromRef} field={fromField} dateFormat={dateFormat}/>
                    </div>
                    {divider}
                    <div>
                        <DateField ref={this.toRef} field={toField} dateFormat={dateFormat}/>
                    </div>
                </div>;
            }

            return <div>
                <DateField ref={this.fromRef} field={fromField} dateFormat={dateFormat}/>
                <DateField ref={this.toRef} field={toField} dateFormat={dateFormat}/>
            </div>;
        }
    }

    private getDivider() {
        let showChildLabel: boolean = true;
        if (this.props.field.showChildLabel !== undefined) {
            showChildLabel = this.props.field.showChildLabel;
        }

        let divider = <div/>;
        if (!showChildLabel) {
            divider = <div>
                <div className={"row ml-0 mr-2"}>
                    -
                </div>
            </div>;
        }
        return divider;
    }

    private static getLabel(name: string, showLabel: boolean) {
        if (showLabel) {
            const label: string = TranslationService.translation(name);
            return <label htmlFor={name}
                          aria-label={name}>
                {label}
            </label>;
        } else {
            return <div/>
        }
    }

    private onChange(name: string, value: (string | string[]), duplicationIndex: string, valid: boolean): void {
        let onChange = this.props.onChange;
        if (onChange !== undefined) {
            let field = this.props.field;
            onChange(name, value, duplicationIndex, valid, field);
        }
    }

    values(): Value[] {
        if (this.isValid().valid) {
            const result: Value[] = [];
            const fromField: FieldComponent = this.fromRef.current;
            const fromValues: Value[] = fromField.values();
            if (fromValues.length > 0) {
                const value = fromValues[0];
                result.push(value);
            }

            const toField: FieldComponent = this.toRef.current;
            const toValues: Value[] = toField.values();
            if (toValues.length > 0) {
                const value = toValues[0];
                result.push(value);
            }

            return result;
        } else {
            return [];
        }
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        const fromField: FieldComponent = this.fromRef.current;
        const toField: FieldComponent = this.toRef.current;

        const fromValid = fromField.isValid();
        const toValid = toField.isValid();

        if (fromValid.valid && toValid.valid) {
            let from: string = "";
            const fromValues: Value[] = fromField.values();
            if (fromValues.length > 0) {
                const fromValue: Value = fromValues[0];
                from = fromValue.values[0];
            }

            let to: string = "";
            const toValues: Value[] = toField.values();
            if (toValues.length > 0) {
                const toValue: Value = toValues[0];
                to = toValue.values[0];
            }

            if (to === "") {
                return getValid(name);
            }

            let dateTo = new Date(from);
            let dateFrom = new Date(to);

            const dateOrder = dateFrom >= dateTo;
            if (dateOrder) {
                return getValid(name);
            } else {
                return getValid(name, wrongOrderBetweenDates);
            }
        } else {
            if (!fromValid.valid) {
                return fromValid;
            }

            if (!toValid.valid) {
                return toValid;
            }

            // Unreachable code, one of the above will be true if we ended up here
            // But the compiler hate us if we don't have a return
            return getValid(name, invalidDate);
        }
    }

    clear(): void {
        const fromField: FieldComponent = this.fromRef.current;
        fromField.clear();

        const toField: FieldComponent = this.toRef.current;
        toField.clear();
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        const from = name + ".from";
        const to = name + ".to";

        values.forEach((value: Value) => {
            if (value.fieldName === from) {
                const fromField: FieldComponent = this.fromRef.current;
                const fromValues: Value[] = [];
                fromValues.push(value);

                fromField.set(fromValues);
            }

            if (value.fieldName === to) {
                const toField: FieldComponent = this.toRef.current;
                const toValues: Value[] = [];
                toValues.push(value);

                toField.set(toValues);
            }
        });
    }

    getFrom() {
        const fromField: FieldComponent = this.fromRef.current;
        const fromValues: Value[] = fromField.values();
        if (fromValues.length > 0) {
            return fromValues[0].values[0];
        }
        return undefined;
    }

    getTo() {
        const toField: FieldComponent = this.toRef.current;
        const toValues: Value[] = toField.values();
        if (toValues.length > 0) {
            return toValues[0].values[0];
        }
        return undefined;
    }
}

export default Period;
