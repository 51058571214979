import React, {ReactElement} from "react";
import {GraphTotalCard} from "./UniversalDashboard";
import "./DashboardTotal.css"
import C3Wrapper from "../graph/C3Wrapper";
import {uuidv4} from "../register/v2/Uuid";


interface props {
    data: GraphTotalCard,
    firstName: string
}

interface state {
    graphTotalWidth: number
    graphTotalHeight: number
    graphId: string
}

class DashboardSummary extends React.Component<props, state> {
    constructor(props: props) {
        super(props);
        const id = 'k' + uuidv4();
        this.state = {
            graphTotalWidth: 200, //dummy values, will be overwritten
            graphTotalHeight: 100, //dummy values, will be overwritten
            graphId: id
        }
    }

    componentDidMount() {
        //explanation: First we render the html page, then we look for the div above the graph, we take its size and feed it back to the c3wrapper element so it knows how much space it can take
        //why do it in this roundabout way? because then we can decide the size in css, much easier to work with.

        const totalGraphDiv = document.getElementById(this.state.graphId + "-graph")
        if (totalGraphDiv) {
            const rect = totalGraphDiv.getBoundingClientRect();
            this.setState({graphTotalHeight: rect.height, graphTotalWidth: rect.width})
        }
    }

    render(): ReactElement {
        const {data} = this.props;
        const highlightedBucket = data.userBucket;

        return <div className={"container pt-2"}>
            <div className={"row"}>
                <div className={"col dashboard-total-col ml-1 mr-1"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <div className={"row m-0 p-0"}>
                                Loggare: {data.totalLoggers}
                            </div>
                            <div className={"row m-0 p-0"}>
                                Registreringar: {data.totalCount}
                            </div>
                            <div className={"row m-0 p-0 highlighted"}>
                                {this.props.firstName}: {data.userCount}
                            </div>
                        </div>
                        <div className={"col text-center"}><h4>{data.headline}</h4>
                        </div>
                        <div className={"col"}>
                            <div className={"row"}>
                                <div className={"col"}/>
                                <div className={"col pr-0 mr-0"}>
                                    Medel: {data.avg}
                                </div>
                                <div className={"col pl-0 ml-0"}>
                                    Max: {data.max}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}/>
                                <div className={"col pr-0 mr-0"}>
                                    Median: {data.median}
                                </div>
                                <div className={"col pl-0 ml-0"}>
                                    Min: {data.min}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={this.state.graphId + "-graph"} className={"row m-0 p-0 total-graph"}>
                        <C3Wrapper
                            id={this.state.graphId}
                            data={data.graph.data}
                            bar={data.graph.bar}
                            height={this.state.graphTotalHeight}
                            width={this.state.graphTotalWidth}
                            highlightedBar={highlightedBucket}
                            legend={{show: false}}
                        />
                    </div>
                </div>
            </div>
        </div>
    }

}

export default DashboardSummary;
