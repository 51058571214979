import {Field} from "../../register/v2/Action";
import React, {createRef} from "react";
import Period from "../../fields/v2/Period";
import {DESKTOP} from "../../../infra/Constants";
import TranslationService from "../../../infra/TranslationService";
import {Report} from "./ReportTable";
import {fetchObjectOf} from "../../../infra/BackendService";
import {FieldError} from "../../fields/v2/FieldComponent";
import {uuidv4} from "../../register/v2/Uuid";


interface props {
    onViewReport: any
    fetchReportsUrl: string

}

interface state {
    report: Report | undefined,
    errors: FieldError[]
}


export class SelectPeriodInterval extends React.Component<props, state> {
    periodRef: any;

    constructor(props: props) {
        super(props);

        this.state = {
            report: undefined,
            errors: []
        }
    }

    render() {
        const reportPeriod: Field = {
            name: "reportPeriod",
            fieldLabel: true,
            mandatory: true,
            defaultValue: "firstDayOfMonth",
            showChildLabel: false,
            duplicationIndex: 0
        };
        this.periodRef = createRef();

        const periodField = <div>
            <Period ref={this.periodRef} field={reportPeriod} device={DESKTOP} dateFormat={"yyyy-MM-dd"}/>
        </div>;

        const view: string = TranslationService.translation("view");
        const viewButton = <div className={"pt-3"}>
            <button id={"viewButton"}
                    aria-label={"view"}
                    className={"btn"}
                    onClick={() => this.view()}>
                {view}
            </button>
        </div>;
        let errors = this.getErrors();
        return <div>
            {periodField}
            {viewButton}
            {errors}
        </div>
    }

    private view() {
        let currentPeriod: Period = this.periodRef.current;
        let from: string | undefined = currentPeriod.getFrom();

        let to: string | undefined = currentPeriod.getTo();
        if (to === undefined) {
            to = "9999-12-31";
        }

        const error: FieldError = currentPeriod.isValid();
        let errors = [];
        if (!error.valid) {
            errors.push(error);
            this.setState({errors: errors});
        } else {
            let url = this.props.fetchReportsUrl + from + "/" + to;
            fetchObjectOf<Report>(url).then((report) => {
                    this.props.onViewReport(report);
                }
            );
        }
    }

    private getErrors(): React.ReactFragment {
        const errors = this.state.errors;
        if (errors.length > 0) {
            return <div>
                {errors.map((error: FieldError) => {
                        const name = error.name;
                        const errorDescription = error.error;
                        const ariaLabel = name + " " + errorDescription;
                        const message = TranslationService.translation(name) + " " + TranslationService.translation(errorDescription);
                        return <div className="errorFont" key={uuidv4()}
                                    aria-label={ariaLabel}>
                            {message}
                        </div>
                    }
                )}
            </div>
        } else {
            return <div/>
        }
    }
}