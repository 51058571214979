import {FieldComponent, FieldError} from "../../fields/v2/FieldComponent";
import Event from "./Event";
import Value from "./Value";
import {Goal} from "./Goal";
import {Action, Field} from "./Action";

export function getEvent(name: string, version: number, fieldReferences: Map<string, any>, id?: string): Event | FieldError[] {
    const errors: FieldError[] = [];
    const event: Event = {
        name: name,
        version: version,
        id: id,
        values: [],
        mailOnDemandSent: false
    };

    fieldReferences.forEach((field) => {
        if (field && field.current !== null) {
            const fieldValue: FieldComponent = field.current;
            const error: FieldError = fieldValue.isValid();
            if (!error.valid) {
                errors.push(error);
            }
            if (errors.length < 1) {
                const values: Value[] = fieldValue.values();
                values.forEach((value: Value) => {
                    event.values.push(value);
                });
            }
        }
    });

    if (errors.length > 0) {
        return errors;
    } else {
        return event;
    }
}

export function getGoal(name: string, version: number, fieldReferences: Map<string, any>, id?: string): Goal | FieldError[] {
    const errors: FieldError[] = [];
    const goal: Goal = {
        name: name,
        version: version,
        id: id,
        predicate: [],
        objective: []
    };

    // We would like to differ the prediacte and objective in a better way.
    // But doing so at this moment (2021-05-23) in will break stuff for Mika so
    // we will wait until we can do this breaking change without too many problems.
    const objectives: Set<string> = new Set();
    objectives.add('anaesthesia-experience.goal.duration');
    objectives.add('anaesthesia-experience.goal.unit');

    fieldReferences.forEach((field) => {
        const fieldValue: FieldComponent = field.current;
        const error: FieldError = fieldValue.isValid();
        if (!error.valid) {
            errors.push(error);
        }
        if (errors.length < 1) {
            const values: Value[] = fieldValue.values();
            values.forEach((value: Value) => {
                const k: string = value.fieldName;
                const v: string = value.values[0];

                if (objectives.has(k)) {
                    goal.objective.push({name: k, value: v});
                } else {
                    goal.predicate.push({name: k, value: v});
                }
            });
        }
    });

    if (errors.length > 0) {
        return errors;
    } else {
        return goal;
    }
}

export function addEventValuesToForm(event: Event, fieldReferences: Map<string, any>): void {
    const values: Value[] = event.values;
    fieldReferences.forEach((fieldRef) => {
        const field: FieldComponent = fieldRef.current;
        if (field) {
            field.set(values);
        }
    });
}

export function addGoalValuesToForm(goal: Goal, fieldReferences: Map<string, any>): void {
    const values: Value[] = [];

    goal.predicate.forEach(predicate => {
        const name: string = predicate.name;
        const value: string = predicate.value;
        const vals: string[] = [];
        vals.push(value);
        values.push({
            fieldName: name,
            values: vals
        });
    });

    goal.objective.forEach(objective => {
        const name: string = objective.name;
        const value: string = objective.value;
        const vals: string[] = [];
        vals.push(value);
        values.push({
            fieldName: name,
            values: vals
        });
    });

    fieldReferences.forEach((fieldRef) => {
        const field: FieldComponent = fieldRef.current;

        field.set(values);
    });
}

export function clearForm(fieldReferences: Map<string, any>): void {
    fieldReferences.forEach((field) => {
        if (field !== null && field.current !== null) {
            const fieldValue: FieldComponent = field.current;
            fieldValue.clear();
        }
    })
}

/**
 * Returns largest possible amount of things that can be checked or submitted (for progress bar)
 * @param action
 */
export function maxPossibleSelectedV2(action?: Action): number {
    if (!action) {
        return 100;
    }

    const count = action.fields.map((field: Field) => countBranch(field)).reduce(sum);

    if (count >= 1) {
        //THIS needs to be redone, the exception list is way bigger than just comments
        return count - 1; //this removes the comment field. This should do something smarter in the future (there might be more than one comment field, or none)
    }
    return count;
}

function countBranch(field: Field): number {
    if (field.fields && field.fields.length > 0) {
        return field.fields.map((field: Field) => countBranch(field)).reduce(sum);
    }

    return 1;
}

function sum(acc: number, val: number) {
    return acc + val;
}

/**
 * Counts how many values are currently checked in (for progress bar)
 * @param result
 */
export function currentSelectedV2(result: Event): number {
    let length = result.values.length;

    const containsComment = result.values.some((val) => val.fieldName.indexOf("comment") > 0);
    if (containsComment) {
        return length - 1;
    }

    return length;
}