import React, {Component} from "react";
import {ActionPreProcessor, ActionTemplate, ATField, ATFieldType, hasOptions} from "./ActionPreProcessor";
import RadioV4Render from "./RadioV4Render";

interface props {
    template: ActionTemplate
    radioClick: (value: string, group: string, duplicationIndex?: number) => void
    duplicationClick: (value: string) => void
}


class ActionRenderer extends Component<props> {
    processor: ActionPreProcessor;

    constructor(props: props, context: any) {
        super(props, context);

        this.processor = new ActionPreProcessor();
    }

    render() {
        const values = this.theGreatFiltering(this.props.template.fields);

        return <div className={"container"}>
            {values}
        </div>
    }

    private theGreatFiltering(input: ATField[]): any[] { //todo change to return react array thing
        const values: any[] = [];

        input.forEach((field: ATField, i) => {
            if (field.type === ATFieldType.RADIO) {
                //const parentName = field.name //TODO fix this when we are no longer in group by mode
                const parentName = "forced.parent"
                values.push(
                    <div className={"row"} key={field.name+field.duplicationIndex+i}>
                        {
                            this.makeRadioButtons(field, parentName)
                        }
                    </div>
                )
                return;
            }

            if (field.type === ATFieldType.CHECKBOX) {
                values.push(<div className={"row"}>checkbox</div>)
                return;
            }

            if (field.type === ATFieldType.TEXT) {
                values.push(<div className={"row"}>text</div>)
                return;
            }


            if (field.type === ATFieldType.FIELD) {
                values.push(<div key={field.name+field.duplicationIndex+i} className={"container pt-4 pl-0"}>
                    {
                        this.makeField(field)
                    }
                </div>)
                return;
            }

            values.push(<div className={"row"}>unknown : {field.type}</div>)
            return;
        });

        return values;
    }

    private makeRadioButtons(field: ATField, groupName: string): any {
        if (hasOptions(field)) {
            const options = field.options?.map((f: ATField) => this.makeRadioButtons(f, field.name));
            return <div key={field.name+field.duplicationIndex}>
                <RadioV4Render onClick={this.props.radioClick}
                               checked={!!field.checked}
                               cssRules={field.cssRules}
                               duplicationIndex={field.duplicationIndex}
                               radioGroup={groupName}
                               value={field.name}/>
                <div className={"pl-4"}>
                    {options}
                </div>
            </div>
        } else {
            return <RadioV4Render key={field.name+field.duplicationIndex}
                                  checked={!!field.checked}
                                  onClick={this.props.radioClick}
                                  cssRules={field.cssRules}
                                  duplicationIndex={field.duplicationIndex}
                                  radioGroup={groupName}
                                  value={field.name}/>
        }
    }

    private makeField(field: ATField) {
        let children: any[];
        children = [];

        //only if duplicable
        const foo = <div key={"duplicationbutton-" + field.name} className={"row"}>duplicate this: <button
            onClick={() => this.duplicate(field.name)}>button</button></div>

        if (hasOptions(field)) {
            children = [foo, this.theGreatFiltering(field.options as ATField[])]; //cast because Idea is silly
        }

        return <div>{children}</div>
    }

    private duplicate(name: string) {
        this.props.duplicationClick(name);
    }
}

export default ActionRenderer;
