import Value from "./Value";

export default interface Event {
    name: string;
    version: number;
    id?: string
    values: Value[],
    mailOnDemandSent: boolean
    hasBeenCountersigned?: boolean
    organisationId?: string
    completenessIndex?: number
}

export function instanceOfEventOrGoal(object: any): object is Event {
    return 'name' in object;
}
