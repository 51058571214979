import React, {ReactElement} from 'react';
import "./PublicDeviation.css";

interface props {
    site: string,

    unsecuredPost<T, K>(payload: T, uri: string): Promise<K>
}

interface state {
    sender: string,
    phone: string,
    feedback: string,
    sent: boolean
}

class PublicDeviation extends React.Component<props, state> {
    constructor(props: Readonly<props>) {
        super(props);

        this.state = {
            sender: '',
            phone: '',
            feedback: '',
            sent: false
        }
    }

    render(): ReactElement {
        if (this.state.sent) {
            return this.thankYou();
        } else {
            return this.feedbackForm();
        }
    }

    private thankYou(): React.JSX.Element {
        return <div className={'container public-deviation-container'}>
            <div className={'row pt-2 m-0'}>
                <h1>Tack för din återkoppling!</h1>
            </div>
            <div className={'row pt-3 m-0'}>
                <p>Vi kommer göra vårt bästa att lösa det problem du har hjälpt oss uppmärksamma.</p>
            </div>
            <div className={'row p-0 m-0 justify-content-end'}>
                <p className={"pd-footerpad"}>Teamet på {this.props.site}</p>
            </div>
        </div>
    }

    private feedbackForm(): React.JSX.Element {
        const headline: React.JSX.Element = <h2 className={"pt-2"}>{this.props.site}</h2>;
        const nameLabel: React.JSX.Element = <div className={"pd-labels"}>Namn (frivilligt)</div>;
        const nameField: React.JSX.Element = <input type={'text'}
                                                    className={"pd-input"}
                                                    placeholder={'Ditt namn'}
                                                    value={this.state.sender}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({sender: event.currentTarget.value})}
        />;
        const phoneLabel: React.JSX.Element = <div className={"pd-labels"}>Telefonnummer (frivilligt)</div>;
        const phoneField: React.JSX.Element = <input type={'text'}
                                                     className={"pd-input"}
                                                     placeholder={'Ditt telefonnummer'}
                                                     value={this.state.phone}
                                                     onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({phone: event.currentTarget.value})}
        />;
        const feedbackLabel: React.JSX.Element = <div className={"pd-labels"}>Återkoppling</div>;
        const feedback: React.JSX.Element = <textarea
            className={"pd-input"}
            rows={4}
            placeholder={'Din återkoppling'}
            value={this.state.feedback}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({feedback: event.currentTarget.value})}
        />;
        const button: React.JSX.Element = <button className={"btn btn-tage-default pt-2 pb-1"} placeholder={'Skicka'}
                                                  onClick={() => this.submit()}>
            Skicka
        </button>;

        return <div className={'container public-deviation-container'}>
            <div className={'row pt-2'}>
                <div className={"col"}>
                    {headline}
                </div>
            </div>
            <div className={'row pt-2'}>
                <div className={'col'}>
                    {nameLabel}
                </div>
            </div>
            <div className={"row"}>
                <div className={'col'}>
                    {nameField}
                </div>
            </div>
            <div className={'row pt-2'}>
                <div className={'col'}>
                    {phoneLabel}
                </div>
            </div>
            <div className={'row'}>
                <div className={'col'}>
                    {phoneField}
                </div>
            </div>
            <div className={'row pt-2'}>
                <div className={'col'}>
                    {feedbackLabel}
                </div>
            </div>
            <div className={'row'}>
                <div className={'col'}>
                    {feedback}
                </div>
            </div>
            <div className={'row pt-3'}>
                <div className={'col text-right pd-button'}>
                    {button}
                </div>
            </div>
        </div>
    }

    private submit() {
        const site = this.props.site;
        const sender = this.state.sender;
        const phone = this.state.phone;
        const feedback = this.state.feedback;

        const message = {
            site: site,
            sender: sender,
            phone: phone,
            feedback: feedback
        };

        const url = '/api/v1/public/deviation-demo';
        this.props.unsecuredPost(message, url).then(() => {
            this.setState({
                sent: true
            });
        });
    }
}

export default PublicDeviation;
