import React, {ReactElement} from 'react';
import {Paragraph} from "../../register/v2/Action";
import {FieldComponent, FieldError, FieldProps} from "./FieldComponent";
import Value from "../../register/v2/Value";
import TranslationService from "../../../infra/TranslationService";

export interface props extends FieldProps {
}

export interface state {
}

export class Description<T extends props, S extends state> extends React.Component<T, S> implements FieldComponent {

    render(): ReactElement {
        let description: React.JSX.Element[];
        const paragraphs = this.props.field.paragraphs;

        if (paragraphs !== undefined) {
            description = paragraphs.map((p: Paragraph) => {
                const paragraph = p.paragraph;
                const translation = TranslationService.translation(paragraph);
                return <div key={p.paragraph} className={"row m-0 p-0"}>{translation}</div>
                }
            );
        } else {
            description = [];
        }

        return <div className={"col"}>
            {description}
        </div>
    }

    clear(): void {
    }

    isValid(): FieldError {
        return {
            name: "",
            valid: true,
            error: ""
        };
    }

    set(values: Value[]): void {
        // only to fool idea to think that values are being used
        if (values === undefined) {
            console.log(values);
        }
    }

    values(): Value[] {
        return [];
    }
}

export default Description;
