import React, {ReactElement} from "react";
import SearchItems from "./SearchItems";
import {Role} from "./Role";
import {Selectable} from "./Selector";
import {fetchObjectOf} from "../../../../infra/BackendService";
import ManageRoles from "./ManageRoles";
import CreateRoleForm from "./CreateRoleForm";

interface props {
}

interface state {
    selectedRole: Role | undefined,
}

class SearchRolesV3 extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            selectedRole: undefined,
        }
    }

    render(): ReactElement {
        const selectedRole = this.state.selectedRole;

        let manageRoles = <div/>;

        if (selectedRole !== undefined) {
            manageRoles = <ManageRoles role={selectedRole}/>;
        }

        return <div className={"row"}>
            <div className={"col-3"}>
                <div aria-label={"search roles"}>
                    <SearchItems selectItem={this.selectedRole()} type={"role"}/>
                </div>
                <div>
                    <div aria-label={"create roles"} className={"mt-4"}>
                        <CreateRoleForm/>
                    </div>
                </div>
            </div>
            <div className={"col-9"}>
                {manageRoles}
            </div>
        </div>
    }

    private selectedRole() {
        return (selectRole: Selectable | undefined) => {
            if (selectRole !== undefined) {
                fetchObjectOf<Role>("/api/v3/backoffice/role/" + selectRole.id).then((role: Role | undefined) => {
                    this.setState({selectedRole: role})
                })
            } else {
                this.setState({selectedRole: undefined})
            }

        };
    }
}

export default SearchRolesV3;
