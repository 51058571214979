import React, {ReactElement} from "react";
import {GraphCard} from "./UniversalDashboard";
import "./DashboardEvent.css";
import C3Wrapper from "../graph/C3Wrapper";
import {uuidv4} from "../register/v2/Uuid";
import TranslationService from "../../infra/TranslationService";

interface props {
    data: GraphCard,
    firstName: string
}

interface state {
    totalGraphId: string
    graphTotalHeight: number
    graphTotalWidth: number
    graphPieHeight: number
    graphPieWidth: number
    userPieGraphId: string,
    worldPieGraphId: string
}

class DashboardEvent extends React.Component<props, state> {
    constructor(props: props) {
        super(props);
        const graphTotal = 'k' + uuidv4();
        const userPieGraphId = 'k' + uuidv4();
        const worldPieGraphId = 'k' + uuidv4();
        this.state = {
            graphTotalWidth: 200, //dummy values, will be overwritten
            graphTotalHeight: 50, //dummy values, will be overwritten
            graphPieHeight: 50, //dummy values, will be overwritten
            graphPieWidth: 50, //dummy values, will be overwritten
            totalGraphId: graphTotal,
            userPieGraphId: userPieGraphId,
            worldPieGraphId: worldPieGraphId
        }
    }

    componentDidMount() {
        const totalGraphDiv = document.getElementById(this.state.totalGraphId + "-graph")
        if (totalGraphDiv) {
            const rect = totalGraphDiv.getBoundingClientRect();
            this.setState({graphTotalHeight: rect.height, graphTotalWidth: rect.width})
        }

        const pieGraphDiv = document.getElementById(this.state.userPieGraphId + "-graph")
        if (pieGraphDiv) {
            const rect = pieGraphDiv.getBoundingClientRect();
            this.setState({graphPieHeight: rect.height, graphPieWidth: rect.width})
        }
    }


    render(): ReactElement {
        const {total, graphs} = this.props.data;
        const highlightedBucket = total.userBucket;
        const userGraph = graphs.userGraph.data.empty ? <div></div> : <C3Wrapper
            id={this.state.userPieGraphId}
            data={graphs.userGraph.data}
            type={graphs.userGraph.data.type}
            height={this.state.graphPieHeight}
            width={this.state.graphPieWidth}
            legend={{show: false}}
        />

        const worldGraph = graphs.worldGraph.data.empty ? <div></div> : <C3Wrapper
            id={this.state.worldPieGraphId}
            data={graphs.worldGraph.data}
            type={graphs.worldGraph.data.type}
            height={this.state.graphPieHeight}
            width={this.state.graphPieWidth}
            legend={{show: false}}
        />

        const totalGraph = total.graph.data.empty ? <div></div> : <C3Wrapper
            className={"reverse-leftmargin"}
            id={this.state.totalGraphId}
            data={total.graph.data}
            bar={total.graph.bar}
            height={this.state.graphTotalHeight}
            highlightedBar={highlightedBucket}
            width={this.state.graphTotalWidth}
            legend={{show: false}}
        />

        let header = <>{total.actionName ? TranslationService.translation(total.actionName) : ""}: {TranslationService.translation(total.headline)}</>;
        return <div
            className={"col dashboard-event-col ml-2 mr-2 mt-4"}>

            <div className={"row"}>
                <div className={"col text-center dashboard-header"}><h6
                    className={"mt-0"}>{header}</h6></div>
            </div>
            <div className={"row"}>
                <div className={"col pt-3"}>Loggare: {total.totalLoggers}</div>
            </div>
            <div className={"row"}>
                <div className={"col"}>Registreringar: {total.totalCount}</div>
            </div>
            <div className={"row"}>
                <div className={"col highlighted"}>{this.props.firstName}: {total.userCount}</div>
            </div>
            <div className={"row"}>
                <div className={"col event-graph-col ml-0 mr-0 pr-0 pl-0"}>
                    <div id={this.state.userPieGraphId + "-graph"} className={"col event-pie-graph-user m-0 p-0"}>
                        {userGraph}
                    </div>
                    <div id={this.state.worldPieGraphId + "-graph"} className={"col event-pie-graph-world m-0 p-0"}>
                        {worldGraph}
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col text-center"}>
                    <h5 className={"mt-0"}>Registreringar</h5>
                </div>
            </div>
            <div className={"row pt-2"}>
                <div className={"col pr-0"}>
                    Medel: {total.avg}
                </div>
                <div className={"col pl-1 pr-0"}>
                    Max: {total.max}
                </div>
            </div>

            <div className={"row"}>
                <div className={"col pr-0"}>
                    Median: {total.median}
                </div>
                <div className={"col pl-1 pr-0"}>
                    Min: {total.min}
                </div>
            </div>

            <div className={"row"}>
                <div id={this.state.totalGraphId + "-graph"} className={"col event-total-graph-col m-0 p-0"}>
                    {totalGraph}
                </div>
            </div>
        </div>
    }
}

export default DashboardEvent;
