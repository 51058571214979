import React, {ChangeEvent, ReactElement} from 'react';
import {deleteItem, fetchArrayOf} from "../../../../infra/BackendService";
import {Selectable} from "./Selector";
import DeleteLogo from "../../../../images/delete-512.png";
import "./Selector.css";

interface props {
    type: string
    selectItem: (item: Selectable | undefined) => void;
}

interface state {
    wantedName: string,
    items: Selectable[],
    selected: Selectable | undefined
}

class SearchItems extends React.Component<props, state> {
    state: state = {
        wantedName: "",
        items: [],
        selected: undefined
    }

    render(): ReactElement {
        const items: Selectable[] = this.state.items;
        const wantedName: string = this.state.wantedName;
        const type = this.props.type;
        const searchField = <div>
            <label className={"row m-0 pb-1"} htmlFor="item">Search for {type}</label>
            <input className={"row m-0 pb-1 mb-4"}
                   type={"text"}
                   id={"item"}
                   value={wantedName}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => this.onChange(e)}
            />
        </div>;

        const foundItems = items.map((item: Selectable, index: number) => {
            let className = "row m-0 pb-1 no-line-break";
            if (item === this.state.selected) {
                className = className + " selected";
            } else {
                className = SearchItems.getClassName(className, index);
            }
            let deleteIcon = item.canBeDeleted ?
                <div className={"col-2"}>
                    <img
                        aria-label={"delete " + item.id}
                        height={16}
                        src={DeleteLogo}
                        alt={"Delete user"}
                        onClick={() => this.deleteItem(item.id, item.name)}
                    />
                </div> : <div/>;
            return <div className={className} key={item.id}>
                <div aria-label={item.id} className={"col-10"}
                     onClick={() => this.selectItem(item)}>
                    {item.name}
                </div>
                {deleteIcon}
            </div>
        });

        return <div>
            {searchField}
            {foundItems}
        </div>
    }

    private onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const wantedName = e.currentTarget.value;
        this.setState({wantedName: wantedName});
        const type = this.props.type;

        if (wantedName.length > 2) {
            this.searchFor(type, wantedName);
        } else {
            this.setState({items: []});
            this.selectItem(undefined);
        }
    }

    private searchFor(type: string, wantedName: string) {
        const url = "/api/v3/backoffice/" + type + "/search/" + wantedName;
        fetchArrayOf<Selectable>(url).then((resolvedItems: Selectable[]) => {
            this.setState({items: resolvedItems});
        });
    }

    private selectItem(item: Selectable | undefined) {
        this.props.selectItem(item);
        this.setState({selected: item})
    }

    private static getClassName(src: string, index: number) {
        const rowNumber = index + 1;
        if (rowNumber % 2 === 0) {
            return src + " grayed";
        }

        return src;
    }

    private deleteItem(id: string, name: string) {
        if (window.confirm("Are you sure you want to delete " + name + "?")) {
            let type = this.props.type;
            const url = "/api/v3/backoffice/" + type + "/" + id;
            deleteItem(url).then(() => {
                const wantedName = this.state.wantedName;
                this.selectItem(undefined);
                this.searchFor(type, wantedName);
            })
        }
    }
}

export default SearchItems;