import React, {ReactElement} from 'react';
import "./Register.css"
import "./History.css"
import TranslationService from "../../../infra/TranslationService";
import Event from "./Event";
import Value from "./Value";
import {uuidv4} from "./Uuid";
import {EventBackend} from "./EventBackend";
import "../../../infra/Util.css"
import {UpdateAble} from "./UpdateAble";

import DeleteLogo from "../../../images/delete-512.png"
import EditLogo from "../../../images/edit-225-225.png"
import CopyLogo from "../../../images/copy-225-225.png"
import ViewLogo from "../../../images/view-512.png"
import {COPY_ALT_TEXT, DELETE_ALT_TEXT, EDIT_ALT_TEXT, GOAL, VIEW_ALT_TEXT} from "../../../infra/Constants";
import {Goal} from "./Goal";

export interface LatestEvent {
    name: string,
    value: string,
    duplicationIndex?: number
}

interface props {
    actionName: string,
    actionVersion: number,
    actionType: string,
    backend: EventBackend,
    editEvent: (event: Event) => void,
    editGoal: (goal: Goal) => void,
    copyEvent: (event: Event) => void,
    copyGoal: (goal: Goal) => void,
    deleteEvent: (id: string) => void,
    deleteGoal: (id: string) => void,
    viewEvent: (event: Event) => void,
    isEditing: boolean
}

interface state {
    latestLimit: number,
    latestEvents: Event[],
    latestGoals: Goal[],
    spinner: boolean
}

class History extends React.Component<props, state> implements UpdateAble {
    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            latestLimit: 10,
            latestEvents: [],
            latestGoals: [],
            spinner: true
        }
    }

    render(): ReactElement {
        const latestEvents = TranslationService.translation("latestEvents");

        let latest;
        if (this.state.spinner) {
            latest = <div data-testid={"no-action-spinner"} className={"spinner"}/>
        } else {
            latest = this.getLatest();
        }

        return <div aria-label={"latest events"}>
            <h4>{latestEvents}</h4>
            {latest}
            {this.getLatestLinks()}
        </div>
    }

    componentDidMount() {
        this.update();
    }

    private getLatest(): React.ReactFragment {
        const actionType = this.props.actionType;
        let index = 0;
        let background: string;

        if (actionType === GOAL) {
            let latestGoals = this.state.latestGoals;
            let goals: React.ReactFragment[] = [];

            if (latestGoals) {
                goals = latestGoals.map((goal: Goal) => {
                    if (index % 2 === 0) {
                        background = ""
                    } else {
                        background = "grayed"
                    }
                    index++;

                    return this.getLatestGoal(goal, background)
                });
            }

            const latestRows = goals.map((latestRow) => latestRow);
            return <>
                {latestRows}
            </>;
        } else {
            let latestEvents = this.state.latestEvents;
            let events: React.ReactFragment[] = [];

            if (latestEvents !== undefined) {
                events = latestEvents.map((event: Event) => {
                    if (index % 2 === 0) {
                        background = ""
                    } else {
                        background = "grayed"
                    }
                    index++;

                    return this.getLatestEvent(event, background)
                });
            }

            const latestRows = events.map((latestRow) => latestRow);
            return <>
                {latestRows}
            </>;
        }
    }

    private getLatestGoal(goal: Goal, background: string): React.ReactFragment {
        const display: string[] = [];
        goal.predicate.forEach(predicate => {
            const value = predicate.value;
            const translated: string = TranslationService.translation(value);
            display.push(translated + " ")
        })

        goal.objective.forEach(objective => {
            const value = objective.value;
            const translated: string = TranslationService.translation(value);
            display.push(translated + " ")
        })

        let key: string;
        if (goal.id !== undefined) {
            key = goal.id;
        } else {
            key = uuidv4();
        }

        return <div className={background + " latestGoal row p-0 m-0"}
                    aria-label={"latest goal"}
                    key={key}>
            <div className={"col-10 p-0"}>
                <p className={"mb-0 mt-2 ml-1"}>{display} </p>
            </div>
            <div className={"col-2 p-0"}>
                <div className={"row p-0 m-0 float-right"}>
                    {this.deleteButton(goal.id)}
                    {this.editGoalButton(goal)}
                    {this.copyGoalButton(goal)}
                </div>
            </div>
        </div>;
    }

    private getLatestEvent(event: Event, background: string): React.ReactFragment {
        const display: string[] = [];
        let itemProp: string = History.getItemProps(event);

        event.values.forEach(val => {
            val.values.forEach(v => {
                const translated: string = TranslationService.translation(v);
                display.push(translated + " ")
            });
        })
        let key: string;
        if (event.id) {
            key = event.id;
        } else {
            key = uuidv4();
        }

        let buttons;
        if (event.mailOnDemandSent) {
            buttons = <div className={"row p-0 m-0 float-right"}>
                {this.viewEventButton(event)}
            </div>;
        } else {
            if (event.hasBeenCountersigned) {
                buttons = <div className={"row p-0 m-0 float-right"}>
                    {this.copyEventButton(event)}
                </div>;
            } else {
                buttons = <div className={"row p-0 m-0 float-right"}>
                    {this.deleteButton(event.id)}
                    {this.editEventButton(event)}
                    {this.copyEventButton(event)}
                </div>;
            }
        }

        return <div className={background + " latestEvent row p-0 m-0"}
                    itemProp={itemProp}
                    aria-label={"latest event"}
                    key={key}>
            <div className={"col-10 p-0"}>
                <p className={"mb-0 mt-2 ml-1"}>{display} </p>
            </div>
            <div className={"col-2 p-0"}>
                {buttons}
            </div>
        </div>;
    }

    private static getItemProps(event: Event) {
        let result: LatestEvent[] = [];
        let id: string = "";
        if (event.id !== undefined) {
            id = event.id;
        }
        result.push({
            name: "id",
            value: id
        });

        event.values.forEach((value: Value) => {
            const name: string = value.fieldName;
            let duplicationIndex: number = 0;
            if (value.duplicationIndex !== undefined) {
                duplicationIndex = Number(value.duplicationIndex); // todo will not survive the upcoming syntax '0-0'
            }

            value.values.forEach((value: string) => {
                result.push({
                    name: name,
                    value: value,
                    duplicationIndex: duplicationIndex
                });
            });
        });

        return JSON.stringify(result);
    }

    private editGoalButton(goal: Goal): React.ReactFragment {
        const ariaLabel = "edit button";
        const onClick = () => this.editGoal(goal);
        return History.getButton(ariaLabel, onClick, EditLogo, EDIT_ALT_TEXT);
    }

    private editEventButton(event: Event): React.ReactFragment {
        if (!this.props.isEditing) {
            const buttonId: string = 'edit-button-' + event.id;
            const ariaLabel = "edit button";
            const onClick = () => this.edit(event);
            return History.getButton(ariaLabel, onClick, EditLogo, EDIT_ALT_TEXT, buttonId);
        }
        return <div/>
    }

    private copyGoalButton(goal: Goal): React.ReactFragment {
        const ariaLabel = "copy button";
        const onClick = () => this.copyGoal(goal);
        return History.getButton(ariaLabel, onClick, CopyLogo, COPY_ALT_TEXT);
    }

    private copyEventButton(event: Event): React.ReactFragment {
        if (!this.props.isEditing) {
            const buttonId: string = 'copy-button-' + event.id;
            const ariaLabel = "copy button";
            const onClick = () => this.copy(event);
            return History.getButton(ariaLabel, onClick, CopyLogo, COPY_ALT_TEXT, buttonId);
        }
        return <div/>
    }

    private viewEventButton(event: Event): React.ReactFragment {
        const buttonId: string = 'view-button-' + event.id;
        const ariaLabel = "view button";
        const onClick = () => this.view(event);
        return History.getButton(ariaLabel, onClick, ViewLogo, VIEW_ALT_TEXT, buttonId);
    }

    private deleteButton(id: string | undefined): React.ReactFragment {
        if (id !== undefined && !this.props.isEditing) {
            const buttonId: string = 'delete-button-' + id;
            const ariaLabel = "delete button";
            const onClick = () => this.delete(id);
            return History.getButton(ariaLabel, onClick, DeleteLogo, DELETE_ALT_TEXT, buttonId);
        } else {
            return <div/>;
        }
    }

    private static getButton(ariaLabel: string, onClick: () => void, image: string, altText: string, buttonId?: string): React.ReactFragment {
        if (buttonId !== undefined) {
            return <div>
                <button id={buttonId}
                        aria-label={ariaLabel}
                        className={"btn btn-invis-bg pl-1 b-0"}
                        onClick={onClick}>
                    <img
                        height={18}
                        src={image}
                        alt={altText}/>
                </button>
            </div>;
        }

        return <div>
            <button aria-label={ariaLabel}
                    className={"btn btn-invis-bg pl-1 b-0"}
                    onClick={onClick}>
                <img
                    height={18}
                    src={image}
                    alt={altText}/>
            </button>
        </div>;
    }

    private getLatestLinks(): React.ReactFragment {
        const allLabel: string = TranslationService.translation("all");
        return <div className={"row m-0 pt-1 pb-4 float-right"}>
            <button aria-label={"ten last events"}
                    className={"btn-link btn-link-reg btn"}
                    onClick={() => {
                        this.setState({latestLimit: 10},
                            this.update)
                    }}>
                [10]
            </button>
            <button aria-label={"twenty last events"}
                    className={"ml-2 btn-link btn-link-reg btn"}
                    onClick={() => {
                        this.setState({latestLimit: 20},
                            this.update)
                    }}>
                [20]
            </button>
            <button aria-label={"fifty last events"}
                    className={"ml-2 btn-link btn-link-reg btn"}
                    onClick={() => {
                        this.setState({latestLimit: 50},
                            this.update)
                    }}>
                [50]
            </button>
            <button aria-label={"all events"}
                    className={"ml-2 btn-link btn-link-reg btn"}
                    onClick={() => {
                        this.setState({latestLimit: Number.MAX_SAFE_INTEGER},
                            this.update)
                    }}>
                [{allLabel}]
            </button>
        </div>;
    }

    update(): void {
        this.setState({spinner: true});

        const actionName = this.props.actionName;
        const actionVersion = this.props.actionVersion;
        const actionType = this.props.actionType;
        const limit = this.state.latestLimit;
        const backend = this.props.backend;

        if (actionType === GOAL) {
            const latestPromise: Promise<Goal[]> = backend.getLatestGoals(actionName, actionVersion, limit);

            latestPromise.then((goals: Goal[]) => {
                    this.setState({
                        latestGoals: goals,
                        spinner: false
                    });
                }
            );
        } else {
            const latestPromise: Promise<Event[]> = backend.getLatestEvents(actionName, actionVersion, limit);

            latestPromise.then((events: Event[]) => {
                    this.setState({
                        latestEvents: events,
                        spinner: false
                    });
                }
            );
        }
    }

    private editGoal(goal: Goal) {
        this.props.editGoal(goal);
    }

    private edit(event: Event) {
        this.props.editEvent(event);
    }

    private copyGoal(goal: Goal) {
        this.props.copyGoal(goal);
    }

    private copy(event: Event) {
        this.props.copyEvent(event);
    }

    private view(event: Event) {
        this.props.viewEvent(event);
    }

    private delete(id: string) {
        const deleteConfirmation: string = TranslationService.translation("deleteConfirmation");

        if (window.confirm(deleteConfirmation)) {
            const actionType: string = this.props.actionType;
            if (actionType === GOAL) {
                this.props.deleteGoal(id);
            } else {
                this.props.deleteEvent(id);
            }
        }
    }
}

export default History;
