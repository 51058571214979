export function uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        let v: number;
        if (c === 'x') {
            v = r;
        } else {
            v = r & (0x3 | 0x8);
        }
        return v.toString(16);
    });
}
