import React, {ChangeEvent, ReactElement} from 'react';
import {Associate} from "../../register/v2/Action";
import {FieldComponent, FieldError, FieldProps} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import Value from "../../register/v2/Value";
import {createMultipleValues, isMultipleValuesValid} from "./FieldUtil";
import DeleteLogo from "../../../images/delete-512.png";
import {uuidv4} from "../../register/v2/Uuid";

interface props extends FieldProps {
}

interface state {
    selectedAssociates: Associate[];
}

const DEFAULT_NOT_SELECTED: string = "defaultNotSelected";

class Associates extends React.Component<props, state> implements FieldComponent {
    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            selectedAssociates: []
        }
    }

    render(): ReactElement {
        const name: string = this.props.field.name;
        const label: string = TranslationService.translation(this.props.field.name);

        let availableAssociates: Associate[] = [];
        if (this.props.field.associates !== undefined) {
            availableAssociates = this.props.field.associates;
        }

        const selectedAssociates: Associate[] = this.state.selectedAssociates;
        const selectAssociate = (e: ChangeEvent<HTMLSelectElement>) => this.selectAssociate(e);

        let showLabel: boolean = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        return <div>
            {this.getField(label, showLabel, name, availableAssociates, selectedAssociates, selectAssociate)}
        </div>
    }

    private getField(labelText: string,
                     showLabel: boolean,
                     name: string,
                     availableAssociates: Associate[],
                     selectedAssociates: Associate[],
                     selectAssociate: (e: React.ChangeEvent<HTMLSelectElement>) => void): React.ReactFragment {
        const label = <h5>

            <div className={"row"}>
                <div className={"col"}>
                    <label htmlFor={name}>{labelText}</label>
                </div>
            </div>
        </h5>;

        const ariaLabel = name + ".associates";
        const field = <div className={"row"}>
            <div className={"col"}>
                <select id={name}
                        name={name}
                        aria-label={ariaLabel}
                        value={DEFAULT_NOT_SELECTED}
                        onChange={selectAssociate}
                >
                    {this.getDefault()}
                    {this.getOptions(availableAssociates)}
                </select>
            </div>
            <div className={"col"}>
                <ul className={"list-unstyled"} aria-label={name + ".selected.associates"}>
                    {this.getSelectedAssociates(selectedAssociates)}
                </ul>
            </div>
        </div>;

        const addingInstrctionsStart = TranslationService.translation(name + ".addingInstrctionsStart");
        const addingInstrctionsMiddle = TranslationService.translation(name + ".addingInstrctionsMiddle");
        const addingInstrctionsEnd = TranslationService.translation(name + ".addingInstrctionsEnd");

        const addingInstructions = <div
            className={"row"}>
            <div className={"col"}>
                <p aria-label={"an.associate.field.adding.instructions"}>
                    {addingInstrctionsStart}
                    <a href={"#/user"}> {addingInstrctionsMiddle} </a> {addingInstrctionsEnd}.
                </p>
            </div>
        </div>;

        if (!showLabel) {
            return <>
                {field}
                {addingInstructions}
            </>;
        }

        return <div>
            {label}
            {field}
            {addingInstructions}
        </div>;
    }

    private getDefault(): React.ReactFragment {
        const name: string = this.getOptionName(DEFAULT_NOT_SELECTED);
        const translatedOption = TranslationService.translation(name);

        return <option key={name}
                       data-testid="select-option"
                       value={name}
        >
            {translatedOption}
        </option>
    }

    getOptions(associates: Associate[]): React.ReactFragment {
        return associates.map((associate: Associate) => {
            const option = associate.userId;
            const displayName = associate.displayName;

            return <option key={uuidv4()}
                           data-testid="select-option"
                           value={option}
            >
                {displayName}
            </option>
        });
    }

    private getSelectedAssociates(associates: Associate[]) {
        Associates.sortAssociates(associates);

        return associates.map((associate: Associate) => {
            const userId = associate.userId;
            const displayName = associate.displayName;

            return <div className={"row"} key={userId}>
                <li id={userId}
                    aria-label={userId}
                    data-testid="selected-associate"
                >
                    {displayName}
                </li>
                <button className={"btn"}
                        aria-label={userId + ".removeButton"}
                        onClick={() => this.removeAssociate(userId)}>
                    <img alt={"ta bort"}
                         height={18}
                         src={DeleteLogo}
                    />
                </button>
            </div>
        });
    }

    private static sortAssociates(associates: Associate[]) {
        associates.sort((a: Associate, b: Associate) => {
            if (a.displayName > b.displayName) {
                return 1;
            } else {
                return -1;
            }
        });
    }

    private getOptionName(option: string): string {
        const name: string = this.props.field.name;

        return name + "." + option;
    }

    private selectAssociate(e: React.ChangeEvent<HTMLSelectElement>) {
        const wantedUserId: string = e.target.value;

        let availableAssociates: Associate[] = [];
        if (this.props.field.associates !== undefined) {
            availableAssociates = this.props.field.associates;
        }

        const selectedAssociate: Associate | undefined = availableAssociates.find((associate: Associate) => associate.userId === wantedUserId);

        if (selectedAssociate !== undefined) {
            const currentAssociates: Associate[] = this.state.selectedAssociates;
            const alreadyAdded: Associate | undefined = currentAssociates.find((associate: Associate) => associate.userId === wantedUserId)
            if (alreadyAdded === undefined) {
                currentAssociates.push(selectedAssociate);
                this.setState({selectedAssociates: currentAssociates});
            }

            this.addLatestAssociate(selectedAssociate);
        }
    }

    private addLatestAssociate(selectedAssociate: Associate) {
        const availableAssociates = this.props.field.associates;
        if (availableAssociates !== undefined) {
            if (availableAssociates.length > 0) {
                if (availableAssociates[0] !== selectedAssociate) {
                    availableAssociates.unshift(selectedAssociate);
                }
            }
        }
    }

    private removeAssociate(userId: string) {
        const currentAssociates: Associate[] = this.state.selectedAssociates;
        const selectedAssociates = currentAssociates.filter((associate: Associate) => associate.userId !== userId);

        this.setState({selectedAssociates: selectedAssociates});
    }

    values(): Value[] {
        if (this.isValid().valid) {
            const associates: Associate[] = this.state.selectedAssociates;
            const values: string[] = [];
            associates.forEach((associate: Associate) => {
                values.push(associate.userId);
            });
            if (values.length > 0) {
                const name = this.props.field.name;
                return createMultipleValues(name, values);
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        const associates: Associate[] = this.state.selectedAssociates;
        const values: string[] = [];
        associates.forEach((associate: Associate) => {
            values.push(associate.userId);
        });

        const mandatory = this.props.field.mandatory;

        return isMultipleValuesValid(name, values, mandatory);
    }

    clear(): void {
        this.setState({selectedAssociates: []});
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        values.forEach((value: Value) => {
            if (value.fieldName === name) {
                let availableAssociates: Associate[] = [];
                if (this.props.field.associates !== undefined) {
                    availableAssociates = this.props.field.associates;
                }
                const currentAssociates: Associate[] = this.state.selectedAssociates;

                value.values.forEach((userId: string) => {
                    const selectedAssociate: Associate | undefined = availableAssociates.find((associate: Associate) => associate.userId === userId);
                    if (selectedAssociate !== undefined) {
                        currentAssociates.push(selectedAssociate);
                    }
                });
                this.setState({selectedAssociates: currentAssociates});
            }
        });
    }
}

export default Associates;