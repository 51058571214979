export const DUPLICATION_MARKER_START = ".duplication-marker-start-";
export const DUPLICATION_MARKER_STOP = "duplication-marker-end";
export const DUPLICATION_INDEX_MARKER = "-copy-";

export function removeDuplicationMarker(sample: string) {
    let toBeRemoved: string = getDuplicationMarker(sample);

    while (toBeRemoved.length > 0) {
        sample = sample.replace(toBeRemoved, '');
        toBeRemoved = getDuplicationMarker(sample)
    }

    return sample
}

export function getDuplicationMarker(fieldName: string): string {
    if (fieldName.includes(DUPLICATION_MARKER_START) && fieldName.includes(DUPLICATION_MARKER_STOP)) {
        const start: number = fieldName.indexOf(DUPLICATION_MARKER_START);
        const stop: number = fieldName.indexOf(DUPLICATION_MARKER_STOP) + DUPLICATION_MARKER_STOP.length;

        return fieldName.substring(start, stop);
    }

    return '';
}

export function getDuplicationMarkers(sample: string): string[] {
    const result: string[] = [];

    let duplicationMarker: string = getDuplicationMarker(sample);
    while (duplicationMarker.length > 0) {
        result.push(duplicationMarker);
        sample = sample.replace(duplicationMarker, '');
        duplicationMarker = getDuplicationMarker(sample)
    }

    return result;
}

export function containsMarkers(sample: string): boolean {
    const marker: string = getDuplicationMarker(sample);

    return marker.length > 0;
}

export function isEqualDisregardingDuplicationMarkers(optionName: string, currentValue: string): boolean {
    if (getDuplicationMarker(optionName) === getDuplicationMarker(currentValue)) {
        const actualOptionName: string = removeDuplicationMarker(optionName);
        const actualCurrentValue: string = removeDuplicationMarker(currentValue);

        return actualOptionName === actualCurrentValue;
    }

    return false;
}

export function containsDisregardingDuplicationMarkers(candidates: string[], wanted: string): boolean {
    const cleanedCandidates: string[] = [];
    candidates.forEach((value: string) => {
        let cleaned = removeDuplicationMarker(value);
        cleanedCandidates.push(cleaned);
    });
    const cleanedWanted = removeDuplicationMarker(wanted);

    return cleanedCandidates.includes(cleanedWanted);
}

export function getDuplicationIndex(sample: string): string {
    const markers: string[] = getDuplicationMarkers(sample);

    const indexes: string[] = [];
    markers.forEach((value: string) => {
        const indexOfDuplicationEnd = value.indexOf(DUPLICATION_MARKER_STOP);
        const indexOfCopy = value.indexOf(DUPLICATION_INDEX_MARKER) + DUPLICATION_INDEX_MARKER.length;

        const index = value.substring(indexOfCopy, indexOfDuplicationEnd - 1);

        indexes.push(index);
    });

    return indexes.join('-');
}

export function compactIndex(toBeDeletedStr: string, currentStr: string): string | undefined {
    const toBeDeletedParts: string[] = toBeDeletedStr.split('-');
    const toBeDeletedLength = toBeDeletedParts.length;

    const currentParts: string[] = currentStr.split('-');
    const currentLength = currentParts.length;

    if (toBeDeletedLength !== currentLength) {
        return currentStr;
    }

    if (toBeDeletedLength > 1) {
        for (let i = 0; i < toBeDeletedLength - 1; i++) {
            if (toBeDeletedParts[i] !== currentParts[i]) {
                return currentStr;
            }
        }
    }

    const lastIndex: number = toBeDeletedLength - 1;

    const toBeDeletedPart: string = toBeDeletedParts[lastIndex];
    const toBeDeleted: number = Number.parseInt(toBeDeletedPart);
    if (isNaN(toBeDeleted)) {
        return currentStr;
    }

    const currentPart: string = currentParts[lastIndex];
    let current: number = Number.parseInt(currentPart);
    if (isNaN(current)) {
        return undefined;
    }

    if (current > toBeDeleted) {
        current--;
        currentParts[lastIndex] = '' + current;
    }

    return currentParts.join('-');
}
