import React, {Component} from 'react';
import SendNewPassword from "./SendNewPassword";

class NewPassword extends Component {

    render() {
        return (
            <div className={"mainContainer"}>
                <SendNewPassword token={this.props.match.params.token}/>
            </div>
        );
    }

}

export default NewPassword;
