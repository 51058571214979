import React, {ReactElement} from "react";


interface props {
}

interface state {
}


class TraindriverLiaTutorReport extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {}
    }


    render(): ReactElement {

        return <div>
            Report for tutor
        </div>;
    }

}


export default TraindriverLiaTutorReport;
