import React, {ReactNode} from "react";
import {Action, Field} from "../../register/v2/Action";
import TranslationService from "../../../infra/TranslationService";
import getField from "../../register/v3/FieldFactory";
import Event, {createEvent, setValue} from "../../register/v3/Event";

interface props {
    action: Action,
    selectedGroupBy: string,
    onGroupByChange: (selectedGroupBy: string) => void,
    user: any,
}

interface state {
    action: Action,
    possibleEventValues: Set<string>
}

class GroupBy extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        const action: Action = removeUnwantedFieldsGroupBy(this.props.action);
        const possibleEventValues: Set<string> = getPossibleEventValues(action);

        this.state = {
            action: action,
            possibleEventValues: possibleEventValues
        };
    }

    render(): ReactNode {
        const action: Action = this.state.action;
        const selectedValue: string = this.props.selectedGroupBy;
        const groupBy: React.ReactFragment = this.getGroupBy(action, selectedValue);

        return <div>
            {groupBy}
        </div>;
    }

    private getGroupBy(action: Action, selectedValue: string): React.ReactFragment {
        let groupBy = <div/>;
        if (action.fields !== undefined) {
            const headline: string = TranslationService.translation("group_by");

            const field: Field = this.getGroupByField(action, selectedValue);
            const event: Event = this.getEvent(selectedValue, field);

            const user = this.props.user;
            const onChange = this.onChange;
            const radio: React.JSX.Element = getField(field, event, onChange, action, user);

            groupBy = <div>
                <h4>{headline}</h4>
                {radio}
            </div>
        }

        return groupBy;
    }

    private getEvent(selectedValue: string, field: Field) {
        const possibleEventValues: Set<string> = this.state.possibleEventValues;
        const duplicationIndex = '0';
        const separator: '.' = '.';
        let event: Event = createEvent();

        const parts: string[] = selectedValue.split(separator);

        let name = field.name;
        let value: string = name;

        for (let index: number = 1; index < parts.length; index++) {
            value = value + separator + parts[index];
            if (possibleEventValues.has(value)) {
                event = setValue(name, value, duplicationIndex, true, field, event);
                name = value;
            }
        }

        return event;
    }

    private getGroupByField(action: Action, defaultValue: string): Field {
        const name: string = action.name;
        const options: (string | Field) [] = [];
        action.fields.forEach((field: Field) => {
            const cleaned: string | Field = this.getOption(field, action);
            options.push(cleaned);
        });

        return {
            name: name,
            type: 'radio',
            fieldLabel: false,
            showLabel: false,
            defaultValue: defaultValue,
            mandatory: true,
            duplicationIndex: 0,
            options: options
        };
    }

    private getOption(field: Field, action: Action): string | Field {
        field.mandatory = true;
        if (field.options === undefined) {
            return field;
        }

        const fieldOptions: (string | Field) [] = field.options.filter((value: string | Field) => typeof value !== 'string');
        field.options = fieldOptions;
        if (fieldOptions.length === 0) {
            const actionName: string = action.name;
            const name = field.name;

            return name.substr(actionName.length + 1);
        } else {
            fieldOptions.forEach((value: string | Field) => {
                    if (typeof value !== 'string') {
                        value.name = this.getNameWithoutFieldName(value.name, field);
                        value.mandatory = true;

                        return this.getOption(value, action);
                    }
                }
            );
        }

        return field;
    }

    private getNameWithoutFieldName(value: string, field: Field): string {
        if (value.startsWith(field.name)) {
            const fieldName: string = field.name;
            const commonStart: number = fieldName.length;

            return value.substr(commonStart + 1);
        } else {
            return value;
        }
    }

    private onChange(name: string, value: (string | string[]), duplicationIndex: string, valid: boolean) {
        const onGroupByChange = this.props.onGroupByChange;
        if (name === duplicationIndex === valid) {
            // Trick Idea to belive that these arguments are used
        }

        let groupBy: string = this.props.selectedGroupBy;
        if (Array.isArray(value) && value.length === 1) {
            groupBy = value[0];
        }
        if (!Array.isArray(value)) {
            groupBy = value;
        }

        onGroupByChange(groupBy);
    }
}

export default GroupBy;

export function removeUnwantedFieldsGroupBy(source: Action): Action {
    const wantedFieldTypes: Set<string> = new Set();
    wantedFieldTypes.add('field');
    wantedFieldTypes.add('radio');
    wantedFieldTypes.add('checkbox');

    const fields: Field[] = getGoodFields(source.fields, wantedFieldTypes);

    return {
        name: source.name,
        version: source.version,
        frontendVersion: source.frontendVersion,
        type: source.type,
        groupBy: source.groupBy,
        fields: fields
    };
}

function getGoodFields(source: Field[], wantedFieldTypes: Set<string>): Field[] {
    const fields: Field[] = [];
    for (let index: number = 0; index < source.length; index++) {
        const sourceField: Field = source[index];
        const current: Field = JSON.parse(JSON.stringify(sourceField))
        if ((current.type !== undefined && wantedFieldTypes.has(current.type))) {
            current.type = 'radio';
            current.showLabel = false;
            current.mandatory = true;

            if (current.options !== undefined) {
                current.options = getGoodOptions(current.options, wantedFieldTypes);
            }

            if (current.fields === undefined) {
                if (current.graphType !== undefined) {
                    current.conditionalOn = undefined;
                    fields.push(current);
                }
            } else if (current.fields.length === 0) {
                if (current.graphType !== undefined) {
                    current.conditionalOn = undefined;
                    fields.push(current);
                }
            } else {
                const goodFields: Field[] = getGoodFields(current.fields, wantedFieldTypes);
                current.fields = goodFields;
                if (current.graphType !== undefined) {
                    current.conditionalOn = undefined;
                    fields.push(current);
                } else {
                    for (let i: number = 0; i < goodFields.length; i++) {
                        const goodField: Field = goodFields[i];
                        if (goodField.graphType !== undefined) {
                            goodField.conditionalOn = undefined;
                            fields.push(goodField);
                        }
                    }
                }
            }
        }
    }

    return fields;
}

function getGoodOptions(source: (string | Field)[] | undefined, wantedFieldTypes: Set<string>): Field[] | undefined {
    if (source === undefined) {
        return undefined;
    }

    const options: Field[] = [];
    for (let index: number = 0; index < source.length; index++) {
        const current: string | Field = source[index];

        if (typeof current !== 'string') {
            if (current.type !== undefined && wantedFieldTypes.has(current.type)) {
                current.type = 'radio';
                current.showLabel = false;
                current.mandatory = true

                if (current.fields !== undefined) {
                    const goodFields: Field[] = getGoodFields(current.fields, wantedFieldTypes);
                    for (let i: number = 0; i < goodFields.length; i++) {
                        const goodField: Field = goodFields[i];
                        if (goodField.graphType !== undefined) {
                            goodField.conditionalOn = undefined;
                            options.push(goodField);
                        }
                    }
                }

                if (current.options === undefined) {
                    if (current.graphType !== undefined) {
                        current.conditionalOn = undefined;
                        options.push(current);
                    }
                } else {
                    const goodOptions: Field[] | undefined = getGoodOptions(current.options, wantedFieldTypes);
                    if (goodOptions !== undefined) {
                        if (current.graphType !== undefined) {
                            current.options = goodOptions;
                            current.conditionalOn = undefined;
                            options.push(current);
                        } else {
                            for (let i: number = 0; i < goodOptions.length; i++) {
                                const goodOption: Field = goodOptions[i];
                                if (goodOption.graphType !== undefined) {
                                    goodOption.conditionalOn = undefined;
                                    options.push(goodOption);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return options;
}

function getPossibleEventValues(action: Action): Set<string> {
    const possibleValues: Set<string> = new Set();
    extractPossibleEventValues(action.fields, possibleValues);

    return possibleValues;
}

function extractPossibleEventValues(source: (string | Field)[], possibleValues: Set<string>) {
    for (let index: number = 0; index < source.length; index++) {
        const current: string | Field = source[index];

        if (typeof current !== 'string') {
            possibleValues.add(current.name);

            const fields: Field[] | undefined = current.fields;
            const options: (string | Field)[] | undefined = current.options;

            if (fields !== undefined && fields.length > 0) {
                extractPossibleEventValues(fields, possibleValues);
            }
            if (options !== undefined && options.length > 0) {
                extractPossibleEventValues(options, possibleValues);
            }
        }
    }
}

export function copyAnAction(source: Action): Action {
    // Sample code for copying an action without applying any changes to it.
    // It was used as inspiration when removeUnwantedFieldsGroupBy was implemented.
    const fields: Field[] = getFields(source.fields);

    return {
        name: source.name,
        version: source.version,
        frontendVersion: source.frontendVersion,
        type: source.type,
        groupBy: source.groupBy,
        fields: fields
    };
}

function getFields(source: Field[]): Field[] {
    const fields: Field[] = [];
    for (let index: number = 0; index < source.length; index++) {
        const current: Field = source[index];
        current.options = getOptions(current.options);
        if (current.fields === undefined) {
            fields.push(current);
        } else if (current.fields.length === 0) {
            fields.push(current);
        } else {
            current.fields = getFields(current.fields);
            fields.push(current);
        }
    }

    return fields;
}

function getOptions(source: (string | Field)[] | undefined): (string | Field)[] | undefined {
    if (source === undefined) {
        return undefined;
    }
    const options: (string | Field)[] = [];
    for (let index: number = 0; index < source.length; index++) {
        const current: string | Field = source[index];
        if (typeof current === 'string') {
            options.push(current);
        } else if (current.options === undefined) {
            options.push(current);
        } else {
            current.options = getOptions(current.options);
            options.push(current);
        }
    }

    return options;
}
