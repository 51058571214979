import React, {ReactElement} from 'react';
import {FieldComponent, FieldError} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import Value from "../../register/v2/Value";
import {getValid} from "./FieldUtil";
import ConditionalField, {ConditionalProps, ConditionalState} from "./ConditionalField";
import {V3} from "../../../infra/Constants";

interface props extends ConditionalProps {
    frontendVersion?: string,
    duplicationIndex?: string,
}

interface state extends ConditionalState {
}

class Label extends ConditionalField<props, state> implements FieldComponent {
    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            ...this.state,
        }
    }

    render(): ReactElement {
        const name: string = this.props.field.name;
        const label: string = TranslationService.translation(this.props.field.name);

        const frontendVersion = this.props.frontendVersion;
        let showLabel: boolean = false;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            showLabel = true;
        }

        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        let field = Label.get(showLabel, name, label);

        if (frontendVersion !== undefined && frontendVersion === V3) {
            return field;
        } else {
            return this.renderField(field, name);
        }
    }

    private static get(showLabel: boolean, name: string, label: string): ReactElement {
        if (showLabel) {
            return <div key={name}>
                <h5 aria-label={name + ".label"}>{label}</h5>
            </div>;
        } else {
            return <div/>
        }
    }

    values(): Value[] {
        return [];
    }

    isValid(): FieldError {
        const name = this.props.field.name;

        return getValid(name);
    }

    clear(): void {
        let visible = this.isConditionalfield();
        this.setState({
            visible: visible
        });
    }

    set(values: Value[]): void {
        // only to fool idea to think that values are being used
        if (values === undefined) {
            console.log(values);
        }
    }
}

export default Label;