import React, {ChangeEvent, ReactElement} from "react";
import {postObjectOf} from "../../../../infra/BackendService";

interface props {
}

interface state {
    name: string,
    description: string,
    isPublic: boolean,
    roleCreated: string
}

export type RolePayload = {
    name: string,
    description: string,
    isPublic: boolean,
}


class CreateRoleForm extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            name: "",
            description: "",
            isPublic: false,
            roleCreated: ""
        };
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleIsPublicChange = this.handleIsPublicChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleNameChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({name: event.target.value});
    }

    handleDescriptionChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({description: event.target.value});
    }

    handleIsPublicChange() {
        let checked = this.state.isPublic;
        this.setState({isPublic: !checked});
    }


    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const url: string = "/api/v3/backoffice/role";
        let name = this.state.name;
        let description = this.state.description;
        let isPublic = this.state.isPublic;
        const payload: RolePayload = {
            name: name,
            description: description,
            isPublic: isPublic
        }

        postObjectOf(payload, url).then(() => this.setState({
            roleCreated: name
        }));

        this.clearForm()
    }

    render(): ReactElement {
        let label = <label className={"row m-0 pb-1"} htmlFor="createRole">Create role</label>
        const form = <form id={"createRole"} onSubmit={this.handleSubmit}>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.name}
                    onChange={(e) => this.handleNameChange(e)}
                    type="text"
                    placeholder="Name"
                    aria-label="Name"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.description}
                    onChange={(e) => this.handleDescriptionChange(e)}
                    type="text"
                    placeholder="Description"
                    aria-label="Description"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <label>
                    <input
                        defaultChecked={this.state.isPublic}
                        onChange={() => this.handleIsPublicChange()}
                        type="checkbox"
                        placeholder="isPublic"
                        id="isPublic"
                        aria-label="isPublic"
                    />Public (users can choose it at sign up) </label>
            </div>
            <div className={"row m-0 pb-2"}>
                <button type="submit" className="btn" aria-label="Save">Save</button>
            </div>
        </form>

        const roleCreated = this.state.roleCreated !== "" ?
            <div>Role {this.state.roleCreated} created</div> : <div/>;
        return <div>
            {label}
            {form}
            {roleCreated}
        </div>

    };

    private clearForm() {
        this.setState({name: "", description: "", isPublic: false})
    }
}

export default CreateRoleForm;