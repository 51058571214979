import React, {Component} from 'react';
import X from "./images/X_2.png";
import "./GlobalToaster.css"

interface props {
    successText: string | null;
    errorText: string | null;
    displaySuccess: boolean;
    displayError: boolean;
    closeToast: () => void
}

interface state {

}

const successDelay = 3;
const errorDelay = 8;

class GlobalToaster extends Component<props, state> {

    render() {
        let error = <div/>;
        let success = <div/>;
        //currently undecided if we should use to error close version for instant or the local one

        if (this.props.displayError) {
            error = <div onClick={() => this.closeErrorToast()}
                         id={"error-toaster"}
                         className={"toast toast-bg-red"}>
                {this.props.errorText}
                <button className={"toast-button toast-btn-red"}><img className={"toast-image"} height={12} src={X}
                                                                      alt={"close toast"}/></button>
            </div>
            this.hideErrorAfterDelay();
        }

        if (this.props.displaySuccess) {
            success = <div onClick={() => this.closeSuccessToast()}
                           id={"success-toaster"}
                           className={"toast toast-bg-green"}>
                {this.props.successText}
                <button className={"toast-button toast-btn-green"}><img className={"toast-image"} height={12} src={X}
                                                                        alt={"close toast"}/></button>
            </div>
            this.hideSuccessAfterDelay();
        }

        return (
            <div>
                {error}
                {success}
            </div>
        );
    }

    private hideErrorAfterDelay() {
        setTimeout(() => {
            this.closeErrorToast();
        }, errorDelay * 1000)
    }

    private closeErrorToast() {
        let toast = document.getElementById("error-toaster")
        toast?.classList.add("hide-alert")

        if (this.props !== undefined && this.props.closeToast !== undefined) {
            this.props.closeToast();
        }
    }

    private hideSuccessAfterDelay() {
        setTimeout(() => {
            this.closeSuccessToast();
        }, successDelay * 1000)
    }

    private closeSuccessToast() {
        let toast = document.getElementById("success-toaster")
        toast?.classList.add("hide-alert")
        if (this.props !== undefined && this.props.closeToast !== undefined) {
            this.props.closeToast();
        }
    }
}

export default GlobalToaster;
