import React, {Component} from 'react';
import './About.css'
import parse from 'html-react-parser';
import HelpModalDialog from "../help/HelpModalDialog";

class About extends Component {
    state = {
        showHelp: false
    };

    componentDidMount() {
        document.title = "About";
    }

    render() {

        let helpDialog;
        if (this.state.showHelp) {
            const headline = this.state.headline;
            const helpBody = this.state.paragraphs;
            const videoLink = this.state.videoLink;

            helpDialog = <div>
                <HelpModalDialog
                    hide={this.hideHelp}
                    headline={headline}
                    body={helpBody}
                    youtubeLink={videoLink}
                />
            </div>;
        } else {
            helpDialog = <div/>;
        }

        return (
            <div>
                <div className={"container"}>
                    <div className={"col"}>

                        <h5 className={"pt-2"}>Hjälp</h5>

                        <div className={"tabbed"}>
                            <p>
                                Behöver du hjälp? <a href="mailto:support@logeze.com?subject=Hjälp med LogEze">Skicka
                                oss
                                ett mail med din fråga. Klicka på mig för att komma till din mail.</a>
                            </p>
                            <p>
                                Vill du hellre ha vår mailadress direkt är den:
                                <br/>
                                support@logeze.com
                            </p>

                            <p>
                                Vi tycker om frågor som kommer via&nbsp;
                                <a href={"https://join.slack.com/t/logeze/shared_invite/enQtNzY3OTYyMzA4NTY0LTZhNDFkMWI3NWUzY2EyYWY0MDBlNDdiMTVjZWVlNTg4ZDBjYmI5ZDFmYWYyODcxMGIyZDcwMmFiYzI4YjgxMGQ"}>Slack</a>
                                &nbsp;här är en direktlänk så du smidigt kan komma till rätt&nbsp;
                                <a href={"https://join.slack.com/t/logeze/shared_invite/enQtNzY3OTYyMzA4NTY0LTZhNDFkMWI3NWUzY2EyYWY0MDBlNDdiMTVjZWVlNTg4ZDBjYmI5ZDFmYWYyODcxMGIyZDcwMmFiYzI4YjgxMGQ"}>
                                    Slack team
                                </a>.
                                <br/>Men föredrar du e-post nås vi alltid på support@logeze.com
                            </p>

                        </div>

                        <h5 className={"pt-2"}>Lite hjälp på traven</h5>
                        <div className={"tabbed"}>
                            <ul>
                                <li className={"btn-link"}
                                    onClick={() => this.showHelp("Uppdatera dina användaruppgifter", [], "https://player.vimeo.com/video/485025545")}
                                >
                                    Uppdatera dina användaruppgifter
                                </li>

                                <li className={"btn-link"}
                                    onClick={() => this.showHelp("Byt/Uppdatera ditt lösenord", [], "https://player.vimeo.com/video/485024100")}
                                >
                                    Byt/Uppdatera ditt lösenord
                                </li>

                                <li className={"btn-link"}
                                    onClick={() => this.showHelp("Kopiera, editera eller ta bort ett mål", [], "https://player.vimeo.com/video/485027415")}
                                >
                                    Kopiera, editera eller ta bort ett mål
                                </li>

                                <li className={"btn-link"}
                                    onClick={() => this.showHelp("Statistik", [], "https://player.vimeo.com/video/485026070")}
                                >
                                    Statistik
                                </li>

                                <li className={"btn-link"}
                                    onClick={() => this.showHelp("Import av Excel", [], "https://player.vimeo.com/video/480908659")}
                                >
                                    Import av Excel
                                </li>

                                <li className={"btn-link"}
                                    onClick={() => this.showHelp("Registrera Jourer och jourkomp", [], "https://player.vimeo.com/video/480916231")}
                                >
                                    Registrera Jourer och jourkomp
                                </li>

                            </ul>
                        </div>

                        <div>
                            {helpDialog}
                        </div>

                        <h5> Välkommen till LogEze! </h5>
                        <p className={"tabbed"}>
                            LogEze är ett system där du kan registrera det mesta du gjort och gör, och framför allt få
                            ut statistik och sammanställningar av det du
                            registrerat. Dessutom kan du fortlöpande se hur det går för ”världen” – alltså alla andra
                            användare som använder systemet. Du kan aldrig se
                            vad en enskild användare har för sig (och andra kan inte se dig – mer om det nedan), men den
                            allmänna statistiken ser du hela tiden.
                        </p>
                        <br/>
                        < div className={"tabbed"}>
                            <p>
                                <a href={"./pdfs/PrivacyPolicy.pdf"}
                                   target={"_blank"}
                                   rel={"noopener noreferrer"}>
                                    <h4>Läs våra användarvillkor och integritetspolicy</h4>
                                </a>
                            </p>
                        </ div>

                        <br/>
                        <img src="./images/the_master.jpg" alt="The master" align="middle"/>
                        <br/>
                        <br/>

                        <h5 className={"pt-4"}>Senaste nytt</h5>

                        {
                            this.props.splashData.allAnnouncements !== undefined ?
                                this.props.splashData.allAnnouncements.map(e => {
                                    return (
                                        <div className={"row pt-1"} key={e.announcement + e.to}>
                                            <div className={"col-sm-2 text-left"}>
                                                {e.from}
                                            </div>
                                            <div className={"col-sm-10 text-left"}>
                                                <div className={"container"}>
                                                    <div className={"row"}>
                                                        <h6>{e.announcement}</h6>
                                                    </div>
                                                    <div className={"row"}>
                                                        <div className={"container"}>
                                                            <div className={"col"}>
                                                                {
                                                                    e.message !== undefined ?
                                                                        e.message.map(message => {
                                                                            return (
                                                                                <div className={"row"}
                                                                                     key={message.paragraph}>
                                                                                    <p className={"mb-1"}>{parse(message.paragraph)}</p>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                ""
                        }

                    </div>
                </div>
            </div>
        );
    }

    showHelp(headline, paragraphs, videoLink) {
        this.setState({
            headline: headline,
            paragraphs: paragraphs,
            videoLink: videoLink,
            showHelp: true
        });
    }

    hideHelp = () => {
        this.setState({showHelp: false});
    }
}

export default About;