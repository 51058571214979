import {Report} from "./ReportTable";
import React from "react";
import SoSMal from "../../../images/SoS-mål.jpg"

interface props {
}

interface state {
    report: Report | undefined,
}


class ResidentTrainingWithGoalsReport extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            report: undefined,
        }
    }

    render() {
        return <div><img alt="SoS mål" src={SoSMal}/></div>
    }
}

export default ResidentTrainingWithGoalsReport