import React from 'react';
import {FieldProps} from "./FieldComponent";
import Value from "../../register/v2/Value";
import TranslationService from "../../../infra/TranslationService";
import {NOT_REGISTERED} from "../../../infra/Constants";

export interface ConditionalProps extends FieldProps {
    shareMyValue?: (notifier: string, values: Value[]) => void
}

export interface ConditionalState {
    visible: boolean
}

class ConditionalField<P extends ConditionalProps, S extends ConditionalState> extends React.Component<P, S> {
    constructor(props: P) {
        super(props);

        let visible = this.isConditionalfield();

        this.state = {
            ...this.state,
            visible: visible
        }
    }

    isConditionalfield() {
        const conditionalOn = this.props.field.conditionalOn;
        return conditionalOn === undefined;
    }

    renderField(field: React.ReactFragment, fieldName: string): React.ReactElement {
        const visible: boolean = this.state.visible;
        if (!visible) {
            return <div/>
        }

        let labelField: boolean = this.props.field.fieldLabel;
        let duplicable = this.props.field.duplicable;
        if (duplicable !== undefined && duplicable) {
            labelField = false;
        }

        let fieldLabel: React.ReactFragment;
        if (labelField) {
            fieldLabel = this.getLabel(fieldName);
        } else {
            fieldLabel = <div/>;
        }

        return <div>
            {fieldLabel}
            {field}
        </div>
    }

    componentDidUpdate(prevProps: Readonly<ConditionalProps>, prevState: Readonly<ConditionalState>, snapshot?: any) {
        const fieldName: string = this.props.field.name;
        this.notifyConditionalFields(fieldName);
    }

    getLabel(fieldName: string, index?: number): React.ReactFragment {
        let label = TranslationService.translation(fieldName);

        if (index !== undefined) {
            label = label + " #" + index;
        }

        return <div className={"pt-4"}>
            <hr/>
            <h4 aria-label={fieldName + ".label"}>{label}</h4>
        </div>;
    }

    notifyConditionalFields(notifier: string) {
        const field = this.props.field;
        const isConditionalOn: boolean | undefined = field.notifyChange;
        if (isConditionalOn !== undefined && isConditionalOn) {
            const shareMyValue = this.props.shareMyValue;
            if (shareMyValue !== undefined) {
                const currentValues = this.values();
                const type = field.type;
                if (type !== undefined && type === 'radio') {
                    if (currentValues.length === 0) {
                        const name = field.name;
                        const value: Value = {
                            fieldName: name,
                            values: [NOT_REGISTERED]
                        };
                        currentValues.push(value);
                    }
                }

                shareMyValue(notifier, currentValues);
            }
        }
    }

    values(): Value[] {
        throw new Error("Should use the clients implementation");
    }
}

export default ConditionalField;
