import React, {Component} from 'react';

class HSFGrouping extends Component {

    render() {
        return (
            <div>
                <h4>Gruppering</h4>
                <ul className={"noPoints pl-0"}>
                    {
                        this.props.groupBy.map(e => (

                            <li key={e.name}>
                                <input type={"radio"}
                                       name={"groupby"}
                                       onChange={() => this.props.onChangeGroup(e)}
                                       defaultChecked={e.defaultGroupBy}
                                       value={e.name}>
                                </input>{e.translatedName}
                            </li>
                        ))
                    }
                </ul>
            </div>
        );
    }
}

export default HSFGrouping;