import React, {createRef, ReactElement} from "react";
import {FieldComponent, FieldError} from "./FieldComponent";
import {Action, Field} from "../../register/v2/Action";
import Value from "../../register/v2/Value";
import TranslationService from "../../../infra/TranslationService";
import NumberField from "./NumberField";
import CustomSelect from "./CustomSelect";
import {getValid, invalidDuration} from "./FieldUtil";
import "./ValueUnit.css"
import ConditionalField, {ConditionalProps, ConditionalState} from "./ConditionalField";
import {V3} from "../../../infra/Constants";
import getFieldNG from "../../register/v3/FieldFactory";
import EventNG, {getValue} from "../../register/v3/Event";

interface props extends ConditionalProps {
    frontendVersion?: string,
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
    event?: EventNG,
    action?: Action,
    user?: any
}

interface state extends ConditionalState {
}

class Age extends ConditionalField<props, state> implements FieldComponent {
    durationRef: any;
    unitRef: any;

    constructor(props: Readonly<props>) {
        super(props);
        this.onChange = this.onChange.bind(this);

        this.state = {
            ...this.state
        }
    }

    render(): ReactElement {
        const name: string = this.props.field.name;
        const defaultSelection: string | undefined = this.getDefaultUnit();
        let min = this.getMin();
        let max = this.getMax();
        let decimalPlaces = this.getDecimalPlaces();
        let fieldSize = this.getFieldSize();

        this.durationRef = createRef();
        this.unitRef = createRef();

        const numberField: Field = {
            name: this.getValueName(name),
            fieldLabel: false,
            type: 'number',
            mandatory: this.getMandatory(),
            min: min,
            max: max,
            decimalPlaces: decimalPlaces,
            fieldSize: fieldSize,
            duplicationIndex: 0
        };
        const unitField: Field = {
            name: Age.getUnitName(name),
            fieldLabel: false,
            type: 'select',
            mandatory: this.getMandatory(),
            options: this.getUnits(),
            showDefaultOption: this.showDefaultOption(),
            duplicationIndex: 0
        };

        let field = this.getField(numberField, unitField, defaultSelection);

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            return <div>
                {field}
            </div>
        } else {
            return this.renderField(field, name);
        }
    }

    private getField(numberField: Field,
                     unitField: Field,
                     defaultSelection: string | undefined): React.ReactElement {
        const name: string = this.props.field.name;
        let showLabel: boolean = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        const label = Age.getLabel(name, showLabel);

        let visibleNumberField;
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            let user: any = {
                dateFormat: 'yyyy-MM-dd'
            }
            if (this.props.user !== undefined) {
                user = this.props.user;
            }
            visibleNumberField = this.getFieldNG(numberField, user, this.onChange);
        } else {
            visibleNumberField = <NumberField ref={this.durationRef}
                                              field={numberField}
            />;
        }

        const valueColumn = <div className={"col numberField"}>
            {visibleNumberField}
        </div>;

        let visibleUnitField;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            let user: any = {
                dateFormat: 'yyyy-MM-dd'
            }
            if (this.props.user !== undefined) {
                user = this.props.user;
            }
            let onChange = this.props.onChange;
            if (onChange !== undefined) {
                visibleUnitField = this.getFieldNG(unitField, user, onChange);
            }
        } else {
            visibleUnitField = <CustomSelect ref={this.unitRef}
                                             field={unitField}
                                             defaultSelection={defaultSelection}
            />;
        }

        const unitColumn = <div className={"col"}>
            {visibleUnitField}
        </div>;


        const field = <div id={name}>
            <div className={"row"}>
                {valueColumn}
                {unitColumn}
            </div>
        </div>;

        return <div>
            {label}
            {field}
        </div>;
    }

    private getFieldNG(field: Field,
                       user: any,
                       onChange: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void): any {
        let visibleField;
        if (this.props.event !== undefined &&
            this.props.duplicationIndex !== undefined &&
            this.props.onChange !== undefined &&
            this.props.action !== undefined
        ) {
            const event: EventNG = this.props.event;
            const action = this.props.action;

            visibleField = getFieldNG(field, event, onChange, action, user);
        }

        return visibleField;
    }

    private onChange(name1: string, value1: string | string[], duplicationIndex: string, valid: boolean): void {
        const event = this.props.event;
        if (event !== undefined) {
            const frontendVersion = this.props.frontendVersion;
            if (frontendVersion !== undefined && frontendVersion === V3) {
                let onChange = this.props.onChange;
                if (onChange !== undefined && this.props.duplicationIndex !== undefined) {
                    this.setDefaultUnitIfNeeded(event, onChange);
                    let field = this.props.field;
                    onChange(name1, value1, duplicationIndex, valid, field);
                }
            }
        }
    }

    private setDefaultUnitIfNeeded(event: EventNG, onChange: (name: string, value: (string | string[]), duplicationIndex: string, valid: boolean, field: Field) => void) {
        if (this.props.duplicationIndex !== undefined) {
            let field1 = this.props.field;
            const fieldName: string = field1.name;
            const name: string = Age.getUnitName(fieldName);
            const value: string = name + '.' + this.getDefaultUnit();
            const duplicationIndex: string = this.props.duplicationIndex;

            const currentValue: string | string[] | undefined = getValue(name, event, duplicationIndex);

            if (currentValue === undefined) {
                onChange(name, value, duplicationIndex, true, field1);
            }
        }
    }

    private static getLabel(name: string, showLabel: boolean) {
        if (showLabel) {
            const label: string = TranslationService.translation(name);
            return <label htmlFor={name}
                          aria-label={name}
            >
                {label}
            </label>;
        } else {
            return <div/>
        }
    }

    getUnits(): string[] {
        let units: string[] = [];
        if (this.props.field.options !== undefined) {
            this.props.field.options.forEach((option: string | Field) => {
                if (typeof option === 'string') {
                    units.push(option)
                }
            });
        }

        return units;
    }

    showDefaultOption(): boolean {
        if (this.props.field.showDefaultOption !== undefined) {
            return this.props.field.showDefaultOption;
        } else {
            return false;
        }
    }

    getValueName(name: string): string {
        return name + ".age";
    }

    getDefaultUnit(): string {
        return this.getUnits()[0];
    }

    private static getUnitName(name: string): string {
        return name + ".unit";
    }

    private getMin(): number {
        let min: number = Number.MIN_SAFE_INTEGER;
        if (this.props.field.min !== undefined) {
            min = this.props.field.min;
        }

        return min;
    }

    private getMax(): number {
        let max: number = Number.MAX_SAFE_INTEGER;
        if (this.props.field.max !== undefined) {
            max = this.props.field.max;
        }

        return max;
    }

    private getDecimalPlaces(): number {
        let decimalPlaces: number = Number.MAX_SAFE_INTEGER;
        if (this.props.field.decimalPlaces !== undefined) {
            decimalPlaces = this.props.field.decimalPlaces;
        }

        return decimalPlaces;
    }

    private getFieldSize(): number {
        let fieldSize: number = 9;
        if (this.props.field.fieldSize !== undefined) {
            fieldSize = this.props.field.fieldSize;
        }

        return fieldSize;
    }

    private getMandatory(): boolean {
        let mandatory: boolean = false;
        if (this.props.field.mandatory !== undefined) {
            mandatory = this.props.field.mandatory;
        }

        return mandatory;
    }

    values(): Value[] {
        if (this.isValid().valid) {
            const result: Value[] = [];
            const duration: FieldComponent = this.durationRef.current;
            const durationValues: Value[] = duration.values();
            if (durationValues.length > 0) {
                const value = durationValues[0];
                result.push(value);
            } else {
                return [];
            }

            const unitField: FieldComponent = this.unitRef.current;
            const unitValues: Value[] = unitField.values();
            if (unitValues.length > 0) {
                const value = unitValues[0];
                result.push(value);
            }

            return result;
        } else {
            return [];
        }
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        const durationField: FieldComponent = this.durationRef.current;
        const unitField: FieldComponent = this.unitRef.current;

        if (durationField === null) {
            return getValid(name, invalidDuration);
        }

        const durationValid = durationField.isValid();
        const unitValid = unitField.isValid();
        let mandatory: boolean = this.getMandatory();

        if (mandatory) {
            if (durationValid.valid && unitValid.valid) {
                return getValid(name);
            } else {
                return getValid(name, invalidDuration);
            }
        }

        if (durationValid.valid && unitValid.valid) {
            if (durationField.values().length === 0 && unitField.values().length === 0) {
                return getValid(name);
            }

            if (durationField.values().length === 0 || unitField.values().length === 0) {
                return getValid(name);
            }

            return getValid(name);
        } else {
            return getValid(name, invalidDuration);
        }
    }

    clear(): void {
        let visible = this.isConditionalfield();
        this.setState({
            visible: visible
        });

        const duration = this.durationRef.current;
        if (duration !== null) {
            duration.clear();
        }

        const unit = this.unitRef.current;
        if (unit !== null) {
            unit.clear();
        }
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        const durationName = this.getValueName(name);
        const unitName = Age.getUnitName(name);

        values.forEach((value: Value) => {
            if (value.fieldName === durationName) {
                const durationField: FieldComponent = this.durationRef.current;
                const durationValues: Value[] = [value];

                durationField.set(durationValues);
            }

            if (value.fieldName === unitName) {
                const unitField: FieldComponent = this.unitRef.current;
                const unitValues: Value[] = [value];

                unitField.set(unitValues);
            }
        });
    }
}

export default Age;
