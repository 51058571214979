import React from "react";

export function FrontPageAbout_desktop_se(): React.ReactFragment {
    return <div
        className={"row aboutFooterRow"}>
        <div className={"container-fluid"}>
            <div className={"row pt-2 pb-3"}>
                <div className={"col-4"}/>
                <div className={"col-3"}>
                    <h5>LogEze</h5>
                    <ul>
                        <li>Följ din utveckling</li>
                        <li>Visualisera och analysera din statistik</li>
                        <li>Jämför dina resultat med andra</li>
                        <li>Utveckla dina färdigheter</li>
                    </ul>
                    <h5>Använd LogEze!</h5>
                </div>
                <div className={"col-2"}/>
                <div className={"col-2"}>
                    {companyInformation_se()}
                </div>
            </div>
            <div className={"row pt-2 pb-3"}>
                <div className={"col-1"}/>
                <div className={"col-10"}>
                    <div className={"text-center"}>
                        <p className={"smallprint"}>
                            Denna sida och tjänst använder INGA kakor och INGA
                            spårningstekniker.
                            Ingen tredje part kan se eller får köpa någon information om ditt besök. Se vår&nbsp;
                            <a href={"./pdfs/PrivacyPolicy.pdf"}
                               target={"_blank"}
                               rel={"noopener noreferrer"}>integritetspolicy</a> för ytterligare information.
                        </p>
                    </div>
                </div>
                <div className={"col-1"}/>
            </div>
        </div>
    </div>;
}

export function FrontPageAbout_desktop_fi(): React.ReactFragment {
    return <div
        className={"row aboutFooterRow"}>
        <div className={"container-fluid"}>
            <div className={"row pt-2 pb-3"}>
                <div className={"col-4"}/>
                <div className={"col-3"}>
                    <h5>LogEze</h5>
                    <ul>
                        <li>Seuraa edistymistäsi</li>
                        <li>Visualisoi ja analysoi tilastosi</li>
                        <li>Vertaa tuloksiasi muihin</li>
                        <li>Paranna taitojasi</li>
                    </ul>
                    <h5>Käyttää LogEze!</h5>
                </div>
                <div className={"col-2"}/>
                <div className={"col-2"}>
                    {companyInformation_com()}
                </div>
            </div>
            <div className={"row pt-2 pb-3"}>
                <div className={"col-1"}/>
                <div className={"col-10"}>
                    <div className={"text-center"}>
                        <p className={"smallprint"}>
                            Tämä sivu EI käytä evästeitä tai seurantaohjelmia. Mikään kolmas osapuoli ei
                            voi nähdä tai ostaa mitään tietoja tämän sivun käytöstäsi. Katso lisätietoja
                            käyttäjäkäytännöstämme.
                        </p>
                    </div>
                </div>
                <div className={"col-1"}/>
            </div>
        </div>
    </div>;
}


export function FrontPageAbout_desktop_com(): React.ReactFragment {
    return <div
        className={"row aboutFooterRow"}>
        <div className={"container-fluid"}>
            <div className={"row pt-2 pb-3"}>
                <div className={"col-4"}/>
                <div className={"col-3"}>
                    <h5>LogEze</h5>
                    <ul>
                        <li>Track your progress</li>
                        <li>Visualize and analyze your statistics</li>
                        <li>Compare your results with others</li>
                        <li>Improve your skills</li>
                    </ul>
                    <h5>Use LogEze!</h5>
                </div>
                <div className={"col-2"}/>
                <div className={"col-2"}>
                    {companyInformation_com()}
                </div>
            </div>
            <div className={"row pt-2 pb-3"}>
                <div className={"col-1"}/>
                <div className={"col-10"}>
                    <div className={"text-center"}>
                        <p className={"smallprint"}>
                            This page does NOT use any cookies or trackers. No third party can see
                            or buy any information regarding your use of this page. See our user policy for further
                            information.
                        </p>
                    </div>
                </div>
                <div className={"col-1"}/>
            </div>
        </div>
    </div>;
}

export function FrontPageAbout_mobile_se(): React.ReactFragment {
    return <div className={"row aboutFooterRow"}>
        <div className={"container-fluid"}>
            <div className={"row pt-2 pb-3"}>
                <div className={"col-1"}/>
                <div className={"col-10"}>
                    <h5>LogEze</h5>
                    <ul>
                        <li>Följ din utveckling</li>
                        <li>Visualisera och analysera din statistik</li>
                        <li>Jämför dina resultat med andra</li>
                        <li>Utveckla dina färdigheter</li>
                    </ul>
                    <h5>Använd LogEze!</h5>
                </div>
                <div className={"col-1"}/>
            </div>
            <div className={"row pt-2 pb-3"}>
                <div className={"col-1"}/>
                <div className={"col-10"}>
                    {companyInformation_se()}
                </div>
                <div className={"col-1"}/>
            </div>
            <div className={"row"}>
                <div className={"col-1"}/>
                <div className={"col"}>
                    <p className={"smallprint"}>Denna sida och tjänst använder INGA kakor och INGA spårningstekniker.
                        Ingen tredje part kan se eller får köpa någon information om ditt besök. Se vår
                        <a href={"./pdfs/PrivacyPolicy.pdf"}
                           target={"_blank"}
                           rel={"noopener noreferrer"}>integritetspolicy</a> för ytterligare information.</p>
                </div>
                <div className={"col-1"}/>
            </div>
        </div>
    </div>;
}

export function FrontPageAbout_mobile_fi(): React.ReactFragment {
    return <div className={"row aboutFooterRow"}>
        <div className={"container-fluid"}>
            <div className={"row pt-2 pb-3"}>
                <div className={"col-1"}/>
                <div className={"col-10"}>
                    <h5>LogEze</h5>
                    <ul>
                        <li>Seuraa edistymistäsi</li>
                        <li>Visualisoi ja analysoi tilastosi</li>
                        <li>Vertaa tuloksiasi muihin</li>
                        <li>Paranna taitojasi</li>
                    </ul>
                    <h5>Käyttää LogEze!</h5>
                </div>
                <div className={"col-1"}/>
            </div>
            <div className={"row pt-2 pb-3"}>
                <div className={"col-1"}/>
                <div className={"col-10"}>
                    {companyInformation_se()}
                </div>
                <div className={"col-1"}/>
            </div>
            <div className={"row"}>
                <div className={"col-1"}/>
                <div className={"col"}>
                    <p className={"smallprint"}>
                        Tämä sivu EI käytä evästeitä tai seurantaohjelmia. Mikään kolmas osapuoli ei
                        voi nähdä tai ostaa mitään tietoja tämän sivun käytöstäsi. Katso lisätietoja
                        käyttäjäkäytännöstämme.
                    </p>
                </div>
                <div className={"col-1"}/>
            </div>
        </div>
    </div>;
}

export function FrontPageAbout_mobile_com(): React.ReactFragment {
    return <div className={"row aboutFooterRow"}>
        <div className={"container-fluid"}>
            <div className={"row pt-2 pb-3"}>
                <div className={"col-1"}/>
                <div className={"col-10"}>
                    <h5>LogEze</h5>
                    <ul>
                        <li>Track your progress</li>
                        <li>Visualize and analyze your statistics</li>
                        <li>Compare your results with others</li>
                        <li>Improve your skills</li>
                    </ul>
                    <h5>Use LogEze!</h5>
                </div>
                <div className={"col-1"}/>
            </div>
            <div className={"row pt-2 pb-3"}>
                <div className={"col-1"}/>
                <div className={"col-10"}>
                    {companyInformation_com()}
                </div>
                <div className={"col-1"}/>
            </div>
            <div className={"row"}>
                <div className={"col-1"}/>
                <div className={"col"}>
                    <p className={"smallprint"}>This page does NOT use any cookies or trackers. No third party can see
                        or buy any information regarding your use of this page. See our user policy for further
                        information.</p>
                </div>
            </div>
        </div>
    </div>;
}

function companyInformation_se(): React.ReactFragment {
    return <div>
        <h5>Företaget</h5>
        <p className={"mb-0"}>Fitymi AB</p>
        <p className={"mb-0"}>Bråvallagatan 6</p>
        <p className={"mb-0"}>113 36 Stockholm</p>
        <p className={"mb-0"}>Sweden</p>
        <p className={"mb-0"}>info@fitymi.eu</p>
        <p className={"mb-0"}>Org nr 559176-4682</p>

        <a id={"sign-up-link-id"}
           href={"/signup"}>
            Skapa ett nytt konto
        </a>

    </div>;
}

function companyInformation_com(): React.ReactFragment {
    return <div>
        <h5>The company</h5>
        <p className={"mb-0"}>Fitymi AB</p>
        <p className={"mb-0"}>Bråvallagatan 6</p>
        <p className={"mb-0"}>113 36 Stockholm</p>
        <p className={"mb-0"}>Sweden</p>
        <p className={"mb-0"}>info@fitymi.eu</p>
        <p className={"mb-0"}>Org nr 559176-4682</p>

        <a id={"sign-up-link-id"}
           href={"/signup"}>
            Create a new account
        </a>

    </div>;
}
