import React, {ReactElement} from "react";
import SearchItems from "./SearchItems";
import {Right} from "./Right";
import {Selectable} from "./Selector";
import {fetchObjectOf} from "../../../../infra/BackendService";
import ManageRights from "./ManageRights";
import CreateRightForm from "./CreateRightForm";

interface props {
}

interface state {
    selectedRight: Right | undefined,
    showCreateForm: boolean
}

class SearchRightsV3 extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            selectedRight: undefined,
            showCreateForm: false,
        }
    }

    render(): ReactElement {
        const selectedRight = this.state.selectedRight;

        let manageRights = <div/>;

        if (selectedRight !== undefined) {
            manageRights = <ManageRights right={selectedRight}/>;
        }
        const showCreateForm = this.state.showCreateForm;

        const createForm = showCreateForm ? <div>
            <div aria-label={"create rights"} className={"mt-4"}>
                <CreateRightForm/>
            </div>
        </div> : <div/>

        return <div>
            <div className={"row"}>
                <div className={"col-3"}>
                    <div aria-label={"search users"}>
                        <SearchItems selectItem={this.selectedRight()} type={"right"}/>
                    </div>
                    {createForm}
                </div>
                <div className={"col-9"}>
                    {manageRights}
                </div>
            </div>
        </div>
    }

    private selectedRight() {
        return (selectRight: Selectable | undefined) => {
            if (selectRight !== undefined) {
                fetchObjectOf<Right>("/api/v3/backoffice/right/" + selectRight.id).then((right: Right | undefined) => {
                    this.setState({selectedRight: right})
                })
            } else {
                this.setState({selectedRight: undefined})
            }

        };
    }

}

export default SearchRightsV3;
