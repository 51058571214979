import React, {ReactElement} from "react";
import TranslationService from "../../../infra/TranslationService";
import {CountersignActions} from "./CountersignForm";

interface props {
    countersignActions: CountersignActions
}

interface state {
}

class CounterSignFormHeader extends React.Component<props, state> {
    render(): ReactElement {
        const countersignActions = this.props.countersignActions;
        const countersign: string = TranslationService.translation("countersign");

        const action: string = TranslationService.translation(countersignActions.action);
        const user: string = countersignActions.user.name;
        const date: string = countersignActions.date;
        const on: string = TranslationService.translation("ondate");
        const forUser: string = TranslationService.translation("forUser");

        const headline = <h2 aria-label={"countersign-headline"}>
            {countersign} {action} {forUser} {user} {on} {date}
        </h2>;

        let kvaCodeLine;
        let kvaCode = countersignActions.kvaCode;
        if (kvaCode !== undefined) {
            kvaCodeLine = <h2 aria-label={"kva-code"}>{kvaCode}</h2>
        } else {
            kvaCodeLine = <div/>
        }
        let deliveryParticipationLine = <div/>;
        let studentWantsToReflect = <div/>
        let deliveryParticipation = countersignActions.deliveryParticipation;
        let beforeOrAfterParticipation = countersignActions.beforeOrAfterParticipation;
        if (deliveryParticipation !== undefined && beforeOrAfterParticipation !== undefined) {
            deliveryParticipationLine =
                <div aria-label={"deliveryParticipation"}>Studenten har {beforeOrAfterParticipation} passningar
                    och {deliveryParticipation} förlossningar.</div>
        }
        if (countersignActions.studentWantsToReflect) {
            studentWantsToReflect =
                <div
                    aria-label={"studentWantsToReflect"}>{TranslationService.translation("delivery.countersign.student.reflect")}</div>
        }

        return <div className={"container"}>
            <div className={"row"}>
                {headline}
            </div>
            <div className={"row"}>
                <hr/>
            </div>
            <div className={"row"}>
                {kvaCodeLine}
            </div>
            <div className={"row"}>
                {deliveryParticipationLine}
            </div>
            <div className={"row"}>
                {studentWantsToReflect}
            </div>
        </div>;
    }
}

export default CounterSignFormHeader;