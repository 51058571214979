import React from 'react';
import {Binder, BinderId, Drug, DrugId} from "./models/Models";
import "./FullScreenModal.css"
import {BackendContext} from "../../infra/BackendContext";
import {Organisation} from "../model/Organisation";
import {EventBackendService} from "../register/v2/EventBackendService";
import TranslationService from "../../infra/TranslationService";

interface props {
    binder: Binder | undefined
    flipModal: () => void;
    organisation: Organisation
    update: () => void;
    requireWitness: boolean;
}

interface state {
    drugs: Drug[],
    valueMap: Map<DrugId, { amount: string, newCount: string }>;
    comment: string | undefined,
    showComment: boolean,
    witness?: string
    witnessWarning?: boolean
}

class FullScreenModal extends React.Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {
            drugs: [],
            valueMap: new Map(),
            comment: undefined,
            showComment: false,
            witness: ""
        }
    }

    async componentDidMount() {
        const drugsPath: string = `/api/v1/knarkbok/books/binder/${this.props.binder?.id.id}`
        const drugUrl: string = EventBackendService.getUrl2(drugsPath);
        const drugsResponse: any = await this.context.get(drugUrl, {
            success: "",
            failure: ""
        }) as any;

        if (drugsResponse !== undefined && drugsResponse.data !== undefined) {
            this.setState({
                drugs: drugsResponse.data.map((o: { drug: Drug }) => {
                    return o.drug
                }),
            });
        }

    }

    render(): React.JSX.Element {
        const {props, tr} = this;

        const commentFlip = <div
            className={"ml-2"}
            title="Lägg till kommentar">
            <button className={"btn btn-tiny-expand"}
                    onClick={(e) => this.flipComment(e)}>
                {this.state.showComment ? "-" : "+"}
            </button>
        </div>;

        const commentField = <div>
            <div className={"row m-0 pb-1 pt-2"}>
                <div className={"col m-0 p-0"}>
                    <div className={"row m-0"}>
                        Kommentar:
                        {commentFlip}
                    </div>
                </div>
            </div>
            {
                this.state.showComment ? <React.Fragment>
                    <div className={"row ml-0 pl-0 pr-3 mb-2"}>
                        <textarea value={this.state.comment}
                                  rows={3}
                                  className={"max-size-input"}
                                  onChange={(e) => this.handleChangeComment(e)}
                        />
                    </div>
                </React.Fragment> : <div/>
            }
        </div>

        let witness = <div></div>
        if (this.props.requireWitness) {
            let cName = "col "
            if (!!this.state.witnessWarning) {
                cName = cName + "witness-warning"
            }
            let witnessLabel = tr("Witness");
            witness = <div className={cName}>
                <div className={"row"}>{witnessLabel + ":"}</div>
                <div className={"row"}><input value={this.state.witness}
                                              aria-label={"witness-field"}
                                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateWitness(e)}
                                              type={"text"}/>
                </div>
            </div>
        }

        return (<div>
                <aside className="knarkbok-modal-background-fullscreen" onClick={props.flipModal}/>
                <div className={"knarkbok-modal-fullscreen"}>
                    <div className={"container p-0 m-0 justify-content-center"}>
                        <div aria-label={'headline'}
                             className={"row fullscreen-header-color"}>
                            <div className={"col-5"}>
                                <h5 className={"mt-0"}>
                                    Läkemedel
                                </h5>
                            </div>
                            <div className={"col-4"}>
                                <h5 className={"mt-0"}>
                                    Antal från apoteket
                                </h5>
                            </div>
                            <div className={"col-3 pl-1"}>
                                <h5 className={"mt-0"}>
                                    Nytt saldo
                                </h5>
                            </div>
                        </div>

                        <div className={"medicine-list pt-2"}>
                            {this.state.drugs.map((drug, i) => {
                                return <div key={drug.name + i} className={"row m-0 pb-2"}>
                                    <div className={"col-5 pl-0"}>{drug.name}</div>
                                    <div className={"col-4"}>
                                        <input value={this.getAmount(drug.id)}
                                               aria-label={drug.name + "_amount"}
                                               onChange={(e) => this.updateAmount(e, drug.id)}
                                               type={"text"}
                                               size={2}/>
                                    </div>
                                    <div className={"col-3"}>
                                        <input value={this.getCount(drug.id)}
                                               aria-label={drug.name + "_count"}
                                               onChange={(e) => this.updateCount(e, drug.id)}
                                               type={"text"}
                                               size={2}/>
                                    </div>
                                </div>
                            })}
                            {witness}
                            {commentField}
                            <div className={"row from-bottom-rel justify-content-end"}>
                                <button aria-label={'submit-button'}
                                        onClick={() => this.sendDelivery()}
                                        className={"btn btn-tage-default mr-3"}>
                                    OK
                                </button>
                                <button aria-label={'cancel'}
                                        onClick={props.flipModal}
                                        className={"btn btn-tage-default-cancel mr-3"}>
                                    Avbryt
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        );
    }

    private getAmount(id: DrugId | undefined): string {
        if (id) {
            let amount = this.state.valueMap.get(id);
            if (amount) {
                return "" + amount.amount;
            }
        }
        return "";
    }

    private updateAmount(e: React.ChangeEvent<HTMLInputElement>, id: DrugId | undefined) {
        const stripped = e.currentTarget.value.replace(/\D/g, '');
        if (id) {
            let valueMap = this.state.valueMap;

            let exists = valueMap.get(id);
            if (exists) {
                exists.amount = stripped;
            } else {
                valueMap.set(id, {amount: stripped, newCount: ""})
            }

            this.setState({valueMap: valueMap})
        }
    }

    private getCount(id: DrugId | undefined): string {
        if (id) {
            let count = this.state.valueMap.get(id);
            if (count) {
                return "" + count.newCount;
            }
        }
        return "";
    }

    private updateCount(e: React.ChangeEvent<HTMLInputElement>, id: DrugId | undefined) {
        const stripped = e.currentTarget.value.replace(/\D/g, '');
        if (id) {
            let valueMap: Map<DrugId, {
                amount: string;
                newCount: string
            }> = this.state.valueMap;

            let exists = valueMap.get(id);
            if (exists) {
                exists.newCount = stripped;
            } else {
                valueMap.set(id, {amount: "", newCount: stripped})
            }

            this.setState({valueMap: valueMap})
        }
    }

    private flipComment(e: React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        this.setState({showComment: !this.state.showComment})
    }

    private handleChangeComment(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({comment: e.currentTarget.value});
    }

    private async sendDelivery() {
        const {valueMap} = this.state;
        const binder = this.props.binder;
        let payload: {
            binderId: BinderId | undefined,
            organisationId: string,
            comment: string | undefined,
            witness: string | undefined
            drugs: {
                drugId: DrugId,
                amount: string,
                newBalance: string
            }[]
        } = {
            binderId: binder?.id,
            organisationId: this.props.organisation.organisationId,
            comment: this.state.comment,
            witness: this.state.witness,
            drugs: []
        }

        for (const entry of valueMap) {
            if (entry[1].amount && entry[1].newCount) {
                payload.drugs.push({
                    drugId: entry[0],
                    amount: entry[1].amount,
                    newBalance: entry[1].newCount
                })
            }
        }

        if (((!this.props.requireWitness || (this.props.requireWitness && this.state.witness)) && ((!this.partiallyFilled(valueMap))))) {
            const drugsPath: string = `/api/v1/knarkbok/top-up-drugs/`
            const drugUrl: string = EventBackendService.getUrl2(drugsPath);

            let topUpResponse = await this.context.post(drugUrl, JSON.stringify(payload), {
                success: "",
                failure: ""
            });

            if (topUpResponse.success) {
                this.props.update();
            }

            this.props.flipModal();
        }

        if (this.props.requireWitness && !this.state.witness) {
            this.setState({witnessWarning: true})
        }
    }

    private updateWitness(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({witness: e.currentTarget.value})
    }

    private tr(s: string): string {
        return TranslationService.translation(s);
    }

    private partiallyFilled(valueMap: Map<DrugId, { amount: string; newCount: string }>) {
        for (const entry of valueMap) {
            if ((!!entry[1].amount && !entry[1].newCount) || (!entry[1].amount && !!entry[1].newCount)) {
                return true;
            }
        }

        return false;
    }
}

export default FullScreenModal;
