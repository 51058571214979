export interface ActionTemplate {
    name: string;
    version: number;
    frontendVersion: string;
    type: ATType;
    fields: ATField[];
}

export interface Fieldd {
    name: string,
    value: string[]
    duplicationIndex?: number
}

export interface ATField {
    type: ATFieldType | any; //TODO strip any
    name: string
    checked?: boolean;
    options?: ATField[];
    cssRules?: string //this is a current test, probably wont make it live
    duplicable?: boolean //this will be mandatory(?)
    duplicationIndex?: number //mandatory aswell?
}

export enum ATFieldType {
    FIELD = "field",
    RADIO = "radio",
    CHECKBOX = "checkbox",
    TEXT = "text",
    OPTION = "option" //This is new (?) maybe needs to be split up
}

export enum ATType {
    REGISTER = "register",
    STATISTICS = "statistics"
}


function findall(state: Fieldd[], name: string): Fieldd[] {
    const returnVal: Fieldd[] = [];
    state.forEach((_state) => {
        if (_state.name === name) {
            returnVal.push(_state);
        }
    })
    return returnVal;
}

export function getHighestDuplication(fields: Fieldd[], name?: string): number { //name added as afterthought to help with render thing
    let highest = 0;

    fields.forEach((f) => {
        if (f.duplicationIndex && f.duplicationIndex > highest) {
            if (name) {
                if (f.name === name) {
                    highest = f.duplicationIndex;
                }
            } else {
                highest = f.duplicationIndex;
            }
        }
    })

    return highest;
}

function shouldBeDuplicated(option: ATField, state: Fieldd[], currentCount: number) {
    const all: Fieldd[] = findall(state, option.name);
    if (all && all.length > 0) {
        const max: number = getHighestDuplication(all);
        return max > currentCount;
    }
    return false;
}

function addDuplicationValueToChildren(field: ATField, currentCount: number): ATField {
    if (hasOptions(field)) {
        field.duplicationIndex = currentCount;
        field.options = field.options?.map((op) => addDuplicationValueToChildren(op, currentCount));
    } else {
        field.duplicationIndex = currentCount;
    }

    return field;
}

function radioHasGrandChildren(option: ATField, state: Fieldd[]): boolean {
    let grandChildren = false;

    if (hasOptions(option)) {
        option.options?.forEach((childOption: ATField) => {
            if (hasOptions(childOption) && valueIsSelectedAndRightDuplicationIndex(childOption, state)) {
                option.checked = true; //TODO this is a convenient place to have this, but logically should be elsewhere
                grandChildren = true;
            }
        })
    }

    return grandChildren;
}

function isAtom(option: ATField, state: Fieldd[]): boolean {
    switch (option.type) {
        case ATFieldType.RADIO :
            return !radioHasGrandChildren(option, state);
        case ATFieldType.FIELD :
            return false;
        default:
            return true;
    }
}

function isDuplicatedField(option: ATField, state: Fieldd[]): boolean {
    const exists = state.find((state) => state.duplicationIndex === option.duplicationIndex && state.name === option.name)

    return !!exists;

}


export class ActionPreProcessor {
    process(template: ActionTemplate, state: Fieldd[], latestSelected?: string): ActionTemplate {
        const copiedActionTemplate: ActionTemplate = JSON.parse(JSON.stringify(template));


        let filtered: ATField[] = this.theGreatFiltering(copiedActionTemplate.fields, state, latestSelected);

        copiedActionTemplate.fields = filtered;

        if (state && state.length < 1) {
            return copiedActionTemplate;
        }

        return copiedActionTemplate;
    }

    private theGreatFiltering(opFields: ATField[] | undefined, state: Fieldd[], latestSelected?: string, parentIndex?: number): ATField[] {
        let filteredOptions: ATField[] = [];
        if (opFields && opFields.length > 0) {

            opFields = opFields
                .map((option) => this.duplicateFields(option, state, latestSelected, parentIndex))
                .reduce((acc, it) => [...acc, ...it]);

            opFields.forEach((option: ATField) => {
                if (isAtom(option, state)) {
                    switch (option.type) {
                        case ATFieldType.RADIO :
                            const radio: ATField = this.handleRadios(option, state, latestSelected);
                            filteredOptions.push(radio)
                            return;
                        default:
                            filteredOptions.push(option);
                    }
                } else {
                    //why does this take "options" duplicationIndex? it's because we need to make sure that duplicateFields method (above) doesn't create children with higher duplication index than the parent. This could be solved in a better manner
                    option.options = this.theGreatFiltering(option.options, state, latestSelected, option.duplicationIndex);
                    filteredOptions.push(option);
                }
            })
        }
        return filteredOptions;
    }

    private duplicateFields(option: ATField, state: Fieldd[], latestSelected: string | undefined, parentIndex?: number): ATField[] {
        let currentCount = option.duplicationIndex || 0;
        const duplicated: ATField[] = [];
        //TODO latest selected css.
        let duplication = JSON.parse(JSON.stringify(option));

        duplication.duplicationIndex = currentCount;
        const addedToC = addDuplicationValueToChildren(duplication, currentCount);
        duplicated.push(addedToC)

        if (parentIndex !== undefined && parentIndex < currentCount + 1) { //since children also have duplication index this fucks up in some cases
            return duplicated;
        }

        while (shouldBeDuplicated(option, state, currentCount)) {
            currentCount++;
            let duplication2 = JSON.parse(JSON.stringify(option));
            const addedToChildren: ATField = addDuplicationValueToChildren(duplication2, currentCount);
            duplicated.push(addedToChildren);
        }

        return duplicated;
    }

    private handleRadios(option: ATField, state: Fieldd[], latestSelected: string | undefined): ATField {
        //This only receives atoms... render accordingly. (the atom part is important to understand)
        if (!valueIsSelectedAndRightDuplicationIndex(option, state) && !isDuplicatedField(option, state)) {
            option.options = [];
        } else {
            this.cullGrandChildren(option);
            this.checkSelectedChildren(option, state);
            option.checked = true;
        }
        if (option.name === latestSelected) { //TODO also if it has correct duplication Index (supply it in latest selected)
            option.options?.forEach((innerOption) => innerOption.cssRules = "newly-clicked");
            option.cssRules = "newly-clicked-parent"
        }

        return option;
    }

    private cullGrandChildren(option: ATField): ATField {
        option.options?.forEach((innerOption) => innerOption.options = [])
        return option;
    }

    private checkSelectedChildren(option: ATField, state: Fieldd[]) {
        option.options?.forEach((innerOption) => {
            if (valueIsSelectedAndRightDuplicationIndex(innerOption, state)) {
                innerOption.checked = true;
            }
        })
    }
}

function valueIsSelectedAndRightDuplicationIndex(option: ATField, state: Fieldd[]): boolean {
    let exists = false;
    if (!Number.isNaN(option.duplicationIndex)) {
        state.forEach((stateField) => {
            let valueEquals = stateField.value.find((valStr: string) => valStr === option.name)
            let duplicationEquals = stateField.duplicationIndex === option.duplicationIndex || stateField.duplicationIndex === undefined;
            //the undefined statefield dup index is to allow it to work on regular cases where dup index is not set
            if (valueEquals && duplicationEquals) {
                exists = true;
            }
        })
    } else {
        return !!valueShouldBeExpanded(option, state);
    }

    return exists;
}

/**
 * warning - currently only supports radio(?) .... bad name?
 * @param option
 * @param state
 */
function valueShouldBeExpanded(option: ATField, state: Fieldd[]) {
    //TODO this needs to take into account duplication index, maybe migrate to the one that takes this into account
    let contains = state.find((value) => {
        return value.value.find((valStr: string) => valStr === option.name)
    })

    return contains;
}

export function hasOptions(option: ATField): boolean {
    if (option.options) {
        return option.options.length > 0
    }
    return false;
}
