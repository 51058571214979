import React, {ChangeEvent, ReactElement} from 'react';
import {FieldComponent, FieldError} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import Value from "../../register/v2/Value";
import "./NumberField.css";
import {
    createSingleValue,
    getValid,
    invalidNumber,
    missingMandatoryField,
    tooLargeNumber,
    tooManyDecimals,
    tooSmall
} from "./FieldUtil";
import {cleanNumberInput, NumberValidationResult, validateNumber} from "../v1/validators/NumberValidator";
import ConditionalField, {ConditionalProps, ConditionalState} from "./ConditionalField";
import {V3} from "../../../infra/Constants";
import {Field} from "../../register/v2/Action";

interface props extends ConditionalProps {
    frontendVersion?: string,
    value?: string,
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
    cssId?: string
}

interface state extends ConditionalState {
    value: string,
    errorMessage: string
}

class NumberField extends ConditionalField<props, state> implements FieldComponent {
    constructor(props: Readonly<props>) {
        super(props);
        let defaultValue: string = "";
        if (this.props.field.defaultValue !== undefined) {
            defaultValue = this.props.field.defaultValue;
        }

        this.state = {
            ...this.state,
            value: defaultValue,
            errorMessage: ""
        }
    }

    render(): ReactElement {
        const name = this.props.field.name;
        const label: string = TranslationService.translation(name);
        let value = this.getValue();
        const onChange = (e: ChangeEvent<HTMLInputElement>) => this.onChange(e);
        let showLabel = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }
        const errorMessage = this.state.errorMessage;

        let size: number;
        if (this.props.field.fieldSize) {
            size = this.props.field.fieldSize;
        } else {
            size = 9;
        }

        const field: React.ReactFragment = this.getField(label, showLabel, name, value, size, errorMessage, onChange);

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            return <div>
                {field}
            </div>
        } else {
            return this.renderField(field, name);
        }
    }

    private getValue(): string {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            let value = this.props.value;
            if (value !== undefined) {
                return value;
            } else {
                return '';
            }
        } else {
            return this.state.value;
        }
    }

    private getField(labelText: string,
                     showLabel: boolean,
                     name: string,
                     value: string,
                     size: number,
                     errorMessage: string,
                     onChange: (e: React.ChangeEvent<HTMLInputElement>) => void): React.ReactFragment {
        const label = <h5>
            <div className={"row"}>
                <div className={"col"}>
                    <label htmlFor={name}>{labelText}</label>
                </div>
            </div>
        </h5>;

        let errorMessageClass;
        if (errorMessage.length > 0) {
            errorMessageClass = "invalidNumberMessage";
        } else {
            errorMessageClass = "none";
        }

        const field = <div className={"row invalidNumber"} onClick={() => this.allowOverflowInParent()}>
            <div className={"col"}>
                <input type={"text"}
                       size={size}
                       id={name}
                       data-testid={name + ".id"}
                       aria-label={name}
                       value={value}
                       onChange={onChange}
                />
                <div className={errorMessageClass}>
                    {errorMessage}
                </div>
            </div>
        </div>;

        if (!showLabel) {
            return <div>
                {field}
            </div>;
        }

        return <div>
            {label}
            {field}
        </div>;
    }

    private onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const min: number = this.getMin();
        const max: number = this.getMax();
        const decimalPlaces: number = this.getDecimalPlaces();
        let currentValue: string = e.currentTarget.value;
        currentValue = cleanNumberInput(currentValue, min, decimalPlaces);

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            if (this.props.onChange) {
                let field = this.props.field;
                const name: string = field.name;
                let duplicationIndex: string = '0';
                if (this.props.duplicationIndex !== undefined) {
                    duplicationIndex = this.props.duplicationIndex;
                }
                let valid = true;
                if (field.mandatory !== undefined && field.mandatory) {
                    if (currentValue === undefined || currentValue === '') {
                        valid = false;
                    }
                }
                if (valid) {
                    const validationResult: NumberValidationResult = validateNumber(currentValue, min, max, decimalPlaces);
                    valid = validationResult.valid;
                    if (valid) {
                        this.setState({errorMessage: ""});
                    } else {
                        const errorMessage = validationResult.translatedMessage;
                        this.setState({errorMessage: errorMessage});
                    }
                }

                this.props.onChange(name, currentValue, duplicationIndex, valid, field);
            }
        } else {
            const validationResult: NumberValidationResult = validateNumber(currentValue, min, max, decimalPlaces);

            if (validationResult.valid) {
                this.setState({value: currentValue, errorMessage: ""});
            } else {
                const errorMessage = validationResult.translatedMessage;
                this.setState({value: currentValue, errorMessage: errorMessage});
            }
        }
    }

    values(): Value[] {
        const name = this.props.field.name;
        const value = this.state.value;
        if (this.isValid().valid) {
            if (value !== "") {
                return createSingleValue(name, value);
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    private getMandatory() {
        let mandatory: boolean = false;
        if (this.props.field.mandatory !== undefined) {
            mandatory = this.props.field.mandatory;
        }
        return mandatory;
    }

    private getMin() {
        let min: number = Number.MIN_SAFE_INTEGER
        if (this.props.field.min !== undefined) {
            min = this.props.field.min;
        }

        return min;
    }

    private getMax() {
        let max: number = Number.MAX_SAFE_INTEGER;
        if (this.props.field.max !== undefined) {
            max = this.props.field.max;
        }
        return max;
    }

    private getDecimalPlaces() {
        let decimalPlaces: number = Number.MAX_SAFE_INTEGER;
        if (this.props.field.decimalPlaces !== undefined) {
            decimalPlaces = this.props.field.decimalPlaces;
        }
        return decimalPlaces;
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        const value = this.state.value;
        const mandatory = this.getMandatory();
        const min: number = this.getMin();
        const max = this.getMax();
        const decimalPlaces = this.getDecimalPlaces();

        if (mandatory) {
            const valid = value !== "";
            if (valid) {
                const validationResult: NumberValidationResult = validateNumber(value, min, max, decimalPlaces);
                if (validationResult.valid) {
                    return getValid(name);
                } else {
                    return invalidNumberError(validationResult);
                }
            } else {
                return getValid(name, missingMandatoryField);
            }
        }

        const validationResult: NumberValidationResult = validateNumber(value, min, max, decimalPlaces);
        if (validationResult.valid) {
            return getValid(name);
        } else {
            return invalidNumberError(validationResult);
        }

        function invalidNumberError(validationResult: NumberValidationResult) {
            const message = validationResult.message;

            if (message === "number input field decimalPlaces") {
                return getValid(name, tooManyDecimals);
            }

            if (message === "number input field min") {
                return getValid(name, tooSmall);
            }

            if (message === "number input field max") {
                return getValid(name, tooLargeNumber);
            }

            return getValid(name, invalidNumber);
        }
    }

    clear(): void {
        let visible = this.isConditionalfield();
        let defaultValue: string = "";
        if (this.props.field.defaultValue !== undefined) {
            defaultValue = this.props.field.defaultValue;
        }

        this.setState({
            value: defaultValue,
            visible: visible
        })
    }

    set(values: Value[]): void {
        let name = this.props.field.name;
        values.forEach((value: Value) => {
            if (value.fieldName === name) {
                const newValue: string = value.values[0];
                this.setState({value: newValue});
            }
        });
    }

    private allowOverflowInParent() {
        //this is needed to show the warning balloon (root cause is the expanding wrapper)
        const css = this.props.cssId
        if (css) {
            const wanted = document.getElementById(css)
            wanted?.classList.add("allowOverflow")
        }
    }
}

export default NumberField;
