import React from "react";
import TranslationService from "../../../infra/TranslationService";
import "./StatisticsNumberFields.css"
import {Field} from "../../register/v2/Action";
import {FilterValue, FilterValueNumber, FilterValueType} from "./FilterValue";
import {uuidv4} from "../../register/v2/Uuid";

export const max_boundary = `fitymi_max_boundary`;
export const min_boundary = `fitymi_min_boundary`;

interface props {
    field: Field
    onChange: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
    onValueChangeNG: (filterValue: FilterValue) => void;
}

interface state {
    upperBound: number | undefined
    lowerBound: number | undefined
    warningText: string
    minKey: string,
    maxKey: string
}

class StatisticsNumberField extends React.Component<props, state> {
    constructor(props: Readonly<props>) {
        super(props);

        this.state = {
            lowerBound: undefined,
            upperBound: undefined,
            warningText: "",
            minKey: uuidv4(),
            maxKey: uuidv4()
        }
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        const {lowerBound, upperBound} = this.state;
        if (prevState.lowerBound && lowerBound === undefined) {
            //values erased, set new key to reset input and prevent dangling values
            this.setState({minKey: uuidv4()})
        }

        if (prevState.upperBound && upperBound === undefined) {
            //values erased, set new key to reset input and prevent dangling values
            this.setState({maxKey: uuidv4()})
        }
    }

    render() {
        const state = this.state;
        const {min, max} = this.props.field;

        return <div className={"container m-0 p-0"}>
            <div className={"col m-0 p-0"}>
                <div className={"row m-0 pt-2"}>
                    <div className={"col m-0 p-0"}>
                        {TranslationService.translation(this.props.field.name) + ":"}
                    </div>
                </div>
                <div className={"row m-0 pt-1"}>
                    <input key={state.minKey}
                           aria-label={this.props.field.name + ".fitymi_min_boundary"}
                           type={"text"} size={3}
                           placeholder={min ? min + "" : 0 + ""}
                           value={state.lowerBound || ""}
                           onChange={(e) => this.updateLowerBound(e)}/>
                    <div
                        className={"pt-1 pr-2 middle-explanation-box"}>{'≤'} x {'≤'}</div>
                    <input key={state.maxKey}
                           type={"text"}
                           placeholder={max ? max + "" : 0 + ""}
                           size={3}
                           value={state.upperBound || ""}
                           onChange={(e) => this.updateUpperBound(e)}/>
                </div>
            </div>
        </div>;
    }

    private updateProps() {
        const {lowerBound, upperBound} = this.state;
        const {onValueChangeNG, field} = this.props;

        const filterValue: FilterValueNumber = {
            fieldName: field.name,
            type: FilterValueType.Number,
            min: lowerBound,
            max: upperBound,
            erase: this.erase
        };

        onValueChangeNG(filterValue);
    }

    private async updateLowerBound(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = +e.currentTarget.value;
        this.setState({lowerBound: newValue}, () => this.updateProps());
    }

    private async updateUpperBound(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = +e.currentTarget.value;
        this.setState({upperBound: newValue}, () => this.updateProps());
    }

    erase = () => {
        this.setState({
            lowerBound: undefined,
            upperBound: undefined,
            warningText: "",
        });
    }
}

export default StatisticsNumberField;
