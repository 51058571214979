import React, {Component} from 'react';
import * as d3 from "d3";

let c3;

const pie = {
    label: {
        format: function (value, ratio, id) {
            ratio = ratio.toFixed(3);
            return value + ' (' + (d3.format('.1%')(ratio)) + ')';
        }
    }
};

const spline = {
    interpolation: {
        type: "linear"
    }
}

const point = {
    show: false
}

class C3Wrapper extends Component {
    componentDidMount() {
        c3 = require('c3');
        this.initChart();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            //does this make sense? investigate, dont we just always init the chart?
            if (this.props.graphType !== prevProps.graphType) {
                this.initChart();
            } else {
                this.initChart();
            }
        }
    };

    initChart() {
        let percentageFormatting = function (d) {
            return d + "%"
        };
        try {
            if (this.props.data.type === "spline") {
                this.props.axis.y.tick = {
                    format: percentageFormatting
                }
                this.props.axis.y.max = 100;
                this.props.axis.y.min = 0;
                this.props.axis.y.padding = {
                    top: 0,
                    bottom: 0
                };
            }
        } catch (e) {
            console.log(e);
        }

        const hl = this.props.highlightedBar;

        this.localC3 = c3.generate({
            bindto: '#' + this.props.id,
            data: {
                ...this.props.data,
                color: function (color, d) {
                    if (hl || hl === 0) { //only use this coloring rule if highlighted really is set in props
                        return d.index === hl ? "red" : color
                    } else
                        return color;
                }
            },
            axis: this.props.axis,
            pie: pie,
            spline: spline,
            point: point,
            bar: this.props.bar,
            legend: this.props.legend ? this.props.legend : null,
        });

        if (this.props.height && this.props.width) {
            this.resize(this.props.height, this.props.width)
        }
    }

    resize(height, width) {
        if (this.localC3 !== undefined) {
            this.localC3.resize({height: height, width: width});
        }
    }

    updateChart() {
        if (this.localC3 !== undefined) {
            this.localC3.load({
                unload: true,
                type: this.props.data.type,
                columns: this.props.data.columns,
                names: this.props.data.names,
                colors: this.props.data.colors,
                bar: this.props.bar
            });
        }
    }

    render() {
        return (
            <div id={this.props.id} className={this.props.className || ""}/>
        );
    }
}

export default C3Wrapper;

