import React, {ReactElement, ReactNode} from "react";
import "./Selector.css";

export interface Selectable {
    id: string,
    name: string,
    canBeDeleted?: boolean
}

interface props {
    assigned: Selectable[],
    available: Selectable[],
    type: string,
    assign: (assigned: Selectable) => void,
    remove: (assigned: Selectable) => void
}

interface state {
    selected: Selectable | undefined
}

class Selector extends React.Component<props, state> {
    constructor(props: Readonly<props>) {
        super(props);
        this.state = {selected: undefined}
    }

    render(): ReactNode {
        const type = this.props.type;

        const assigned = this.props.assigned;
        const assignedElements: ReactElement[] = this.getElements(assigned, type + " assignedElements");

        const removeButton = <button aria-label={type + "-remove-button"}
                                   onClick={() => this.remove()}>
            {"->"}
        </button>;

        const assignButton = <button aria-label={type + "-assign-button"}
                                   onClick={() => this.assign()}>
            {"<-"}
        </button>;

        const available = this.props.available;
        const availableElements: ReactElement[] = this.getElements(available, type + " availableElements");

        return <div>
            <div className={"row mt-3"}>
                <h3>{type}</h3>
            </div>
            <div className={"row"}>
                <div className={"col-5"}>
                    {assignedElements}
                </div>
                <div className={"col-2"}>
                    {removeButton}
                    {assignButton}
                </div>
                <div className={"col-5"}>
                    {availableElements}
                </div>
            </div>
        </div>
    }

    private getElements(elements: Selectable[], type: string): ReactElement[] {
        return elements.map((value: Selectable, index: number) => {
            let className = Selector.getClassName("", index);
            if (value === this.state.selected) {
                className = "selected";
            }

            return <div key={index + type}
                        onClick={() => this.select(value)}
                        className={className}>
                {value.name}
            </div>
        });
    }

    private assign() {
        const selected = this.state.selected;
        const assign = this.props.assign;
        if (selected !== undefined) {
            assign(selected);
        }
    }

    private remove() {
        const selected = this.state.selected;
        const remove = this.props.remove;
        if (selected !== undefined) {
            remove(selected);
        }
    }

    private select(value: Selectable) {
        this.setState({selected: value});
    }

    private static getClassName(src: string, index: number) {
        const rowNumber = index + 1;
        if (rowNumber % 2 === 0) {
            return src + " grayed";
        }

        return src;
    }
}

export default Selector;
