import React, {ReactElement} from "react";
import {Organisation} from "../../../../model/Organisation";
import {BackendContext, BackendInterface, Result} from "../../../../../infra/BackendContext";

interface Org {
    organisationId: string,
    organisationName: string,
    organisationDescription: string,
    indentation: number,
    parentId?: string,
    parentName?: string,
}

interface props {
}

interface state {
    organisations: Organisation[]
}

class OrganisationHierarchies extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: props) {
        super(props);

        this.state = {
            organisations: []
        }
    }

    render(): ReactElement {
        const organisations: React.JSX.Element = this.getOrganisations();

        return <div>
            <h1>Manage Organisation Hierarchies</h1>
            <div>
                {organisations}
            </div>
        </div>;
    }

    componentDidMount() {
        this.getOrganisationHierarchy();
    }

    private getOrganisations(): React.JSX.Element {
        const organisations: Organisation[] = this.state.organisations;
        if (organisations.length === 0) {
            return <div/>;
        }
        const flatted: Org[] = this.flatten(organisations);
        const withParentNames: Org[] = this.addParetNames(flatted);
        const rows: React.JSX.Element[] = this.getOrganisationRows(withParentNames);

        return <div>
            <div className={'row'}>
                <div className={'col-4'}>
                    Organisations
                </div>
                <div className={'col-8'}>
                    Current parents - select a new parent to move the organisation
                </div>
            </div>
            {rows}
        </div>;
    }

    private getOrganisationRows(withParentNames: Org[]): React.JSX.Element[] {
        let uniquekey: number = 0;

        return withParentNames.map((organisation: Org, index: number) => {
            let horisontalLine: React.JSX.Element = <div/>
            if (organisation.indentation === 0) {
                horisontalLine = <hr/>
            }
            const indentation = organisation.indentation * 25;

            let className: string = 'row grayed';
            if (index % 2 === 0) {
                className = 'row';
            }

            const organisationId: string = organisation.organisationId;

            const noOrganisation: React.JSX.Element = <option key={'' + uniquekey++}
                                                              value={undefined}>
                Root organisation
            </option>;

            const options: React.JSX.Element[] = withParentNames
                .filter((candidate: Org) => organisation.organisationId !== candidate.organisationId)
                .map((org) => {
                    let optionName: string = org.parentName + ' - ' + org.organisationName;
                    if (org.parentId === undefined) {
                        optionName = org.organisationName;
                    }

                    return <option key={'' + uniquekey++}
                                   value={org.organisationId}>
                        {optionName}
                    </option>;
                });

            let ariaLabel: string = organisation.parentName + ' - ' + organisation.organisationName;
            if (organisation.parentId === undefined) {
                ariaLabel = organisation.organisationName;
            }

            return <div key={'' + uniquekey++}>
                {horisontalLine}
                <div>
                    <div className={className}>
                        <div className={'col-4'}>
                            <div style={{marginLeft: indentation}}>
                                {organisation.organisationName}
                            </div>
                        </div>
                        <div className={'col-8'}>
                            <select name="parent"
                                    aria-label={ariaLabel}
                                    defaultValue={organisation.parentId}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.selectNewParent(organisationId, e.currentTarget.value)}
                            >
                                {noOrganisation}
                                {options}
                            </select>
                        </div>
                    </div>
                </div>
            </div>;
        });
    }

    private flatten(organisations: Organisation[]): Org[] {
        const flatted: Org[] = [];
        for (let organisation of organisations) {
            const indentation = 0;
            let bar: Org[] = this.flattenAndIndent(organisation, [], indentation, undefined);
            flatted.push(...bar);
        }

        return flatted;
    }

    private flattenAndIndent(organisation: Organisation, organisations: Org[], indentation: number, parentId?: string): Org[] {
        const o: Org = {
            organisationId: organisation.organisationId,
            organisationName: organisation.organisationName,
            organisationDescription: organisation.organisationDescription,
            indentation: indentation,
            parentId: parentId,
        };
        organisations.push(o);

        if (organisation.children.length !== 0) {
            for (const item of organisation.children) {
                const parentId1 = organisation.organisationId;
                const newIndentation = indentation + 1;

                this.flattenAndIndent(item, organisations, newIndentation, parentId1);
            }
        }

        return organisations;
    }

    private addParetNames(source: Org[]): Org[] {
        const lookupMap: Map<string, Org> = new Map<string, Org>();
        for (const item of source) {
            lookupMap.set(item.organisationId, item);
        }

        for (const item of source) {
            let completeName: string = '';

            let parentId: string | undefined = item.parentId;
            while (parentId !== undefined) {
                let parent: Org | undefined = lookupMap.get(parentId);
                if (parent !== undefined) {
                    if (completeName.length === 0) {
                        completeName = parent.organisationName + completeName;
                    } else {
                        completeName = parent.organisationName + ' - ' + completeName;
                    }

                    parentId = parent.parentId;
                }
            }

            item.parentName = completeName;
        }

        return source;
    }

    private selectNewParent(organisationId: string, newParent: string) {
        const url: string = '/api/v3/backoffice/organisation/change/parent'
        const payload: {
            organisationId: string,
            newParentId: string,
        } = {
            organisationId: organisationId,
            newParentId: newParent
        };
        let json = JSON.stringify(payload);
        const response: Promise<Result> = this.context.post(url, json);
        response.then(() => {
            this.getOrganisationHierarchy();
        });
    }

    private getOrganisationHierarchy() {
        const url: string = '/api/v3/backoffice/organisation/organisations/all'
        const response: Promise<Result> = this.context.get(url);
        response.then((result: Result) => {
            const organisations: Organisation[] = result.data as Organisation[];

            this.setState({
                organisations: organisations
            });
        });
    }
}

export default OrganisationHierarchies;
