import React, {ChangeEvent, ReactElement} from 'react';
import {FieldComponent, FieldError, FieldProps} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import Value from "../../register/v2/Value";
import {createSingleValue, getValid, invalidEmail, missingMandatoryField} from "./FieldUtil";
import {V3} from "../../../infra/Constants";
import {Field} from "../../register/v2/Action";

export interface emailFieldProps extends FieldProps {
    frontendVersion?: string,
    value?: string,
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
}

export interface emailFieldState {
    value: string,
    errorMessage: string
}

export function validateEmail(value: string): boolean {
    if (value.includes("@")) {
        let emailParts = value.split("@");

        const localPart = emailParts[0];
        if (localPart.length === 0) {
            return false;
        }

        const domain = emailParts[1];
        if (domain.length < 3) {
            return false;
        }

        const domainHasTwoParts = domain.includes(".");
        const domainNotEndingWithDot = !domain.endsWith(".");

        return domainHasTwoParts && domainNotEndingWithDot;
    }

    return false;
}

export class EmailField<T extends emailFieldProps, S extends emailFieldState> extends React.Component<T, S> implements FieldComponent {
    constructor(props: T) {
        super(props);
        this.state = {
            ...this.state,
            value: "",
            errorMessage: ""
        }
    }

    render(): ReactElement {
        const name = this.props.field.name;
        const labelText: string = TranslationService.translation(name);
        const value = this.getValue();

        let showLabel: boolean = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        let fieldSize = 45;
        if (this.props.field.fieldSize !== undefined) {
            fieldSize = this.props.field.fieldSize;
        }


        const frontendVersion = this.props.frontendVersion;
        let field: React.ReactFragment;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            const errorMessage = this.state.errorMessage;
            const onChange = (e: ChangeEvent<HTMLInputElement>) => this.onChange(e);
            field = EmailField.getFieldNG(labelText, showLabel, name, value, fieldSize, errorMessage, onChange);

        } else {
            const onChange = (e: ChangeEvent<HTMLInputElement>) => this.setState({value: e.currentTarget.value});
            field = EmailField.getField(labelText, showLabel, name, value, fieldSize, onChange);
        }


        return <div>
            {field}
        </div>
    }


    private static getFieldNG(labelText: string,
                              showLabel: boolean,
                              name: string,
                              value: string,
                              fieldSize: number,
                              errorMessage: string,
                              onChange: (e: React.ChangeEvent<HTMLInputElement>) => void) {
        const label = <h5>
            <div className={"row"}>
                <div className={"col"}>
                    <label htmlFor={name}>{labelText}</label>
                </div>
            </div>
        </h5>;

        let errorMessageClass;
        if (errorMessage.length > 0) {
            errorMessageClass = "invalidFieldMessage";
        } else {
            errorMessageClass = "none";
        }

        const field = <>
            <div className={"row"}>
                <div className={"col"}>
                    <input type={"text"}
                           size={fieldSize}
                           id={name}
                           data-testid={name}
                           name={name}
                           value={value}
                           onChange={onChange}
                    />
                </div>
            </div>
            <div className={"row d-flex justify-content-left pt-3 m-0"}>
                <div className={errorMessageClass}>
                    {errorMessage}
                </div>
            </div>
        </>;

        if (!showLabel) {
            return <>
                {field}
            </>;
        }

        return <>
            {label}
            {field}
        </>;
    }

    private static getField(labelText: string,
                            showLabel: boolean,
                            name: string,
                            value: string,
                            fieldSize: number,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => void): React.ReactFragment {

        const label = <h5>
            <div className={"row"}>
                <div className={"col"}>
                    <label htmlFor={name}>{labelText}</label>
                </div>
            </div>
        </h5>;

        const field = <div className={"row"}>
            <div className={"col"}>
                <input type={"text"}
                       size={fieldSize}
                       id={name}
                       data-testid={name}
                       name={name}
                       value={value}
                       onChange={onChange}
                />
            </div>
        </div>;

        if (!showLabel) {
            return <>
                {field}
            </>;
        }

        return <>
            {label}
            {field}
        </>;
    }

    values(): Value[] {
        const name = this.props.field.name;
        const value = this.getValue();
        if (this.isValid().valid) {
            if (value !== "") {
                return createSingleValue(name, value);
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    isValid(): FieldError {
        const value = this.state.value;
        return this.validateValue(value);
    }

    validateValue(value: string): FieldError {
        const name = this.props.field.name;
        let mandatory: boolean = false;

        if (this.props.field.mandatory !== undefined) {
            mandatory = this.props.field.mandatory;
        }

        if (mandatory) {
            const hasValue: boolean = value !== "";
            if (hasValue) {
                const validDate: boolean = validateEmail(value);
                if (validDate) {
                    return getValid(name);
                }
                return getValid(name, invalidEmail);
            }
            return getValid(name, missingMandatoryField);
        }

        const empty: boolean = value === "";
        if (empty) {
            return getValid(name);
        }

        const validEmail: boolean = validateEmail(value);
        if (validEmail) {
            return getValid(name);
        } else {
            return getValid(name, invalidEmail);
        }
    }

    clear(): void {
        this.setState({value: ""});
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        values.forEach((value: Value) => {
            if (value.fieldName === name) {
                const newValue: string = value.values[0];
                this.setState({value: newValue});
            }
        });
    }

    private getValue(): string {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            let value: string | undefined = this.props.value;
            if (value !== undefined) {
                return value;
            } else {
                return '';
            }
        } else {
            return this.state.value;
        }
    }

    private onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const currentValue: string = e.currentTarget.value;

        let onChange = this.props.onChange;


        if (onChange !== undefined) {
            let field = this.props.field;
            const name: string = field.name;
            let index: string | undefined = this.props.duplicationIndex;
            let duplicationIndex: string = index !== undefined ? index : "0";
            let fieldError: FieldError = this.validateValue(currentValue);
            const valid = fieldError.valid;
            if (valid) {
                this.setState({errorMessage: ""});
            } else {
                const errorMessage = TranslationService.translation(fieldError.error);
                this.setState({errorMessage: errorMessage});
            }
            onChange(name, currentValue, duplicationIndex, valid, field);
        }
    }

}

export default EmailField;
