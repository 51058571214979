import React, {ReactElement} from 'react';
import {FieldComponent, FieldError, FieldProps} from "./FieldComponent";
import Value from "../../register/v2/Value";
import {DependingDescriptionOption, Paragraph} from "../../register/v2/Action";
import TranslationService from "../../../infra/TranslationService";

export interface props extends FieldProps {
}

export interface state {
    wantedDescriptionName: string
}

export class DependingDescription<T extends props, S extends state> extends React.Component<T, S> implements FieldComponent {
    constructor(props: T) {
        super(props);
        this.state = {
            ...this.state,
            wantedDescriptionName: ""
        }
    }

    render(): ReactElement {
        let description = this.findDescription();

        return <div className={"col"}>
            {description}
        </div>
    }

    private findDescription(): React.JSX.Element[] {
        const wanted = this.state.wantedDescriptionName;

        const dependingDescriptions: DependingDescriptionOption[] | undefined = this.props.field.dependingDescriptions;
        if (dependingDescriptions !== undefined) {
            const wantedDescription = dependingDescriptions.find(dependingDescription => dependingDescription.dependsOn === wanted);
            if (wantedDescription !== undefined) {
                return wantedDescription.paragraphs.map((p: Paragraph) => {
                    const paragraph = p.paragraph;
                    const translation = TranslationService.translation(paragraph);
                    return <div key={p.paragraph} className={"row m-0 p-0"}>{translation}</div>
                    }
                );
            }
        }

        return [];
    }

    componentDidMount() {
        if (this.props.field.dependsOn !== undefined) {
            const dependsOn: string = this.props.field.dependsOn;
            const elementById = document.getElementById(dependsOn);
            if (elementById !== null) {
                elementById.addEventListener("change", this.dependeeChanged());
            }
        }
    }

    componentWillUnmount() {
        if (this.props.field.dependsOn !== undefined) {
            const dependsOn: string = this.props.field.dependsOn;
            const elementById = document.getElementById(dependsOn);
            if (elementById !== null) {
                elementById.removeEventListener("change", this.dependeeChanged());
            }
        }
    }

    dependeeChanged(): any {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            const wantedName = event.currentTarget.value;
            this.setState({wantedDescriptionName: wantedName});
        }
    }

    clear(): void {
    }

    isValid(): FieldError {
        return {
            name: "",
            valid: true,
            error: ""
        };
    }

    set(values: Value[]): void {
        // only to fool idea to think that values are being used
        if (values === undefined) {
            console.log(values);
        }
    }

    values(): Value[] {
        return [];
    }
}

export default DependingDescription;
