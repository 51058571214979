import React, {ChangeEvent, ReactElement} from 'react';
import {FieldComponent, FieldError} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import Value from "../../register/v2/Value";
import {createSingleValue, isSingleValueValid} from "./FieldUtil";
import ConditionalField, {ConditionalProps, ConditionalState} from "./ConditionalField";
import {V3} from "../../../infra/Constants";
import {Field} from "../../register/v2/Action";

interface props extends ConditionalProps {
    frontendVersion?: string,
    value?: string,
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
}

interface state extends ConditionalState {
    value: string
}

class Text extends ConditionalField<props, state> implements FieldComponent {
    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            ...this.state,
            value: ""
        }
    }

    render(): ReactElement {
        const name: string = this.props.field.name;
        const label: string = TranslationService.translation(this.props.field.name);
        const value = this.getValue();
        const onChange = (e: ChangeEvent<HTMLInputElement>) => this.onChange(e);

        let showLabel: boolean = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        const field = Text.getField(label, showLabel, name, value, onChange);

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            return <div>
                {field}
            </div>
        } else {
            return this.renderField(field, name);
        }
    }

    private getValue() {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            let value = this.props.value;
            if (value !== undefined) {
                return value;
            } else {
                return '';
            }
        } else {
            return this.state.value;
        }
    }

    private static getField(labelText: string,
                            showLabel: boolean,
                            name: string,
                            value: string,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => void): React.ReactFragment {
        const label = <h5>
            <div className={"row"}>
                <div className={"col"}>
                    <label htmlFor={name}>{labelText}</label>
                </div>
            </div>
        </h5>;

        const field = <div className={"row"}>
            <div className={"col"}>
                <input type={"text"}
                       aria-label={name}
                       id={name}
                       data-testid={name}
                       value={value}
                       onChange={onChange}
                />
            </div>
        </div>;

        if (!showLabel) {
            return <>
                {field}
            </>;
        }

        return <div>
            {label}
            {field}
        </div>;
    }

    private onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const currentValue: string = e.currentTarget.value;

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            let onChange = this.props.onChange;
            if (onChange !== undefined) {
                let field = this.props.field;
                const name: string = field.name;
                let duplicationIndex: string = '0';
                if (this.props.duplicationIndex !== undefined) {
                    duplicationIndex = this.props.duplicationIndex;
                }

                onChange(name, currentValue, duplicationIndex, true, field);
            }
        } else {
            this.setState({value: currentValue})
        }
    }

    values(): Value[] {
        const name = this.props.field.name;
        const value = this.getValue();

        if (this.isValid().valid) {
            if (value !== "") {
                return createSingleValue(name, value);
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        const value = this.getValue();
        const mandatory = this.props.field.mandatory;

        return isSingleValueValid(name, value, mandatory);
    }

    clear(): void {
        let visible = this.isConditionalfield();
        this.setState({
            value: "",
            visible: visible
        })
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        if (values) {
            values.forEach((value: Value) => {
                if (value.fieldName === name) {
                    const newValue: string = value.values[0];
                    this.setState({value: newValue});
                }
            });
        }
    }
}

export default Text;
