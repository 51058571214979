import React from "react";
import {getSecurityToken} from "../../../infra/SecurityToken";
import GenericModal from "./GenericModal";
import ViewLia2 from "../../../images/lia2_instructions.png";
import TranslationService from "../../../infra/TranslationService";
import "./Lia2GradingInstructions.css"

export const lia2GradingInstructions = <div>
    <p className={"btn-link"} onClick={downloadInstructions}>Ladda ner detta dokument som pdf</p>
    <p>
        <b>Former för kunskapskontroll och principer för betygsättning:</b></p>
    <p>
        <br/>

    </p>
    <p><b>Bedömningsgrunder:</b>
    </p>
    <p>LIA är en delkurs inom Yrkeshögskolan och skall därmed betygsättas. Betyget sätts av
        ansvarig lärare på skolan, men din bedömning av den studerandes färdigheter och
        kompetenser är ett viktigt underlag för att rätt betyg skall kunna sättas.
    </p>
    <p>
        <br/>

    </p>
    <p>När du gör din bedömning skall denna utgå ifrån den nivå den studerande befinner sig på vid
        avslutad handledning, men den skall även grundas på den nivå den studerande förväntas
        befinna sig på i aktuell kurs. Med andra ord kan man nå respektive kursmål på en mycket
        hög nivå, även i början av sin utbildning och även om man har mycket kvar att lära innan
        man blir en erfaren lokförare.</p>
    <p><
        br/>

    </p>
    <div className={"row"}>
        <div className={"col p-0 mx-auto text-center"}>
            <img src={ViewLia2} width="375" height="192" alt={"Lia progression"}/>
        </div>
    </div>

    <p><b>Definitioner:</b></p>

    <p><b>i samråd med …</b> = Den studerande och handledaren <i>samtalar</i> om ett ämnesområde eller en
        uppgift <i>under tiden</i> den utförs. Lotsning av handledaren sker.
    </p>
    <p>

    </p>
    <p><b>efter samråd med …</b> =
        Den studerande och handledaren <i>samtalar</i> om
        ett ämnesområde eller en uppgift <i>innan</i> den
        utförs. Den studerande kan på egen hand återge ett förlopp eller
        genomföra ett moment.
    </p>
    <p>

    </p>
    <p><b>med viss säkerhet …</b> =
        Den studerande ställer kontrollfrågor under sin återgivning av ett
        förlopp eller genomförande av ett moment och med tillfredsställande
        resultat
    </p>
    <p>

    </p>
    <p><b>med säkerhet …</b> = Den
        studerande återger ett förlopp eller genomför ett moment
        självständigt och med gott resultat.
    </p>
    <p>

    </p>
    <p><b>översiktligt …</b> =
        Den studerande återger ett förlopp eller utför ett moment utan att
        nämna eller visa detaljer.
    </p>
    <p>

    </p>
    <p>
        <b>utförligt och nyanserat …</b> =
        Den studerande nämner och visar detaljer när ett förlopp återges
        eller ett moment utförs. Den studerande visar på alternativ och kan
        diskutera för- och nackdelar.
    </p>
    <p><i>I bedömningsunderlaget ska du bedöma till vilken grad den studerandes färdigheter och kompetenser
        stämmer in på de olika påståendena.</i>
    </p>
    <p><br/>

    </p>
    <p><b>Instruktioner vid bedömning:</b></p>
    <p><b>Skala</b><b>1-4</b></p>
    <p>
        I skalan 1-4 uppnår den studerande i olika grad det satta målet. Dvs. en 1:a betyder att den
        studerande klarar målet, om än med liten marginal. En 4:a betyder att den studerande klarar
        uppgiften med ett gott resultat.
    </p>
    <p><br/>

    </p>
    <p><b>Når ej kursmålet</b>
    </p>
    <p>
        Denna ruta skall kryssas i när du anser att den studerande inte klarar av det specifika
        kursmålet. Det är viktigt att du lämnar en kommentar till denna bedömning så att läraren kan
        förstå var kunskapen brister.
    </p>
    <p><br/>

    </p>
    <p><b>Omdöme kan ej ges</b>
    </p>
    <p>
        Om du och den studerande inte haft möjlighet att träna på/praktisera det satta kursmålet och
        du därmed inte kan göra en bedömning kryssas denna ruta i. Även här är det bra att du kort
        kommenterar varför, speciellt om det gäller flera bedömningspunkter.
    </p><br/>
    <p><i>
        Känner du dig osäker på hur du skall göra din bedömning är du alltid välkommen att höra av
        dig till ansvarig utbildningsledare för respektive utbildning.
    </i></p>
</div>

export function downloadInstructions() {
    return fetch(process.env.REACT_APP_LOCALHOST + '/api/v1/download', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        }
    )
        .then(response => {
            response.blob()
                .then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'LIA 2 Instruktioner.pdf';
                    a.click();
                });
        })
}

export function getLia2HtmlInstructions(showPopoverModal: undefined | boolean, flipPopover: (() => void) | undefined) {
    if (showPopoverModal && flipPopover) {
        const headline = TranslationService.translation("traindriver-lia-2.instructions");

        return <GenericModal headline={headline}
                             body={lia2GradingInstructions}
                             hide={flipPopover}/>;

    }

    return <div/>;
}
