import React from "react";
import {FieldProps} from "./FieldComponent";
import {Field} from "../../register/v2/Action";
import Select, {createFilter} from 'react-select'
import {fetchArrayOf, fetchObjectOf} from "../../../infra/BackendService";
import TranslationService from "../../../infra/TranslationService";
import translationService from "../../../infra/TranslationService";
import {DESKTOP} from "../../../infra/Constants";
import AsyncSelect from "react-select/async";
import {OptionType} from "../../model/SelectOption";


interface props extends FieldProps {
    field: Field,
    value?: string,
    duplicationIndex: string,
    onChange: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void,
    device?: string
}

interface state {
    chapters?: OptionType[],
    sections?: OptionType[],
    categories?: OptionType[],
    subcategories?: OptionType[]
    level5subcategories?: OptionType[]
    level6subcategories?: OptionType[]
    level7subcategories?: OptionType[]
    chapter?: OptionType | null,
    section?: OptionType | null,
    category?: OptionType | null,
    subcategory?: OptionType | null,
    level5subcategory?: OptionType | null,
    level6subcategory?: OptionType | null,
    level7subcategory?: OptionType | null,
    selectedInputTypeIsHierarchical?: boolean,
    allIcdCodes?: OptionType[],
    icdCode?: OptionType | null,
    defaultSequentialOptions?: OptionType[]
}


class ICD10 extends React.Component<props, state> {
    constructor(props: Readonly<props>) {
        super(props);

        this.onChapterChange = this.onChapterChange.bind(this);
        this.onSectionChange = this.onSectionChange.bind(this);
        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.onSubcategoryChange = this.onSubcategoryChange.bind(this);
        this.onLevel5SubcategoryChange = this.onLevel5SubcategoryChange.bind(this);
        this.onLevel6SubcategoryChange = this.onLevel6SubcategoryChange.bind(this);
        this.onLevel7SubcategoryChange = this.onLevel7SubcategoryChange.bind(this);
        this.onChoosingOption = this.onChoosingOption.bind(this);
        this.onIcdCodeChange = this.onIcdCodeChange.bind(this);


        this.state = {
            icdCode: undefined
        }
    }

    componentDidMount() {
        this.retrieveChapters();
        this.retrieveAllIcdCodes();
        this.retrieveDefaultOptions();
        let currentValue = this.props.value;
        let fieldName = this.props.field.name;

        if (currentValue !== undefined && currentValue !== null) {
            currentValue = this.getValueWithFieldName(currentValue);
            this.setState({selectedInputTypeIsHierarchical: true});
            let codeUrl = `/api/v1/icd-code/${currentValue}/${fieldName}`;
            fetchObjectOf<any>(codeUrl).then((code: any) => {
                if (code !== undefined) {
                    let selectedOption = this.getOptionFromCode(code);
                    let parentsUrl = `/api/v1/icd-codes/parents/${currentValue}/${fieldName}`;
                    fetchArrayOf<any>(parentsUrl).then((codes: any) => {
                        if (codes !== undefined) {
                            if (codes.length === 0) {
                                this.setState({
                                    chapter: selectedOption,
                                    section: null,
                                    category: null,
                                    subcategory: null,
                                    level5subcategory: null,
                                    level6subcategory: null,
                                    level7subcategory: null
                                });
                                this.retrieveSections(selectedOption.value);
                            } else {
                                let parentOptions = this.getOptionsFromCodes(codes);
                                if (codes.length === 1) {
                                    this.setState({
                                        chapter: parentOptions[0],
                                        section: selectedOption,
                                        category: null,
                                        subcategory: null,
                                        level5subcategory: null,
                                        level6subcategory: null,
                                        level7subcategory: null
                                    });
                                    this.retrieveSections(parentOptions[0].value);
                                    this.retrieveCategories(selectedOption.value);
                                } else if (codes.length === 2) {
                                    this.setState({
                                        chapter: parentOptions[1],
                                        section: parentOptions[0],
                                        category: selectedOption,
                                        subcategory: null,
                                        level5subcategory: null,
                                        level6subcategory: null,
                                        level7subcategory: null
                                    });
                                    this.retrieveSections(parentOptions[1].value);
                                    this.retrieveCategories(parentOptions[0].value);
                                    this.retrieveSubcategories(selectedOption.value);
                                } else if (codes.length === 3) {
                                    this.setState({
                                        chapter: parentOptions[2],
                                        section: parentOptions[1],
                                        category: parentOptions[0],
                                        subcategory: selectedOption,
                                        level5subcategory: null,
                                        level6subcategory: null,
                                        level7subcategory: null
                                    })
                                    this.retrieveSections(parentOptions[2].value);
                                    this.retrieveCategories(parentOptions[1].value);
                                    this.retrieveSubcategories(parentOptions[0].value);
                                    this.retrieveLevel5Subcategories(selectedOption.value);
                                } else if (codes.length === 4) {
                                    this.setState({
                                        chapter: parentOptions[3],
                                        section: parentOptions[2],
                                        category: parentOptions[1],
                                        subcategory: parentOptions[0],
                                        level5subcategory: selectedOption,
                                        level6subcategory: null,
                                        level7subcategory: null
                                    })
                                    this.retrieveSections(parentOptions[3].value);
                                    this.retrieveCategories(parentOptions[2].value);
                                    this.retrieveSubcategories(parentOptions[1].value);
                                    this.retrieveLevel5Subcategories(parentOptions[0].value);
                                    this.retrieveLevel6Subcategories(selectedOption.value);
                                } else if (codes.length === 5) {
                                    this.setState({
                                        chapter: parentOptions[4],
                                        section: parentOptions[3],
                                        category: parentOptions[3],
                                        subcategory: parentOptions[1],
                                        level5subcategory: parentOptions[0],
                                        level6subcategory: selectedOption,
                                        level7subcategory: null
                                    })
                                    this.retrieveSections(parentOptions[4].value);
                                    this.retrieveCategories(parentOptions[3].value);
                                    this.retrieveSubcategories(parentOptions[2].value);
                                    this.retrieveLevel5Subcategories(parentOptions[1].value);
                                    this.retrieveLevel6Subcategories(parentOptions[0].value);
                                    this.retrieveLevel7Subcategories(selectedOption.value);
                                } else if (codes.length === 6) {
                                    this.setState({
                                        chapter: parentOptions[5],
                                        section: parentOptions[4],
                                        category: parentOptions[3],
                                        subcategory: parentOptions[2],
                                        level5subcategory: parentOptions[1],
                                        level6subcategory: parentOptions[0],
                                        level7subcategory: selectedOption
                                    })
                                    this.retrieveSections(parentOptions[5].value);
                                    this.retrieveCategories(parentOptions[4].value);
                                    this.retrieveSubcategories(parentOptions[3].value);
                                    this.retrieveLevel5Subcategories(parentOptions[3].value);
                                    this.retrieveLevel6Subcategories(parentOptions[1].value);
                                    this.retrieveLevel7Subcategories(parentOptions[0].value);
                                }
                            }

                        }
                    });
                }
            });
        }
    }


    onChoosingOption(e: React.ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;
        if (value === "hierarchical" + this.props.duplicationIndex) {
            this.setState({selectedInputTypeIsHierarchical: true, icdCode: null});
        } else {
            if (value === "sequential" + this.props.duplicationIndex) {
                this.setState({
                    selectedInputTypeIsHierarchical: false,
                    chapter: null,
                    section: null,
                    category: null,
                    subcategory: null,
                    sections: undefined,
                    categories: undefined,
                    subcategories: undefined
                });
            }
        }
    }


    render() {
        let duplicationIndex = this.props.duplicationIndex;
        let selectIsHierarchical = this.state.selectedInputTypeIsHierarchical;
        let isHierarchical = selectIsHierarchical !== undefined && selectIsHierarchical;
        let iSequential = selectIsHierarchical !== undefined && !selectIsHierarchical;
        let hierarchical = "hierarchical" + duplicationIndex;
        let sequential = "sequential" + duplicationIndex;
        let searchingTypeRadio = <div style={{columns: 1}}>
            <span className={"no-line-break"}>
                <input type="radio" value={hierarchical} name={hierarchical} checked={isHierarchical}
                       aria-label={hierarchical}
                       onChange={this.onChoosingOption}/> Stegvis sökning bland ICD-koder
            </span>
            <br/>
            <span className={"no-line-break"}>
                <input type="radio" value={sequential} name={sequential} checked={iSequential}
                       aria-label={sequential}
                       onChange={this.onChoosingOption}/> Sök bland samtliga ICD-koder samt favoriter och senast valda
            </span>

        </div>;

        let hierarchicalDiagnosis = <div/>;
        let sequentialDiagnosis = <div/>;
        const name = this.props.field.name;
        const labelText = TranslationService.translation(this.props.field.name);
        const label = <h5>
            <div className={"row"}>
                <div className={"col"}>
                    <label aria-label={name + ".label"}
                           htmlFor={name}>{labelText}</label>
                </div>
            </div>
        </h5>;
        if (isHierarchical) {
            let selectedValue = this.props.value !== undefined ?
                this.getValueWithFieldName(this.props.value) : undefined;
            const dropdownSize = this.props.device === DESKTOP ? "col-6" : "col-12";

            const currentChapters: OptionType[] | undefined = this.state.chapters;
            const currentChapter: OptionType | undefined | null = selectedValue !== undefined ? this.state.chapter : null;

            const chapterSelect = <div className={"row"}>
                <div className={dropdownSize}>
                    <Select options={currentChapters}
                            isMulti={false}
                            onChange={this.onChapterChange}
                            noOptionsMessage={() => currentChapters === undefined ? "Loading icd codes, please wait" : "No options"}
                            value={currentChapter}
                            placeholder={"Välj ett kapitel"}
                            aria-label={"chapter.select.label"}
                            id={"chapter.select"}
                    />
                </div>
            </div>;

            const currentSections: OptionType[] | undefined = this.state.sections;
            const currentSection: OptionType | undefined | null = selectedValue !== undefined ? this.state.section : null;

            let sectionSelect = currentSections !== undefined ? <div className={"row"}>
                <div className={dropdownSize}>
                    <Select
                        options={currentSections}
                        isMulti={false}
                        onChange={this.onSectionChange}
                        value={currentSection}
                        placeholder={"Välj ett avsnitt"}
                        aria-label={"section.select.label"}
                        id={"section.select"}
                    />
                </div>
            </div> : <div/>;


            const currentCategories: OptionType[] | undefined = this.state.categories;
            const currentCategory: OptionType | undefined | null = selectedValue !== undefined ? this.state.category : null;

            let categorySelect = currentCategories !== undefined ? <div className={"row"}>
                <div className={dropdownSize}>
                    <Select
                        options={currentCategories}
                        isMulti={false}
                        onChange={this.onCategoryChange}
                        value={currentCategory}
                        placeholder={"Väl en kategori"}
                        aria-label={"category.select.label"}
                        id={"category.select"}
                    />
                </div>
            </div> : <div/>;


            const currentSubcategories: OptionType[] | undefined = this.state.subcategories;
            const currentSubcategory: OptionType | undefined | null = selectedValue !== undefined ? this.state.subcategory : null;


            let subcategorySelect = currentSubcategories !== undefined ? <div className={"row"}>
                <div className={dropdownSize}>
                    <Select
                        options={currentSubcategories}
                        isMulti={false}
                        onChange={this.onSubcategoryChange}
                        value={currentSubcategory}
                        placeholder={"Välj en underkategori"}
                        aria-label={"subcategory.select.label"}
                        id={"subcategory.select"}
                    />
                </div>
            </div> : <div/>;

            const currentLevel5Subcategories: OptionType[] | undefined = this.state.level5subcategories;
            const currentLevel5Subcategory: OptionType | undefined | null = selectedValue !== undefined ? this.state.level5subcategory : null;

            let level5subcategorySelect = currentLevel5Subcategories !== undefined ? <div className={"row"}>
                <div className={dropdownSize}>
                    <Select
                        options={currentLevel5Subcategories}
                        isMulti={false}
                        onChange={this.onLevel5SubcategoryChange}
                        value={currentLevel5Subcategory}
                        placeholder={"Välj en underkategori"}
                        aria-label={"level5subcategory.select.label"}
                        id={"level5subcategory.select"}
                    />
                </div>
            </div> : <div/>;

            const currentLevel6Subcategories: OptionType[] | undefined = this.state.level6subcategories;
            const currentLevel6Subcategory: OptionType | undefined | null = selectedValue !== undefined ? this.state.level6subcategory : null;


            let level6subcategorySelect = currentLevel6Subcategories !== undefined ? <div className={"row"}>
                <div className={dropdownSize}>
                    <Select
                        options={currentLevel6Subcategories}
                        isMulti={false}
                        onChange={this.onLevel6SubcategoryChange}
                        value={currentLevel6Subcategory}
                        placeholder={"Välj en underkategori"}
                        aria-label={"level6subcategory.select.label"}
                        id={"level6subcategory.select"}
                    />
                </div>
            </div> : <div/>;

            const currentLevel7Subcategories: OptionType[] | undefined = this.state.level7subcategories;
            const currentLevel7Subcategory: OptionType | undefined | null = selectedValue !== undefined ? this.state.level7subcategory : null;


            let level7subcategorySelect = currentLevel7Subcategories !== undefined ? <div className={"row"}>
                <div className={dropdownSize}>
                    <Select
                        options={currentLevel7Subcategories}
                        isMulti={false}
                        onChange={this.onLevel7SubcategoryChange}
                        value={currentLevel7Subcategory}
                        placeholder={"Välj en underkategori"}
                        aria-label={"level7subcategory.select.label"}
                        id={"level7subcategory.select"}
                    />
                </div>
            </div> : <div/>;


            const chapterLabel = <h6 className={"pt-3"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <label aria-label={"label.chapter.select"}
                               htmlFor={"chapter.select"}>Kapitel</label>
                    </div>
                </div>
            </h6>;

            const sectionLabel = currentSections !== undefined ? <h6 className={"pt-3"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <label aria-label={"label.section.select"}
                               htmlFor={"section.select"}>Avsnitt</label>
                    </div>
                </div>
            </h6> : <div/>;

            const categoryLabel = currentCategories !== undefined ? <h6 className={"pt-3"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <label aria-label={"label.category.select"}
                               htmlFor={"category.select"}>Kategori</label>
                    </div>
                </div>
            </h6> : <div/>;

            const subcategoryLabel = currentSubcategories !== undefined ? <h6 className={"pt-3"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <label aria-label={"label.subcategory.select"}
                               htmlFor={"subcategory.select"}>Subkategori</label>
                    </div>
                </div>
            </h6> : <div/>;

            const level5subcategoryLabel = currentLevel5Subcategories !== undefined ? <h6 className={"pt-3"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <label aria-label={"label.level5subcategory.select"}
                               htmlFor={"level5subcategory.select"}>Subkategori, nivå 2</label>
                    </div>
                </div>
            </h6> : <div/>;

            const level6subcategoryLabel = currentLevel6Subcategories !== undefined ? <h6 className={"pt-3"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <label aria-label={"label.level6subcategory.select"}
                               htmlFor={"level6subcategory.select"}>Subkategori, nivå 3</label>
                    </div>
                </div>
            </h6> : <div/>;

            const level7subcategoryLabel = currentLevel7Subcategories !== undefined ? <h6 className={"pt-3"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <label aria-label={"label.level7subcategory.select"}
                               htmlFor={"level7subcategory.select"}>Subkategori, nivå 4</label>
                    </div>
                </div>
            </h6> : <div/>;


            hierarchicalDiagnosis = <>{label}
                {chapterLabel}
                {chapterSelect}
                {sectionLabel}
                {sectionSelect}
                {categoryLabel}
                {categorySelect}
                {subcategoryLabel}
                {subcategorySelect}
                {level5subcategoryLabel}
                {level5subcategorySelect}
                {level6subcategoryLabel}
                {level6subcategorySelect}
                {level7subcategoryLabel}
                {level7subcategorySelect}
            </>;
        }

        if (iSequential) {
            let selectedValue = this.props.value !== undefined ? this.getValueWithFieldName(this.props.value) : undefined;
            const dropdownSize = this.props.device === DESKTOP ? "col-6" : "col-12";
            if (this.state.allIcdCodes !== undefined) {

                const icdCodes: OptionType[] | undefined = this.state.allIcdCodes;
                const currentIcdCode: OptionType | undefined | null = selectedValue !== undefined ? this.state.icdCode : null;

                const filterOptions = (inputValue: string) => {
                    if (inputValue.length > 2) {
                        return icdCodes.filter((i) =>
                            i.label.toLowerCase().includes(inputValue.toLowerCase())
                        );
                    }

                };


                const promiseOptions = (inputValue: string) =>
                    new Promise<OptionType[]>((resolve) => {
                        resolve(filterOptions(inputValue));
                    });

                let defaultOptions = this.state.defaultSequentialOptions;
                let enICDKod: string = translationService.translation("icd kod");
                sequentialDiagnosis = <>{label}
                    <div className={"row"}>
                        <div className={dropdownSize}>
                            <AsyncSelect loadOptions={promiseOptions}
                                         defaultOptions={defaultOptions}
                                         filterOption={createFilter({ignoreAccents: false})}
                                         isMulti={false}
                                         onChange={this.onIcdCodeChange}
                                         value={currentIcdCode}
                                         placeholder={enICDKod}
                                         noOptionsMessage={({inputValue}) => (defaultOptions === undefined) ? "Laddar ICD-koder, var god vänta" : (!inputValue || inputValue.length < 3 ? "Skriv fler än 2 tecken" : "Inga koder hittade")}
                                         aria-label={"icdCode.select.label"}
                                         id={"icdCode.select"}
                            />
                        </div>
                    </div>
                </>;
            }
        }
        return <div>
            {searchingTypeRadio}
            {hierarchicalDiagnosis}
            {sequentialDiagnosis}
        </div>;
    }

    private getValueWithFieldName(value: string): string {
        let fieldName = this.props.field.name;
        if (!value.startsWith(fieldName)) {
            value = fieldName + "." + value;
        }
        return value;
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    private onChapterChange(newValue: any): void {
        this.setState({
            chapter: newValue,
            section: null,
            category: null,
            subcategory: null,
            categories: undefined,
            subcategories: undefined,
            level5subcategories: undefined,
            level6subcategories: undefined,
            level7subcategories: undefined
        })
        this.retrieveSections(newValue.value);
        let field = this.props.field;
        this.props.onChange(field.name, newValue.value, this.props.duplicationIndex, true, field);
    }

    private onIcdCodeChange(newValue: any): void {
        this.setState({
            icdCode: newValue
        })
        let field = this.props.field;
        this.props.onChange(field.name, newValue.value, this.props.duplicationIndex, true, field);
    }

    private onSectionChange(newValue: any): void {
        this.setState({
            section: newValue,
            category: null,
            subcategory: null,
            level5subcategory: null,
            level6subcategory: null,
            level7subcategory: null,
            subcategories: undefined,
            level5subcategories: undefined,
            level6subcategories: undefined,
            level7subcategories: undefined
        })
        this.retrieveCategories(newValue.value);
        let field = this.props.field;
        this.props.onChange(this.props.field.name, newValue.value, this.props.duplicationIndex, true, field);
    }

    private onCategoryChange(newValue: any): void {
        this.setState({
            category: newValue,
            subcategory: null,
            level5subcategory: null,
            level6subcategory: null,
            level7subcategory: null,
            level5subcategories: undefined,
            level6subcategories: undefined,
            level7subcategories: undefined
        })
        this.retrieveSubcategories(newValue.value);
        let field = this.props.field;
        this.props.onChange(field.name, newValue.value, this.props.duplicationIndex, true, field);
    }

    private onSubcategoryChange(newValue: any): void {
        this.setState({
            subcategory: newValue,
            level5subcategory: null,
            level6subcategory: null,
            level7subcategory: null,
            level6subcategories: undefined,
            level7subcategories: undefined
        })
        this.retrieveLevel5Subcategories(newValue.value);
        let field = this.props.field;
        this.props.onChange(field.name, newValue.value, this.props.duplicationIndex, true, field);
    }

    private onLevel5SubcategoryChange(newValue: any): void {
        this.setState({
            level5subcategory: newValue,
            level6subcategory: null,
            level7subcategory: null,
            level7subcategories: undefined
        })
        this.retrieveLevel6Subcategories(newValue.value);
        let field = this.props.field;
        this.props.onChange(field.name, newValue.value, this.props.duplicationIndex, true, field);
    }

    private onLevel6SubcategoryChange(newValue: any): void {
        this.setState({
            level6subcategory: newValue,
            level7subcategory: null,
        })
        this.retrieveLevel7Subcategories(newValue.value);
        let field = this.props.field;
        this.props.onChange(field.name, newValue.value, this.props.duplicationIndex, true, field);
    }

    private onLevel7SubcategoryChange(newValue: any): void {
        this.setState({
            level7subcategory: newValue,
        })
        let field = this.props.field;
        this.props.onChange(field.name, newValue.value, this.props.duplicationIndex, true, field);
    }

    private getOptionsFromCodes(codes: any): OptionType[] {
        let options: OptionType[] = [];
        codes.forEach((code: any) => {
            let option: OptionType = this.getOptionFromCode(code);
            options.push(option);
        })
        return options;
    }

    private getOptionFromCode(code: any): OptionType {
        return {
            value: code.code,
            label: code.title
        };
    }

    private retrieveChapters() {
        let url = "/api/v1/icd-codes/children";
        fetchArrayOf<any>(url).then((codes: any) => {
            if (codes !== undefined && codes !== null) {
                let options: OptionType[] = this.getOptionsFromCodes(codes);
                this.setState({chapters: options});
            }
        });
    }

    private retrieveAllIcdCodes() {
        let url = "/api/v1/icd-codes";
        fetchArrayOf<any>(url).then((codes: any) => {
            if (codes !== undefined && codes !== null) {
                let options: OptionType[] = this.getOptionsFromCodes(codes);
                this.setState({allIcdCodes: options});
            }
        });
    }


    private retrieveSections(chapter: string) {
        let fieldName = this.props.field.name;
        let url = `/api/v1/icd-codes/children/${chapter}/${fieldName}`;
        fetchArrayOf<any>(url).then((codes: any) => {
            if (codes !== undefined && codes !== null) {
                let options: OptionType[] = this.getOptionsFromCodes(codes);
                this.setState({sections: options});
            }
        });
    }

    private retrieveCategories(section: string) {
        let fieldName = this.props.field.name;
        let url = `/api/v1/icd-codes/children/${section}/${fieldName}`;
        fetchArrayOf<any>(url).then((codes: any) => {
            if (codes !== undefined && codes !== null) {
                let options: OptionType[] = this.getOptionsFromCodes(codes);
                this.setState({categories: options});
            }
        });
    }

    private retrieveSubcategories(category: string) {
        let fieldName = this.props.field.name;
        let url = `/api/v1/icd-codes/children/${category}/${fieldName}`;
        fetchArrayOf<any>(url).then((codes: any) => {
            if (codes !== undefined && codes !== null) {
                let options: OptionType[] = this.getOptionsFromCodes(codes);
                this.setState({subcategories: options});
            }
        });
    }

    private retrieveLevel5Subcategories(subcategory: string) {
        let fieldName = this.props.field.name;
        let url = `/api/v1/icd-codes/children/${subcategory}/${fieldName}`;
        fetchArrayOf<any>(url).then((codes: any) => {
            if (codes !== undefined && codes !== null) {
                let options: OptionType[] = this.getOptionsFromCodes(codes);
                this.setState({level5subcategories: options});
            }
        });
    }

    private retrieveLevel6Subcategories(subcategory: string) {
        let fieldName = this.props.field.name;
        let url = `/api/v1/icd-codes/children/${subcategory}/${fieldName}`;
        fetchArrayOf<any>(url).then((codes: any) => {
            if (codes !== undefined && codes !== null) {
                let options: OptionType[] = this.getOptionsFromCodes(codes);
                this.setState({level6subcategories: options});
            }
        });
    }

    private retrieveLevel7Subcategories(subcategory: string) {
        let fieldName = this.props.field.name;
        let url = `/api/v1/icd-codes/children/${subcategory}/${fieldName}`;
        fetchArrayOf<any>(url).then((codes: any) => {
            if (codes !== undefined && codes !== null) {
                let options: OptionType[] = this.getOptionsFromCodes(codes);
                this.setState({level7subcategories: options});
            }
        });
    }


    private retrieveDefaultOptions() {
        let url = "/api/v1/icd-codes/default";
        fetchArrayOf<any>(url).then((codes: any) => {
            if (codes !== undefined) {
                let options: OptionType[] = this.getOptionsFromCodes(codes);
                this.setState({defaultSequentialOptions: options});
            }
        });
    }
}

export default ICD10;