import React from "react";
import {FORGOT_PASSWORD_API} from "./RecoverNewPassword";

interface state {
    hasSentRecovery: boolean
    message: string
}

interface props {
    email: string
}

class EmailInUseMessage extends React.Component<props, state> {
    constructor(props: props, context: any) {
        super(props, context);
        this.state = {hasSentRecovery: false, message: ""}
    }

    render() {
        const defaultErrorMessage = <div className={"container text-center"}>
            <div className={"row"}>
            </div>
            <div className={"row pt-2"}>
                <div className={"col"}>
                   E-post adressen används redan.
                </div>

            </div>
            <div className={"row pt-2"}>
                <div className={"col"}>
                    <button onClick={this.handleEmailReset}
                            className={"btn btn-tage-default"}>Skicka mail för att återställa lösenordet
                    </button>
                </div>
            </div>

            <div className={"row pt-2"}>
                <div className={"col"}>
                    Maila support@logeze.com om du har frågor.
                </div>

            </div>

        </div>

        const recovered = <div className={"container"}>
            <div className={"row"}>
                <div className={"col"}>
                    {this.state.message}
                </div>
            </div>
        </div>;

        return this.state.hasSentRecovery ? recovered : defaultErrorMessage;
    }

    private handleEmailReset = () => {
        const {email} = this.props;

        if (email) {
            let payload = {
                "email": email
            };

            //this should do something with error messages aswell
            fetch(FORGOT_PASSWORD_API, {
                method: "POST",
                body: JSON.stringify(payload)
            })
                .then(res => res.json())
                .then(() => {
                    let message = "Ett mail med instruktioner har skickats till " + email;
                    this.setState({
                        message,
                        hasSentRecovery: true
                    })

                }).catch(console.log);
        }
    }
}

export default EmailInUseMessage;
