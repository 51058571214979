import EmailField, {emailFieldProps, emailFieldState} from "./EmailField";
import {FieldError} from "./FieldComponent";
import {getValid} from "./FieldUtil";


export interface props extends emailFieldProps {
    userEmail?: string;
}

interface state extends emailFieldState {
}

export class EmailFieldWithUserValidation extends EmailField<props, state> {
    constructor(props: props) {
        super(props);
        this.state = {
            value: "",
            errorMessage: ""
        }
    }

    isValid(): FieldError {
        const basicValidation: FieldError = super.isValid();
        if (!basicValidation.valid) {
            return super.isValid();
        }
        const name = this.props.field.name;
        const value = this.state.value;
        if (value === this.props.userEmail) {
            return getValid(name, name + ".same.as.logged.in.user");
        }
        return getValid(name);
    }

    validateValue(value: string): FieldError {
        const name = this.props.field.name;
        if (value === this.props.userEmail) {
            return getValid(name, name + ".same.as.logged.in.user");
        }
        return super.validateValue(value);
    }


}
