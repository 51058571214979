import React, {ReactNode} from "react";
import Login from "./Login";
import "./LoginNavbar.css"

class LoginNavbar extends Login {

    render(): ReactNode {

        const emailInput = this.getEmailInput(16);
        const passwordInput = this.getPasswordInput(16, true);
        const loginButton = this.getLoginButton("btn login-navbar");
        const errorMessage = this.getErrorMessage();
        const passwordRecoveryLink = this.getPasswordRecoveryLink("navbar-forgotten-link");

        return <div className={"container"}>
            <div className={"row pt-1 m-0 pr-4"}>
                <div className={"col-10 p-0 m-0"}>
                    <div className={"row pt-1 m-0 justiy-content-start"}>
                        <div className={"col p-0 m-0 color-reversed"}>
                            {errorMessage}
                        </div>
                        <div className={"col p-0 m-0"}>
                            {emailInput}
                        </div>
                        <div className={"col p-0 m-0"}>
                            {passwordInput}
                        </div>
                    </div>
                </div>
                <div className={"col-2 p-0 m-0"}>
                    {loginButton}
                </div>
            </div>
            <div className={"row m-0 adjust-recover-link"}>
                {passwordRecoveryLink}
            </div>
        </div>
    }
}

export default LoginNavbar;
