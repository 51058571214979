import React from 'react';
import "./FrontPage.css";
import Filur from "./okand_filur.png"
import {NavLink} from "react-router-dom";
import {UserData} from "../model/UserData";

interface props {
    loggedIn: () => void
    setUserData: (userData: UserData) => void
    setLoading: (state: boolean) => void
}

interface state {
}

class FrontPage extends React.Component<props, state> {

    componentDidMount() {
        document.title = "LogEze"
    }

    render() {
        return (
            <div className={"container-fluid mainContainer"}>
                <div className={"row"}>
                    <div className={"col pr-0 leftSideBox"}>
                        <div className={"image-container"}>
                            <img className={"filur-image"} src={Filur} alt={"Doctor wearing protection cloths"}/>
                            <div className={"leftSideTextBox"}>Vad har du och världen gjort idag?</div>
                            <div className={"leftSideTextBoxSmall"}>
                                För att organisera dina placeringar, och jämföra dig med världen.
                            </div>
                            <div className={"registerButtonLeftSide"}>
                                <NavLink to={"/signup"}>
                                    <button className={"btn btn-register"}>Skapa konto</button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className={"col righSideBox m-0 p-0"}>
                        <div className={"rightSideImageBox"}>
                        </div>
                    </div>
                </div>
                <div className={"row fakeFooterRow"}>
                    <div className={"container-fluid"}>
                        <div className={"row pt-2 pb-3"}>
                            <div className={"col-1"}/>
                            <div className={"col-2"}>
                                <h6>Företaget</h6>
                                <p className={"mb-0"}>Fitymi AB</p>
                                <p className={"mb-0"}>Bråvallagatan 6</p>
                                <p className={"mb-0"}>113 36 Stockholm</p>
                                <p className={"mb-0"}>Org nr 559176-4682</p>
                            </div>
                            <div className={"col-3"}>
                                <h6>LogEze för ST-läkare</h6>
                                <p className={"mb-0"}>Hur många dagar har du gjort på din ST?</p>
                                <p className={"mb-0"}>Hur många dagar är du verkligen på kliniken?</p>
                                <p className={"mb-0"}>Har du fått vara på de placeringar du ska vara enligt ditt
                                    kontrakt?</p>
                                <p className={"mb-0"}>Hur många dagar?</p>
                                <p className={"mb-0"}>Hur mycket schemaspacklas du?</p>
                                <p className={"mb-0"}>Hur många jourer har du gått?</p>
                                <p className={"mb-0"}>Hur många dagar har du kvar tills du kan ansöka om att bli
                                    specialst?</p>
                                <p className={"mb-0"}>LogEze kan hjälpa dig och din studierektor med svaren.</p>
                            </div>
                            <div className={"col-3"}>
                                <h6>LogEze för Studierektorer</h6>
                                <p className={"mb-0"}>Hur många dagar har dina ST verkligen gjort?</p>
                                <p className={"mb-0"}>Hur många dagar har dina ST kvar innan ansökan om
                                    specialistexamen?</p>
                                <p className={"mb-0"}>Har du fullgjort din del av avtalet med rätt placeringar?</p>
                                <p className={"mb-0"}>Med överblicken LogEze kan ge dig kan du svara på de här
                                    frågorna <i>och</i> ha data som stöder dig.</p>
                            </div>
                            <div className={"col-3"}>
                                <h6>LogEze för prehostipal vård</h6>
                                <p className={"mb-0"}>Hur många liv har räddats under senaste månaden?</p>
                                <p className={"mb-0"}>Hur många motbud har ni fått?</p>
                                <p className={"mb-0"}>Hur lång tid har ni vart rödmarkerade?</p>
                                <p className={"mb-0"}>Vilket bränsle har tankats?</p>
                                <p className={"mb-0"}>Är knarkböckerna inventerade?</p>
                                <p className={"mb-0"}>Hur får ni allvarliga avvikelser?</p>
                                <p className={"mb-0"}>Hur många uppdrag gjorde ni igår? Förra veckan??</p>
                                <p className={"mb-0"}>Har SOS rätt i sin statistik?</p>
                                <p className={"mb-0"}>Registrerar ni alla uppdrag, tankningar och avvikelser har du
                                    svaret. När som helst.</p>
                            </div>
                            <div className={"col-1"}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FrontPage;
