import React from "react";
import {Action, Field} from "../v2/Action";
import Event, {getValue} from "./Event";
import {DESKTOP} from "../../../infra/Constants";

import Label from "../../fields/v2/Label";
import Radio from "../../fields/v2/Radio";
import NumberField from "../../fields/v2/NumberField";
import DateField from "../../fields/v2/DateField";
import Checkbox from "../../fields/v2/Checkbox";
import Text from "../../fields/v2/Text";
import Comment from "../../fields/v2/Comment";
import TimeField from "../../fields/v2/TimeField";
import CustomSelect from "../../fields/v2/CustomSelect";
import {SearchableList} from "../../fields/v2/SearchableList";
import Age from "../../fields/v2/Age";
import Duration from "../../fields/v2/Duration";
import SearchItemDynamicSelect from "../../fields/v2/SearchItemDynamicSelect";
import Period from "../../fields/v2/Period";
import ICD10 from "../../fields/v2/ICD10";
import StaticSelect from "../../fields/v2/StaticSelect";
import {getDuplicationIndex} from "./DuplicationIndex";
import {EmailFieldWithUserValidation} from "../../fields/v2/EmailFieldWithUserValidation";
import EmailField from "../../fields/v2/EmailField";
import OrganisationDynamicSelect from "../../fields/v2/OrganisationDynamicSelect";
import StaffDynamicSelect from "../../fields/v2/StaffDynamicSelect";
import HospitalDynamicSelect from "../../fields/v2/HospitalDynamicSelect";
import {Organisation} from "../../model/Organisation";

export default function getField(field: Field,
                                 event: Event,
                                 onChange: (name: string, value: (string | string[]), duplicationIndex: string, valid: boolean, field: Field) => void,
                                 action: Action,
                                 user: any,
                                 currentOrganisation?: Organisation | undefined,
                                 obj?: { device?: string, cssId?: string }) {

    const duplicationIndex: string = getDuplicationIndex(field);
    // console.log('Calculated duplication index: <' + duplicationIndex + '>');

    const name = field.name;
    const type = field.type;
    const frontendVersion = action.frontendVersion;

    if (type === "field") {
        return <Label field={field}
                      frontendVersion={frontendVersion}
                      duplicationIndex={duplicationIndex}
        />
    }

    const value: string | string[] | undefined = getValue(name, event, duplicationIndex);

    if (type === "checkbox") {
        let currentValue: string[] = [];
        if (typeof value === 'string') {
            currentValue.push(value);
        }

        if (Array.isArray(value)) {
            currentValue = value;
        }

        let key = field.name + "-" + duplicationIndex;

        return <div key={key} className={"maxProgress"}><Checkbox key={key}
                                                                  field={field}
                                                                  device={DESKTOP}
                                                                  frontendVersion={frontendVersion}
                                                                  value={currentValue}
                                                                  duplicationIndex={duplicationIndex}
                                                                  onChange={onChange}
                                                                  enableAllOptions={false}
                                                                  event={event}
                                                                  action={action}
                                                                  user={user}
        /></div>
    }

    if (type === "radio") {
        const currentValue: string = value as string;
        let key = field.name + "-" + duplicationIndex;
        return <div key={key} className={"maxProgress"}><Radio key={key}
                                                               field={field}
                                                               device={DESKTOP}
                                                               frontendVersion={frontendVersion}
                                                               value={currentValue}
                                                               duplicationIndex={duplicationIndex}
                                                               onChange={onChange}
                                                               event={event}
                                                               action={action}
                                                               user={user}
        /></div>
    }

    if (type === "number") {
        const currentValue: string = value as string;
        return <div className={"maxProgress"}><NumberField field={field}
                                                           frontendVersion={frontendVersion}
                                                           value={currentValue}
                                                           duplicationIndex={duplicationIndex}
                                                           onChange={onChange}
                                                           cssId={obj?.cssId}
        /></div>
    }

    if (type === "time") {
        const currentValue: string = value as string;
        return <div className={"maxProgress"}><TimeField field={field}
                                                         frontendVersion={frontendVersion}
                                                         value={currentValue}
                                                         duplicationIndex={duplicationIndex}
                                                         onChange={onChange}
        /></div>
    }

    if (type === "date") {
        let dateFormat: string = 'yyyy-MM-dd';
        if (user !== undefined && user.dateFormat !== undefined) {
            dateFormat = user.dateFormat;
        }

        const currentValue: string = value as string;
        return <div className={"maxProgress"}><DateField field={field}
                                                         frontendVersion={frontendVersion}
                                                         value={currentValue}
                                                         duplicationIndex={duplicationIndex}
                                                         onChange={onChange}
                                                         dateFormat={dateFormat}
        /></div>
    }

    if (type === "period") {
        let dateFormat: string = 'yyyy-MM-dd';
        if (user !== undefined && user.dateFormat !== undefined) {
            dateFormat = user.dateFormat;
        }

        return <div className={"maxProgress"}><Period field={field}
                                                      action={action}
                                                      frontendVersion={frontendVersion}
                                                      event={event}
                                                      duplicationIndex={duplicationIndex}
                                                      onChange={onChange}
                                                      user={user}
                                                      device={DESKTOP}
                                                      dateFormat={dateFormat}
        /></div>
    }

    if (type === "text") {
        const currentValue: string = value as string;
        return <div className={"maxProgress"}><Text field={field}
                                                    frontendVersion={frontendVersion}
                                                    value={currentValue}
                                                    duplicationIndex={duplicationIndex}
                                                    onChange={onChange}
        /></div>
    }

    if (type === "comment") {
        const currentValue: string = value as string;
        return <div className={"maxProgress"}><Comment field={field}
                                                       frontendVersion={frontendVersion}
                                                       value={currentValue}
                                                       duplicationIndex={duplicationIndex}
                                                       onChange={onChange}
        /></div>
    }

    if (type === "select") {
        const currentValue: string = value as string;
        return <div className={"maxProgress"}><CustomSelect field={field}
                                                            frontendVersion={frontendVersion}
                                                            value={currentValue}
                                                            duplicationIndex={duplicationIndex}
                                                            onChange={onChange}
        /></div>
    }

    if (type === "staticSelect") {
        const currentValue: string = value as string;
        return <div className={"maxProgress"}><StaticSelect field={field}
                                                            frontendVersion={frontendVersion}
                                                            value={currentValue}
                                                            duplicationIndex={duplicationIndex}
                                                            onChange={onChange}
                                                            actionName={action.name}
                                                            version={action.version}
        /></div>
    }

    if (type === "dynamicSelect") {
        let currentValue: string[] = [];
        if (typeof value === 'string') {
            currentValue.push(value);
        }

        if (Array.isArray(value)) {
            currentValue = value;
        }
        let optionsSource = field.optionsSource;
        let dependsOnField = field.dependsOnField;
        let dependsOnFieldValue: string | undefined = undefined;
        if (dependsOnField !== undefined) {
            let dependsOnFieldValues = getValue(dependsOnField, event, "0");
            if (Array.isArray(dependsOnFieldValues)) {
                dependsOnFieldValue = dependsOnFieldValues[0];
            } else {
                if (typeof dependsOnFieldValues === 'string') {
                    dependsOnFieldValue = dependsOnFieldValues;
                }
            }
        }

        if (optionsSource === 'organisationsForUser') {
            return <div className={"maxProgress"}><OrganisationDynamicSelect field={field}
                                                                             frontendVersion={frontendVersion}
                                                                             value={currentValue}
                                                                             duplicationIndex={duplicationIndex}
                                                                             onChange={onChange}
                                                                             currentOrganisation={currentOrganisation}
                                                                             optionsSource={optionsSource}
                                                                             actionName={action.name}
                                                                             version={action.version}
                                                                             dependsOnFieldValue={dependsOnFieldValue}
            /></div>
        }

        if (optionsSource === 'hospitalsForStudent') {
            return <div className={"maxProgress"}><HospitalDynamicSelect field={field}
                                                                         frontendVersion={frontendVersion}
                                                                         value={currentValue}
                                                                         duplicationIndex={duplicationIndex}
                                                                         onChange={onChange}
            /></div>
        }
    }


    if (type === "dynamicSelectWithoutTranslation") {
        let currentValue: string[] = [];
        if (typeof value === 'string') {
            currentValue.push(value);
        }

        if (Array.isArray(value)) {
            currentValue = value;
        }
        let optionsSource = field.optionsSource;
        let dependsOnField = field.dependsOnField;
        let dependsOnFieldValue: string | undefined = undefined;
        if (dependsOnField !== undefined) {
            let dependsOnFieldValues = getValue(dependsOnField, event, "0");
            if (Array.isArray(dependsOnFieldValues)) {
                dependsOnFieldValue = dependsOnFieldValues[0];
            } else {
                if (typeof dependsOnFieldValues === 'string') {
                    dependsOnFieldValue = dependsOnFieldValues;
                }
            }
        }

        if (optionsSource === 'coworkersForUser') {
            return <div className={"maxProgress"}><StaffDynamicSelect field={field}
                                                                      frontendVersion={frontendVersion}
                                                                      value={currentValue}
                                                                      duplicationIndex={duplicationIndex}
                                                                      onChange={onChange}
                                                                      optionsSource={optionsSource}
                                                                      actionName={action.name}
                                                                      version={action.version}
                                                                      dependsOnFieldValue={dependsOnFieldValue}
            /></div>
        }

        if (optionsSource === 'icd-10') {
            const currentValue: string = value as string;
            return <div className={"maxProgress"}><ICD10 field={field}
                                                         duplicationIndex={duplicationIndex}
                                                         value={currentValue}
                                                         onChange={onChange}
                                                         device={obj?.device}
            /></div>
        }

        return <div className={"maxProgress"}><SearchItemDynamicSelect field={field}
                                                                       frontendVersion={frontendVersion}
                                                                       value={currentValue}
                                                                       duplicationIndex={duplicationIndex}
                                                                       onChange={onChange}
                                                                       optionsSource={optionsSource}
                                                                       actionName={action.name}
                                                                       version={action.version}
                                                                       dependsOnFieldValue={dependsOnFieldValue}
        /></div>
    }


    if (type === "searchableList") {
        let currentValue: string[] = [];
        if (typeof value === 'string') {
            currentValue.push(value);
        }

        if (Array.isArray(value)) {
            currentValue = value;
        }

        let searchedItemType = field.searchedItem;

        return <div className={"maxProgress"}><SearchableList field={field}
                                                              frontendVersion={frontendVersion}
                                                              value={currentValue}
                                                              duplicationIndex={duplicationIndex}
                                                              searchedItemType={searchedItemType}
                                                              onChange={onChange}
        /></div>
    }

    //age does not need a maxProgress tag since it is put on the 2 subFields
    if (type === "age") {
        return <Age field={field}
                    frontendVersion={frontendVersion}
                    duplicationIndex={duplicationIndex}
                    onChange={onChange}
                    event={event}
                    action={action}
                    user={user}
        />
    }

    if (type === "duration") {
        return <div className={"maxProgress"}><Duration field={field}
                                                        frontendVersion={frontendVersion}
                                                        duplicationIndex={duplicationIndex}
                                                        onChange={onChange}
                                                        event={event}
                                                        action={action}
                                                        user={user}
        /></div>
    }

    if (type === "email") {
        const currentValue: string = value as string;
        return <div className={"maxProgress"}><EmailField
            value={currentValue}
            key={name}
            field={field}
            userEmail={user.email}
            frontendVersion={frontendVersion}
            duplicationIndex={duplicationIndex}
            onChange={onChange}
        /></div>
    }

    if (type === "countersign") {
        const currentValue: string = value as string;
        return <div className={"maxProgress"}><EmailFieldWithUserValidation
            value={currentValue}
            key={name}
            field={field}
            userEmail={user.email}
            frontendVersion={frontendVersion}
            duplicationIndex={duplicationIndex}
            onChange={onChange}
        /></div>
    }


    throw new Error("Can't find create Field <" + type + "> for field <" + name + ">");
}
