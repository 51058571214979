import React, {ChangeEvent, ReactElement} from "react";
import {postObjectOf} from "../../../../infra/BackendService";

interface props {
}

interface state {
    name: string,
    description: string,
    type: string,
    version: string
    rightCreated: string
}

export type RightPayload = {
    name: string,
    description: string,
    type: string,
    version: string
}


class CreateRightForm extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            name: "",
            description: "",
            type: "",
            version: "",
            rightCreated: ""
        };
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleVersionChange = this.handleVersionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleNameChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({name: event.target.value});
    }

    handleDescriptionChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({description: event.target.value});
    }

    handleTypeChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({type: event.target.value});
    }

    handleVersionChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({version: event.target.value});
    }


    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const url: string = "/api/v3/backoffice/right";
        let name = this.state.name;
        let description = this.state.description;
        let type = this.state.type;
        let version = this.state.version;
        const payload: RightPayload = {
            name: name,
            description: description,
            type: type,
            version: version
        }

        postObjectOf(payload, url).then(() => this.setState({
            rightCreated: name
        }));

        this.clearForm()
    }

    render(): ReactElement {
        let label = <label className={"row m-0 pb-1"} htmlFor="createRight">Create right</label>
        const form = <form id={"createRight"} onSubmit={this.handleSubmit}>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.name}
                    onChange={(e) => this.handleNameChange(e)}
                    type="text"
                    placeholder="Name"
                    aria-label="Name"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.description}
                    onChange={(e) => this.handleDescriptionChange(e)}
                    type="text"
                    placeholder="Description"
                    aria-label="Description"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.type}
                    onChange={(e) => this.handleTypeChange(e)}
                    type="text"
                    placeholder="Type"
                    aria-label="Type"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <input
                    value={this.state.version}
                    onChange={(e) => this.handleVersionChange(e)}
                    type="text"
                    placeholder="Version"
                    aria-label="Version"
                />
            </div>
            <div className={"row m-0 pb-2"}>
                <button type="submit" className="btn" aria-label="Save">Save</button>
            </div>
        </form>

        const rightCreated = this.state.rightCreated !== "" ?
            <div>Right {this.state.rightCreated} created</div> : <div/>;
        return <div>
            {label}
            {form}
            {rightCreated}
        </div>

    };

    private clearForm() {
        this.setState({name: "", description: "", type: "", version: ""})
    }
}

export default CreateRightForm;