import React, {createRef, ReactElement} from 'react';
import {Field} from "../../register/v2/Action";
import {FieldComponent, FieldError, FieldProps} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import Value from "../../register/v2/Value";
import {getValid, invalidTime, missingMandatoryField} from "./FieldUtil";
import TimeField from "./TimeField";

interface props extends FieldProps {
}

class TimePeriod extends React.Component<props> implements FieldComponent {
    fromRef: any;
    toRef: any;

    render(): ReactElement {
        const name: string = this.props.field.name;
        const label: string = TranslationService.translation(this.props.field.name);

        const fromField: Field = {
            name: name + ".from",
            fieldLabel: false,
            duplicationIndex: 0
        };

        const toField: Field = {
            name: name + ".to",
            fieldLabel: false,
            duplicationIndex: 0
        };

        this.fromRef = createRef();
        this.toRef = createRef();

        return <div>
            {this.getFields(label, name, fromField, toField)}
        </div>
    }

    private getFields(label: string, name: string, fromField: Field, toField: Field) {
        return <div>
            <h5>
                <label htmlFor={name}>{label}</label>
            </h5>
            <div id={name}
                 aria-label={name}
            >
                <TimeField ref={this.fromRef} field={fromField}/>
                <TimeField ref={this.toRef} field={toField}/>
            </div>
        </div>;
    }

    values(): Value[] {
        if (this.isValid().valid) {
            const result: Value[] = [];
            const fromField: FieldComponent = this.fromRef.current;
            const fromValues: Value[] = fromField.values();
            if (fromValues.length > 0) {
                const value = fromValues[0];
                result.push(value);
            }

            const toField: FieldComponent = this.toRef.current;
            const toValues: Value[] = toField.values();
            if (toValues.length > 0) {
                const value = toValues[0];
                result.push(value);
            }

            return result;
        } else {
            return [];
        }
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        const fromField: FieldComponent = this.fromRef.current;
        const toField: FieldComponent = this.toRef.current;

        const fromValid = fromField.isValid();
        const toValid = toField.isValid();

        if (fromValid.valid && toValid.valid) {
            let from: string = "";
            const fromValues: Value[] = fromField.values();
            if (fromValues.length > 0) {
                const fromValue: Value = fromValues[0];
                from = fromValue.values[0];
            }

            let to: string = "";
            const toValues: Value[] = toField.values();
            if (toValues.length > 0) {
                const toValue: Value = toValues[0];
                to = toValue.values[0];
            }

            const mandatory = this.props.field.mandatory;
            if (mandatory !== undefined && mandatory && from === "") {
                return getValid(name, missingMandatoryField);
            }

            if (to === "") {
                return getValid(name);
            }

            return getValid(name);
        } else {
            return getValid(name, invalidTime);
        }
    }

    clear(): void {
        const fromField: FieldComponent = this.fromRef.current;
        fromField.clear();

        const toField: FieldComponent = this.toRef.current;
        toField.clear();
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        const from = name + ".from";
        const to = name + ".to";

        values.forEach((value: Value) => {
            if (value.fieldName === from) {
                const fromField: FieldComponent = this.fromRef.current;
                const fromValues: Value[] = [];
                fromValues.push(value);

                fromField.set(fromValues);
            }

            if (value.fieldName === to) {
                const toField: FieldComponent = this.toRef.current;
                const toValues: Value[] = [];
                toValues.push(value);

                toField.set(toValues);
            }
        });
    }
}

export default TimePeriod;