/*
    Should we keep track of mandatory values?
    A missing, mandatory value should prevent us from saving.
 */

import {Action, Field} from "../v2/Action";
import EventOLD from "../v2/Event";
import ValueOLD from "../v2/Value";
import {getDuplicationIndex as getDuplicationIndexFromField} from "./DuplicationIndex"
import {shouldBeVisible} from "./ConditionalOn";

const keySeparator: string = '@';

export default interface Event {
    id?: string,
    values: Map<string, Value>
}

export interface Value {
    name: string,
    value: string | string[],
    duplicationIndex: string,
    valid: boolean,
    field: Field
}

export function getKey(name: string, duplicationIndex: string,): string {
    return name + keySeparator + duplicationIndex;
}

function getDuplicationIndex(key: string): string {
    const start: number = key.indexOf(keySeparator);
    if (start !== -1) {
        return key.substring(start + 1);
    }

    return '';
}

export function createEvent(id?: string): Event {
    const event: Event = {
        values: new Map<string, Value>()
    }

    if (id !== undefined) {
        event.id = id;
    }

    return event;
}

export function isEmptyEvent(event: Event): boolean {
    return event.values.size === 0;
}

export function getValue(name: string, event: Event, duplicationIndex: string): string | string[] | undefined {
    const key: string = getKey(name, duplicationIndex);
    const value: Value | undefined = event.values.get(key);

    if (value !== undefined) {
        return value.value;
    }

    return value;
}

export function setValue(name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field, event: Event): Event {
    // It might be tempting to claim that the name and field.name are the same things.
    // This is, however, not necessary true since we sometimes stitch a new ending on the name.
    // An example may be period where period.to and period.from both are thing in the field period

    const key: string = getKey(name, duplicationIndex);
    const currentValues: Map<string, Value> = event.values;
    currentValues.delete(key);

    if (Array.isArray(value) && value.length === 1) {
        value = value[0];
    }

    if (value !== undefined && value.length !== 0) {
        const val: Value = {
            name: name,
            value: value,
            duplicationIndex: duplicationIndex,
            valid: valid,
            field: field
        }

        currentValues.set(key, val);
    }

    removeInvisibleValues(event);

    return event;
}

function removeInvisibleValues(event: Event) {
    const fieldsForRemoval: Field[] = [];

    event.values.forEach((value: Value) => {
        const field: Field = value.field;
        const visible: boolean = shouldBeVisible(field, event);
        if (!visible) {
            fieldsForRemoval.push(field);
        }

        // look up, is there any parent that should be invisible
        const invisibleParent: boolean = hasInvisibleParent(field, event);
        if (invisibleParent) {
            fieldsForRemoval.push(field);
        }
    });

    fieldsForRemoval.forEach((f: Field) => {
        const duplicationIndex: string = getDuplicationIndexFromField(f);
        let name = f.name;
        const key: string = getKey(name, duplicationIndex);
        event.values.delete(key)
    });
}

function hasInvisibleParent(field: Field, event: Event): boolean {
    let parent = field.parent;
    if (parent === undefined) {
        return false;
    } else {
        const visible: boolean = shouldBeVisible(parent, event);
        if (!visible) {
            return true;
        } else {
            return hasInvisibleParent(parent, event);
        }
    }
}

/**
 * Check if the event contains any child for the field
 */
export function hasChildField(field: Field, event: Event, duplicationIndex: string): boolean {
    const name: string = field.name;
    const currentValues: Map<string, Value> = event.values;
    let result: boolean = false;

    currentValues.forEach((value: Value, key: string) => {
        if (key.startsWith(name)) {
            const di: string = getDuplicationIndex(key);
            if (di.startsWith(duplicationIndex)) {
                result = true;
                return;
            }
        }
    });

    return result;
}

/**
 * Delete a value and all of its children
 */
export function deleteValueAndAllChildren(field: Field, name: string, event: Event): Event {
    const currentValues: Map<string, Value> = event.values;
    const duplicationIndex: string = getDuplicationIndexFromField(field)
    const key: string = getKey(name, duplicationIndex);
    const deleted: boolean = currentValues.delete(key);
    const delimitedName = name + '.';

    if (!deleted) {
        const toBeDeleted: string [] = [];
        currentValues.forEach((value: Value, key: string) => {
            if (key.startsWith(delimitedName)) {
                const di: string = getDuplicationIndex(key);
                if (di.startsWith(duplicationIndex)) {
                    toBeDeleted.push(key);
                }
            }
        });

        toBeDeleted.forEach((key: string) => currentValues.delete(key));
    }

    return event;
}

export function hasValue(wantedFieldName: string, wantedValue: string, duplicationIndex: string, event: Event): boolean {
    const currentValues: Map<string, Value> = event.values;
    const key: string = getKey(wantedFieldName, duplicationIndex);

    const hasValue = currentValues.has(key);
    if (hasValue) {
        const currentValue: Value | undefined = currentValues.get(key);
        if (currentValue !== undefined) {
            if (typeof currentValue.value === 'string') {
                return currentValue.value === wantedValue;
            }

            return currentValue.value.includes(wantedValue);
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function getEventForBackend(action: Action, event: Event): EventOLD {
    const name = action.name;
    const version = action.version;
    const mailOnDemandSent = false;

    const converted: EventOLD = {
        name: name,
        version: version,
        values: [],
        mailOnDemandSent: mailOnDemandSent
    };

    if (event.id !== undefined) {
        converted.id = event.id;
    }

    const values: ValueOLD[] = [];
    event.values.forEach((value) => {
        let convertedValue: ValueOLD = {
            fieldName: value.name,
            duplicationIndex: value.duplicationIndex,
            values: []
        };

        if (typeof value.value === 'string') {
            const v: string = value.value;
            convertedValue.values.push(v);
        }

        if (Array.isArray(value.value)) {
            const values: string[] = [...value.value];
            convertedValue.values.push(...values);
        }

        values.push(convertedValue);
    });
    converted.values.push(...values);

    return converted;
}

export function getEventForFrontend(src: EventOLD): Event {
    let target: Event;
    if (src.id !== undefined) {
        target = createEvent(src.id);
    } else {
        target = createEvent();
    }

    src.values.forEach((srcValue: ValueOLD) => {
        const name: string = srcValue.fieldName;

        let value: string | string[];
        if (srcValue.values.length === 1) {
            value = srcValue.values[0];
        } else {
            value = srcValue.values;
        }

        let duplicationIndex: string;
        if (srcValue.duplicationIndex !== undefined) {
            duplicationIndex = srcValue.duplicationIndex;
        } else {
            duplicationIndex = '0';
        }

        const valid: boolean = true;

        const field: Field = {
            name: name,
            duplicationIndex: 0,
            fieldLabel: true
        };

        setValue(name, value, duplicationIndex, valid, field, target);
    });

    return target;
}

export function eventAsJSON(event: Event): string {
    let res: any = {};
    event.values.forEach(function (value: Value, key) {
        res[key] = value
    });

    return JSON.stringify(res, undefined, 2);
}
