import React from 'react';
import ReactDOM from "react-dom";
import "./CommentModal.css";

interface props {
    headline?: string,
    body: string[],
    hide: () => void;
}

interface state {
}

class CommentModal extends React.Component<props, state> {
    modalRef: any;

    constructor(props: props) {
        super(props);
        this.modalRef = React.createRef();
    }

    close() {
        this.props.hide();
    }

    onKeyDown(e: any) {
        if (e.key === "Escape") {
            this.props.hide();
        }
    }

    closeOnOutsideClick = (e: any) => {
        if (e.target.contains(this.modalRef.current)) {
            this.close();
        }
    };

    render() {
        const headline: string = this.props.headline ? this.props.headline : "";

        const body = this.props.body.map(paragraph => {
            return (
                <div key={paragraph}>
                    <p>{paragraph}</p>
                </div>
            )
        });

        // @ts-ignore
        const Content = ({modalRef, closeOnOutsideClick}) =>
            <aside className="c-modal-cover" onClick={(e) => closeOnOutsideClick(e)}>
                <div className="c-modal shadows"
                     onKeyDown={(e) => this.onKeyDown(e)}
                     ref={modalRef}
                     tabIndex={0}
                >

                    <div className={"row"}>
                        <div className={"col"}>
                            <button className="c-modal__close mt-2"
                                    aria-label="Close Modal"
                                    onClick={() => this.close()}
                            >
                                <span className="u-hide-visually">Close</span>
                                <svg className="c-modal__close-icon"
                                     viewBox="0 0 40 40">
                                    <path d="M 10,10 L 30,30 M 30,10 L 10,30"/>
                                </svg>
                            </button>

                        </div>
                    </div>

                    <div className="c-modal__body">
                        <div className={"row m-0 pb-3 justify-content-center"}>
                            <div className={"col-sm-9"}>
                                <h3>
                                    {headline}
                                </h3>
                            </div>
                        </div>

                        <div className={"row m-0 pb-3 justify-content-center"}>
                            <div className={"col-sm-9"}>
                                {body}
                            </div>
                        </div>
                    </div>
                </div>
            </aside>;

        return (
            ReactDOM.createPortal(
                <Content closeOnOutsideClick={this.closeOnOutsideClick} modalRef={this.modalRef}/>,
                // @ts-ignore
                document.querySelector("#modal")
            )
        );
    }
}

export default CommentModal;
