import React, {ReactElement} from 'react';
import "./Register.css"
import {Action, Condition, Conditional, Field, FieldCount, Layout} from "./Action";
import TranslationService from "../../../infra/TranslationService";
import {DESKTOP, MOBILE} from "../../../infra/Constants";
import CustomSelect from "../../fields/v2/CustomSelect";
import DependingSelect from "../../fields/v2/DependingSelect";
import Text from "../../fields/v2/Text";
import DateField from "../../fields/v2/DateField";
import Period from "../../fields/v2/Period";
import TimeField from "../../fields/v2/TimeField";
import TimePeriod from "../../fields/v2/TimePeriod";
import Radio from "../../fields/v2/Radio";
import Checkbox from "../../fields/v2/Checkbox";
import Comment from "../../fields/v2/Comment";
import NumberField from "../../fields/v2/NumberField";
import Duration from "../../fields/v2/Duration";
import Age from "../../fields/v2/Age";
import Associates from "../../fields/v2/Associates";
import TextArea from "../../fields/v2/TextArea";
import {SearchableList} from "../../fields/v2/SearchableList";
import EmailField from "../../fields/v2/EmailField";
import {getLia2HtmlInstructions} from "./Lia2GradingInstructions";
import {EmailFieldWithUserValidation} from "../../fields/v2/EmailFieldWithUserValidation";
import Description from "../../fields/v2/Description";
import Value from "./Value";
import DependingDescription from "../../fields/v2/DependingDescription";
import Label from "../../fields/v2/Label";
import DuplicableField from "../../fields/v2/DuplicableField";
import {FieldComponent} from "../../fields/v2/FieldComponent";
import {
    containsDisregardingDuplicationMarkers,
    isEqualDisregardingDuplicationMarkers
} from "../../fields/v2/DuplicationUtility";

interface props {
    action: Action,
    fieldReferences: Map<string, any>,
    device: string,
    dateFormat: string,
    showPopoverModal?: boolean,
    flipPopover?: () => void,
    userEmail?: string
    prefilledValues?: Value[]
}

class Form extends React.Component<props> {
    private conditionalOnExceptions: string[] = [
        'field',
        'radio',
        'checkbox',
        'number',
        'text',
        'time',
        'age',
        'duration',
        "searchableList",
        "dynamicSelect"
    ];

    render(): ReactElement {
        const fields = this.props.action.fields;
        const actionName: string = this.props.action.name;
        const fieldReferences: any = this.props.fieldReferences;
        const conditionalOnExceptions = this.conditionalOnExceptions;
        const device: string = this.props.device;
        const dateFormat = this.props.dateFormat;
        const showPopoverModal = this.props.showPopoverModal;
        const flipPopover = this.props.flipPopover;
        const shareMyValue = this.shareMyValue.bind(this);
        let userEmail: string = "";
        if (this.props.userEmail !== undefined) {
            userEmail = this.props.userEmail;
        }
        const prefilledValues = this.props.prefilledValues;

        const form = fields.map(field => {
            return Form.drawField(field, fieldReferences, conditionalOnExceptions, device, dateFormat, userEmail, shareMyValue, showPopoverModal, prefilledValues, flipPopover);
        });

        return <div key={actionName}
                    aria-label={"registration form v2"}>
            {form}
        </div>
    }

    private static drawField(field: Field,
                             fieldReferences: Map<string, any>,
                             conditionalOnExceptions: string[],
                             device: string,
                             dateFormat: string,
                             userEmail: string,
                             shareMyValue?: (notifier: string, values: Value[]) => void,
                             showPopoverModal?: boolean,
                             prefilledValues?: Value[],
                             flipPopover?: () => void): React.ReactFragment {
        const rows: FieldCount[] = Form.getRows(field, device);
        let fieldIndex = 0;
        let rowCount = 0;
        const key: string = field.name;

        if (field.duplicable) {
            return this.getDuplicableFields(key, field, fieldReferences, prefilledValues);
        }

        const regularFields = Form.getRegularFields(key, field, rows, rowCount, fieldIndex, fieldReferences, conditionalOnExceptions, device, dateFormat, shareMyValue, userEmail, showPopoverModal, prefilledValues, flipPopover);

        if (field.type === 'field') {
            const label: React.ReactFragment = getField(field, fieldReferences, device, dateFormat, shareMyValue, userEmail, showPopoverModal, prefilledValues, flipPopover);
            return <div key={key}>
                {label}
                {regularFields}
            </div>;
        }

        return regularFields;
    }

    private static getDuplicableFields(key: string, field: Field, fieldReferences: Map<string, any>, prefilledValues: Value[] | undefined): React.ReactElement {
        let name = field.name;
        const ref: any = React.createRef();
        fieldReferences.set(name, ref);
        let duplicableField = <DuplicableField ref={ref}
                                               field={field}
                                               prefilledValues={prefilledValues}/>;
        return <div key={key}>
            {duplicableField}
        </div>;
    }

    private static getRegularFields(key: string,
                                    field: Field,
                                    rows: FieldCount[],
                                    rowCount: number,
                                    fieldIndex: number,
                                    fieldReferences: Map<string, any>,
                                    conditionalOnExceptions: string[],
                                    device: string,
                                    dateFormat: string,
                                    shareMyValue: ((notifier: string, values: Value[]) => void) | undefined,
                                    userEmail: string,
                                    showPopoverModal: boolean | undefined,
                                    prefilledValues: Value[] | undefined,
                                    flipPopover: (() => void) | undefined): React.ReactElement {

        const fields = rows.map((row: FieldCount) => {
            const rowKey = key + "." + rowCount++;
            if (field.fields !== undefined) {
                const columns: Field[] = [];
                for (let k = 0; k < row.fields; k++) {
                    let f: Field = field.fields[fieldIndex];
                    if (f !== undefined) {
                        fieldIndex++;
                        columns.push(f);
                    }
                }

                return <div key={rowKey} className={"row"} aria-label={rowKey}>
                    {Form.drawColumns(columns, fieldReferences, device, dateFormat, userEmail, shareMyValue, showPopoverModal, prefilledValues, flipPopover)}
                </div>;
            } else {
                return <div key={rowKey} className={"row"} aria-label={rowKey}>
                    {Form.drawColumn(field, fieldReferences, device, dateFormat, userEmail, shareMyValue, showPopoverModal, prefilledValues, flipPopover)}
                </div>;
            }
        });

        let label = Form.getLabel(field.name);

        const type = field.type;
        if (type !== undefined && conditionalOnExceptions.includes(type)) {
            label = <div/>;
        }

        return <div key={key}>
            {label}
            {fields}
        </div>;
    }

    public static drawColumn(field: Field,
                             fieldReferences: Map<string, any>,
                             device: string,
                             dateFormat: string,
                             userEmail: string,
                             shareMyValue?: (notifier: string, values: Value[]) => void,
                             showPopoverModal?: boolean,
                             prefilledValues?: Value[],
                             flipPopover?: () => void) {
        const key = field.name;
        const fields = getFields(field, fieldReferences, device, dateFormat, shareMyValue, userEmail, prefilledValues, showPopoverModal, flipPopover);

        return <div key={key} className={"col"}>
            {fields}
        </div>;
    }

    public static drawColumns(fields: Field[],
                              fieldReferences: Map<string, any>,
                              device: string,
                              dateFormat: string,
                              userEmail: string,
                              shareMyValue?: (notifier: string, values: Value[]) => void,
                              showPopoverModal?: boolean,
                              prefilledValues?: Value[],
                              flipPopover?: () => void): React.ReactFragment[] {
        const columns: React.ReactFragment[] = [];
        fields.forEach((field) => {
            if (field.hidden !== undefined && field.hidden) {
                return;
            }
            const column = Form.drawColumn(field, fieldReferences, device, dateFormat, userEmail, shareMyValue, showPopoverModal, prefilledValues, flipPopover);
            columns.push(column)
        });

        return columns;
    }

    static getRows(field: Field, device: string): FieldCount[] {
        let rows: FieldCount[] = [];

        if (field.layout !== undefined) {
            const layout: Layout = field.layout;

            if (device === DESKTOP) {
                rows = layout.desktop.rows;
            }

            if (device === MOBILE) {
                rows = layout.mobile.rows;
            }

            if (field.fields !== undefined) {
                let sum = rows.reduce((partial_sum, a) => partial_sum + a.fields, 0);

                while (sum < field.fields.length) {
                    rows.push({fields: 1});
                    sum++;
                }
            }
        }

        if (rows.length === 0) {
            if (field.fields === undefined) {
                rows.push({fields: 1});
            } else {
                const fields = field.fields.length;
                rows.push({fields: fields});
            }
        }

        return rows;
    }

    public static getLabel(fieldName: string, index?: number): React.ReactFragment {
        let label = TranslationService.translation(fieldName);

        if (index !== undefined) {
            label = label + " #" + index;
        }

        return <div className={"pt-4"}>
            <hr/>
            <h4 aria-label={fieldName + ".label"}>{label}</h4>
        </div>;
    }

    private shareMyValue(notifier: string, values: Value[]): void {
        const fieldReferences = this.props.fieldReferences;

        let allValues: Value[] = [];
        fieldReferences.forEach((child) => {
            const field: FieldComponent = child.current;
            if (field !== null) {
                const items = field.values();
                items.forEach((value: Value) => {
                    allValues.push(value);
                });
            }
        });
        allValues = addMissingToAllValues(values, allValues);

        const filtered = new Map(fieldReferences);
        filtered.delete(notifier);

        Form.notifyConditionalOn(filtered, allValues);
    }

    public static notifyConditionalOn(fieldReferences: Map<string, any>, values: Value[]): void {
        fieldReferences.forEach((child) => {
            if (child !== null && child.current !== null) {
                const conditionalField: any = child.current;

                if (conditionalField.props.field.conditionalOn !== undefined) {
                    const conditionalOn: Conditional = conditionalField.props.field.conditionalOn;
                    if (conditionalOn !== undefined) {
                        const operator: string = conditionalOn.operator;
                        let predicates: (Value | Condition)[] = conditionalOn.predicates;

                        Form.applyOr(operator, predicates, values, conditionalField);
                        Form.applyAnd(operator, predicates, values, conditionalField);

                    }
                }
            }
        });
    }

    private static applyOr(operator: string, predicates: (Value | Condition)[], values: Value[], conditionalField: any) {
        if (operator === 'or') {
            for (let predicateIndex = 0; predicateIndex < predicates.length; predicateIndex++) {
                const predicate: Value | Condition = predicates[predicateIndex];
                if (!(predicate instanceof Condition)) {
                    const wantedFieldName: string = predicate.fieldName;

                    for (let valuesIndex = 0; valuesIndex < values.length; valuesIndex++) {
                        const value: Value = values[valuesIndex];
                        const currentFieldName: string = value.fieldName;

                        if (isEqualDisregardingDuplicationMarkers(currentFieldName, wantedFieldName)) {
                            const wantedFieldValues: string[] = predicate.values;
                            for (let currentValueIndex = 0; currentValueIndex < value.values.length; currentValueIndex++) {
                                const currentValue: string = value.values[currentValueIndex];

                                if (containsDisregardingDuplicationMarkers(wantedFieldValues, currentValue)) {
                                    if (!conditionalField.state.visible) {
                                        conditionalField.setState({visible: true});
                                    }

                                    return;
                                }
                            }
                            if (conditionalField.state.visible) {
                                conditionalField.clear();
                            }
                        }
                    }
                }
            }
        }
    }

    private static applyAnd(operator: string, predicates: (Value | Condition)[], values: Value[], conditionalField: any) {
        if (operator === 'and') {
            const wanted = predicates.length;
            let numberOfExactMatches: number = 0;
            let numberOfTimeWeHaveSeenAWantedField: number = 0;
            for (let predicateIndex = 0; predicateIndex < wanted; predicateIndex++) {
                const predicate: Value | Condition = predicates[predicateIndex];
                if (!(predicate instanceof Condition)) {
                    const wantedFieldName: string = predicate.fieldName;

                    for (let valuesIndex = 0; valuesIndex < values.length; valuesIndex++) {
                        const value: Value = values[valuesIndex];
                        const currentFieldName: string = value.fieldName;

                        if (isEqualDisregardingDuplicationMarkers(currentFieldName, wantedFieldName)) {
                            const wantedFieldValues: string[] = predicate.values;
                            for (let currentValueIndex = 0; currentValueIndex < value.values.length; currentValueIndex++) {
                                const currentValue: string = value.values[currentValueIndex];

                                if (containsDisregardingDuplicationMarkers(wantedFieldValues, currentValue)) {
                                    numberOfExactMatches++;
                                }
                            }
                            numberOfTimeWeHaveSeenAWantedField++;
                        }
                    }
                }
            }

            if (numberOfTimeWeHaveSeenAWantedField === 0) {
                return;
            }

            if (numberOfExactMatches === wanted) {
                if (!conditionalField.state.visible) {
                    conditionalField.setState({visible: true});
                }

                return;
            }

            if (conditionalField.state.visible) {
                conditionalField.clear();
            }
        }
    }
}

export default Form;

export function addMissingToAllValues(additionalValues: Value[], allValues: Value[]): Value[] {
    let notFound: boolean = false;
    const allFieldNames: string[] = [];
    allValues.forEach((b: Value) => {
        allFieldNames.push(b.fieldName);
    });

    additionalValues.forEach((a: Value) => {
        if (allValues.length === 0) {
            notFound = true;
        } else {
            if (!allFieldNames.includes(a.fieldName)) {
                notFound = true;
            } else {
                allValues.forEach((b: Value) => {
                    if (a.fieldName === b.fieldName) {
                        let aValues = a.values;
                        let bValues = b.values;
                        if (aValues.length === bValues.length) {
                            bValues.forEach((bVal: string) => {
                                if (!aValues.includes(bVal)) {
                                    notFound = true;
                                }
                            });
                        }
                    }
                });
            }
        }

        if (notFound) {
            allValues.push(a);
        }
        notFound = false;
    });

    return allValues;
}

export function getFields(field: Field,
                          fieldReferences: Map<string, any>,
                          device: string,
                          dateFormat: string,
                          shareMyValue?: (notifier: string, values: Value[]) => void,
                          userEmail?: string,
                          prefilledValues?: Value[],
                          showPopoverModal?: boolean,
                          flipPopover?: () => void): React.ReactFragment {

    const fields = field.fields;
    const key: string = field.name;
    let fieldInstance;

    if (field.duplicable) {
        return getDuplicableFields(key, field, fieldReferences, prefilledValues);
    }

    fieldInstance = getField(field, fieldReferences, device, dateFormat, shareMyValue, userEmail, showPopoverModal, prefilledValues, flipPopover);
    if (fields === undefined || fields.length === 0) {
        return <div key={key}>
            {fieldInstance}
        </div>
    } else {
        let fieldInstances = fields.map(field => {
            if (field.duplicable) {
                return getDuplicableFields(key, field, fieldReferences, prefilledValues);
            }
            return getFields(field, fieldReferences, device, dateFormat, shareMyValue, userEmail, prefilledValues, showPopoverModal, flipPopover);
        });

        return <React.Fragment key={key}>
            <div key={key}>
                {fieldInstance}
            </div>
            {fieldInstances}
        </React.Fragment>;
    }
}

function getDuplicableFields(key: string, field: Field, fieldReferences: Map<string, any>, prefilledValues: Value[] | undefined): React.ReactElement {
    let name = field.name;
    const ref: any = React.createRef();
    fieldReferences.set(name, ref);
    let duplicableField = <DuplicableField ref={ref}
                                           field={field}
                                           prefilledValues={prefilledValues}/>;
    return <div key={key}>
        {duplicableField}
    </div>;
}

export function getField(field: Field,
                         fieldReferences: Map<string, any>,
                         device: string,
                         dateFormat: string,
                         shareMyValue?: (notifier: string, values: Value[]) => void,
                         userEmail?: string,
                         showPopoverModal?: boolean,
                         prefilledValues?: Value[],
                         flipPopover?: () => void): React.ReactFragment {
    const name = field.name;
    const type = field.type;
    const ref: any = React.createRef();
    fieldReferences.set(name, ref);

    /*
    Enable when they will be used
    const datef = UserSessionUtilService.getDateFormat();
    const resol = UserSessionUtilService.getResolution();
    const email = UserSessionUtilService.getUserEmail();
     */

    if (type === "field") {
        let key = name + ".label";
        return <Label ref={ref}
                      key={key}
                      shareMyValue={shareMyValue}
                      field={field}
        />
    }
    if (type === "select" || type === 'dynamicSelect') {
        return <CustomSelect ref={ref}
                             key={name}
                             field={field}
        />
    }

    if (type === "dependingSelect") {
        return <DependingSelect ref={ref}
                                key={name}
                                field={field}
        />
    }

    if (type === "text") {
        return <Text ref={ref}
                     key={name}
                     shareMyValue={shareMyValue}
                     field={field}/>
    }

    if (type === "textarea") {
        return <TextArea ref={ref}
                         field={field}/>
    }

    if (type === "date") {
        return <DateField ref={ref}
                          key={name}
                          field={field}
                          dateFormat={dateFormat}
        />
    }

    if (type === "period") {
        return <Period ref={ref}
                       key={name}
                       field={field}
                       device={device}
                       dateFormat={dateFormat}
        />
    }

    if (type === "time") {
        return <TimeField ref={ref}
                          key={name}
                          shareMyValue={shareMyValue}
                          field={field}
                          prefilledValues={prefilledValues}
        />
    }

    if (type === "timeperiod") {
        return <TimePeriod ref={ref}
                           key={name}
                           field={field}
        />
    }

    if (type === "radio") {
        if (name === "traindriver-lia-2.forward") {
            return <div>
                <div>
                    <div className={"row mb-3"}/>
                    <div className={"btn-link"}
                         onClick={flipPopover}>{TranslationService.translation("traindriver-lia-2.instructions")}</div>
                    {getLia2HtmlInstructions(showPopoverModal, flipPopover)}
                </div>
                <Radio ref={ref}
                       key={name}
                       field={field}
                       shareMyValue={shareMyValue}
                       device={device}
                       prefilledValues={prefilledValues}
                />
            </div>
        }
        return <Radio ref={ref}
                      key={name}
                      field={field}
                      shareMyValue={shareMyValue}
                      device={device}
                      prefilledValues={prefilledValues}
        />
    }

    if (type === "checkbox") {
        return <Checkbox ref={ref}
                         key={name}
                         shareMyValue={shareMyValue}
                         enableAllOptions={false}
                         field={field}
                         device={device}
        />
    }

    if (type === "comment") {
        return <Comment ref={ref}
                        field={field}/>
    }

    if (type === "number") {
        return <NumberField ref={ref}
                            shareMyValue={shareMyValue}
                            field={field}/>
    }

    if (type === "duration") {
        return <Duration ref={ref}
                         key={name}
                         field={field}
        />
    }

    if (type === "age") {
        return <Age ref={ref}
                    key={name}
                    field={field}
        />
    }

    if (type === "associate") {
        return <Associates ref={ref}
                           key={name}
                           field={field}
        />
    }

    if (type === "searchableList") {
        return <SearchableList ref={ref}
                               key={name}
                               field={field}
                               searchedItemType={field.searchedItem}
        />
    }

    if (type === "email") {
        return <EmailField ref={ref}
                           key={name}
                           field={field}
        />
    }

    if (type === "countersign") {
        return <EmailFieldWithUserValidation
            ref={ref}
            key={name}
            field={field}
            userEmail={userEmail}
        />
    }

    if (type === "sharingInvitation") {
        return <EmailFieldWithUserValidation ref={ref}
                                             key={name}
                                             field={field}
                                             userEmail={userEmail}

        />
    }

    if (type === "description") {
        return <Description ref={ref}
                            key={name}
                            field={field}
        />
    }

    if (type === "dependingDescription") {
        return <DependingDescription ref={ref}
                                     key={name}
                                     field={field}
        />
    }

    throw new Error("Can't find create FieldComponent <" + type + "> for field <" + name + ">");
}
