import React from 'react';
import {BackendContext} from "../../infra/BackendContext";
import {EventBackendService} from "../register/v2/EventBackendService";
import {Organisation} from "../model/Organisation";
import {ArchivedDrugBook, ArchivedDrugBooks} from "./models/Models";
import './DrugBookArchive.css'
import ArchivedDrugBookEventFocus from "./ArchivedDrugBookEventFocus";

interface props {
    organisation: Organisation
}

interface state {
    spinner: boolean,
    archivedDrugBooks: ArchivedDrugBooks,
    selectedDrugBook: ArchivedDrugBook | undefined
}

class DrugBookArchive extends React.Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);

        this.closeFocus = this.closeFocus.bind(this);

        this.state = {
            spinner: true,
            archivedDrugBooks: {
                archivedDrugBooks: [],
            },
            selectedDrugBook: undefined
        }
    }

    async componentDidMount() {
        let organisation: string = this.props.organisation.organisationId;

        const path: string = `/api/v1/knarkbok/druglord/archive/${organisation}`
        const url: string = EventBackendService.getUrl2(path);
        const response: any = await this.context.get(url, {
            success: "",
            failure: ""
        }) as any;

        let archivedDrugBooks: ArchivedDrugBooks;
        if (response.data === undefined) {
            archivedDrugBooks = {
                archivedDrugBooks: []
            };
        } else {
            archivedDrugBooks = response.data;
        }

        this.setState({
            spinner: false,
            archivedDrugBooks: archivedDrugBooks
        });
    }

    render(): React.JSX.Element {
        if (this.state.spinner) {
            return <div aria-label={"drug-book-archive-spinner"}
                        className={"spinner"}/>
        }

        const selectedDrugBook: ArchivedDrugBook | undefined = this.state.selectedDrugBook;
        if (selectedDrugBook) {
            return <ArchivedDrugBookEventFocus
                archivedDrugBook={selectedDrugBook}
                closeFocus={this.closeFocus}
            />
        }

        const archivedBooks: React.JSX.Element[] = this.getArchivedBooks();
        return <div className={"container"}>
            <div aria-label={'Drug Book Archive'}
                 className={"row"}>
                <h1>Arkiverade förbrukningsjournaler</h1>
            </div>
            {archivedBooks}
        </div>;
    }

    private getArchivedBooks(): React.JSX.Element[] {
        const archivedDrugBooks: ArchivedDrugBooks = this.state.archivedDrugBooks;
        const archivedDrugBookResponses: ArchivedDrugBook[] = archivedDrugBooks.archivedDrugBooks;

        if (archivedDrugBookResponses !== undefined && archivedDrugBookResponses.length > 0) {
            return archivedDrugBookResponses.map((archived: ArchivedDrugBook, index: number) => {
                let grayed: string;
                if (index % 2 === 0) {
                    grayed = " alert-secondary ";
                } else {
                    grayed = "";
                }

                const name: string = archived.name;
                const activeFrom: string = archived.activeFrom;
                const activeTo: string = archived.activeTo;

                return <div className={"container hover-pointer" + grayed}
                            onClick={() => this.showDrugBookEvents(archived)}>
                    <div className={"row"}>
                        <h3>{name}</h3>
                    </div>
                    <div className={"row"}>
                        Aktiv: {activeFrom} -- {activeTo}
                    </div>
                </div>
            });
        }

        return this.noArchivedBooks();
    }

    private noArchivedBooks(): React.JSX.Element[] {
        return [
            <div className={"row"}>
                Det finns inga arkiverade förbrukningsjournaler
            </div>
        ];
    }

    private showDrugBookEvents(archivedBook: ArchivedDrugBook) {
        this.setState({
            selectedDrugBook: archivedBook
        });
    }

    private closeFocus(): void {
        this.setState({
            selectedDrugBook: undefined
        });
    }
}

export default DrugBookArchive;
