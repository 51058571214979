import React, {Component} from 'react';
import "./VerticalNavBar.css";
import TranslationService from "../../infra/TranslationService";

class VerticalNavBar extends Component {
    render() {
        const reports = TranslationService.translation("supervisor.headline.reports");
        const users = TranslationService.translation("supervisor.headline.users");
        const statistics = TranslationService.translation("supervisor.headline.statistics");

        return (
            <div className="vertical-nav col-l" id="sidebar">

                {
                    this.isCapio() ? <React.Fragment>
                            <p className="font-weight-bold p-2 mb-0 vertical-nav-rubrik">{reports}</p>
                            <ul className="nav flex-column">
                                <li className="nav-item vertical-nav-item" id={"capio-deviation-vn"}>
                                    <button className="nav-link btn btn-supervisor-vertical"
                                            onClick={() => {
                                                this.setVerticalActive("capio-deviation-vn")
                                                this.props.setContent("capio-deviation");
                                            }}>
                                        Avvikelse
                                    </button>
                                </li>
                                <li className="nav-item vertical-nav-item" id={"capio-overtime-supervisor-vn"}>
                                    <button className="nav-link btn btn-supervisor-vertical"
                                            onClick={() => {
                                                this.setVerticalActive("capio-overtime-supervisor-vn")
                                                this.props.setContent("capio-overtime-supervisor");
                                            }}>
                                        Övertid
                                    </button>
                                </li>
                            </ul>
                        </React.Fragment>
                        :
                        ""
                }

                <p className="font-weight-bold p-2 mb-0 vertical-nav-rubrik">{users}</p>
                <p className="font-weight-bold p-2 mb-0 vertical-nav-rubrik">{statistics}</p>
                <ul className="nav flex-column"/>
            </div>

        );
    }

    isCapio() {
        let capio = false;
        let user = this.props.userData.user;
        user.rights.map(right => {
            if (right.name === "supervisor-capio") {
                capio = true;
            }
            return null;
        })

        return capio;
    }

    setVerticalActive(id) {
        let allNavBarItems = document.getElementsByClassName("vertical-nav-item");
        for (let i = 0; i < allNavBarItems.length; i++) {
            allNavBarItems[i].classList.remove("vertical-active");
        }

        let wanted = document.getElementById(id);
        wanted.classList.add("vertical-active");
    }
}

export default VerticalNavBar;
