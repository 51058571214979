import React from "react";
import TranslationService from "../../../infra/TranslationService";
import "./StatisticsNumberFields.css"
import {Field} from "../../register/v2/Action";
import {FilterValue, FilterValueDate, FilterValueType} from "./FilterValue";
import DateField from "../../fields/v2/DateField";
import {V3} from "../../../infra/Constants";


interface props {
    field: Field
    onChange: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
    onValueChangeNG: (filterValue: FilterValue) => void;
}

interface state {
    from: string | undefined
    to: string | undefined
}

class StatisticsDateFields extends React.Component<props, state> {
    constructor(props: Readonly<props>) {
        super(props);

        this.state = {
            from: "",
            to: ""
        }
    }

    render() {
        return <div className={"container m-0 p-0"}>
            <div className={"col m-0 p-0"}>
                <div className={"row m-0 pt-2"}>
                    <div className={"col m-0 p-0"}>
                        <h6>{TranslationService.translation(this.props.field.name) + ":"}</h6>
                    </div>
                </div>
                <div className={"row m-0 pt-1"}>
                    <label className={"pt-1 pr-2"}> Från:</label> <DateField frontendVersion={V3}
                                                                             dateFormat={"yyyy-MM-dd"}
                                                                             value={this.state.from}
                                                                             field={{
                                                                                 ...this.props.field,
                                                                                 showLabel: false
                                                                             }}
                                                                             onChange={this.from.bind(this)}/>
                    <label className={"pt-1 pr-2"}> Till: </label><DateField frontendVersion={V3}
                                                                             dateFormat={"yyyy-MM-dd"}
                                                                             value={this.state.to}
                                                                             field={{
                                                                                 ...this.props.field,
                                                                                 showLabel: false
                                                                             }}
                                                                             onChange={this.to.bind(this)}/>
                </div>
            </div>
        </div>
    }


    private updateProps() {
        const {from, to} = this.state;
        const {onValueChangeNG, field} = this.props;

        const filterValue: FilterValueDate = {
            fieldName: field.name,
            type: FilterValueType.Date,
            from: from ? from : " ",
            to: to ? to : " ",
            erase: this.erase
        };

        onValueChangeNG(filterValue);
    }

    from(name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) {
        if (name || duplicationIndex || valid || field) {
            //thanks linter
        }
        let arrayGuarded;
        if (Array.isArray(value) && value.length > 0) {
            arrayGuarded = value[0];
        } else {
            arrayGuarded = value as string;
        }

        this.setState({from: arrayGuarded}, () => this.updateProps());
    }

    to(name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) {
        if (name || duplicationIndex || valid || field) {
            //thanks linter
        }
        let arrayGuarded;
        if (Array.isArray(value) && value.length > 0) {
            arrayGuarded = value[0];
        } else {
            arrayGuarded = value as string;
        }

        this.setState({to: arrayGuarded}, () => this.updateProps());
    }

    erase = () => {
        this.setState({
            from: "",
            to: ""
        });
    }
}

export default StatisticsDateFields;
