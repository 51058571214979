import React, {ReactElement} from "react";
import {Right} from "./Right";
import {UserOldRoles} from "../../../model/UserOldRoles";
import {Role} from "./Role";
import {fetchArrayOf} from "../../../../infra/BackendService";
import {Organisation} from "./Organisation";

interface props {
    right: Right
}

interface state {
    roles: Role[]
    users: UserOldRoles[],
    organisations: Organisation[]
}

class ManageRights extends React.Component<props, state> {


    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            roles: [],
            users: [],
            organisations: []
        }
    }


    render(): ReactElement {
        const roles = this.state.roles;
        const roleRows = roles.map((role: Role, index: number) => {
                let className: string = ManageRights.getClassName("col-5", index);
                const roleName = role.name;
                return <div key={"roleRow " + roleName} className={"row"} aria-label={roleName}>
                    <div className={className}>
                        {roleName}
                    </div>
                </div>
            }
        )

        const users = this.state.users;
        const userRows = users.map((user: UserOldRoles, index: number) => {
                let className: string = ManageRights.getClassName("col-5", index);
                const userName = user.userName;
                const fullName = user.firstName + " " + user.lastName;
                return <div key={"userRow " + userName} className={"row"} aria-label={userName + " " + fullName}>
                    <div className={className}>
                        {userName} {fullName}
                    </div>
                </div>
            }
        )
        const organisations = this.state.organisations;
        const organisationRows = organisations.map((organisation: Organisation, index: number) => {
            let className: string = ManageRights.getClassName("col-5", index);
            const organisationName = organisation.name;
            return <div key={"organisationRow " + organisationName} className={"row"} aria-label={organisationName}>
                <div className={className}>
                    {organisationName}
                </div>
            </div>
            }
        )
        const rightName = this.props.right.name;
        return <div>
            <div className={"row mt-3"}>
                <h1>{rightName}</h1>
            </div>
            <div className={"row mt-3"}>
                <h3>Roles</h3>
            </div>
            {roleRows}
            <div className={"row mt-3"}>
                <h3>Users</h3>
            </div>
            {userRows}
            <div className={"row mt-3"}>
                <h3>Organisations</h3>
            </div>
            {organisationRows}
        </div>;
    }

    componentDidMount() {
        this.getRightAffiliations();
    }

    private getRightAffiliations() {
        const right = this.props.right;
        const rightId = right.id;

        this.getRoles(rightId);
        this.getUsers(rightId);
        this.getOrganisations(rightId);
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        if (prevProps.right !== this.props.right) {
            this.getRightAffiliations();
        }
    }

    private getUsers(rightId: string) {
        const usersUrl = "/api/v3/backoffice/right/users/" + rightId
        fetchArrayOf<UserOldRoles>(usersUrl).then((users) => {
            this.setState({users: users});
        });
    }

    private getRoles(rightId: string) {
        const rolesUrl = "/api/v3/backoffice/right/roles/" + rightId
        fetchArrayOf<Role>(rolesUrl).then((roles) => {
            this.setState({roles: roles});
        });
    }

    private getOrganisations(rightId: string) {
        const rolesUrl = "/api/v3/backoffice/right/organisations/" + rightId
        fetchArrayOf<Organisation>(rolesUrl).then((organisations) => {
            this.setState({organisations: organisations});
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    private static getClassName(src: string, index: number) {
        const rowNumber = index + 1;
        if (rowNumber % 2 === 0) {
            return src + " grayed";
        }

        return src;
    }
}

export default ManageRights;