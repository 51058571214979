import React from 'react';
import "./FrontPage.css";
import {
    FrontPageAbout_desktop_com,
    FrontPageAbout_desktop_fi,
    FrontPageAbout_desktop_se,
    FrontPageAbout_mobile_com,
    FrontPageAbout_mobile_fi,
    FrontPageAbout_mobile_se
} from "./FrontPageAboutLocalized";
import {changeLayoutWidth, DESKTOP, MOBILE} from "../../infra/Constants";
import "./FrontPageAbout.css";

interface props {
    domain: string
}

interface state {
    layout: string
}

class FrontPageAbout extends React.Component<props, state> {
    constructor(props: props) {
        super(props);
        this.state = {
            layout: MOBILE
        }
        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    private onResize() {
        const width = window.innerWidth;
        const layout = this.state.layout;

        if (width > changeLayoutWidth && layout === MOBILE) {
            this.setState({
                layout: DESKTOP
            });
        }

        if (width <= changeLayoutWidth && layout === DESKTOP) {
            this.setState({
                layout: MOBILE
            });
        }
    }

    render(): React.ReactFragment {
        const domain = this.props.domain;
        const layout: string = this.state.layout;

        if (layout === DESKTOP) {
            if (domain === 'se') {
                return FrontPageAbout_desktop_se()
            }
            if (domain === 'com') {
                return FrontPageAbout_desktop_com()
            }
            if (domain === 'fi') {
                return FrontPageAbout_desktop_fi()
            }

            return FrontPageAbout_desktop_com()
        } else {
            if (domain === 'se') {
                return FrontPageAbout_mobile_se()
            }
            if (domain === 'com') {
                return FrontPageAbout_mobile_com()
            }
            if (domain === 'fi') {
                return FrontPageAbout_mobile_fi()
            }

            return FrontPageAbout_mobile_com()
        }
    }
}

export default FrontPageAbout;
