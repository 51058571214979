import React, {ChangeEvent, ReactElement} from 'react';
import {FieldComponent, FieldError} from "./FieldComponent";
import TranslationService from "../../../infra/TranslationService";
import Value from "../../register/v2/Value";
import {createSingleValue, getValid, invalidTime, missingMandatoryField} from "./FieldUtil";
import "./TimeField.css"
import ConditionalField, {ConditionalProps, ConditionalState} from "./ConditionalField";
import {V3} from "../../../infra/Constants";
import {Field} from "../../register/v2/Action";

interface props extends ConditionalProps {
    frontendVersion?: string,
    value?: string,
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
}

interface state extends ConditionalState {
    value: string
}

class TimeField extends ConditionalField<props, state> implements FieldComponent {
    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            ...this.state,
            value: ""
        }
    }

    render(): ReactElement {
        const name = this.props.field.name;
        const label: string = TranslationService.translation(name);
        const value = this.getValue();
        const onChange = (e: ChangeEvent<HTMLInputElement>) => this.onChange(e);
        const onBlur = (e: ChangeEvent<HTMLInputElement>) => this.onBlur(e);

        let showLabel = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        const field = TimeField.getField(label, showLabel, name, value, onChange, onBlur);

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            return <div>
                {field}
            </div>
        } else {
            return this.renderField(field, name);
        }
    }

    private getValue() {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            let value = this.props.value;
            if (value !== undefined) {
                return value;
            } else {
                return '';
            }
        } else {
            return this.state.value;
        }
    }

    componentDidMount() {
        let prefilledValues = this.props.prefilledValues;
        if (prefilledValues !== undefined) {
            this.set(prefilledValues);
        }
    }

    private static getField(labelText: string,
                            showLabel: boolean,
                            name: string,
                            value: string,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
                            onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void): React.ReactFragment {

        const label = <h5>
            <div className={"row"}>
                <div className={"col"}>
                    <label htmlFor={name}
                           data-testid={name + ".label"}>
                        {labelText}
                    </label>
                </div>
            </div>
        </h5>;

        const field = <div className={"row"}>
            <div className={"col"}>
                <input type={"text"}
                       id={name}
                       aria-label={name}
                       value={value}
                       placeholder={"HH:MM"}
                       onChange={onChange}
                       onBlur={onBlur}
                />
            </div>
        </div>;

        if (!showLabel) {
            return <div>
                {field}
            </div>;
        }

        return <div>
            {label}
            {field}
        </div>;
    }

    private onChange(e: React.ChangeEvent<HTMLInputElement>) {
        let currentValue: string = e.currentTarget.value;
        currentValue = cleanTimeInput(currentValue);

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            this.shareChange(currentValue);
        } else {
            this.setState({value: currentValue})
        }
    }

    private onBlur(e: React.ChangeEvent<HTMLInputElement>) {
        let currentValue: string = e.currentTarget.value;
        const fixedValue = formatAsTime(currentValue);

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            this.shareChange(fixedValue);
        } else {
            this.setState({value: fixedValue});
        }
    }

    private shareChange(currentValue: string) {
        let field = this.props.field;
        const name: string = field.name;
        let duplicationIndex: string = '0';
        if (this.props.duplicationIndex !== undefined) {
            duplicationIndex = this.props.duplicationIndex;
        }
        const valid = validateTime(currentValue);

        if (this.props.onChange) {
            this.props.onChange(name, currentValue, duplicationIndex, valid, field);
        }
    }

    values(): Value[] {
        const name = this.props.field.name;
        const value = this.getValue();
        if (this.isValid().valid) {
            if (value !== "") {
                return createSingleValue(name, value);
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        const value = this.getValue();
        let mandatory: boolean = false;

        if (this.props.field.mandatory !== undefined) {
            mandatory = this.props.field.mandatory;
        }

        if (mandatory) {
            const valid = value !== "";
            if (valid) {
                const validTime = validateTime(value);
                if (validTime) {
                    return getValid(name);
                } else {
                    return getValid(name, invalidTime);
                }
            } else {
                return getValid(name, missingMandatoryField);
            }
        }

        const validDate = validateTime(value);
        if (validDate) {
            return getValid(name);
        } else {
            return getValid(name, invalidTime);
        }
    }

    clear(): void {
        let visible = this.isConditionalfield();
        this.setState({
            value: "",
            visible: visible
        })
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        values.forEach((value: Value) => {
            if (value.fieldName === name) {
                const newValue: string = value.values[0];
                this.setState({value: newValue});
            }
        });
    }
}

export default TimeField;

export function cleanTimeInput(dirtyStr: string): string {
    let allowedChars: Set<string> = new Set(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ':']);

    let cleanStr: string = "";

    let size: number = dirtyStr.length;
    for (let i = 0; i < size; i++) {
        let character: string = dirtyStr[i];

        if (allowedChars.has(character)) {
            cleanStr += character;
        }
    }

    if (cleanStr.length === 3 && !cleanStr.endsWith(':')) {
        let hours: string = cleanStr.substr(0, 2);
        let minute: string = cleanStr.substr(2, 1);

        cleanStr = hours + ':' + minute;
    }

    if (cleanStr.endsWith('::')) {
        let end = cleanStr.length - 1;
        cleanStr = cleanStr.substr(0, end);
    }

    while (cleanStr.includes("::")) {
        cleanStr = cleanStr.replace("::", ":");
    }

    if (cleanStr.indexOf(":") === 1) {
        cleanStr = "0" + cleanStr;
    }

    if (cleanStr.length > 5) {
        cleanStr = cleanStr.substr(0, 5);
    }

    return cleanStr;
}

export function formatAsTime(currentValue: string): string {
    if (currentValue.length === 1 && !isNaN(parseInt(currentValue))) {
        return "0" + currentValue + ":00";
    }

    if (currentValue.length > 1 && /^\d+$/.test(currentValue)) { //regex checks for literal numbers in string
        return currentValue + ":00";
    }

    if (currentValue.length > 1 && currentValue.includes(":")) {
        const values = currentValue.split(":")

        if (values[1].length === 1 && !isNaN(parseInt(values[1]))) {
            return values[0] + ":0" + values[1];
        }
    }

    return currentValue;
}

export function validateTime(sample: string): boolean {
    if (sample === undefined || sample.length === 0) {
        // if time isn't a mandatory field, an empty string is a valid value
        return true;
    }

    if (sample.length === 5) {
        let res: string[] = sample.split(":");
        let hour: number = parseInt(res[0]);

        if (hour >= 0 && hour < 24) {
            let minute: number = parseInt(res[1]);

            if (minute >= 0 && minute < 60) {
                return true;
            }
        }

        return false;
    }

    return false;
}
