import ReportTable, {Report, ReportColumn} from "./ReportTable";
import React from "react";

interface props {
}

interface state {
    reports: GoalReport[] | undefined,
}

interface TrainingReport extends Report {
    typeOfTraining: string,
    trainingCount: number,
}

interface GoalReport {
    goal: string,
    subGoals: SubGoalReport[]
}

interface SubGoalReport {
    subGoal: string,
    reports: TrainingReport[] | undefined
}

class ResidentTrainingReport extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            reports: undefined,
        }
    }

    render() {
        let reports = this.state.reports;
        if (reports === undefined) {
            return <div/>
        }

        const trainingReports = reports.map((goalReport: GoalReport) => {
                return <div><h2 className={"strong pb-2"}>{goalReport.goal}</h2>
                    {
                        goalReport.subGoals.map((subGoal: SubGoalReport) => {
                            return <div className={"pb-4"}>
                                <h3 className={"strong pb-2"}> {subGoal.subGoal}</h3>
                                {
                                    subGoal.reports?.map((report: TrainingReport) => {
                                        return <div className={"pb-4"}>
                                            <div className={"pb-2"}>
                                                <u>{report.typeOfTraining}</u> - {report.trainingCount} registreringar
                                            </div>
                                            {report.reportColumns === undefined || report.reportColumns.length === 0 ?
                                                <div/> : <ReportTable report={report} horizontalScrolling={false}/>}
                                        </div>
                                    })
                                }

                            </div>
                        })
                    }
                </div>

            }
        );
        return <div>
            <h1 className={"strong pb-3"}>Rapport ST-undervisning</h1>
            {/*<h2 className={"strong pb-2"}>Delmål A – gäller alla specialiteter</h2>*/}
            {/*<h3 className={"strong pb-2"}>A1 – Medarbetare, ledarskap och pedagogik</h3>*/}
            {trainingReports}
        </div>;
    }

    componentDidMount() {
        const reportColumns1: ReportColumn[] = [
            {
                header: "Datum",
                cells: [
                    {
                        values: ["2022-01-07"]
                    },
                    {
                        values: ["2022-03-07"]
                    }
                ]
            },
            {
                header: "Plats",
                cells: [
                    {
                        values: ["Stockholm"]
                    },
                    {
                        values: ["Uppsala"]
                    }
                ]
            },
            {
                header: "Beskrivning",
                cells: [
                    {
                        values: ["A description of this course"]
                    },
                    {
                        values: ["Another description of the course"]
                    }
                ]
            },
            {
                header: "Kurs-/undervisningsledare",
                cells: [
                    {
                        values: ["Professor Tagesson"]
                    },
                    {
                        values: ["Doctor X"]
                    }
                ]
            }

        ]

        const reportColumns2: ReportColumn[] = [
            {
                header: "Datum",
                cells: [
                    {
                        values: ["2022-03-07"]
                    },
                    {
                        values: ["2022-05-07"]
                    }
                ]
            },
            {
                header: "Plats",
                cells: [
                    {
                        values: ["Stockholm"]
                    },
                    {
                        values: ["Göteborg"]
                    }
                ]
            },
            {
                header: "Beskrivning",
                cells: [
                    {
                        values: ["A detailed description of this training"]
                    },
                    {
                        values: ["Another description of the training"]
                    }
                ]
            },
            {
                header: "Kurs-/undervisningsledare",
                cells: [
                    {
                        values: ["Professor Yagesson"]
                    },
                    {
                        values: ["Doctor Y"]
                    }
                ]
            }

        ]


        const trainingReports: TrainingReport[] = [
            {
                typeOfTraining: "Kurser",
                trainingCount: 2,
                reportColumns: reportColumns1
            },

            {
                typeOfTraining: "Utbildningsdagar",
                trainingCount: 2,
                reportColumns: reportColumns2
            },
            {
                typeOfTraining: "Ej registrerat/övrigt",
                trainingCount: 2,
                reportColumns: reportColumns2
            },
        ]

        const subGoalReport1: SubGoalReport = {
            subGoal: "A1 – Medarbetare, ledarskap och pedagogik",
            reports: trainingReports
        }

        const subGoalReport2: SubGoalReport = {
            subGoal: "A2 – Etik, mångfald och jämlikhet",
            reports: [
                {
                    typeOfTraining: "Kurser",
                    trainingCount: 0
                },
                {
                    typeOfTraining: "Utbildningsdagar",
                    trainingCount: 0
                },
                {
                    typeOfTraining: "Ej registrerat/övrigt",
                    trainingCount: 0
                },
            ]
        }

        const subGoalReport3: SubGoalReport = {
            subGoal: "B1 – Kommunikation med patienter och närstående",
            reports: trainingReports
        }

        const subGoalReport4: SubGoalReport = {
            subGoal: "B2 – Sjukdomsförebyggande arbete",
            reports: [
                {
                    typeOfTraining: "Kurser",
                    trainingCount: 0
                },
                {
                    typeOfTraining: "Utbildningsdagar",
                    trainingCount: 0
                },
                {
                    typeOfTraining: "Ej registrerat/övrigt",
                    trainingCount: 0
                },
            ]
        }


        const goalReport1: GoalReport = {
            goal: "Delmål A – gäller alla specialiteter",
            subGoals: [subGoalReport1, subGoalReport2]
        }

        const goalReport2: GoalReport = {
            goal: "Delmål B – gäller många specialiteter",
            subGoals: [subGoalReport3, subGoalReport4]
        }

        this.setState({reports: [goalReport1, goalReport2]})
    }
}

export default ResidentTrainingReport