import React, {ChangeEvent} from 'react';
import {fetchObjectOf, postObjectOf, unsecuredFetch, unsecuredPost,} from "../../../infra/BackendService";
import {Action, Error} from "../../register/v2/Action";
import Form from "../../register/v2/Form";
import {DESKTOP} from "../../../infra/Constants";
import TranslationService from "../../../infra/TranslationService";
import Event, {instanceOfEventOrGoal} from "../../register/v2/Event";
import {FieldError} from "../../fields/v2/FieldComponent";
import {getEvent} from "../../register/v2/Util";
import {uuidv4} from "../../register/v2/Uuid";
import CounterSignFormHeader from "./CounterSignFormHeader";
import {getValid, missingMandatoryField} from "../../fields/v2/FieldUtil";


interface Props {
    match: any;
}

// is this one really needed? Wouldn't be just as well off with two fields in the state? Will be reviewed in #276
export interface CountersignActions {
    action: string,
    user: {
        name: string
    },
    date: string,
    baseAction: Action,
    extraAction: Action | undefined,
    overridden?: boolean,
    alreadySigned?: boolean,
    expired?: boolean,
    prefilledEvent?: Event,
    kvaCode?: string,
    deliveryParticipation?: string,
    beforeOrAfterParticipation?: string,
    studentWantsToReflect?: boolean
}

interface CountersignEvents {
    countersignId: string,
    baseEvent: Event | Error | undefined,
    extraEvent: Event | Error | undefined,
    firstName?: string | undefined,
    lastName?: string | undefined,
}

interface State {
    countersignId: string,
    countersignActions: CountersignActions | undefined,
    firstName?: string,
    lastName?: string,
    errors: FieldError[],
    submitResult: string,
    showPopoverModal: boolean,
    publicCountersign: boolean,
}

class CountersignForm extends React.Component<Props, State> {
    baseFieldReferences: Map<string, React.ReactInstance> = new Map<string, React.ReactInstance>();
    extraFieldReferences: Map<string, React.ReactInstance> = new Map<string, React.ReactInstance>();
    fieldReferences: Map<string, React.ReactInstance> = new Map<string, React.ReactInstance>();

    constructor(props: Readonly<Props>) {
        super(props);
        const countersignId: string = this.props.match.params.countersignId;
        this.state = {
            countersignId: countersignId,
            countersignActions: undefined,
            errors: [],
            submitResult: "",
            showPopoverModal: false,
            publicCountersign: false,
            firstName: "",
            lastName: "",
        }
    }

    render() {
        const submitResult = this.state.submitResult;
        if (submitResult.length !== 0) {
            const thankYou = TranslationService.translation("thank you");
            return (
                <div className={"container"}>
                    <h1>{thankYou}</h1>
                </div>
            );
        }

        const countersignActions = this.state.countersignActions;

        if (countersignActions === undefined) {
            return <div
                className={"spinner"}
                aria-label={"waiting for countersign event"}
            />
        }

        if (countersignActions.expired) {
            return <h4 aria-label={"countersign.expired"}
                       className={"mt-3"}>{TranslationService.translation("countersign.expired")}</h4>
        }

        if (countersignActions.overridden) {
            return <h4 aria-label={"countersign.overridden"}
                       className={"mt-3"}>{TranslationService.translation("countersign.overridden")}</h4>
        }

        if (countersignActions.alreadySigned) {
            return <h4 aria-label={"countersign.already.signed"}
                       className={"mt-3"}>{TranslationService.translation("countersign.already.signed")}</h4>
        }

        const header = <CounterSignFormHeader countersignActions={countersignActions}/>

        const baseAction = countersignActions.baseAction;
        const event = countersignActions.prefilledEvent;
        const prefilledValues = event === undefined ? undefined : event.values;

        let baseForm = <Form action={baseAction}
                             fieldReferences={this.baseFieldReferences}
                             device={DESKTOP}
                             dateFormat={"yyyy-MM-dd"}
                             showPopoverModal={this.state.showPopoverModal}
                             flipPopover={() => this.flipPopover()}
                             prefilledValues={prefilledValues}/>;

        const extraAction = countersignActions.extraAction;
        let extraForm = <div/>;
        if (extraAction !== undefined) {
            extraForm = <Form action={extraAction}
                              fieldReferences={this.extraFieldReferences}
                              device={DESKTOP}
                              dateFormat={"yyyy-MM-dd"}/>;

        }

        const saveButton = this.getSaveButton();
        const errors = this.getErrors();
        let nameHeader = <div/>;
        let firstNameInput = <div/>;
        let lastNameInput = <div/>;
        if (this.state.publicCountersign) {
            nameHeader = <div>
                <hr/>
                <h4>{TranslationService.translation("countersign.signer.name")}</h4></div>
            let firstName = this.state.firstName;
            let firstNamePlaceholder = TranslationService.translation("First name");
            firstNameInput = <input type="text"
                                    id={"firstName"}
                                    name={"firstName"}
                                    placeholder={firstNamePlaceholder}
                                    value={firstName}
                                    aria-label={"firstName"}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.changeFirstName(e)}
            />

            let lastName = this.state.lastName;
            let lastNamePlaceholder = TranslationService.translation("Last name");
            lastNameInput = <input type="text"
                                   id={"lastName"}
                                   name={"lastName"}
                                   placeholder={lastNamePlaceholder}
                                   value={lastName}
                                   aria-label={"lastName"}
                                   onChange={(e: ChangeEvent<HTMLInputElement>) => this.changeLastName(e)}
            />
        }

        return (
            <div className={"container"}>
                {header}
                {nameHeader}
                <div>{firstNameInput} {lastNameInput} </div>
                {baseForm}
                {extraForm}
                {errors}
                {saveButton}
            </div>
        );
    };

    private changeLastName(e: React.ChangeEvent<HTMLInputElement>) {
        let lastName = e.currentTarget.value;
        this.validateField("Last name", lastName);

        this.setState({lastName: lastName});

    }

    private validateField(fieldName: string, value: string) {
        let errors = this.state.errors;
        let error = errors.find(error => error.name === fieldName);
        if (value.length === 0) {
            if (error === undefined) {
                errors.push(getValid(fieldName, missingMandatoryField));
                this.setState({errors: errors});
            }
        } else {
            if (error !== undefined) {
                const index = errors.findIndex(err => err === error);
                if (index !== -1) {
                    errors.splice(index, 1);
                }
            }
        }
    }

    private changeFirstName(e: React.ChangeEvent<HTMLInputElement>) {
        let firstName = e.currentTarget.value;
        this.validateField("First name", firstName);
        this.setState({firstName: firstName});
    }

    flipPopover(): void {
        this.setState({showPopoverModal: !this.state.showPopoverModal});
    }


    componentDidMount() {
        let countersignUrl: string = this.props.match.url;
        const countersignId = this.state.countersignId;
        if (countersignUrl.startsWith("/public-countersign")) {
            this.unsecuredFetch(countersignId);
        } else {
            this.secureFetch(countersignId);
        }
    }

    private unsecuredFetch(countersignId: string) {
        let url: string = "/api/v2/public/countersign/" + countersignId;
        unsecuredFetch<CountersignActions>(url)
            .then((countersignEvent) => {
                    if (countersignEvent !== undefined) {
                        this.setState({
                            publicCountersign: true,
                            countersignActions: countersignEvent
                        });
                    }
                }
            );
    }


    private secureFetch(countersignId: string) {
        let url: string = "/api/v2/countersign/" + countersignId;
        fetchObjectOf<CountersignActions>(url)
            .then((countersignEvent) => {
                    if (countersignEvent !== undefined) {
                        this.setState({
                            countersignActions: countersignEvent
                        });
                    }
                }
            );
    }

    private getSaveButton(): React.ReactFragment {
        const save: string = TranslationService.translation("Kontrasignera");

        return <div className={"justify-content-end pt-3 m-0"}>
            <button id={"submitButton"}
                    aria-label={"save button"}
                    disabled={this.state.errors.length > 0}
                    className={"btn btn-save btn-submitNewReg"}
                    onClick={() => this.save(this.baseFieldReferences, this.extraFieldReferences)}>
                {save}
            </button>
        </div>;
    }

    private async save(baseReferences: Map<string, React.ReactInstance>, extraReferences: Map<string, React.ReactInstance>) {
        const countersignId = this.state.countersignId;
        const countersignEvents: CountersignEvents = {
            countersignId: countersignId,
            baseEvent: undefined,
            extraEvent: undefined
        };
        let allErrors: FieldError[] = [];
        if (this.state.publicCountersign) {
            if (this.state.firstName?.length === 0) {
                let error: FieldError = getValid("First name", missingMandatoryField);
                allErrors.push(error);
                this.setState({errors: allErrors});
            }
            if (this.state.lastName?.length === 0) {
                let error: FieldError = getValid("Last name", missingMandatoryField);
                allErrors.push(error);
                this.setState({errors: allErrors});
            }
        }
        if (allErrors.length === 0) {
            const countersignEvent = this.state.countersignActions;
            if (countersignEvent !== undefined) {
                const baseAction: Action = countersignEvent.baseAction;
                const baseName: string = baseAction.name;
                const baseVersion: number = baseAction.version;
                const baseEvent: Event | FieldError[] = getEvent(baseName, baseVersion, baseReferences);
                if (instanceOfEventOrGoal(baseEvent)) {
                    countersignEvents.baseEvent = baseEvent;
                    if (countersignEvent.extraAction !== undefined) {
                        const extraAction: Action = countersignEvent.extraAction;
                        const extraName: string = extraAction.name;
                        const extraVersion: number = extraAction.version;

                        let extraEvent: Event | FieldError[] = getEvent(extraName, extraVersion, extraReferences);

                        if (instanceOfEventOrGoal(extraEvent)) {
                            countersignEvents.extraEvent = extraEvent;
                            await this.postCountersign(countersignEvents);
                        } else {
                            const errors: FieldError[] = extraEvent as FieldError[];
                            allErrors.push(...errors);
                            this.setState({errors: allErrors});
                        }
                    } else {
                        await this.postCountersign(countersignEvents);
                    }
                } else {
                    const errors: FieldError[] = baseEvent as FieldError[];
                    allErrors.push(...errors);
                    this.setState({errors: allErrors});
                }
            }
        }

    }

    private async postCountersign(countersignEvents: CountersignEvents) {
        let result: string;
        if (this.state.publicCountersign) {
            countersignEvents.firstName = this.state.firstName;
            countersignEvents.lastName = this.state.lastName;
            const url: string = "/api/v2/public/countersign";
            result = await unsecuredPost(countersignEvents, url);
        } else {
            const url: string = "/api/v2/countersign";
            result = await postObjectOf(countersignEvents, url);
        }

        this.setState({submitResult: result});
    }

    private getErrors(): React.ReactFragment {
        const errors = this.state.errors;
        if (errors.length > 0) {
            return <div>
                {errors.map((error: FieldError) => {
                        const name = error.name;
                        const errorDescription = error.error;
                        const ariaLabel = name + " " + errorDescription;
                        const message = TranslationService.translation(name) + " " + TranslationService.translation(errorDescription);
                        return <div className="errorFont" key={uuidv4()}
                                    aria-label={ariaLabel}>
                            {message}
                        </div>
                    }
                )}
            </div>
        } else {
            return <div/>
        }
    }
}

export default CountersignForm;
