import {Field} from "../../register/v2/Action";


/**
 * If you want to use expanding spacers in your expanding fields, you must supply an optionsLayout.
 */

export function getNumberOfSpacers(field: Field, value: string): number {
    if (!field.options) {
        return 0;
    }
    for (let option of field.options) {
        if (typeof option !== 'string') {
            if (option.name === value) {
                return option.options?.length || 0;
            }
        }
    }

    return 0;
}

export function getBreakpoints(field: Field, optionsLayout: string): (Field | string)[] {
    let breakpoints: (string | Field)[] = [];

    if (field.options) {
        if (optionsLayout === "desktop" && field.optionsLayout) {
            const columns = field.optionsLayout.desktop.columns;
            stepThroughOptions(columns);
        }
        if (optionsLayout === "mobile" && field.optionsLayout) {
            const columns = field.optionsLayout.mobile.columns;
            stepThroughOptions(columns)
        }
    }

    return breakpoints;

    function stepThroughOptions(columns: number) {
        if (field.options) {
            const optionsLength = field.options.length;
            const step = Math.ceil(optionsLength / columns);
            let stepsTaken = step;

            while (stepsTaken < optionsLength) {
                let option = field.options[stepsTaken - 1];
                save(option);
                stepsTaken = stepsTaken + step;
            }
            let option = field.options[optionsLength - 1];
            save(option);
        }
    }

    function save(option: string | Field) {
        if (typeof option === "string") {
            breakpoints.push(field.name + "." + option);
        } else {
            breakpoints.push(option);
        }
    }
}

/**
 * Returns the column that contains value
 */
export function findValue(value: string, field: Field): number {
    let valueIndex = 0;
    if (field.options) {
        for (let i = 0; i < field.options.length; i++) {
            let option = field.options[i];
            if (typeof option === 'string') {
                if (value === field.name + "." + option) {
                    valueIndex = i;
                }
            } else {
                if (value === option.name) {
                    valueIndex = i;
                }
            }
        }
    }

    let columnIndex = 0;
    if (field.optionsLayout && field.options) {
        const columns = field.optionsLayout.desktop.columns
        const optionsLength = field.options.length;
        const breakpoint = Math.ceil(optionsLength / columns);
        columnIndex = Math.floor(valueIndex / breakpoint);
    }

    return columnIndex;
}
