import React, {ChangeEvent, ReactElement} from 'react';
import {FieldComponent, FieldError, FieldProps} from "./FieldComponent";
import Value from "../../register/v2/Value";
import {createSingleValue, isSingleValueValid} from "./FieldUtil";

interface props extends FieldProps {
}

interface state {
    value: string
}

class TextArea extends React.Component<props, state> implements FieldComponent {
    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            value: ""
        }
    }

    render(): ReactElement {
        const name = this.props.field.name;
        const ariaLabel = this.props.field.name;
        const rows = 5;
        const cols = 35;
        const value = this.state.value;
        const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => this.setState({value: e.currentTarget.value});

        return <textarea aria-label={ariaLabel}
                         className={"form-control"}
                         id={name}
                         rows={rows}
                         cols={cols}
                         value={value}
                         onChange={onChange}
        />
    }

    values(): Value[] {
        const name = this.props.field.name;
        const value = this.state.value;

        if (this.isValid().valid) {
            if (value !== "") {
                return createSingleValue(name, value);
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    isValid(): FieldError {
        const name = this.props.field.name;
        const value = this.state.value;
        const mandatory = this.props.field.mandatory;

        return isSingleValueValid(name, value, mandatory);
    }

    clear(): void {
        this.setState({value: ""})
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        values.forEach((value: Value) => {
            if (value.fieldName === name) {
                const newValue: string = value.values[0];
                this.setState({value: newValue});
            }
        });
    }
}

export default TextArea;
