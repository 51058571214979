import React from "react";
import {fetchObjectOf} from "../../../infra/BackendService";
import TranslationService from "../../../infra/TranslationService";
import ExportEventsButton from "../../splashpage/ExportEventsButton";
import RegisterResultFailureMessage from "../../register/v2/RegisterResult";
import {User} from "../../model/User";
import {hasRight} from "../../../utils/HasRight";

interface props {
    user: User
}

interface state {
    studentReport: DeliveryStudentReport | undefined,
    showFailureMessage: boolean
}

export interface DeliveryStudentReport {
    name: string,
    beforeOrAfterParticipation: number,
    deliveryParticipation: number,
    procedureCountMap: Map<string, ProcedureCount[]>
    positionCountList: ProcedureCount[]
}

export interface ProcedureCount {
    name: string
    observed: number,
    participated: number,
    totalCount: number,
    self: number,
    weightedSum: number
}


class StudentDeliveryReport extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            studentReport: undefined,
            showFailureMessage: false,
        }
    }

    componentDidMount() {
        let url = "/api/v1/reports/student/delivery"
        fetchObjectOf<DeliveryStudentReport>(url).then((table) => {
            if (table !== undefined) {
                this.setState({studentReport: table})
            }
        });
    }

    render() {
        let studentReport = this.state.studentReport;
        if (studentReport !== undefined) {
            let failureModal: React.JSX.Element = <div/>;
            const failureMessage = ["Något gick tokigt! Vi jobbar på att lösa det."];

            if (this.state.showFailureMessage) {
                failureModal =
                    <RegisterResultFailureMessage message={failureMessage}
                                                  hideFailureMessage={this.hideFailureMessage}/>;
            }
            const action: string = 'delivery';
            const version: number = 1;
            const hasExportRight: boolean = hasRight(this.props.user, "delivery", "export-events");
            return <div className={"pt-5"}>
                <h1 className={"pb-3"}>{TranslationService.translation("delivery.midterm-report.for") + " " + studentReport.name} </h1>
                <div>
                    <ExportEventsButton hasExportRight={hasExportRight}
                                        buttonText={"Exportera registreringar"}
                                        showFailureMessage={this.showFailureMessage}
                                        actionName={action}
                                        version={version}
                                        scope={'user'}
                    />
                </div>
                {this.drawTable(studentReport)}
                {failureModal}
            </div>
        } else {
            return <div
                className={"spinner"}
                aria-label={"waiting for table"}
            />
        }
    }


    private drawTable(studentReport: DeliveryStudentReport): React.ReactElement {
        const tableStyle = {
            border: "none",
            boxShadow: "none"
        };

        if (!studentReport) return <div>No data available</div>;
        const procedureMap = new Map(Object.entries(studentReport.procedureCountMap));

        const rows = Array.from(procedureMap.entries()).map(([evaluation, procedures], index) => (
            <React.Fragment key={index}>
                <tr className={"grayed"}>
                    <th colSpan={5}>{evaluation}</th>
                </tr>
                {procedures.map((procedure: ProcedureCount, idx: number) => (
                    <tr key={idx}>
                        <td>{TranslationService.translation(procedure.name)}</td>
                        <td>{procedure.totalCount}</td>
                        <td>{procedure.observed}</td>
                        <td>{procedure.participated}</td>
                        <td>{procedure.self}</td>
                    </tr>
                ))}
            </React.Fragment>
        ));

        const procedureCount = studentReport.positionCountList;
        const procedureCountRows = procedureCount?.map((procedure, index) => (
            <tr key={index}>
                <td colSpan={1}>{TranslationService.translation(procedure.name)}</td>
                <td colSpan={4}>{procedure.totalCount}</td>
            </tr>
        ));
        const procedureCountFragment = <React.Fragment>
            <tr className={"grayed"}>
                <th colSpan={5}>{TranslationService.translation("delivery.midterm-report.baby.position")}</th>
            </tr>
            {procedureCountRows}
        </React.Fragment>

        return (
            <div className={"scroll-sideways"}>
                <table className="table" style={tableStyle}>
                    <thead aria-label={"table-header"}>
                    <tr style={tableStyle} className={"m-0 p-0 grayed"}>
                        <th>{TranslationService.translation("delivery.midterm-report.procedure")}</th>
                        <th>{TranslationService.translation("delivery.midterm-report.total.count")}</th>
                        <th>{TranslationService.translation("delivery.midterm-report.observed.count")}</th>
                        <th>{TranslationService.translation("delivery.midterm-report.participated.count")}</th>
                        <th>{TranslationService.translation("delivery.midterm-report.self.count")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colSpan={1}>Passningar</td>
                        <td colSpan={4}>{studentReport.beforeOrAfterParticipation}</td>
                    </tr>
                    <tr>
                        <td colSpan={1}>Förlossning</td>
                        <td colSpan={4}>{studentReport.deliveryParticipation}</td>
                    </tr>
                    {rows}
                    {procedureCountFragment}
                    </tbody>
                </table>
            </div>
        );
    }

    hideFailureMessage = () => {
        this.setState({showFailureMessage: false})
    }

    showFailureMessage = (showFailureMessage: boolean) => {
        this.setState({showFailureMessage: showFailureMessage})
    }
}

export default StudentDeliveryReport