import React, {Component} from 'react';
import './Signup.css'
import EmailInputField from "../fields/v1/EmailInputField";
import EmailInUseMessage from "./EmailInUseMessage";
import TranslationService from "../../infra/TranslationService";

const SIGNUPURI = process.env.REACT_APP_LOCALHOST + "/signup-new-logger";

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state.email = "";
        this.onChangeEmail = this.onChangeEmail.bind(this);
    }

    state = {
        email: "",
        message: "",
        showWrongEmailMessage: false,
        sentInvite: false,
        displayEmailInUseMessage: false
    };

    componentDidMount() {
        this.setState({message: this.initialMessage});
    }

    initialMessage =
        <div>{TranslationService.translatePerDomain("Du kommer strax få ett mail med en länk till en registreringssida.", this.props.domain)}<br/>
            <br/>
            {TranslationService.translatePerDomain("På grund av spam kan vår leverantör av e-posttjänster inte alltid skicka mail till hotmail-adresser.", this.props.domain)}
            <br/>
            <br/>
            {TranslationService.translatePerDomain("Vi förstår att det kan vara ett problem, men det är utom vår kontroll och vi har ännu inte lyckats hitta en lösning.", this.props.domain)}
            <br/>
            <br/>
            {TranslationService.translatePerDomain(`Har du inte fått en inbjudan om en stund så kontakta oss via e-post`, this.props.domain)}
            <a className={"pl-1"} href="mailto:support@logeze.com">support@logeze.com</a>.
            <br/>
            <br/>
            {TranslationService.translatePerDomain("Här är en", this.props.domain)}
            <a className={"pl-1 pr-1"} href="https://vimeo.com/487763727" target="_blank" rel={"noopener noreferrer"}>{TranslationService.translatePerDomain("instruktionsfilm", this.props.domain)}</a>
            {TranslationService.translatePerDomain("om du vill se hur man gör innan du registrerar dig.", this.props.domain)}

        </div>;

    sentMessage() {
        return <div>{TranslationService.translatePerDomain("Kul och välkommen till LogEze!", this.props.domain)}<br/>
            <br/>
            {TranslationService.translatePerDomain("Du får strax ett mail med instruktioner till:", this.props.domain)}
            {this.state.email}.<br/>
            <br/>
            <b>Gmail</b> {TranslationService.translatePerDomain("placerar ofta mailet bland \"Kampanjer\".", this.props.domain)}<br/>
            <b>Hotmail, Live, MSN</b> {TranslationService.translatePerDomain("kan ibland spärra vårt mail av oklar anledning.", this.props.domain)}<br/>
            <br/>
            {TranslationService.translatePerDomain("Har du inte fått en inbjudan om en stund så kontakta oss via e-post", this.props.domain)} <a className={"pl-1"}
                href="mailto:support@logeze.com">support@logeze.com</a>.
        </div>;
    };

    render() {
        let emailSize;
        if (window.innerWidth < 1042) {
            emailSize = 22;
        } else {
            emailSize = 45;
        }
        const emailInUseMessage = <EmailInUseMessage email={this.state.email}/>
        return (
            <div className={"signupContainer text-center pt-4"}>
                <div className={"row m-0"}>
                    <div className={"col-1 col-md-3 p-0 m-0"}>

                    </div>
                    <div className={"col-10 col-md-6 p-0 m-0"}>
                        <div className={"row justify-content-center"}>
                            {
                                !this.state.sentInvite ?
                                    <React.Fragment>
                                        <h5 className={"pr-2"}>{TranslationService.translatePerDomain("E-post:", this.props.domain)}</h5>
                                        <EmailInputField size={emailSize}
                                                         invite={this.state.email}
                                                         onChangeEmail={this.onChangeEmail}/>
                                        <button className={"btn btn-signup p-1"} id={"signupButton"}
                                                onClick={this.sendSignupMailHandler}>{TranslationService.translatePerDomain("Registrera", this.props.domain)}
                                        </button>
                                    </React.Fragment>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    <div className={"col-1 col-md-3 p-0 m-0"}/>
                </div>
                {
                    this.state.showWrongEmailMessage ?
                        <div className={"row pt-3"}>
                            <div className={"col"}>
                                {TranslationService.translatePerDomain("Du har angivit en icke godkänd email-adress", this.props.domain)}
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    this.state.displayEmailInUseMessage ? emailInUseMessage : ""
                }
                <div className={"row pt-3"}>
                    <div id={"confirmation"} className={"col"}>
                        {this.state.message}
                    </div>
                </div>
            </div>
        );
    }

    sendSignupMailHandler = () => {
        let email = this.state.email;
        if (email !== "") {
            this.setState({showWrongEmailMessage: false});
            if (email.includes("@hotmail")) {
                // eslint-disable-next-line no-restricted-globals
                let acceptHotmail = confirm("Du försöker använda en hotmail-adress, på grund av omständigheter utanför vår kontroll funkar dessa adresser sporadiskt.\n\n " +
                    "Vi önskar att ni använde en annan adress.\n " +
                    "Om ni trots detta vill fortsätta med nuvarande adress tryck på \"OK\". Tryck \"Avbryt\" för att byta adress");
                if (acceptHotmail) {
                    this.setState({sentInvite: true});
                    this.signup();
                }
            } else {
                this.setState({sentInvite: true});
                this.signup();
            }
        } else {
            this.setState({showWrongEmailMessage: true});
        }

    };

    async signup() {
        const payload = {
            email: this.state.email,
        };

        const fetchObject = {
            method: "POST",
            body: JSON.stringify(payload)
        }

        const fetch = this.props.inheritedFetch(SIGNUPURI, fetchObject);

        fetch.then(res => {
            if (res.status === 403) {
                this.setState({displayEmailInUseMessage: true});
            }
            return res.json();
        })
            .then((responseObject) => {
                if (responseObject && responseObject.o === "k") {
                    this.setState({message: this.sentMessage()});
                }
            }).catch(console.log);
    }

    onChangeEmail(email) {
        this.setState({message: ""});
        this.setState({email: email})
    }
}

export default Signup;