import React from 'react';
import "./CapioEvaluationModal.css"
import {postObjectOf} from "../../../infra/BackendService";
import {DeviationEvent} from "./CapioDeviation";

interface Props {
    parentId: string,
    flipVisibility: () => void;
    appendEvent: (parentId: string, event: DeviationEvent) => void;
}

interface State {
    severity: number,
    probability: number
}

const capioDeviationEvaluationUrl = "/api/v1/supervisor/deviation/evaluation"

class CapioEvaluationModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            severity: 0,
            probability: 0
        }
    }


    render() {
        return (
            <div>
                <aside className="deviation-evaluation-modal-background" onClick={() => this.props.flipVisibility()}/>
                <div className={"deviation-evaluation-modal"}>
                    <div>Allvarlighestgrad:</div>
                    <div className={"row p-0 m-0"}>
                        <input className={"mt-1 mr-1"} type={"radio"} name={"severity"} value={1}
                               onClick={() => this.setSeverity(1)}/>
                        <div className={"pr-4"}>1</div>
                        <input className={"mt-1 mr-1"} type={"radio"} name={"severity"} value={2}
                               onClick={() => this.setSeverity(2)}/>
                        <div className={"pr-4"}>2</div>
                        <input className={"mt-1 mr-1"} type={"radio"} name={"severity"} value={3}
                               onClick={() => this.setSeverity(3)}/>
                        <div className={"pr-4"}>3</div>
                        <input className={"mt-1 mr-1"} type={"radio"} name={"severity"} value={4}
                               onClick={() => this.setSeverity(4)}/>
                        <div className={"pr-4"}>4</div>
                    </div>

                    <div className={"pt-3"}>Sannolikhet för upprepning:</div>
                    <div className={"row p-0 m-0"}>
                        <input className={"mt-1 mr-1"} type={"radio"} name={"probability"} value={1}
                               onClick={() => this.setProbability(1)}/>
                        <div className={"pr-4"}>1</div>
                        <input className={"mt-1 mr-1"} type={"radio"} name={"probability"} value={2}
                               onClick={() => this.setProbability(2)}/>
                        <div className={"pr-4"}>2</div>
                        <input className={"mt-1 mr-1"} type={"radio"} name={"probability"} value={3}
                               onClick={() => this.setProbability(3)}/>
                        <div className={"pr-4"}>3</div>
                        <input className={"mt-1 mr-1"} type={"radio"} name={"probability"} value={4}
                               onClick={() => this.setProbability(4)}/>
                        <div className={"pr-4"}>4</div>
                    </div>
                    <div className={"row m-0 pr-3 justify-content-end"}>
                        <button className={"btn btn-evaluate-save-button"} onClick={() => this.save()}>Spara</button>
                    </div>
                </div>
            </div>
        );
    }

    private setSeverity(value: number) {
        this.setState({severity: value});
    }

    private setProbability(value: number) {
        this.setState({probability: value});
    }

    private async save() {

        const payload = {
            parentId: this.props.parentId,
            severity: this.state.severity,
            probability: this.state.probability
        }


        const event: DeviationEvent = await postObjectOf(payload, capioDeviationEvaluationUrl);
        this.props.appendEvent(this.props.parentId, event)
        this.props.flipVisibility();
    }

}

export default CapioEvaluationModal;
