import React, {Component} from 'react';
import UserProfile from './UserProfile';
import UserPassword from './UserPassword';
import TranslationService from '../../infra/TranslationService';
import {getSecurityToken} from '../../infra/SecurityToken';
import {valid} from '../fields/v1/EmailInputField';
import {Organisation} from "./Organisation";
import {BackendContext, BackendInterface} from "../../infra/BackendContext";
import {EventBackendService} from "../register/v2/EventBackendService";

const UPDATEUSERAPI = process.env.REACT_APP_LOCALHOST + '/api/v1/update-user';

interface Preferences {
    dateFieldAsDate: boolean;
}

interface Organisations {
    availableOrganisations: Organisation[];
    actualOrganisations: Organisation[];
}

interface UserData {
    user: {
        firstName: string;
        lastName: string;
        phone: string;
        email: string;
        locale: string;
        preferences?: string;
        userName: string;
    };
}

interface Props {
    userData: UserData;
    refreshExpiredToken: () => void;
    securityToken: string;
}

interface State {
    localChanges: boolean;
    dateFormat: string;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    language: string;
    country: string;
    availableOrganisations: Organisation[];
    actualOrganisations: Organisation[];
    showSubmitFeedback: boolean;
    submitFeedbackText: string;
    preferences: Preferences;
    emailErrorMessage?: string;
}

class UserPage extends Component<Props, State> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: Props) {
        super(props);

        const user = this.props.userData.user;
        const locale = user.locale;
        const preferencesJson = user.preferences;

        this.state = {
            localChanges: false,
            dateFormat: 'ÅÅÅÅ-MM-DD',
            email: user.email,
            phone: user.phone,
            firstName: user.firstName,
            lastName: user.lastName,
            language: locale === 'sv_SE' ? 'swedish' : locale === 'en_GB' ? 'english' : 'Svenska',
            country: 'Sverige',
            showSubmitFeedback: false,
            submitFeedbackText: '',
            availableOrganisations: [],
            actualOrganisations: [],
            preferences: preferencesJson !== undefined ? JSON.parse(preferencesJson) : {dateFieldAsDate: false},
        };

        this.handleFirstName = this.handleFirstName.bind(this);
        this.handleLastName = this.handleLastName.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleLanguage = this.handleLanguage.bind(this);
        this.handleOrganisations = this.handleOrganisations.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.handleDateFormat = this.handleDateFormat.bind(this);
        this.setLocalChanges = this.setLocalChanges.bind(this);
        this.handleDateFieldOption = this.handleDateFieldOption.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        const path: string = "/api/v1/user-settings/organisations"
        const url: string = EventBackendService.getUrl2(path);
        const response: any = await this.context.get(url, {
            success: "",
            failure: ""
        }) as Organisations;

        if (response !== undefined && response.data !== undefined) {
            this.setState({
                availableOrganisations: response.data.availableOrganisations,
                actualOrganisations: response.data.actualOrganisations
            });
        }

    }

    handleFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({localChanges: true, firstName: event.target.value});
    }

    handleLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({localChanges: true, lastName: event.target.value});
    }

    handlePhone(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({localChanges: true, phone: event.target.value});
    }

    handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({localChanges: true, email: event.target.value});

        if (valid(event.target.value)) {
            this.setState({emailErrorMessage: ''});
        } else {
            const userprofileEmailError = TranslationService.translation('userprofileEmailError');
            this.setState({emailErrorMessage: userprofileEmailError});
        }
    }

    handleOrganisations(selectedOrganisations: Organisation[]) {
        this.setState({localChanges: true, actualOrganisations: selectedOrganisations});
    }

    handleLanguage(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({localChanges: true, language: event.target.value});
    }

    handleDateFieldOption(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({localChanges: true, preferences: {dateFieldAsDate: event.target.checked}});
    }

    handleDateFormat(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({localChanges: true, dateFormat: event.target.value});
    }

    handleCountry(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({localChanges: true, country: event.target.value});
    }

    onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const submitFeedbackText = TranslationService.translation('Your profile has been updated, you must logout and login again to see the changes');
        const submitFeedbackTextError = TranslationService.translation('There was an error when updating your profile');

        const updatedUser = {
            userName: this.props.userData.user.userName,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            dateFormat: this.state.dateFormat,
            language: this.state.language,
            country: this.state.country,
            preferences: this.state.preferences,
            selectedOrganisations: this.state.actualOrganisations,
        };
        this.props.refreshExpiredToken();

        fetch(UPDATEUSERAPI, {
            headers: {
                'X-Custom-header': getSecurityToken(),
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(updatedUser),
        })
            .then((res) => res.json())
            .then(() => {
                this.setState({submitFeedbackText: submitFeedbackText, showSubmitFeedback: true, localChanges: false});
            })
            .catch((e) => {
                this.setState({submitFeedbackText: submitFeedbackTextError, showSubmitFeedback: true});
                console.log(e);
            });
    }

    render() {
        return (
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col text-center"}>
                        <form onSubmit={(event) => this.onSubmit(event)}>
                            <div aria-label={"user profile"}>
                                <UserProfile lastName={this.state.lastName}
                                             firstName={this.state.firstName}
                                             phone={this.state.phone}
                                             email={this.state.email}
                                             language={this.state.language}
                                             dateFormat={this.state.dateFormat}
                                             localChanges={this.state.localChanges}
                                             emailErrorMessage={this.state.emailErrorMessage}
                                             dateFieldAsDates={this.state.preferences.dateFieldAsDate}
                                             availableOrganisations={this.state.availableOrganisations}
                                             selectedOrganisations={this.state.actualOrganisations}
                                             handleOrganisations={this.handleOrganisations}
                                             setLocalChanges={this.setLocalChanges}
                                             handlePhone={this.handlePhone}
                                             handleEmail={this.handleEmail}
                                             handleLanguage={this.handleLanguage}
                                             handleDateFieldOption={this.handleDateFieldOption}
                                             handleLastName={this.handleLastName}
                                             handleFirstName={this.handleFirstName}
                                />
                            </div>
                        </form>
                        {this.state.showSubmitFeedback ? <div>{this.state.submitFeedbackText}</div> : ''}
                        <hr/>
                        <UserPassword securityToken={this.props.securityToken}
                                      refreshExpiredToken={this.props.refreshExpiredToken}/>
                        <hr/>
                    </div>
                </div>
            </div>
        );
    }

    setLocalChanges(value: boolean) {
        this.setState({localChanges: value});
    }
}

export default UserPage;
