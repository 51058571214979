import React, {Component} from 'react';
import './Supervisor.css'
import SupervisorDelegator from "./SupervisorDelegator";
import VerticalNavBar from "./VerticalNavBar";
import {getSecurityToken} from "../../infra/SecurityToken";

const USERLISTAPI = process.env.REACT_APP_LOCALHOST + "/api/v1/supervised-users";

class Supervisor extends Component {
    constructor(props) {
        super(props);
        this.setCurrentContent = this.setCurrentContent.bind(this);
    }

    state = {
        userList: [],
        currentContent: "",
        actionData: {},
    };

    setCurrentContent(content) {
        this.setState({currentContent: content});
    }

    componentDidMount() {
        this.props.refreshExpiredToken();
        fetch(USERLISTAPI, {
            headers: {
                'X-Custom-header': getSecurityToken()
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({userList: data});
            })
            .catch(console.log);
    }

    render() {

        return (
            <div>
                <div className="row pl-3">
                    <div>
                        <VerticalNavBar
                            userData={this.props.userData}
                            setContent={this.setCurrentContent}
                            refreshExpiredToken={this.props.refreshExpiredToken}
                            userList={this.state.userList}/>
                    </div>
                    <SupervisorDelegator
                        securityToken={this.props.securityToken}
                        userList={this.state.userList}
                        refreshExpiredToken={this.props.refreshExpiredToken}
                        currentContent={this.state.currentContent}
                        userData={this.props.userData}/>
                </div>
            </div>

        );
    }
}

export default Supervisor;