import React, {ReactElement} from 'react';
import "./Form.css";
import {Action, Field} from "../v2/Action";
import Event, {
    createEvent,
    deleteValueAndAllChildren,
    getKey,
    getValue,
    hasChildField,
    isEmptyEvent,
    setValue,
    Value
} from "./Event";
import TranslationService from "../../../infra/TranslationService";
import translationService from "../../../infra/TranslationService";
import DeleteLogo from "../../../images/delete-512.png";
import {isCopy, isEdit, isRegister, isView, Mode, register} from "./Mode";
import getField from "./FieldFactory";
import FieldError, {getErrors, rebuildErrorsForCurrentState} from "./FieldError";
import {getDefaultDateValue} from "../../fields/v2/DateField";
import {getDuplicationIndex} from "./DuplicationIndex";
import {addDuplicationIndexToConditionalOnPredicates, shouldBeVisible} from "./ConditionalOn";
import {EventBackend} from "../v2/EventBackend";
import lodash from 'lodash';
import {Organisation} from "../../model/Organisation";


interface props {
    action: Action,
    event: Event,
    mode: Mode,
    save: (event: Event, action: Action) => Promise<void>,
    update: (event: Event, action: Action) => Promise<void>,
    user: any,
    device?: string
    updateProgress?: (event: Event) => void,
    backend?: EventBackend,
    currentlySaving: boolean,
    currentOrganisation?: Organisation
    updateMode?: (mode: Mode) => void
}

interface state {
    currentAction: Action,
    event: Event,
    mode: Mode,
    errors: FieldError[],
    mandatoryFields: Field[]
}

class Form extends React.Component<props, state> {
    constructor(props: Readonly<props>) {
        super(props);

        this.onChange = this.onChange.bind(this);

        const src = this.props.action;
        let event: Event = this.props.event;
        if (isEmptyEvent(event)) {
            event = this.getNewEvent(src);
        }

        const currentAction: Action = this.prepareWithDuplicates(src, event);
        this.enrichCurrentAction(currentAction);

        const mandatoryFields: Field[] = [];
        const errors: FieldError[] = this.getMissingMandatoryFields(src, event, mandatoryFields);
        const mode = this.props.mode;
        if (isCopy(mode)) {
            delete event.id;
        }
        this.state = {
            currentAction: currentAction,
            event: event,
            mode: mode,
            errors: errors,
            mandatoryFields: mandatoryFields
        }
    }

    async componentDidMount() {
        document.addEventListener("mousedown", this.handleClickEvent)
        if (isRegister(this.props.mode)) {
            await this.fillInPrefilledValues();
        }
    }

    private async fillInPrefilledValues() {
        const action: Action = this.props.action;
        if (this.props.backend && action) {
            let organisationId = this.props.currentOrganisation ? this.props.currentOrganisation.organisationId : null;
            const prefilledValues = await this.props.backend.getPrefilledValues(action, organisationId);
            if (prefilledValues && prefilledValues.length > 0) {
                for (let i = 0; i < prefilledValues.length; i++) {
                    let wantedField = action.fields.find((elem: Field) => elem.name === prefilledValues[i].name)
                    if (!wantedField) {
                        //dummy version, why does a onchange need a field?
                        wantedField = {name: prefilledValues[i].name, fieldLabel: false, duplicationIndex: 0}
                    }
                    await this.onChange(prefilledValues[i].name, prefilledValues[i].value, "0", true, wantedField)
                }
            }
        }
    }

    private handleClickEvent = () => {
        const event: Event = this.state.event;
        return this.props.updateProgress?.(event);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickEvent)
    }

    render(): ReactElement {
        const currentAction: Action = this.state.currentAction;
        const originalAction: Action = this.props.action;
        const actionName: string = currentAction.name;
        const event: Event = this.state.event;
        const mode: Mode = this.state.mode;
        const user = this.props.user;
        const form: React.ReactFragment = this.getForm(currentAction, event, mode, originalAction, user);

        return <div key={actionName}
                    aria-label={"registration form v3"}>
            {form}
        </div>
    }

    private enrichCurrentAction(currentAction: Action) {
        this.enrichWithParents(currentAction);
        this.enrichConditionalOns(currentAction);
    }

    prepareWithDuplicates(src: Action, event: Event): Action {
        const target: Action = JSON.parse(JSON.stringify(src));
        const duplicationReceivers: Field[] = target.fields;
        duplicationReceivers.forEach((field: Field) => {
            this.enrichWithDuplicatedFields(field, event, duplicationReceivers, src, undefined);
        });

        return target;
    }

    private enrichWithDuplicatedFields(field: Field, event: Event, duplicationReceivers: Field[], action: Action, parent: Field | undefined) {
        const fields = field.fields;
        if (fields === undefined || fields.length === 0) {
            if (field.duplicable) {
                this.addDuplicatedFields(field, event, duplicationReceivers, action, parent);
            }
        } else {
            fields.forEach((f: Field) => {
                if (f.duplicationIndex === undefined) {
                    f.duplicationIndex = 0;
                }
                let dr: Field[] = [];
                if (fields !== undefined) {
                    dr = fields;
                }

                this.enrichWithDuplicatedFields(f, event, dr, action, field);
            });

            if (field.duplicable) {
                this.addDuplicatedFields(field, event, duplicationReceivers, action, parent);
            }
        }
    }

    private getMissingMandatoryFields(action: Action, event: Event, mandatoryFields: Field[]): FieldError[] {
        const fields: Field[] = action.fields;
        const missingMandatoryFields: FieldError[] = [];
        fields.forEach((field: Field) => {
            this.findMandatoryFields(field, event, missingMandatoryFields, mandatoryFields);
        });

        return missingMandatoryFields;
    }

    private findMandatoryFields(field: Field, event: Event, missingMandatoryFields: FieldError[], mandatoryFields: Field[]) {
        const fields = field.fields;
        if (fields === undefined || fields.length === 0) {
            this.setMandatory(field, event, missingMandatoryFields, mandatoryFields);
        } else {
            fields.forEach((f: Field) => {
                this.findMandatoryFields(f, event, missingMandatoryFields, mandatoryFields);
            });
            this.setMandatory(field, event, missingMandatoryFields, mandatoryFields);
        }
    }

    private setMandatory(field: Field, event: Event, missingMandatoryFields: FieldError[], mandatoryFields: Field[]) {
        if (field.mandatory !== undefined) {
            const mandatory: boolean = field.mandatory;
            if (mandatory) {
                mandatoryFields.push(field);
                let name = field.name;
                const duplicationIndex: string = getDuplicationIndex(field);
                const value: string | string[] | undefined = getValue(name, event, duplicationIndex);

                if (value === undefined) {
                    getErrors(name, false, missingMandatoryFields);
                }

                if (typeof value === 'string' && value === '') {
                    getErrors(name, false, missingMandatoryFields);
                }

                if (Array.isArray(value) && value.length === 0) {
                    getErrors(name, false, missingMandatoryFields);
                }
            }
        }
    }

    private getForm(currentAction: Action, event: Event, mode: Mode, originalAction: Action, user: any): React.ReactFragment {
        const fields: Field [] = currentAction.fields;
        const form = fields.map((field: Field) => {
            const duplicationIndex = '' + field.duplicationIndex;

            const visibleField: React.ReactFragment = this.renderFields(field, event, fields, originalAction, true, user, undefined);

            let name = field.name;
            let key = name + '-' + duplicationIndex;

            let index: string = '';
            if (field.duplicable !== undefined &&
                field.duplicable &&
                field.duplicationIndex !== undefined) {
                index = ' #' + (field.duplicationIndex + 1);
            }

            let translated = translationService.translation(name);
            translated = translated + index;

            let heading;
            const visible = shouldBeVisible(field, event);
            if (visible) {
                heading = <>
                    <hr aria-label={'horizontal row'}/>
                    <h4>{translated}</h4>
                </>
            } else {
                heading = <div/>;
            }

            return <div key={key}>
                {heading}
                {visibleField}
            </div>
        });
        const buttons: React.ReactFragment = this.getButtons(mode);

        return <div aria-label={'form'}>
            {form}
            {buttons}
        </div>;
    }

    private renderFields(field: Field,
                         event: Event,
                         duplicationReceivers: Field[],
                         action: Action,
                         rootField: boolean,
                         user: any,
                         parent: Field | undefined
    ): React.ReactFragment {
        const key = this.getKey(field);
        const visible = shouldBeVisible(field, event);
        if (!visible) {
            const ariaLabel = field.name + ' is conditional on but not triggered';
            return <div key={key} aria-label={ariaLabel}/>;
        }

        const duplicationHeadline = this.getDuplicationHeadline(field, rootField);
        const isDuplicable = this.isDuplicable(field);
        const device = this.props.device;

        if (field.fields === undefined || field.fields.length === 0) {
            const fieldInstances: ({} | React.ReactNodeArray)[] = [];

            if (isDuplicable) {
                const duplicationButtons = this.getDuplicationButtons(field, duplicationReceivers, action, parent);
                const fieldInstance: React.ReactFragment = getField(field, event, this.onChange, action, user, this.props.currentOrganisation, {device: device});

                return this.renderDuplicableField(field, key, duplicationHeadline, fieldInstance, fieldInstances, duplicationButtons);
            } else {
                return this.renderField(field, event, key, fieldInstances, action, user);
            }
        } else {
            const fieldInstances = this.getFieldInstances(field, event, action, user);

            if (isDuplicable) {
                const duplicationButtons = this.getDuplicationButtons(field, duplicationReceivers, action, parent);

                const fieldInstance: React.ReactFragment = getField(field, event, this.onChange, action, user, this.props.currentOrganisation, {device: device});

                return this.renderDuplicableField(field, key, duplicationHeadline, fieldInstance, fieldInstances, duplicationButtons);
            } else {
                return this.renderField(field, event, key, fieldInstances, action, user);
            }
        }
    }

    private getFieldInstances(field: Field, event: Event, action: Action, user: any): React.ReactFragment[] {
        let fieldInstances: React.ReactFragment[] = [];
        if (field.fields !== undefined) {
            const fields = field.fields;
            fieldInstances = fields.map((f: Field) => {
                const isDuplicable = this.isDuplicable(f);
                if (isDuplicable) {
                    return this.renderFields(f, event, fields, action, false, user, field);
                } else {
                    return this.renderFields(f, event, fields, action, false, user, field);
                }
            });
        }

        return fieldInstances;
    }

    private renderDuplicableField(field: Field,
                                  key: string,
                                  duplicationHeadline: {} | React.ReactNodeArray,
                                  fieldInstance: {},
                                  fieldInstances: ({} | React.ReactNodeArray)[],
                                  duplicationButtons: React.JSX.Element
    ) {

        return <div aria-label={field.name}
                    key={key}>
            {duplicationHeadline}
            {fieldInstance}
            {fieldInstances}
            {duplicationButtons}
        </div>;
    }

    private renderField(field: Field, event: Event, key: string, fieldInstances: ({} | React.ReactNodeArray)[],
                        action: Action, user: any) {
        const fieldInstance: React.ReactFragment = getField(field, event, this.onChange, action, user, this.props.currentOrganisation, {device: this.props.device});

        return <div aria-label={field.name}
                    key={key}>
            {fieldInstance}
            {fieldInstances}
        </div>;
    }

    private getDuplicationHeadline(field: Field, rootField: boolean): React.ReactFragment {
        if (!rootField) {

            let name = field.name;

            const index: string = ' #' + (field.duplicationIndex + 1);
            const label: string = TranslationService.translation(name) + index;

            return <h5>{label}</h5>;
        } else {
            return <div/>
        }
    }

    private getKey(field: Field) {
        return field.name + '-' + field.duplicationIndex;
    }

    private getDuplicationButtons(field: Field, duplicationReceivers: Field[], action: Action, parent: Field | undefined) {
        const duplicationButton = this.getDuplicationButton(field, duplicationReceivers, action, parent);
        const deleteButton = this.getDeleteButton(field, duplicationReceivers);

        return <div>
            {duplicationButton}
            {deleteButton}
        </div>;
    }

    private getDuplicationButton(field: Field, duplicationReceivers: Field[], action: Action, parent: Field | undefined): React.ReactFragment {
        const addDuplication = () => this.addDuplication(field, duplicationReceivers, action, parent);

        return <button onClick={addDuplication}>+</button>;
    }

    private getDeleteButton(duplicated: Field, duplicationReceivers: Field[]): React.ReactFragment {
        if (duplicated.duplicationIndex !== undefined && duplicated.duplicationIndex > 0) {
            const removeDuplication = () => this.deleteDuplication(duplicated, duplicationReceivers);

            return <button aria-label={"-"}
                           onClick={removeDuplication}>
                <img className={"pb-1"}
                     alt={"Delete"}
                     height={18}
                     aria-label={"-"}
                     src={DeleteLogo}/>
            </button>;
        } else {
            const ariaLabel = duplicated.name + ' is the only copy and cannot be deleted';

            return <div aria-label={ariaLabel}/>;
        }
    }

    private isDuplicable(field: Field) {
        if (field.duplicable !== undefined) {
            return field.duplicable;
        } else {
            return false;
        }
    }

    addDuplication(field: Field, duplicationReceivers: Field[], action: Action, parent: Field | undefined) {
        const duplicated: Field = this.getFieldForDuplication(field, action);

        this.addDuplicatedField(duplicationReceivers, field, duplicated, parent);

        const currentAction: Action = this.state.currentAction;
        this.enrichCurrentAction(currentAction);

        this.setState({
            currentAction: currentAction
        });
    }

    private getFieldForDuplication(src: Field, action: Action): Field {
        for (const candidate of action.fields) {
            let res: Field | undefined = this.findFieldForDuplication(src, candidate);
            if (res !== undefined) {
                let copy = JSON.parse(JSON.stringify(res));
                if (src.duplicationIndex !== undefined) {
                    copy.duplicationIndex = src.duplicationIndex + 1;
                }

                return copy;
            }
        }

        return JSON.parse(JSON.stringify(src));
    }

    private findFieldForDuplication(src: Field, candidate: Field): Field | undefined {
        if (src.name === candidate.name) {
            return candidate;
        }

        if (candidate.fields !== undefined) {
            for (let field of candidate.fields) {
                let forCopy = this.findFieldForDuplication(src, field);
                if (forCopy !== undefined) {
                    return forCopy;
                }
            }
        }

        return undefined;
    }

    private deleteDuplication(duplicated: Field, duplicationReceivers: Field[]) {
        const event: Event = this.state.event;
        let field = duplicated.name;
        const updatedEvent: Event = deleteValueAndAllChildren(duplicated, field, event);

        this.deleteDuplicatedField(duplicationReceivers, duplicated);

        const newAction: Action = this.state.currentAction;
        this.enrichCurrentAction(newAction);

        this.setState({
            currentAction: newAction,
            event: updatedEvent
        });
    }

    private deleteDuplicatedField(duplicationReceivers: Field[], duplicated: Field) {
        if (duplicated.duplicationIndex > 0) {
            let index = 0;
            for (let i = 0; i < duplicationReceivers.length; i++) {
                const candidate: Field = duplicationReceivers[i];
                if (candidate.name === duplicated.name && candidate.duplicationIndex === duplicated.duplicationIndex) {
                    index = i;
                }
            }

            // filter might have been a good idea,
            // but we are relying on the reference to duplicationReceivers to be unchanged so filter wasn't a good idea.
            duplicationReceivers.splice(index, 1);

            this.reindexDuplicationReceivers(duplicationReceivers, duplicated);
        }
    }

    private onChange(name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field): void {
        const event: Event = this.state.event;
        const currentAction: Action = this.state.currentAction;
        this.enrichCurrentAction(currentAction);
        this.enrichEvent(currentAction, event);

        const updatedEvent: Event = setValue(name, value, duplicationIndex, valid, field, event);
        const currentErrors: FieldError[] = rebuildErrorsForCurrentState(event, this.state.errors, this.state.mandatoryFields);
        const fieldErrors: FieldError[] = getErrors(name, valid, currentErrors);
        this.removeDuplicatedInvisibleFields(currentAction, updatedEvent);

        this.setState({
            event: updatedEvent,
            errors: fieldErrors,
            currentAction: currentAction
        }, () => {
            const action: Action = this.props.action;
            this.addVisibleDefaultValues(updatedEvent, action, name);
        });
    }

    private addVisibleDefaultValues(event: Event, action: Action, changingField: string) {
        const fields: Field[] = action.fields;
        fields.forEach((field: Field) => {
            let isChanging = changingField === field.name;
            this.enrichWithDefaultValues(field, event, isChanging);
        });

        this.setState({
            event: event
        });
    }

    private getButtons(mode: Mode): React.ReactFragment {
        /*
            Cases:

            * Regular fill out form - show a save button
            * Edit - show cancel and update buttons
            * Copy - show a save button
            * View - show a close button
         */

        const invalid = this.isInValid();
        const errorMessage = this.getErrorMessage();
        const loadingWheel = this.getLoadingWheel();

        if (isView(mode)) {
            const cancelTranslation: string = TranslationService.translation("cancel");
            const cancelButton = <div>
                <button aria-label={"cancel button"}
                        name={'cancel'}
                        className={"btn btn-save btn-submitNewReg"}
                        onClick={() => this.cancel()}>
                    {cancelTranslation}
                </button>
            </div>;

            return <div>
                <div aria-label={'buttons'}
                     className={"d-flex justify-content-end pt-3 m-0"}>
                    {cancelButton}
                </div>
            </div>;
        }

        if (isEdit(mode)) {
            const cancelTranslation: string = TranslationService.translation("cancel");
            const cancelButton = <div>
                <button aria-label={"cancel button"}
                        name={'cancel'}
                        className={"btn btn-save btn-submitNewReg"}
                        onClick={() => this.cancel()}>
                    {cancelTranslation}
                </button>
            </div>;

            const updateTranslation: string = TranslationService.translation("update");
            const updateButton = <div>
                <button aria-label={"update button"}
                        name={'update'}
                        disabled={invalid}
                        className={"btn btn-save btn-submitNewReg"}
                        onClick={() => this.updateEvent()}>
                    {updateTranslation}
                </button>
            </div>;

            return <div>
                {errorMessage}
                <div aria-label={'buttons'}
                     className={"d-flex justify-content-end pt-3 m-0"}>
                    {cancelButton}
                    {loadingWheel}
                    {updateButton}
                </div>
            </div>;
        }

        const saveTranslation: string = TranslationService.translation("save");

        const saveButton = <div className={"d-flex justify-content-end pt-3 m-0"}>
            {loadingWheel}
            <button aria-label={"save button"}
                    name={'save'}
                    disabled={invalid}
                    className={"btn btn-save btn-submitNewReg"}
                    onClick={() => this.saveEvent()}>
                {saveTranslation}
            </button>
        </div>;

        return <div>
            {errorMessage}
            <div aria-label={'buttons'}>
                {saveButton}
            </div>
        </div>;
    }

    private isInValid() {
        return this.state.errors.length > 0 || this.props.currentlySaving;
    }

    private getErrorMessage() {
        const invalid: boolean = this.isInValid();
        let errorMessages = [<div key={'no error message'}/>];
        if (invalid) {
            errorMessages = this.state.errors.map((value: FieldError) => {
                const invalidField = TranslationService.translation('invalid field');
                const invalidFieldName = TranslationService.translation(value.name);
                const errorMessage: string = invalidField + ' ' + invalidFieldName

                return <div key={value.name}>
                    <div className={"row d-flex justify-content-end pt-3 m-0"}>
                        <div className={'invalidFieldMessage'}>
                            {errorMessage}
                        </div>
                    </div>
                </div>;
            });
        }

        return <div className={'container'}>
            {errorMessages}
        </div>;
    }

    private async saveEvent() {
        const event: Event = this.state.event;
        const action: Action = this.props.action;
        const save = this.props.save;

        await save(event, action);

        const empty: Event = this.getNewEvent(action);
        const currentAction: Action = this.prepareWithDuplicates(action, empty);
        this.enrichCurrentAction(currentAction);

        this.setState({
            event: empty,
            currentAction: currentAction
        }, async () => {
            await this.fillInPrefilledValues()
            this.updateProgressBar();
        });
    }

    private async updateEvent() {
        const event: Event = this.state.event;
        const action: Action = this.props.action;
        const update = this.props.update;

        if (update !== undefined) {
            await update(event, action);
        }

        const empty: Event = this.getNewEvent(action);
        const currentAction: Action = this.prepareWithDuplicates(action, empty);
        this.enrichCurrentAction(currentAction);
        let mode = register();

        this.setState({
            event: empty,
            currentAction: currentAction,
            mode: mode
        }, async () => {
            await this.fillInPrefilledValues()
            this.updateProgressBar()
            this.props.updateMode ? this.props.updateMode(mode) : this.null();
        });
    }

    private cancel() {
        const action: Action = this.props.action;
        const empty: Event = this.getNewEvent(action);
        const currentAction: Action = this.prepareWithDuplicates(action, empty);
        this.enrichCurrentAction(currentAction);
        let mode = register();

        this.setState({
            event: empty,
            currentAction: currentAction,
            mode: mode
        }, async () => {
            await this.fillInPrefilledValues()
            this.updateProgressBar();
            this.props.updateMode ? this.props.updateMode(mode) : this.null();
        });

    }

    private getNewEvent(action: Action): Event {
        const event: Event = createEvent();

        const fields: Field[] = action.fields;
        fields.forEach((field: Field) => {
            this.enrichWithDefaultValues(field, event, false);
        });

        return event;
    }

    private enrichWithDefaultValues(field: Field, event: Event, isChanging: boolean): void {
        let fields = field.fields;
        const visible: boolean = shouldBeVisible(field, event);
        if (!visible) {
            return;
        }
        if (fields === undefined || fields.length === 0) {
            if (field.defaultValue !== undefined) {
                this.enrichEventWithDefaultValues(field, event, isChanging);
            }
        } else {
            fields.forEach((f: Field) => {
                this.enrichWithDefaultValues(f, event, isChanging);
            });

            if (field.defaultValue !== undefined) {
                this.enrichEventWithDefaultValues(field, event, isChanging);
            }
        }
    }

    private enrichEventWithDefaultValues(field: Field, event: Event, isChanging: boolean): void {
        if (field.defaultValue !== undefined) {
            const duplicationIndex: string = getDuplicationIndex(field);
            const name: string = field.name;
            const currentValue: string | string[] | undefined = getValue(name, event, duplicationIndex);

            if (field.type === 'date') {
                if (isChanging) {
                    //
                    // Allow the user to clear the field
                    //
                    // If we don't know if we are in onChange or not,
                    // then we will add the default value to the field
                    // as soon as it is empty. A stupid behaviour.
                    // Worse than this hack.

                    return;
                }
                if (currentValue === undefined) {
                    const defaultValue: string = field.defaultValue;
                    const value: string = getDefaultDateValue(defaultValue);
                    const valid = true;

                    setValue(name, value, duplicationIndex, valid, field, event);
                }

                return;
            }

            if (field.type === 'radio') {
                if (currentValue === undefined) {
                    const value: string = field.defaultValue;
                    const valid = true;

                    setValue(name, value, duplicationIndex, valid, field, event);
                }

                return;
            }

            throw Error('Default value should have been set for <' + name + '>');
        }
    }

    private addDuplicatedFields(field: Field, event: Event, duplicationReceivers: Field[], action: Action, parent: Field | undefined) {
        let candidate: Field = this.getFieldForDuplication(field, action);
        let di: string = '' + candidate.duplicationIndex;
        while (hasChildField(candidate, event, di)) {
            this.addDuplicatedField(duplicationReceivers, field, candidate, parent);

            candidate = this.getFieldForDuplication(candidate, action);
            di = '' + candidate.duplicationIndex;
        }
    }

    private addDuplicatedField(duplicationReceivers: Field[], field: Field, duplicated: Field, parent: Field | undefined) {
        let index = 0;
        for (let i = 0; i < duplicationReceivers.length; i++) {
            const c: Field = duplicationReceivers[i];
            if (c.name === field.name && c.duplicationIndex === field.duplicationIndex) {
                index = i;
            }
        }
        duplicated.parent = parent;

        index++;
        duplicationReceivers.splice(index, 0, lodash.cloneDeep(duplicated));
        this.reindexDuplicationReceivers(duplicationReceivers, duplicated);
    }

    private reindexDuplicationReceivers(duplicationReceivers: Field[], duplicated: Field) {
        // Sometimes the duplication index on the field gets messed up.
        // For example, when a duplicated field is deleted in the middle of a few duplications.
        // Get them straight by re-indexing them.

        const currentFields: Field[] = duplicationReceivers.filter((f: Field) => f.name === duplicated.name);
        for (let index = 0; index < currentFields.length; index++) {
            const field: Field = currentFields[index];

            field.duplicationIndex = index;
        }
    }

    private enrichWithParents(action: Action) {
        action.fields.forEach((parent: Field) => {
            if (parent.fields !== undefined) {
                parent.fields.forEach((child: Field) => {
                    this.enrichWithParent(child, parent);
                })
            }

            if (parent.options !== undefined) {
                parent.options.forEach((option: string | Field) => {
                    if (typeof option !== 'string') {
                        this.enrichWithParent(option, parent);
                    }
                });
            }
        });
    }

    private enrichEvent(currentAction: Action, event: Event) {
        currentAction.fields.forEach((field) => {
            this.assignCompleteFieldToEvent(event, field);
        });
    }

    private assignCompleteFieldToEvent(event: Event, field: Field) {
        this.replaceFieldInEvent(field, event);
        let subFields = field.fields;
        let options = field.options;
        if (subFields === undefined && options === undefined) {
            return;
        }

        if (subFields !== undefined) {
            subFields.forEach((subField) => {
                this.assignCompleteFieldToEvent(event, subField);
            });
        }
        if (options !== undefined) {
            options.forEach((option) => {
                if (typeof option !== 'string') {
                    this.assignCompleteFieldToEvent(event, option);
                }
            });
        }

    }

    private replaceFieldInEvent(field: Field, event: Event) {
        let values: Map<string, Value> = event.values;

        const key: string = getKey(field.name, field.duplicationIndex + "");
        if (values.has(key)) {
            let existingValue = values.get(key);
            let newValue = Object.assign({}, existingValue, {field: field});
            values.delete(key);
            values.set(key, newValue);
        }
    }

    private enrichWithParent(child: Field, parent: Field) {
        child.parent = parent;

        if (child.fields !== undefined) {
            child.fields.forEach((c: Field) => this.enrichWithParent(c, child));
        }

        if (child.options !== undefined) {
            child.options.forEach((option: string | Field) => {
                if (typeof option !== 'string') {
                    this.enrichWithParent(option, child);
                }
            });
        }
    }

    private enrichConditionalOns(action: Action) {
        const conditionalOn: Field[] = [];

        action.fields.forEach((field: Field) => {
            if (field.conditionalOn !== undefined) {
                conditionalOn.push(field);
            }

            if (field.fields !== undefined) {
                field.fields.forEach((child: Field) => {
                    if (child.conditionalOn !== undefined) {
                        conditionalOn.push(child);
                    }
                    this.findConditionalOn(child, conditionalOn);
                })
            }

            if (field.options !== undefined) {
                field.options.forEach((option: string | Field) => {
                    if (typeof option !== 'string') {
                        if (option.conditionalOn !== undefined) {
                            conditionalOn.push(option);
                        }
                        this.findConditionalOn(option, conditionalOn);
                    }
                });
            }
        });

        conditionalOn.forEach((field: Field) => {
            addDuplicationIndexToConditionalOnPredicates(field);
        });
    }

    private findConditionalOn(field: Field, conditionalOn: Field[]) {
        if (field.fields !== undefined) {
            field.fields.forEach((child: Field) => {
                if (child.conditionalOn !== undefined) {
                    conditionalOn.push(child);
                }
                this.findConditionalOn(child, conditionalOn);
            })
        }

        if (field.options !== undefined) {
            field.options.forEach((option: string | Field) => {
                if (typeof option !== 'string') {
                    if (option.conditionalOn !== undefined) {
                        conditionalOn.push(option);
                    }
                    this.findConditionalOn(option, conditionalOn);
                }
            });
        }
    }

    private removeDuplicatedInvisibleFields(action: Action, event: Event): Action {
        const fieldsForRemoval: Field[] = [];
        const fields: Field[] = action.fields;
        fields.forEach((field: Field) => {
            if (field.duplicationIndex > 0) {
                const visible: boolean = shouldBeVisible(field, event);
                if (!visible) {
                    fieldsForRemoval.push(field);
                }
            }
        });
        const fieldsToKeep: Field[] = [];
        fields.forEach((candidate: Field) => {
            if (!fieldsForRemoval.includes(candidate)) {
                fieldsToKeep.push(candidate);
            }
        });
        action.fields = fieldsToKeep;

        return action;
    }

    private updateProgressBar = () => {
        let updateProgress = this.props.updateProgress;
        if (updateProgress) {
            updateProgress(this.state.event);
        }
    }

    private getLoadingWheel() {
        if (this.props.currentlySaving) {
            return <div
                className={"spinner pr-5 mb-4"}
                aria-label={"wating for save result"}
            />
        }

        return <div/>
    }

    private null() {

    }
}

export default Form;
