import React, {ReactElement} from "react";
import ReportTable, {Report} from "./ReportTable";
import {SelectPeriodInterval} from "./SelectPeriodInterval";


interface props {
}

interface state {
    report: Report | undefined,
}


class SurgeryReport extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            report: undefined,
        }
    }

    populateReport = (reportValue: Report) => {
        this.setState({report: reportValue});
    }

    render(): ReactElement {
        const selection = this.selectPeriod();
        let report = this.getReport();

        return <div>
            {selection}
            <hr/>
            {report}
        </div>;
    }

    private getReport() {
        if (this.state.report === undefined) {
            return <div/>
        }

        return <ReportTable report={this.state.report}
                            horizontalScrolling={true}/>
    }

    private selectPeriod() {
        let url = "/api/v1/reports/surgery/"
        return <div>
            <SelectPeriodInterval onViewReport={this.populateReport} fetchReportsUrl={url}/>
        </div>
    }

}


export default SurgeryReport;
