import React from "react";
import "./RegisterResult.css";

interface props {
    hideFailureMessage: () => void,
    message: string[]
}

interface state {
    failureMessage: string[]
}

class RegisterResult extends React.Component<props, state> {
    constructor(props: any) {
        super(props);
        const failureMessage = this.props.message;

        this.state = {
            failureMessage: failureMessage
        }
    }

    render() {
        const formBackground: string = "register-modal-background";
        const onClick = this.props.hideFailureMessage;

        const failureMessage = this.state.failureMessage;
        const messageLines: React.JSX.Element [] = failureMessage.map((row) => {
            return <div key={row} className={"row m-0 p-0"}>
                {row}
            </div>
        });
        const message: React.JSX.Element = <div>
            {messageLines}
        </div>;

        const dialogueBackground: string = "register-modal failed-registration";
        const confirmationButton: React.JSX.Element = <div>
            <div className={"row m-0 pt-2 pr-3 justify-content-end"}>
                <button className={"btn btn-tage-default-cancel"}
                        onClick={onClick}>OK
                </button>
            </div>
        </div>;

        return <div>
            <aside className={formBackground}
                   onClick={onClick}/>
            <div className={dialogueBackground}>
                <div className={"container p-0 m-0"}>
                    {message}
                    {confirmationButton}
                </div>
            </div>
        </div>;
    }
}

export default RegisterResult;
