export const GRAPH_TYPE_DISTRIBUTION: string = 'distributionAnalysis';
export const GRAPH_TYPE_TOTAL_AVERAGE: string = 'totalAverageAnalysis';
export const GRAPH_TYPE_MOVING_AVERAGE: string = 'movingAverageAnalysis';

export const MOVING_AVERAGE: string = 'movingAverage';
export const DEFAULT_MOVING_AVERAGE: number = 28;

export const PERIOD_SELECTION: string = 'periodSelection';
export const LAST_SELECTION: string = 'lastSelection';
export const FIRST_SELECTION: string = 'firstSelection';
export const ALL_REGISTRATIONS: string = 'allRegistrations';

export const FIRST_AMOUNT: string = 'firstAmount';
export const LAST_AMOUNT: string = 'lastAmount';
export const PERIOD_FROM: string = 'period.from';
export const PERIOD_TO: string = 'period.to';
