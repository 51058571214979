import React, {Component} from "react";
import {BackendContext} from "../../../infra/BackendContext";
import {EventBackendService} from "../../../components/register/v2/EventBackendService";
import {Organisation} from "../../../components/model/Organisation";
import TranslationService from "../../../infra/TranslationService";
import "./UserAdministration.css"

import DeleteLogo from "../../../images/delete-512.png"
import EditLogo from "../../../images/edit-225-225.png"
import CreateUserModal from "../../../components/create-user-modal/CreateUserModal";

export type UAU = {
    userName: string,
    firstName: string,
    lastName: string,
    lastRegister?: string,
    email: string,
    phone: string,
    roles?: Role[]
};

export type Role = { name: string, roleId: string, description: string };

interface UAUResponse extends TageResponse {
    data: { users: UAU }
}

export interface TageResponse {
    success: boolean,
    message: string
}

interface props {
    organisation?: Organisation
}

interface state {
    users: UAU[],
    showUserModal: boolean,
    editing: boolean,
    creating: boolean,
    currentUserName?: string
    sortOrder?: string
}

enum SortOrder {
    FIRSTNAME = "firstName",
    LASTNAME = "lastName",
    EMAIL = "email",
    FIRSTNAMEREVERSE = "firstNameR",
    LASTNAMEREVERSE = "LastNameR",
    EMAILREVERSE = "emailR",
}

function firstnameOrder(reverse: boolean) {
    return (a: UAU, b: UAU) => {
        const aCaps: string = a.firstName.toUpperCase();
        const bCaps: string = b.firstName.toUpperCase();
        if (aCaps < bCaps) {
            return -1 * (reverse ? -1 : 1);
        }
        if (aCaps > bCaps) {
            return 1 * (reverse ? -1 : 1);
        }
        return 0;
    };
}

function lastnameOrder(reverse: boolean) {
    return (a: UAU, b: UAU) => {
        const aCaps = a.lastName.toUpperCase();
        const bCaps = b.lastName.toUpperCase();
        if (aCaps < bCaps) {
            return -1 * (reverse ? -1 : 1);
        }
        if (aCaps > bCaps) {
            return 1 * (reverse ? -1 : 1);
        }
        return 0;
    };
}

function emailOrder(reverse: boolean) {
    return (a: UAU, b: UAU) => {
        const aCaps = a.email.toUpperCase();
        const bCaps = b.email.toUpperCase();
        if (aCaps < bCaps) {
            return -1 * (reverse ? -1 : 1);
        }
        if (aCaps > bCaps) {
            return 1 * (reverse ? -1 : 1);
        }
        return 0;
    };
}

class UserAdministration extends Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);

        this.state = ({
            showUserModal: false,
            editing: false,
            creating: false,
            users: []
        })
    }

    render() {
        const usersTable = this.userTable();
        const {tr} = this;
        const {editing, creating, users, currentUserName} = this.state;
        let modal = <div></div>

        if (editing || creating) {
            let user;
            if (editing) {
                user = users.find((usr) => usr.userName === currentUserName)
            }

            modal = <CreateUserModal hideModal={() => this.hideModal()}
                                     user={user}
                                     editing={editing}
                                     organisation={this.props.organisation}
                                     updateUserList={() => this.updateUserList()}
                                     creating={creating}>
            </CreateUserModal>
        }


        return <div className={"container ua-container"}>
            <div className={"row pt-4"}>
                <div className={"col"}>
                    <h1>
                        {tr("User administration")}
                    </h1>
                </div>
            </div>
            <div className={"row pt-3"}>
                <div className={"col-8"}>
                    <div className={"row m-0 p-0"}>
                        <div className={"col text-left pl-0"}>
                            <button
                                onClick={() => this.createNewUser()}
                                aria-label={"Create new user"}
                                className={"btn btn-tage-default"}> {tr("Create new user")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"row pt-4"}>
                <div className={"col"}>
                    <h3>
                        {tr("Current users:")}
                    </h3>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col uau-table"}>
                    {usersTable}
                </div>
            </div>
            {modal}
        </div>
    }


    async componentDidMount() {
        await this.updateUserList();
    }

    userTable(): React.JSX.Element {
        const {tr} = this;

        if (this.state) {
            const {users, sortOrder} = this.state


            if (users && users.length > 0) {
                let selected = " uaselected";
                let firstNameClass, lastNameClass, emailClass;
                firstNameClass = lastNameClass = emailClass = "btn btn-mini ml-1";
                let firstNameButtonSymbol, lastNameButtonSymbol, emailButtonSymbol;
                firstNameButtonSymbol = lastNameButtonSymbol = emailButtonSymbol = "▼";

                let orderedUsers: UAU[] = [];

                if (sortOrder === SortOrder.FIRSTNAME || sortOrder === SortOrder.FIRSTNAMEREVERSE) {
                    firstNameButtonSymbol = sortOrder === SortOrder.FIRSTNAMEREVERSE ? "▼" : "▲"
                    firstNameClass = firstNameClass + selected;

                    orderedUsers = users.sort(firstnameOrder(sortOrder === SortOrder.FIRSTNAMEREVERSE));
                }

                if (sortOrder === SortOrder.LASTNAME || sortOrder === SortOrder.LASTNAMEREVERSE) {
                    lastNameButtonSymbol = sortOrder === SortOrder.LASTNAMEREVERSE ? "▼" : "▲"
                    lastNameClass = lastNameClass + selected;

                    orderedUsers = users.sort(lastnameOrder(sortOrder === SortOrder.LASTNAMEREVERSE));
                }

                if (sortOrder === SortOrder.EMAIL || sortOrder === SortOrder.EMAILREVERSE) {
                    emailButtonSymbol = sortOrder === SortOrder.EMAILREVERSE ? "▼" : "▲"
                    emailClass = emailClass + selected;

                    orderedUsers = users.sort(emailOrder(sortOrder === SortOrder.EMAILREVERSE));
                }

                if (!sortOrder) {
                    orderedUsers = users;
                }


                return <table>
                    <thead>
                    <tr>
                        <th style={{padding: 0}}>
                            {tr("firstName")}
                            <button onClick={() => this.sortByFirstName()}
                                    aria-label={"sort-by-firstname"}
                                    className={firstNameClass}>{firstNameButtonSymbol}
                            </button>
                        </th>
                        <th>{tr("lastName")}
                            <button onClick={() => this.sortByLastName()}
                                    aria-label={"sort-by-lastname"}
                                    className={lastNameClass}>{lastNameButtonSymbol}
                            </button>
                        </th>
                        <th>{tr("email")}
                            <button onClick={() => this.sortByEmail()}
                                    aria-label={"sort-by-email"}
                                    className={emailClass}>{emailButtonSymbol}
                            </button>
                        </th>
                        <th>{tr("edit")}</th>
                        <th>{tr("delete")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orderedUsers.map((user: UAU, index: number) => {
                        return <tr key={index + "table_row"} className={index % 2 === 0 ? "grayed" : ""}>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                            <td>
                                <button aria-label={"edit-user"}
                                        className={"btn btn-invis-bg pl-1 b-0"}
                                        onClick={() => this.handleEdit(user)}>
                                    <img
                                        height={18}
                                        src={EditLogo}
                                        alt={"altText"}/>
                                </button>
                            </td>
                            <td>
                                <button aria-label={"delete-user"}
                                        className={"btn btn-invis-bg pl-1 b-0"}
                                        onClick={() => this.handleDelete(user)}>
                                    <img
                                        height={18}
                                        src={DeleteLogo}
                                        alt={"altText"}/>
                                </button>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            }
        }
        return <div></div>
    }

    private tr(string: string): string {
        return TranslationService.translation(string)
    }

    private handleEdit(user: UAU) {
        this.setState({editing: true, currentUserName: user.userName})
        return undefined;
    }

    private async handleDelete(user: UAU) {
        const {tr} = this;
        let orgId: string = this.props.organisation?.organisationId || "0";
        const path: string = `/api/v1/administration/user/${user.userName}/${orgId}`
        const url: string = EventBackendService.getUrl2(path);

        if(window.confirm(tr("This will delete user:") + user.firstName + " " + user.lastName)) {
            const deleteResult = await this.context.delete(url, {
                success: "",
                failure: this.tr("Failed to delete user")
            }) as TageResponse;

            if (deleteResult.success) {
                await this.updateUserList();
            }
        }
    }

    private createNewUser() {
        this.setState({creating: true})
    }

    private hideModal() {
        this.setState({editing: false, creating: false, currentUserName: undefined})
    }

    private sortByFirstName() {
        if (this.state.sortOrder === SortOrder.FIRSTNAME) {
            this.setState({sortOrder: SortOrder.FIRSTNAMEREVERSE})
        } else {
            this.setState({sortOrder: SortOrder.FIRSTNAME})
        }
    }

    private sortByLastName() {
        if (this.state.sortOrder === SortOrder.LASTNAME) {
            this.setState({sortOrder: SortOrder.LASTNAMEREVERSE})
        } else {
            this.setState({sortOrder: SortOrder.LASTNAME})
        }
    }

    private sortByEmail() {
        if (this.state.sortOrder === SortOrder.EMAIL) {
            this.setState({sortOrder: SortOrder.EMAILREVERSE})
        } else {
            this.setState({sortOrder: SortOrder.EMAIL})
        }
    }

    private async updateUserList() {
        let orgId: string = this.props.organisation?.organisationId || "0";
        const path: string = `/api/v1/administration/user/${orgId}`
        const url: string = EventBackendService.getUrl2(path);

        const response: any = await this.context.get(url, {
            success: "",
            failure: ""
        }) as UAUResponse;

        if (response && response.success) {
            this.setState({users: response.data.users})
        }
    }
}

export default UserAdministration;
