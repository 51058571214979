import {Action, Field} from "../register/v2/Action";
import React from "react";
import {removeUnwantedFieldsGroupBy} from "../statistics/v3/GroupBy";
import ActionRenderer from "./ActionRenderer";
import {ActionPreProcessor, ActionTemplate, ATField, ATFieldType, ATType, Fieldd} from "./ActionPreProcessor";
import TranslationService from "../../infra/TranslationService";

interface props {
    action: Action,
    selectedGroupBy: string,
    onGroupByChange: (selectedGroupBy: string) => void,
    user: any,
}

interface state {
    action: Action,
    values: Fieldd[],
    currentlySelected: string,
    template: ActionTemplate
    // possibleEventValues: Set<string>
}

function isDescendant(name: string, groupName: any): boolean {
    return name.startsWith(groupName);
}

class GroupByV4Render extends React.Component<props, state> {
    processor: ActionPreProcessor;

    constructor(props: props) {
        super(props);

        const action: Action = removeUnwantedFieldsGroupBy(this.props.action);

        const secondProcess: ActionTemplate = this.translateToAT(action)

        this.processor = new ActionPreProcessor();
        const defaultValues: Fieldd[] = [];
        let currentlySelected = "";
        if (secondProcess.name === "airway") {
            defaultValues.push({name: "forced.parent", value: ["airway.outcome.success"]})
            currentlySelected = "airway.outcome.success";
        }

        const initialTemplate = this.processor.process(secondProcess, defaultValues, "foo")
        this.state = {values: defaultValues, currentlySelected, template: initialTemplate, action}
    }

    render() {
        const headline: string = TranslationService.translation("group_by");
        return <>
            <h4>{headline}</h4>
            <ActionRenderer radioClick={this.onRadiobuttonClick}
                            duplicationClick={this.onDuplicationClick}
                            template={this.state.template}/>
        </>

    }

    onRadiobuttonClick = (radioVal: string, group: string, duplicationIndex?: number): void => {
        let values = this.cleanUp(radioVal, group, duplicationIndex);

        const contains = values.find((val) => (val.name === radioVal && val.duplicationIndex === duplicationIndex));

        if (!contains) {
            values.push({name: group, value: [radioVal], duplicationIndex: duplicationIndex})
        }

        const translated = this.translateToAT(this.state.action)
        const template = this.processor.process(translated, values, radioVal)

        this.setState({currentlySelected: radioVal, values, template})
        this.props.onGroupByChange(radioVal);
    }

    onDuplicationClick = (fieldName: string): void => {
        console.log("no duplication here")
    }

    private cleanUp(value: any, groupName: any, duplicationIndex?: number): Fieldd[] {
        //this needs to be smarter in the generic case, but in groupby, eliminating everything except current tree is sufficient
        const values = this.state.values;
        const remainingValues: Fieldd[] = []
        if (groupName === "forced.parent") { //this is an exception for groupby and the field-parent mess
            return [];
        } else {
            values.forEach((val) => {
                if (!(isDescendant(val.name, groupName))) {
                    remainingValues.push(val);
                }
            })

        }
        return remainingValues
    }

    private translateToAT(action: Action): ActionTemplate {
        const fields: ATField[] = []

        action.fields.forEach((field: Field) => {
            const convertedOptions: ATField[] = this.translateOptionToAt(field.options)
            const converted: ATField = {
                type: field.type,
                name: field.name,
                checked: false,
                options: convertedOptions,
            }

            fields.push(converted);
        })

        return {
            name: action.name,
            version: action.version,
            frontendVersion: action.frontendVersion,
            type: ATType.STATISTICS,
            fields: fields
        }
    }

    private translateOptionToAt(options: (string | Field)[] | undefined): ATField[] {
        const convertedOptions: ATField [] = []

        options?.forEach((option) => {
            try {
                //what about empty arrays?
                const suboptions = option as Field;
                const inneroptions = this.translateOptionToAt(suboptions.options);

                convertedOptions.push({
                    type: suboptions.type,
                    name: suboptions.name,
                    checked: false,
                    options: inneroptions
                })
            } catch (e) {
                convertedOptions.push({
                    name: JSON.stringify(option), type: ATFieldType.OPTION
                })
            }
        })

        return convertedOptions;
    }
}

export default GroupByV4Render;