import React from 'react';
import {isLocalhost} from "../../../infra/Utils";
import EditLogo from "../../../images/edit-225-225.png"
import {fetchArrayOf, postObjectOf} from "../../../infra/BackendService";
import "./EditHeadlineElement.css"

interface Props {
    eventId: string,
    headline: string,
    updateType: (sequenceNumber: string, value: string) => void;
    sequenceNumber: string;
    updateParent: () => void;
}

interface State {
    showDropdown: boolean;
    deviationTypes: SelectOption [];
}

interface SelectOption {
    value: string,
    displayName: string
}

const UPDATE_DEVIATION_EVENT_API = "/api/v1/supervisor/deviation/type"
const GET_DEVIATION_OPTIONS_API = "/api/v1/supervisor/deviation/options"

class EditHeadlineElement extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            showDropdown: false,
            deviationTypes: []
        }
    }

    async componentDidMount() {
        let eventId = this.props.eventId;
        let uri: string = GET_DEVIATION_OPTIONS_API + `/` + eventId;
        const deviationTypes: SelectOption[] | undefined = await fetchArrayOf<SelectOption>(uri);

        if (deviationTypes) {
            this.setState({deviationTypes: deviationTypes})
        }
    }

    render() {
        const shouldRenderTypeOptions = this.shouldRenderTypeOptions();

        let typeSelect: React.JSX.Element = <div/>
        if (this.state.showDropdown) {
            const selectOptions = this.state.deviationTypes;
            const typeOptions: React.JSX.Element[] = selectOptions.map(option => {
                const value = option.value;
                const displayName = option.displayName;
                return <option value={value}
                               key={value}>
                    {displayName}
                </option>
            });

            const defaultOption = <option selected={true} disabled value={""}>-- Välj --</option>;
            typeSelect = <div className={"pl-3 mt-1"}>
                <select name={"deviation-update"}
                        onChange={(e) => this.handleSelect(e)}>
                    {defaultOption}
                    {typeOptions}
                </select>
            </div>;
        }

        let deviationTypeEditButton: React.JSX.Element = <div/>;
        if (shouldRenderTypeOptions) {
            deviationTypeEditButton = <div className={"row pl-3 m-0"}>
                <div className={"hover-edit-deviation"}>
                    <img className={""}
                         alt={"edit deviation type"}
                         src={EditLogo}
                         height={16}
                         onClick={() => this.flipVisibility()}
                    />
                </div>
                {typeSelect}
            </div>;
        }

        return deviationTypeEditButton;
    }


    private shouldRenderTypeOptions(): boolean {
        let headline = this.props.headline;
        return headline === "Typ av avvikelse" && isLocalhost();
    }

    private flipVisibility() {
        this.setState({showDropdown: !this.state.showDropdown})
    }

    private async handleSelect(e: React.SyntheticEvent<HTMLSelectElement, Event>) {
        const payload = {
            value: e.currentTarget.value,
            field: "capio-deviation.type",
            eventId: this.props.eventId
        }

        await postObjectOf(payload, UPDATE_DEVIATION_EVENT_API).then(
            (success) => {
                //this.props.updateType(this.props.sequenceNumber, displayName);

                //THIS MIGHT NOT BE THE ROAD WE GO DOWN ON (this.props.updateParent)
                //consolidate logic between this and emailmodal and evaluation.
                this.props.updateParent();
                this.flipVisibility();
                console.log(success)
            },
            (error) => {
                console.log(error);
            }
        );
    }

    private displayName(value: string): string {
        return this.state.deviationTypes.find(e => e.value === value)?.displayName || "";
    }
}

export default EditHeadlineElement;
