import React from 'react';
import {ModalDisplayBinder} from "../KnarkbokModal";
import {KnarkbokService} from "../Knarkbokservice";
import {DiscardReason} from "../models/Models";
import "./Move.css";
import {BackendContext, BackendInterface} from "../../../infra/BackendContext";

interface props {
    closeModal: () => void;
    book: ModalDisplayBinder;
    KBService: KnarkbokService;
    isDruglord: boolean;
}

interface state {
    spinner: boolean;
    inputDiscarded: string | undefined
    newBalance: string | undefined,
    displayCheckWarning: boolean,
    discardReason: DiscardReason | undefined,
    comment: string | undefined;
    showComment: boolean;
}

class PartialAdminister extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {
            spinner: false,
            inputDiscarded: undefined,
            newBalance: undefined,
            displayCheckWarning: false,
            discardReason: undefined,
            showComment: false,
            comment: undefined
        }
    }

    render() {
        if (this.state.spinner) {
            return this.getSpinner()
        }

        const commentFlip = <div
            aria-label={'show hide comment'}
            className={"pt-2"}
            title="Lägg till kommentar">
            <button className={"btn btn-tiny-expand"} onClick={(e) => this.flipComment(e)}>
                {this.state.showComment ? "-" : "+"}
            </button>
        </div>;

        const commentField = <div>
            <div className={"row m-0 pt-2"}>
                <div className={"pt-2 pr-2"}>Kommentar:</div>
                {commentFlip}
            </div>
            {
                this.state.showComment ? <React.Fragment>
                    <div className={"row m-0"}>
                        <textarea value={this.state.comment}
                                  onChange={(e) => this.handleChangeComment(e)}
                                  className={"max-size-input"}
                        />
                    </div>
                </React.Fragment> : <div/>
            }
        </div>

        const warningStuff = this.state.displayCheckWarning ? <div>
            <div className={"row m-0 p-0 pb-3 justify-content-center"}>
                <h5 className={"innermodal-warning-text"}>Är du säker på att antalet är korrekt?</h5>
            </div>
            <div className={"row m-0 pr-4 justify-content-end"}>
                <button className={"btn btn-red"} onClick={() => this.submit(true)}>Jag är säker</button>
                <button className={"btn btn-tage-default-cancel  ml-2 mr-2"}
                        onClick={() => this.closeWarning()}>Avbryt
                </button>
            </div>
        </div> : "";

        const submitCancelButtons = this.state.displayCheckWarning ? "" :
            <div className={"row m-0 pr-4 pt-2 justify-content-end"}>

                <button aria-label={'ok'}
                        className={"btn btn-tage-default"} onClick={() => this.submit()}>
                    OK
                </button>
                <button aria-label={'cancel'}
                        className={"btn btn-tage-default-cancel ml-2 mr-2"}
                        onClick={this.props.closeModal}>
                    Avbryt
                </button>
            </div>;


        return (
            <div className={"container m-0 p-0"}>
                <div className={"row"}>
                    SUBMIT PARTIAL TEST TEMP

                    {commentField}
                </div>
                <div className={"row"}>

                    {warningStuff}
                    {submitCancelButtons}
                </div>
            </div>
        );
    }

    private getSpinner() {
        return <div aria-label={"administer-drug-spinner"}
                    className={"spinner"}/>;
    }

    private submit = async (forceSubmit?: boolean) => {
        console.log("submitted!")
    }


    private flipComment(e: React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        this.setState({showComment: !this.state.showComment})
    }

    private handleChangeComment(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({comment: e.currentTarget.value});
    }

    private closeWarning() {
        this.setState({displayCheckWarning: false})
    }
}

export default PartialAdminister;
