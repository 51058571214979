import React from "react";
import {Action} from "../../register/v2/Action";
import Analysis from "./Analysis";
import PeriodNumberOf from "./PeriodNumberOf";
import {GraphOptions} from "./GraphOptions";
import {GRAPH_TYPE_DISTRIBUTION} from "../Constants";
import GroupByV4Render from "../../action-preprocessor/GroupByV4Render";
import {User} from "../../model/User";

interface props {
    action: Action,
    graphOptions: GraphOptions,
    user: User

    selectedGroupBy: string,
    onGroupByChange: (selectedGroupBy: string) => void,

    onGraphTypeChange: (graphType: string) => void,
    onMovingAverageChange: (movingAverage: number | undefined) => void,

    onPeriodChange: (from: string, to: string) => void,
    onFirstEventsChange: (firstEvents: number | undefined) => void,
    onLastEventsChange: (firstEvents: number | undefined) => void,
    onAllEventsChange: () => void,
}

interface state {
}

class Selection extends React.Component<props, state> {
    constructor(props: props) {
        super(props);
        this.state = {}
    }

    render(): React.ReactNode {
        const action: Action = this.props.action;
        const user: any = this.props.user;
        const selectedGroupBy = this.props.selectedGroupBy;
        const onGroupByChange = this.props.onGroupByChange;

        const groupBy = this.getGroupBy(action, selectedGroupBy, onGroupByChange, user);

        const analysis = this.getAnalysis();
        const periodNumberOf = this.getPeriodNumberOf();

        return <div className={"container m-0 b-0 p-0"}>
            <hr/>
            <div className={"row"}>
                <div className={"col"}>
                    {groupBy}
                </div>
                <div className={"col"}>
                    {analysis}
                </div>
            </div>
            <hr/>
            <div className={"row"}>
                <div className={"col"}>
                    {periodNumberOf}
                </div>
            </div>
        </div>;
    }

    private getGroupBy(action: Action,
                       selectedGroupBy: string,
                       onGroupByChange: (selectedGroupBy: string) => void,
                       user: any): React.ReactFragment {
        return <div><GroupByV4Render action={action} selectedGroupBy={selectedGroupBy} onGroupByChange={onGroupByChange}
                                     user={user}/>
        </div>;
    }

    private getAnalysis(): React.JSX.Element {
        const graphOptions: GraphOptions = this.props.graphOptions;

        let graphType: string = GRAPH_TYPE_DISTRIBUTION;
        if (graphOptions.graphType !== undefined) {
            graphType = graphOptions.graphType;
        }

        let movingAverage: number | undefined = graphOptions.movingAverage;

        const onGraphTypeChange = this.props.onGraphTypeChange;
        const onMovingAverageChange = this.props.onMovingAverageChange;

        return <Analysis graphType={graphType}
                         movingAverage={movingAverage}
                         onGraphTypeChange={onGraphTypeChange}
                         onMovingAverageChange={onMovingAverageChange}
        />;
    }

    private getPeriodNumberOf(): React.JSX.Element {
        const action: Action = this.props.action;
        const graphOptions: GraphOptions = this.props.graphOptions;
        const user: any = this.props.user;
        const dateFormat: string = user.dateFormat;
        const onFirstEventsChange = this.props.onFirstEventsChange;
        const onLastEventsChange = this.props.onLastEventsChange;
        const onPeriodChange = this.props.onPeriodChange;
        const onAllEventsChange = this.props.onAllEventsChange;

        return <PeriodNumberOf action={action}
                               graphOptions={graphOptions}
                               dateFormat={dateFormat}
                               user={user}
                               onPeriodChange={onPeriodChange}
                               onFirstEventsChange={onFirstEventsChange}
                               onLastEventsChange={onLastEventsChange}
                               onAllEventsChange={onAllEventsChange}
        />;
    }
}

export default Selection;
