import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface Props {
    value: string,
    updateStateWithValue: (value: string, elementName: string) => void,
    elementName: string,
    oldName: string,
    dateFieldAsDate: boolean,
    mandatory: boolean,
    dateFormat: string,
}

export function getShortISO(date: Date) {
    function pad(number: number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }

    return date.getUTCFullYear() + '-' + pad(date.getUTCMonth() + 1) + '-' + pad(date.getUTCDate());
}

export default class DateInputField extends React.Component<Props> {

    render() {
        const value = this.props.value;
        return this.getDatePicker(value);
    }

    getDatePicker(value: string): DatePicker {
        let datePicker;
        if (value === "" || value === undefined || value === null) {
            datePicker = <DatePicker
                showWeekNumbers
                id={this.props.oldName + "InputField"}
                dateFormat="yyyy-MM-dd"
                onChange={(date: Date) => this.handleSetDate(date)}
            />;
        } else {
            const defaultDate = new Date(value)
            datePicker = <DatePicker
                showWeekNumbers
                id={this.props.oldName + "InputField"}
                dateFormat="yyyy-MM-dd"
                selected={defaultDate}
                onChange={(date: Date) => this.handleSetDate(date)}
            />;
        }

        // @ts-ignore
        return datePicker;
    }

    private handleSetDate(date: Date) {
        if (date !== null) {
            let dateString = getShortISO(date)

            this.props.updateStateWithValue(dateString, this.props.elementName);
        }
    }
}