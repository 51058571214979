import React, {ReactElement} from "react";
import TranslationService from "../../../infra/TranslationService";
import {uuidv4} from "../../register/v2/Uuid";
import TableCell from "./TableCell";
import "./LiaReport.css"


export interface ReportColumn {
    header: string,
    cells: ComplexCell[] | string[],
}

export interface ComplexCell {
    values: string[]
    classNames?: string[]
    delimiter?: string

}

export interface Report {
    reporterName?: string,
    reporterNameClass?: string
    reporterSchoolClass?: string
    reportColumns?: ReportColumn[]
}


interface props {
    reportName?: string,
    report: Report,
    legend?: any,
    horizontalScrolling: boolean
}

interface state {
}


class ReportTable extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            report: undefined,
            legend: undefined
        }
    }

    render(): ReactElement {
        const report = this.drawReport();
        return <div>
            {report}
        </div>;
    }


    private drawReport() {
        const columns = this.props.report?.reportColumns

        if (!columns) {
            return <div>empty</div>
        }
        let reportClass = "";
        if (this.props.horizontalScrolling) {
            reportClass = "scroll-sideways";
        }
        const legend = this.props.legend ? <div>{this.props.legend}</div> : <div/>

        let reportNameHeader = <div/>
        const reportName = this.props.reportName;
        if (reportName !== undefined) {
            reportNameHeader = <h1 className={"pl-1"} aria-label={"reportName"}>{reportName}</h1>
        }

        const name = this.props.report.reporterName;
        let reporterName = <div/>;
        let reporterNameClass = "";
        if (name !== undefined) {
            const studentNameHeader = TranslationService.translation("traindriver-lia-2.reporter.name");
            let nameClass = this.props.report.reporterNameClass;
            if (nameClass !== undefined) {
                reporterNameClass = nameClass;
            }
            reporterName = <div aria-label={"studentName"} className={"pl-1 pb-3"}>
                <span>{studentNameHeader + ": "}</span><span aria-label={"reporterName"}
                                                             className={reporterNameClass}> {name}</span>
            </div>;
        }

        let schoolClass = <div/>
        let schoolClassName = this.props.report.reporterSchoolClass;
        if (schoolClassName !== undefined) {
            let translatedLabel = TranslationService.translation("traindriver-lia-2.reporter.school.class.name");
            schoolClass = <div aria-label={"schoolClassName"}
                               className={"pl-1  pb-3"}>
                {translatedLabel}: {schoolClassName}
            </div>
        }

        return <div>
            {reportNameHeader}
            {reporterName}
            {schoolClass}
            <div className={reportClass} aria-label="scrollableTable">
                <div className={"flex-nowrap d-flex pb-3"} id="table">
                    {this.drawColumns(columns)}
                </div>
            </div>
            {legend}
        </div>
    }

    private drawColumns(columns: ReportColumn[]) {
        return columns.map((column) => {

            return <div key={uuidv4()}>{this.drawColumn(column.cells, column.header)}</div>
        });
    }

    private drawColumn(columnCells: ComplexCell[] | string[], header: string) {
        const _foo: Array<ComplexCell | string> = columnCells;

        const cells = _foo.map((cell: ComplexCell | string, index: number) => {

            const className: string = ReportTable.getClassName("", index);
            if (!ReportTable.instanceOfComplexCell(cell)) {
                return <TableCell key={uuidv4()}
                                  text={cell}
                                  label={header + "-" + index}
                                  background={className}
                                  fontColor=""/>;
            } else {
                const complexCell: ComplexCell = cell as ComplexCell;
                const nonBreakingSpace: string = "\u00a0";
                let values: string[] = complexCell.values;
                let classNames: string[] | undefined = complexCell.classNames;
                let delimiter: string | undefined = cell.delimiter === undefined ? "" : cell.delimiter;
                if (values.length > 1) {
                    return <div key={uuidv4()} className={className + " row m-0 pb-1 no-line-break pr-4 pl-1"}
                                aria-label={header + "-" + index}>
                        <div>
                            {
                                values.map((value, index) => {
                                    let classNameForValue = classNames === undefined ? "" : classNames[index];
                                    return <span key={uuidv4()}
                                                 className={classNameForValue}>{value !== "" ? value : nonBreakingSpace} {index < values.length - 1 ? delimiter : ""} </span>
                                })

                            }
                        </div>
                    </div>;
                } else {
                    return <TableCell key={uuidv4()}
                                      text={values[0]}
                                      label={header + "-" + index}
                                      background={className}
                                      fontColor={classNames === undefined ? "" : classNames[0]}/>;
                }

            }
        });

        return ReportTable.getColumn(cells, header);
    }

    private static getColumn(rows: React.ReactFragment[], header: string): React.ReactFragment {
        const tableHeader: string = TranslationService.translation(header);
        let className = "col pt-1 pl-0 pr-0";

        return <div className={className}>
            <div className={"row m-0 pb-2 no-line-break pr-4 pl-1"} aria-label={tableHeader}>
                <h6 className={"strong"}>
                    {tableHeader}
                </h6>
            </div>
            {rows}
        </div>
    }


    private static getClassName(src: string, index: number) {
        const rowNumber = index + 1;
        if (rowNumber % 2 === 0) {
            return src + " grayed";
        }

        return src;
    }


    private static instanceOfComplexCell(object: any): object is ComplexCell {
        return (object as ComplexCell).values !== undefined;

    }
}

export default ReportTable;
