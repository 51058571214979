import React from 'react';
import ReactDOM from "react-dom";
import "./components/user/Modal.css";

class LoggedOutModal extends React.Component {
    constructor(props) {
        super(props);

        this.onRespond = this.onRespond.bind(this);

        this.state = {
            isOpen: true,
            textField: this.props.textField
        }

        this.modalRef = React.createRef();
    }

    state = {
        isOpen: true,
        textField: "Du har blivit utloggad"
    };

    onOpen = () => {
        this.setState({isOpen: true});
    };

    onClose = () => {
        this.setState({isOpen: false});
        this.props.onRespond();
    };

    onRespond = () => {
        this.props.onRespond();
    };

    closeOnOutsideClick = (e) => {
        if (e.target.contains(this.modalRef.current)) {
            this.onClose();
        }
    };

    render() {
        const {isOpen} = this.state;
        let {textField} = this.state;
        let {buttonText} = this.props;

        return (
            <React.Fragment>
                {isOpen &&
                <LoggedOutModalContent
                    onClose={this.onClose}
                    stopTimer={this.stopTimer}
                    buttonText={buttonText}
                    textField={textField}
                    onRespond={this.onRespond}

                    onRequestClose={this.onClose}
                    shouldCloseOnOverlayClick={true}
                    closeOnOutsideClick={this.closeOnOutsideClick}
                    modalRef={this.modalRef}
                />
                }
            </React.Fragment>
        );
    }
}

const LoggedOutModalContent = ({onClose, textField, buttonText, onRespond, modalRef, closeOnOutsideClick}) => {

    return ReactDOM.createPortal(
        <aside className="c-modal-cover" onClick={(e) => closeOnOutsideClick(e)}>
            <div className="c-modal shadows" ref={modalRef}>
                <button className="c-modal__close" aria-label="Close Modal" onClick={onClose}>
                    <span className="u-hide-visually">Close</span>
                    <svg className="c-modal__close-icon" viewBox="0 0 40 40">
                        <path d="M 10,10 L 30,30 M 30,10 L 10,30">

                        </path>
                    </svg>
                </button>
                <div className="c-modal__body">

                    <div className={"row m-0 justify-content-center"}>
                        <div className={"col-sm-9"}>{textField}</div>
                        <div className={"col-sm-3"}>
                            <button className={"btn btn-small m-0"} onClick={() => onRespond()}>{buttonText}</button>
                        </div>
                    </div>

                </div>
            </div>
        </aside>,
        document.querySelector("#modal")
    );
};

export default LoggedOutModal;