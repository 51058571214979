import React, {ChangeEvent} from "react";
import {FieldComponent, FieldError} from "./FieldComponent";
import Value from "../../register/v2/Value";
import {createMultipleValues, isMultipleValuesValid} from "./FieldUtil";
import TranslationService from "../../../infra/TranslationService";
import DeleteLogo from "../../../images/delete-512.png";
import {DELETE_ALT_TEXT, V3} from "../../../infra/Constants";
import {fetchArrayOf, fetchObjectOf} from "../../../infra/BackendService";
import ConditionalField, {ConditionalProps, ConditionalState} from "./ConditionalField";
import {Field} from "../../register/v2/Action";

export interface SearchedItem {
    code: string,
    description: string
}

interface props extends ConditionalProps {
    searchedItemType?: string,
    frontendVersion?: string,
    value?: string[],
    duplicationIndex?: string,
    onChange?: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
}

interface state extends ConditionalState {
    wanted: string
    selected: SearchedItem [],
    suggestions: SearchedItem[]
}

export class SearchableList extends ConditionalField<props, state> implements FieldComponent {
    constructor(props: Readonly<props> | props) {
        super(props);

        this.state = {
            ...this.state,
            wanted: "",
            selected: [],
            suggestions: []
        }
    }

    render() {
        const name: string = this.props.field.name;
        const label: string = TranslationService.translation(this.props.field.name);
        const selected: SearchedItem[] = this.getValue();
        let showLabel: boolean = true;
        if (this.props.field.showLabel !== undefined) {
            showLabel = this.props.field.showLabel;
        }

        let searchField: React.ReactFragment;
        let selectedValues: React.ReactFragment;
        let suggestions: React.ReactFragment;

        if (this.isMultiple()) {
            const wanted = this.state.wanted;
            const onChange = (e: ChangeEvent<HTMLInputElement>) => this.onChange(e);
            searchField = this.getSearchField(label, showLabel, name, wanted, onChange);
            suggestions = this.getSuggestions();
        } else {
            if (selected.length === 0) {
                const wanted = this.state.wanted;
                const onChange = (e: ChangeEvent<HTMLInputElement>) => this.onChange(e);
                searchField = this.getSearchField(label, showLabel, name, wanted, onChange);
                suggestions = this.getSuggestions();
            } else {
                searchField = <div/>;
                suggestions = <div/>;
            }
        }
        selectedValues = this.getSelected(label, showLabel, name, selected);

        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            return <div>
                {searchField}
                {selectedValues}
                {suggestions}
            </div>
        } else {
            searchField = this.renderField(searchField, "");
            return <div>
                {searchField}
                {selectedValues}
                {suggestions}
            </div>
        }
    }

    componentDidMount() {
        this.prepareSelectedValuesV3();
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        if (this.props.value !== prevProps.value) {
            this.prepareSelectedValuesV3();
        }
    }

    private prepareSelectedValuesV3() {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            let value = this.props.value;
            if (value !== undefined && value.length > 0) {
                const codeType = this.props.searchedItemType;
                let baseUrl: string = "";
                if (codeType !== undefined) {
                    baseUrl = '/api/v1/item/' + codeType + "/";
                }

                const newCodeUrls: string[] = [];
                value.forEach((code: string) => {
                    if (code !== "") {
                        const url = baseUrl + code;
                        newCodeUrls.push(url);
                    }
                });

                this.setState({
                    selected: []
                }, () => {
                    newCodeUrls.forEach((url: string) => {
                        // it would be nice if it was possible to do a get with a list of codes
                        // instead of many calls.
                        // But many calls seems to work in an acceptable way at the moment.
                        fetchObjectOf<SearchedItem>(url)
                            .then((code: SearchedItem | undefined) => {
                                    if (code !== undefined) {
                                        const selected: SearchedItem[] = this.state.selected;
                                        if (selected.length < newCodeUrls.length) {
                                            selected.push(code);
                                            selected.sort((lhs, rhs) => {
                                                if (lhs.code < rhs.code) {
                                                    return -1;
                                                }
                                                if (lhs.code > rhs.code) {
                                                    return 1;

                                                }
                                                return 0;


                                            });

                                            this.setState({
                                                selected: selected
                                            });
                                        }
                                    }
                                }
                            );
                    });
                });
            } else {
                this.setState({
                    selected: []
                });
            }
        }
    }

    private getValue() {
        return this.state.selected;
    }

    getSearchField(labelText: string,
                   showLabel: boolean,
                   name: string,
                   wanted: string,
                   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void): React.ReactFragment {
        const label = <h5>
            <div className={"row"}>
                <div className={"col"}>
                    <label htmlFor={name}>{labelText}</label>
                </div>
            </div>
        </h5>;

        const searchLabel: string = labelText + ".search";
        const field = <div className={"row"}>
            <div className={"col"}>
                <input type={"text"}
                       id={name}
                       aria-label={searchLabel}
                       data-testid={name}
                       value={wanted}
                       onChange={onChange}
                />
            </div>
        </div>;
        let fieldFragment: React.ReactFragment;
        if (!showLabel) {
            fieldFragment = <>
                {field}
            </>;
        } else {
            fieldFragment = <div>
                {label}
                {field}
            </div>;
        }

        return fieldFragment;
    }

    getSelected(labelText: string,
                showLabel: boolean,
                name: string,
                selected: SearchedItem[]): React.ReactFragment {
        if (selected.length === 0) {
            return <div/>;
        }
        let label;
        if (!this.isMultiple()) {
            label = <h5>
                <div className={"row"}>
                    <div className={"col"}>
                        <label aria-label={labelText}
                               htmlFor={name}>
                            {labelText}
                        </label>
                    </div>
                </div>
            </h5>;
        } else {
            label = <div/>;
        }

        const fields = selected.map((value: SearchedItem) => {
            const code: string = value.code;
            const description = value.description;
            const selectedLabel: string = labelText + ".selected " + code;
            const deleteButton = this.deleteButton(labelText + ".selected", code);
            return <div key={code} className={"row"}>
                <div className={"col"}>
                    <div id={name}
                         aria-label={selectedLabel}
                         data-testid={name}>
                        {code} {description} {deleteButton}
                    </div>
                </div>
            </div>;
        });

        if (!showLabel) {
            return <>
                {fields}
            </>;
        }

        return <div>
            {label}
            {fields}
        </div>;
    }

    private isMultiple() {
        let multiple: boolean = false;
        if (this.props.field.multiple !== undefined) {
            multiple = this.props.field.multiple;
        }

        return multiple;
    }

    deleteButton(label: string, currentCode: string): React.ReactFragment {
        const ariaLabel = label + ".delete.button " + currentCode;
        const onClick = () => {
            let values = this.getValue();
            const filtered = values.filter((code: SearchedItem) => code.code !== currentCode);

            const frontendVersion = this.props.frontendVersion;
            if (frontendVersion !== undefined && frontendVersion === V3) {
                const currentValue: string[] = filtered.map((v: SearchedItem) => {
                    return v.code;
                });

                let field = this.props.field;
                const name: string = field.name;
                let duplicationIndex: string = '0';
                if (this.props.duplicationIndex !== undefined) {
                    duplicationIndex = this.props.duplicationIndex;
                }

                let onChange = this.props.onChange;
                if (onChange !== undefined) {
                    onChange(name, currentValue, duplicationIndex, true, field);
                }

                this.setState({
                    wanted: '',
                });
            } else {
                this.setState({
                    selected: filtered,
                    wanted: ""
                });
            }
        };

        return <div>
            <button aria-label={ariaLabel}
                    className={"btn btn-invis-bg pl-1 b-0"}
                    onClick={onClick}>
                <img
                    height={18}
                    src={DeleteLogo}
                    alt={DELETE_ALT_TEXT}/>
            </button>
        </div>;
    }

    private getSuggestions(): React.ReactFragment {
        const suggestions: SearchedItem[] = this.state.suggestions;

        if (suggestions.map === undefined) {
            return <div/>;
        }

        return suggestions.map((classificationCode: SearchedItem) => {
            const code = classificationCode.code;
            const description = classificationCode.description;

            return <div key={code} aria-label={"suggestion"}>
                <div
                    aria-label={code}
                    onClick={() => this.selectSuggestion(classificationCode)}>
                    {code} {description}
                </div>
            </div>
        });
    }

    private onChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const wanted: string = e.currentTarget.value;
        this.setState({
            wanted: wanted
        });

        if (wanted.length > 2) {
            const searchedItemType = this.props.searchedItemType;
            let url: string = "";
            if (searchedItemType !== undefined) {
                url = '/api/v1/items/' + searchedItemType + "/" + wanted;
            }

            const suggestions: Promise<SearchedItem[]> = fetchArrayOf<SearchedItem>(url);
            suggestions.then((resolvedSuggestions: SearchedItem[]) => {
                this.setState({suggestions: resolvedSuggestions});
            });
        } else {
            this.setState({suggestions: []})
        }
    }

    private selectSuggestion(classificationCode: SearchedItem): void {
        const frontendVersion = this.props.frontendVersion;
        if (frontendVersion !== undefined && frontendVersion === V3) {
            let field = this.props.field;
            const name: string = field.name;
            let duplicationIndex: string = '0';
            if (this.props.duplicationIndex !== undefined) {
                duplicationIndex = this.props.duplicationIndex;
            }

            if (this.isMultiple()) {
                const currentValue: string[] = [];
                if (this.props.value !== undefined) {
                    this.props.value.forEach((v: string) => currentValue.push(v));
                }
                currentValue.push(classificationCode.code);

                if (this.props.onChange) {
                    this.props.onChange(name, currentValue, duplicationIndex, true, field);
                }
            } else {
                const currentValue: string = classificationCode.code;

                if (this.props.onChange) {
                    this.props.onChange(name, currentValue, duplicationIndex, true, field);
                }
            }

            this.setState({
                wanted: '',
                suggestions: []
            });
        } else {
            const selected: SearchedItem[] = this.getValue();
            selected.push(classificationCode);
            this.setState({
                selected: selected,
                suggestions: []
            });
        }
    }

    clear(): void {
        let visible = this.isConditionalfield();
        this.setState({
            wanted: "",
            selected: [],
            suggestions: [],
            visible: visible
        })
    }

    isValid(): FieldError {
        const name: string = this.props.field.name;
        const selected: SearchedItem[] = this.getValue();
        const values: string[] = selected.map((value: SearchedItem) => value.code);
        const mandatory: boolean | undefined = this.props.field.mandatory;

        return isMultipleValuesValid(name, values, mandatory)
    }

    set(values: Value[]): void {
        const name = this.props.field.name;
        const searchItemType = this.props.searchedItemType;
        values.forEach((value: Value) => {
            if (value.fieldName === name) {

                const codeType = this.props.searchedItemType;
                let baseUrl: string = "";
                if (codeType !== undefined) {
                    baseUrl = '/api/v1/item/' + searchItemType + "/";
                }

                const newCodeUrls: string[] = [];
                value.values.forEach((code: string) => {
                    if (code !== "") {
                        const url = baseUrl + code;
                        newCodeUrls.push(url);
                    }
                });
                newCodeUrls.forEach((url: string) => {
                    // it would be nice if it was possible to do a get with a list of codes
                    // instead of many calls.
                    // But many calls seems to work in an acceptable way at the moment.
                    fetchObjectOf<SearchedItem>(url).then((code) => {
                            if (code !== undefined) {
                                const selected: SearchedItem[] = this.state.selected;
                                selected.push(code);
                                this.setState({
                                    selected: selected,
                                });
                            } else {
                                this.clear();
                            }
                        }
                    );
                });
            }
        });
    }

    values(): Value[] {
        const name = this.props.field.name;
        const selected = this.state.selected;

        const values: string[] = selected.map((value: SearchedItem) => value.code);

        return createMultipleValues(name, values);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }
}
