import React, {Component} from 'react';
import "./Loading2.css"

function distanceTo(x1, y1, x2, y2) {
    return Math.sqrt(Math.pow((x2 - x1), 2) + Math.pow((y2 - y1), 2))
}

class Loading2 extends Component {
    componentDidMount() {
        const canvas = document.querySelector(".loading-canvas");
        if (canvas) {
            const sizething = Math.min(window.innerWidth, 400);
            const width = canvas.width = this.width = sizething;
            const height = canvas.height = this.height = sizething;
            const ctx = canvas.getContext("2d");

            ctx.fillStyle = "rgb(255,255,255)";
            ctx.fillRect(0, 0, width, height);
            ctx.translate(width / 2, height / 2);

            const MAX_BALLS = 7;
            //start

            let angle = 0;
            const size = (width / 100) * 4;
            const distx = 5;
            const disty = 5;
            const speed = 2;
            const smallSize = (width / 100);
            const upperLeftCornerX = -width / 2;
            const upperLeftCornerY = -height / 2;
            const bHoleSize = (width / 100) * 2;
            const poofSize = (width / 200);
            const smallBallMaxVelocity = (width / 200) * 3;
            const airDrag = width / 70000;

            const greyBall = new GreyBall(angle, size, distx, disty, speed, width, height, poofSize + 3);
            const greyBall2 = new GreyBall(180, size, distx, disty, speed, width, height, poofSize + 3);
            const blackHole = new BlackHole(bHoleSize, poofSize, poofSize + 1);

            const ballArray = [];
            const vanishingPoofsArray = [];

            for (let i = 0; i < 7; i++) {
                const smallball = createNewSmallBall();
                ballArray.push(smallball);
            }

            function createNewSmallBall() {
                const minX = upperLeftCornerX;
                const maxX = width / 2;
                const minY = upperLeftCornerY;
                const maxY = height / 2;

                const randomBall = rand(1, 4);
                let smallBall;

                if (randomBall === 1) {
                    smallBall = new SmallBall(minX, rand(minY, maxY), rand(2, 4), rand(2, 4), smallSize, smallBallMaxVelocity, airDrag);
                    smallBall.setMaxBounds(minX, minY, maxX, maxY);
                }
                if (randomBall === 2) {
                    smallBall = new SmallBall(maxX, rand(minY, maxY), rand(-2, -4), rand(-2, -4), smallSize, smallBallMaxVelocity, airDrag);
                    smallBall.setMaxBounds(minX, minY, maxX, maxY);
                }
                if (randomBall === 3) {
                    smallBall = new SmallBall(rand(minX, maxX), maxY, rand(2, 4), rand(-2, -4), smallSize, smallBallMaxVelocity, airDrag);
                    smallBall.setMaxBounds(minX, minY, maxX, maxY);
                }
                if (randomBall === 4) {
                    smallBall = new SmallBall(rand(minX, maxX), minY, rand(-2, -4), rand(2, 4), smallSize, smallBallMaxVelocity, airDrag);
                    smallBall.setMaxBounds(minX, minY, maxX, maxY);
                }

                function rand(min, max) {
                    return Math.floor(Math.random() * (max - min + 1)) + min;
                }

                return smallBall;
            }

            function loop() {
                const clearList = [];
                const clearPoofList = [];
                //stop the trail for grey balls
                greyBall.drawWhite(ctx);
                greyBall2.drawWhite(ctx);
                clearCanvas();

                //small ball control center
                for (const sb of ballArray) {
                    if (inCenter(sb)) {
                        clearList.push(sb);
                    }

                    if (colliding(sb)) {
                        sb.reverseVelocity()
                        sb.immunityToGreyballCollision = true;
                        setTimeout(() => {
                            sb.immunityToGreyballCollision = false;
                        }, 200)
                    }
                    sb.draw(ctx);
                }

                greyBall.draw(ctx);
                greyBall2.draw(ctx);
                blackHole.draw(ctx);

                //center explosions
                for (const poof of vanishingPoofsArray) {
                    poof.draw(ctx);
                    if (poof.done) {
                        clearPoofList.push(poof);
                    }
                }

                //remove exploded balls and play animation
                for (const ball of clearList) {
                    const ballC = ball.getCurrentCoords();
                    const ballX = ballC.x;
                    const ballY = ballC.y;

                    const index = ballArray.indexOf(ball);
                    if (index > -1) {
                        ballArray.splice(index, 1);
                    }

                    const poof = new VanishingPoof(ballX, ballY, poofSize * 2);
                    vanishingPoofsArray.push(poof);
                    /*
                                    if (ballArray.length < MAX_BALLS) {
                                        const newB = createNewSmallBall();
                                        const newB2 = createNewSmallBall();
                                        ballArray.push(newB);
                                        ballArray.push(newB2);
                                    }
                     */
                    if (ballArray.length < MAX_BALLS) {
                        ballArray.push(createNewSmallBall())
                    }

                }

                //clear old poofs
                for (const poof of clearPoofList) {
                    const index = vanishingPoofsArray.indexOf(poof);
                    if (index > -1) {
                        vanishingPoofsArray.splice(index, 1);
                    }
                }

                requestAnimationFrame(loop);
            }

            loop();

            function colliding(sb) {
                if (sb.immunityToGreyballCollision) {
                    return false;
                }

                const sbCord = sb.getCurrentCoords();
                const gb1Cord = greyBall.getCurrentCoords();
                const gb2Cord = greyBall2.getCurrentCoords();

                const gb1Dist = distanceTo(sbCord.x, sbCord.y, gb1Cord.x, gb1Cord.y);
                if (gb1Dist < gb1Cord.size) {
                    return true;
                }

                const gb2Dist = distanceTo(sbCord.x, sbCord.y, gb2Cord.x, gb2Cord.y);
                return gb2Dist < gb2Cord.size;
            }

            function inCenter(smallBall) {
                const ballCoords = smallBall.getCurrentCoords();

                const distanceToCenter = distanceTo(0, 0, ballCoords.x, ballCoords.y);

                return distanceToCenter < blackHole.getSize();
            }

            function clearCanvas() {
                ctx.fillStyle = "rgba(255,255,255, 0.2)";
                ctx.fillRect(-width / 2, -height / 2, width, height);
            }
        }
    }

    render() {
        return (
            <div>

            </div>
        );
    };


}

export default Loading2;

export class GreyBall {
    angle;
    size;
    distanceX;
    distanceY;
    speed;
    windowWidth;
    windowHeight;
    whiteWidth;

    currentX;
    currentY;

    constructor(angle, size, distanceX, distanceY, speed, windowWidth, windowHeight, whiteWidth) {
        this.angle = angle;
        this.size = size;
        this.distanceX = distanceX;
        this.distanceY = distanceY;
        this.speed = speed;
        this.windowWidth = windowWidth;
        this.windowHeight = windowHeight;
        this.whiteWidth = whiteWidth;
    }

    draw(ctx) {
        const x = this.currentX = (this.windowWidth / this.distanceX) * Math.cos(this.degToRad(this.angle));
        const y = this.currentY = (this.windowHeight / this.distanceY) * Math.sin(this.degToRad(this.angle));

        ctx.fillStyle = "rgb(82,206,255)";
        ctx.beginPath();
        ctx.arc(x, y, this.size, this.degToRad(0), this.degToRad(360), false);
        ctx.fill();

        if (this.angle >= 360) {
            this.angle = 0
        } else {
            this.angle += this.speed;
        }
    }

    drawWhite(ctx) {
        const x = this.currentX = (this.windowWidth / this.distanceX) * Math.cos(this.degToRad(this.angle));
        const y = this.currentY = (this.windowHeight / this.distanceY) * Math.sin(this.degToRad(this.angle));

        ctx.fillStyle = "rgb(255,255,255)";
        ctx.beginPath();
        ctx.arc(x, y, this.size + this.whiteWidth, this.degToRad(0), this.degToRad(360), false);
        ctx.fill();
    }

    getCurrentCoords() {
        return {x: this.currentX, y: this.currentY, size: this.size}
    }

    degToRad(degrees) {
        return degrees * Math.PI / 180;
    }

}

export class VanishingPoof {
    x;
    y;
    count = 0;
    done = false;
    poofSize;

    constructor(x, y, poofSize) {
        this.x = x;
        this.y = y;
        this.poofSize = poofSize;
    }

    draw(ctx) {
        ctx.fillStyle = "rgb(0,0,0)";
        let blastSize = (this.count * this.poofSize) + this.poofSize;

        ctx.beginPath();
        ctx.arc(this.x, this.y, blastSize, this.degToRad(0), this.degToRad(360), false);
        ctx.fill();

        ctx.fillStyle = "rgb(255,255,255)";
        ctx.beginPath();
        ctx.arc(this.x, this.y, blastSize - this.poofSize, this.degToRad(0), this.degToRad(360), false);
        ctx.fill();


        if (this.count >= 5) {
            this.done = true;
        }

        this.count += 1;
    }

    degToRad(degrees) {
        return degrees * Math.PI / 180;
    }

}

export class SmallBall {
    x;
    y;
    vx;
    vy;
    size;

    maxX;
    maxY;
    minY;
    minX;

    maxVelocity = 7;
    immunityToGreyballCollision = false;
    airDrag = 0;

    constructor(x, y, vx, vy, size, maxVelocity, airDrag) {
        this.x = x;
        this.y = y;
        this.vx = vx;
        this.vy = vy;
        this.size = size;
        this.maxVelocity = maxVelocity;
        this.airDrag = airDrag;
    }

    draw(ctx) {
        const gravity = this.calculateGravity(this.x, this.y);

        ctx.fillStyle = "rgb(215,215,215)";
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, this.degToRad(0), this.degToRad(360), false);
        ctx.fill();

        this.OOBXCheck();
        this.OOBYCheck();

        this.x += this.vx;
        this.addToVx(gravity.x);

        this.y += this.vy;
        this.addToVy(gravity.y);

        this.reduceVelocityByAirDrag();
    }


    calculateGravity(x, y) {
        const distance = distanceTo(0, 0, x, y)
        const force = Math.abs(1 / (distance)) * 25;
        const xDistance = Math.abs(x);
        const yDistance = Math.abs(y);
        const sum = xDistance + yDistance;

        const xForceRelation = xDistance / sum
        const yForceRelation = yDistance / sum

        const xForce = force * xForceRelation;
        const yForce = force * yForceRelation;
        const xReturn = x < 0 ? xForce : -1 * xForce;
        const yReturn = y < 0 ? yForce : -1 * yForce;

        return {x: xReturn, y: yReturn}
    }

    reverseVelocity() {
        this.addToVx(1);
        this.addToVy(1);
        this.vx = -1 * this.vx;
        this.vy = -1 * this.vy;
    }


    degToRad(degrees) {
        return degrees * Math.PI / 180;
    }

    OOBXCheck() {
        const futurePos = this.x + this.vx;
        if (futurePos <= this.minX || futurePos >= this.maxX) {
            this.vx *= -1;
        }
    }

    OOBYCheck() {
        const futurePos = this.y + this.vy;
        if (futurePos <= this.minY || futurePos >= this.maxY) {
            this.vy *= -1;
        }
    }

    setMaxBounds(minX, minY, maxX, maxY) {
        this.minX = minX;
        this.minY = minY;
        this.maxX = maxX;
        this.maxY = maxY;
    }

    getCurrentCoords() {
        return {x: this.x, y: this.y, size: this.size}
    }

    addToVx(x) {
        if (this.vx + x > this.maxVelocity) {
            this.vx = this.maxVelocity;
        } else {
            this.vx += x;
        }

    }

    addToVy(y) {
        if (this.vy + y > this.maxVelocity) {
            this.vy = this.maxVelocity;
        } else {
            this.vy += y;
        }
    }

    reduceVelocityByAirDrag() {
        if (this.vx > 0) {
            this.vx -= this.airDrag;
        } else {
            this.vx += this.airDrag;
        }

        if (this.vy > 0) {
            this.vy -= this.airDrag;
        } else {
            this.vy += this.airDrag;
        }
    }
}

export class BlackHole {
    size;
    pulseCount = 0;
    smallPulse;
    largePulse;

    constructor(size, smallPulse, largePulse) {
        this.size = size;
        this.smallPulse = smallPulse;
        this.largePulse = largePulse;
    }

    draw(ctx) {
        ctx.fillStyle = "rgb(35,35,35)";
        ctx.beginPath();
        const bigPulse = this.pulseCount === 50 ? this.largePulse : 0;
        const pulse = this.pulseCount >= 50 ? this.smallPulse : 0;
        ctx.arc(0, 0, this.size + pulse + bigPulse, this.degToRad(0), this.degToRad(360), false);
        ctx.fill();

        if (this.pulseCount >= 60) {
            this.pulseCount = 0;
        }
        this.pulseCount += 1;
    }

    degToRad(degrees) {
        return degrees * Math.PI / 180;
    }

    getSize() {
        return this.size;
    }
}
