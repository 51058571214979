import Value from "../../register/v2/Value";
import {FieldError} from "./FieldComponent";

export function createSingleValue(name: string, valueStr: string): Value[] {
    const values: string[] = [];
    values.push(valueStr)
    const value: Value = {
        fieldName: name,
        values: values
    };
    const result: Value[] = [];
    result.push(value);

    return result;
}

export function createMultipleValues(name: string, srcValues: string[]): Value[] {
    const result: Value[] = [];
    let oldStyle = true;
    //TODO this is a hack, revert it when you have time
    for (let srcVal of srcValues) {
        if (srcVal.includes(name)) {
            oldStyle = false;
            const localFieldName = srcVal.substr(0, srcVal.lastIndexOf("."));
            const contains = result.find(val => val.fieldName === localFieldName)
            if (contains) {
                contains.values.push((srcVal))
            } else {
                result.push({fieldName: localFieldName, values: [srcVal]})
            }
        }
    }

    if (oldStyle) {
        const value: Value = {
            fieldName: name,
            values: srcValues
        };
        result.push(value);
    }

    //this is the old solution, make something more like this
    /*
    const value: Value = {
        fieldName: name,
        values: srcValues
    };
    const result: Value[] = [];
    result.push(value);
    */

    return result;
}

export function isSingleValueValid(name: string, value: string, mandatory: boolean | undefined): FieldError {
    let mandatoryValue: boolean = false;
    if (mandatory !== undefined) {
        mandatoryValue = mandatory;
    }

    if (mandatoryValue) {
        const valid = value !== "";
        if (valid) {
            return getValid(name);
        } else {
            return getValid(name, missingMandatoryField);
        }
    }

    return getValid(name);
}

export function isMultipleValuesValid(name: string, values: string[], mandatory: boolean | undefined): FieldError {
    let mandatoryValue: boolean = false;
    if (mandatory !== undefined) {
        mandatoryValue = mandatory;
    }

    if (mandatoryValue) {
        const valid = values.length > 0;
        if (valid) {
            return getValid(name);
        } else {
            return getValid(name, missingMandatoryField);
        }
    }

    return getValid(name);
}

export const missingMandatoryField = "mandatory field is missing";
export const invalidDate = "invalid date";
export const invalidEmail = "invalid email";
export const invalidTime = "invalid time";
export const invalidNumber = "invalid number";
export const tooSmall = "too small number";
export const tooLargeNumber = "too large number";
export const tooManyDecimals = "too many decimals";
export const invalidDuration = "invalid duration";
export const wrongOrderBetweenDates = "wrong order between dates";

export function getValid(name: string, error?: string): FieldError {
    let valid: boolean;
    if (error === undefined) {
        valid = true;
        error = "";
    } else {
        valid = false;
    }

    return {
        name: name,
        valid: valid,
        error: error
    }
}
