import React, {Component} from "react";
import "./NewNew.css";
import {BackendContext, Message} from "../../infra/BackendContext";
import {EventBackendService} from "../register/v2/EventBackendService";
import {cleanNumberInput} from "../fields/v1/validators/NumberValidator";
import TageButton from "./TageButton";


interface GiftRule {
    completenessIndex: number,
    timesToComplete: number,
    actionId: actionId,
}

interface actionId {
    name: string,
    version: number
}

interface state {
    selectOptions: actionId[],
    backendCallSucceeds: boolean
    rule: GiftRule
    redeemAction: string
    giftCode: string
    selectedCompletenessIndex: number
    selectedTimesToComplete: number
    selectedActionName: string,
    redeemedCode: string
}

interface props {
    refreshExpiredToken: () => void;
    securityToken: string;
    userData: any
}

enum ClaimState {
    "Notclaimable",
    "Claimable",
    "Claimed"
}

class NewNew extends Component<props, state> {
    static contextType = BackendContext;
    loggedIn = () => {
    }

    fakeUserData = {
        user: {
            userName: '',
            userIdentifiers: [],
            organisations: [],
            preferences: '',
            rights: [],
            locale: '',
            phone: '',
            lastName: '',
            firstName: '',
            email: '',
            dateFormat: ''
        },
        userName: '',
        securityToken: '',
        refreshThreshold: undefined,
        navBar: {
            brand: {
                translatedName: 'translated brand name',
                route: '/',
                subList: []
            },
            items: [],
            organisations: [
                {id: '1', selected: false, name: 'first'},
                {id: '2', selected: true, name: 'second'}
            ]
        }
    };

    data = [
        {
            name: "cvk",
            progressDone: 34,
            progressMax: 70,
            goal: "70 registreringar över 95%",
            claimState: ClaimState.Notclaimable
        },
        {
            name: "luftväg",
            progressDone: 10,
            progressMax: 150,
            goal: "150 registreringar över 95%",
            claimState: ClaimState.Notclaimable
        },
        {
            name: "prehospital",
            progressDone: 19,
            progressMax: 20,
            goal: "20 registreringar",
            claimState: ClaimState.Notclaimable
        },
        {
            name: "fieldtester",
            progressDone: 4,
            progressMax: 3,
            goal: "3 registreringar",
            claimState: ClaimState.Claimable
        },
        {
            name: "fieldtester2",
            progressDone: 4,
            progressMax: 3,
            goal: "3 registreringar",
            claimState: ClaimState.Claimed
        }
    ]

    globalStats = {
        toplist: [
            {
                name: "Sture",
                registered: 45
            },
            {
                name: "Erik",
                registered: 36
            },
            {
                name: "Oskar",
                registered: 30
            }
        ],

        user: {
            position: 12
        }

    }

    constructor(props: any) {
        super(props);

        this.state = {
            selectOptions: [],
            backendCallSucceeds: true,
            rule: {
                completenessIndex: 0,
                timesToComplete: 0,
                actionId: {
                    name: "",
                    version: 0
                }
            },
            giftCode: "",
            redeemAction: "",
            selectedCompletenessIndex: 0,
            selectedTimesToComplete: 0,
            selectedActionName: "",
            redeemedCode: ""
        };
    }

    componentDidMount() {
        const {userData} = this.props;
        const val = userData.navBar.items.find((item: any) => item.route === "/register");

        const list: actionId[] = []
        val.subList.map((subitem: any) => {
            list.push({name: subitem.name, version: subitem.version})
            return null;
        })

        if (list && list.length > 0) {
            this.setState({selectOptions: list, selectedActionName: list[0].name, redeemAction: list[0].name});
        } else {
            this.setState({selectOptions: []})
        }


    }

    render() {
        return <div className={"container"}>
            <div className={"container pb-5"}>
                <hr/>
                <div className={"row pb-2"}>
                    <div className={"col"}>Backend lyckas med sitt anrop: <input type={"checkbox"}
                                                                                 onChange={() => this.flipBackendCallSucceeds()}
                                                                                 checked={this.state.backendCallSucceeds}/>
                    </div>

                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        <button className={"btn button-1"} onClick={(e) => this.handleButtonClick(e, {
                            success: `Registreringen lyckades`,
                            failure: `Fel uppstod vid registrering`
                        })}>Registrera
                        </button>
                    </div>
                    <div className={"col"}>
                        <button className={"btn button-2"} onClick={(e) => this.handleButtonClick(e)}>Avbryt</button>
                    </div>
                    <div className={"col"}>
                        <button className={"btn button-3"}>Disabled</button>
                    </div>
                    <div className={"col"}>
                        <TageButton buttonText={"Tage"}
                                    buttonId={"tage-test-knapp"}
                                    buttonClickCallback={(message) => this.tageButtonBackend(message)}/>
                    </div>
                </div>
                <hr/>
                <hr/>
            </div>
            <div className={"row pt-5"}>
                <div className={"col-2"}>&nbsp;</div>
                <div className={"col-8 user-progress-ci pb-3"}>
                    <div className={"row m-0"}>
                        <div className={"col-2 text-left"}><h5>Form</h5></div>
                        <div className={"col-4"}><h5>Progress</h5></div>
                        <div className={"col-4 text-left"}><h5>Mål</h5></div>
                        <div className={"col-2 text-center"}><h5>Claim</h5></div>
                    </div>
                    {
                        this.data.map(e => {
                            const percentageDone = Math.round((e.progressDone / e.progressMax) * 100);
                            const percentageWidth = Math.min(percentageDone, 100);
                            return <div className={"row m-0 pt-2"} key={e.name}>
                                <div className={"col-2 text-left"}>{e.name}</div>
                                <div className={"col-4"}>
                                    <div className={"progress-bar-background"}>
                                        <div className="progress-bar progress-bar-colors"
                                             role="progressbar"
                                             aria-valuenow={percentageDone}
                                             aria-valuemin={0}
                                             aria-valuemax={100}
                                             style={{width: `${percentageWidth}%`}}>
                                            {percentageDone}%
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-4 text-left"}>{e.goal}</div>
                                <div className={"col-2 text-center"}>
                                    <button
                                        className={this.getButtonClass(e.claimState)}
                                        onClick={() => this.getButtonFun(e.claimState)}>
                                        {e.claimState === 2 ? "Claimed" : "Claim"}
                                    </button>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className={"row pt-5"}>
                <div className={"col-2"}>&nbsp;</div>
                <div className={"col-8 user-progress-ci pb-3"}>
                    <div className={"row m-0 pl-2"}>
                        <h5>Global / org</h5>
                    </div>

                    <div className={"row m-0 pt-2"}>
                        <div className={"col-2"}>
                            <h6>
                                Position
                            </h6>
                        </div>
                        <div className={"col-7"}>
                            <h6>
                                Namn
                            </h6>
                        </div>
                        <div className={"col-3"}>
                            <h6>
                                Registrerat
                            </h6>
                        </div>
                    </div>
                    {
                        this.globalStats.toplist.map((e, index: number) => {
                            return <div className={"row m-0"} key={e.name + index}>
                                <div className={"col-2"}>
                                    {index + 1}
                                </div>
                                <div className={"col-7"}>
                                    {e.name}
                                </div>
                                <div className={"col-3"}>
                                    {e.registered}
                                </div>
                            </div>
                        })
                    }
                    <div className={"row ml-1 pt-3 pl-2"}>
                        Din placering: {this.globalStats.user.position}
                    </div>
                </div>
                <div className={"col-2"}>&nbsp;</div>
            </div>
            <hr/>
            <hr/>
            <div className={"container"}>
                <div className={"row pt-5"}>
                    <div className={"col-2"}>&nbsp;</div>
                    <div className={"col-8 user-progress-ci pb-3"}>
                        <div className={"row m-0 pt-3 pl-3"}>
                            <h6>
                                Add new gift rule
                            </h6>
                        </div>
                        <div className={"row m-0 p-0"}>
                            <div className={"col-5"}>
                                <div className={"row m-0 p-0"}>
                                    Action:
                                </div>
                                <select value={this.state.selectedActionName}
                                        onChange={(e) => this.setSelectValueRule(e)}>
                                    {
                                        this.state.selectOptions.map((option: actionId, i: number) => {
                                            return <option key={i + option.name}
                                                           value={option.name}>{option.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className={"col-2"}>
                                <div className={"row m-0 p-0"}>
                                    Times:
                                </div>

                                <input value={this.state.selectedTimesToComplete}
                                       onChange={(e) => this.selectTimesToComplete(e)} size={4} type={"text"}/>
                            </div>
                            <div className={"col-2"}>
                                <div className={"row m-0 p-0"}>
                                    CI:
                                </div>

                                <input value={this.state.selectedCompletenessIndex}
                                       onChange={(e) => this.selectCompletenessIndex(e)} size={4} type={"text"}/>
                            </div>
                            <div className={"col-3 pt-3"}>
                                <button onClick={(e) => this.saveRule(e)} className={"btn btn-tage-default"}>Save
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"col-2"}>&nbsp;</div>
                </div>
            </div>
            <div className={"container"}>
                <div className={"row pt-5"}>
                    <div className={"col-2"}>&nbsp;</div>
                    <div className={"col-8 user-progress-ci pb-3"}>
                        <div className={"row m-0 pt-3 pl-3"}>
                            <h6>
                                Add new gift code
                            </h6>
                        </div>
                        <div className={"row m-0 pl-3"}>
                            <div className={"pr-3"}>
                                Code:
                            </div>
                            <input value={this.state.giftCode} onChange={(e) => this.setGiftCode(e)} type={"text"}/>
                            <button onClick={(e) => this.saveGiftCode(e)} className={"btn btn-tage-default"}>Save
                            </button>
                        </div>
                    </div>
                    <div className={"col-2"}>&nbsp;</div>
                </div>
            </div>
            <hr/>
            <hr/>
            <div className={"container"}>
                <div className={"row pt-5"}>
                    <div className={"col-2"}>&nbsp;</div>
                    <div className={"col-8 user-progress-ci pb-3"}>
                        <div className={"row m-0 pt-3 pl-3"}>
                            <h6>
                                Redeem giftcode
                            </h6>
                        </div>
                        <div className={"row m-0 pl-3"}>
                            <div className={"col-5"}>
                                <div className={"row m-0 p-0"}>
                                    Action:
                                </div>
                                <select value={this.state.redeemAction} onChange={(e) => this.setSelectValueRedeem(e)}>
                                    {
                                        this.state.selectOptions.map((option: actionId, i: number) => {
                                            return <option key={i + option.name}
                                                           value={option.name}>{option.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <button onClick={(e) => this.redeemCode(e)} className={"btn btn-tage-default"}>Redeeem
                            </button>
                        </div>
                    </div>
                    <div className={"col-2"}>&nbsp;</div>
                </div>
            </div>
        </div>
    }

    getButtonClass(state: ClaimState): string {
        switch (state) {
            case ClaimState.Claimable:
                return "btn btn-tage-default"
            case ClaimState.Notclaimable:
                return "btn btn-tage-default-cancel"
            case ClaimState.Claimed:
                return "btn btn-tage-default-claimed"
            default:
                return "btn btn-tage-default-cancel"

        }
    }

    getButtonFun(state: ClaimState): void {
        switch (state) {
            case ClaimState.Claimable:
                return alert("claimable!")
            case ClaimState.Notclaimable:
                return alert("nejdu!")
            case ClaimState.Claimed:
                return alert(`already claimed: ${this.state.redeemedCode}`)
            default:
                return alert("nejdu!")
        }
    }

    private async redeemCode(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()

        const path = "/api/v1/gift/redeem/";
        const url = EventBackendService.getUrl2(path);

        const version = this.getVersionForAction(this.state.redeemAction)
        const getResult = await this.context.post(url, JSON.stringify({
            name: {name: this.state.redeemAction},
            version: version || 0
        }), {
            success: `Lyckades`,
            failure: `Fel uppstod`
        });

        if (!getResult.success) {
            console.log("error vid fetch!", getResult)
        } else {
            this.setState({redeemedCode: getResult.data.code})
        }
    }

    private async saveGiftCode(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
    }

    private async saveRule(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()

        const path = "/api/v1/gift/rules/";
        const url = EventBackendService.getUrl2(path);

        const version = this.getVersionForAction(this.state.selectedActionName)

        const postResult = await this.context.post(url, JSON.stringify({
            completenessIndex: this.state.selectedCompletenessIndex,
            timesToComplete: this.state.selectedTimesToComplete,
            actionId: {name: {name: this.state.selectedActionName}, version: version || 0}
        }), {
            success: `Sparat`,
            failure: `Fel uppstod`
        });

        if (!postResult.success) {
            console.log("error", postResult)
        }
    }

    private getVersionForAction(actionName: string) {
        return this.state.selectOptions.find((opt) => opt.name === actionName)?.version;
    }

    private setSelectValueRedeem(e: React.SyntheticEvent<HTMLSelectElement>) {
        e.preventDefault()
        this.setState({redeemAction: e.currentTarget.value})
    }

    private setSelectValueRule(e: React.SyntheticEvent<HTMLSelectElement>) {
        e.preventDefault()
        this.setState({selectedActionName: e.currentTarget.value});
    }

    private selectTimesToComplete(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let currentValue: string = e.currentTarget.value;
        currentValue = cleanNumberInput(currentValue, 0, 2);

        this.setState({selectedTimesToComplete: +currentValue});
    }

    private selectCompletenessIndex(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let currentValue: string = e.currentTarget.value;
        currentValue = cleanNumberInput(currentValue, 0, 2);

        this.setState({selectedCompletenessIndex: +currentValue});
    }

    private setGiftCode(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        this.setState({giftCode: e.currentTarget.value})
    }

    private flipBackendCallSucceeds() {
        this.setState({backendCallSucceeds: !this.state.backendCallSucceeds})
    }

    private async handleButtonClick(e: React.MouseEvent<HTMLButtonElement>, message = {
        success: `Lyckades`,
        failure: `Fel uppstod`
    }): Promise<boolean> {
        e.preventDefault();
        await this.delay(1200);
        await this.context.test(this.state.backendCallSucceeds, message);

        return this.state.backendCallSucceeds
    }

    private async tageButtonBackend(message?: Message): Promise<boolean> {
        let msg;
        if (message) {
            msg = message
        } else {
            msg = {success: "Lyckades3", failure: `Fel uppstod3`}
        }

        await this.delay(3600);
        await this.context.test(this.state.backendCallSucceeds, msg);

        return this.state.backendCallSucceeds
    }

    delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
}

export default NewNew;
