import React, {Component} from 'react';

class About extends Component {

    render() {
        return (
            <div>
                about
            </div>
        );
    }

}

export default About;
