import React from 'react';
import "./KnarkbokModal.css"
import {BackendContext} from "../../infra/BackendContext";
import {KnarkbokService} from "./Knarkbokservice";
import TempKBControlPanel from "./internals/TempKBControlPanel";
import KnarkbokOverview from "./KnarkbokOverview";
import {Organisation} from "../model/Organisation";
import PharmacyOrder from "./PharmacyOrder";
import DrugBookArchive from "./DrugBookArchive";

interface props {
    KBService: KnarkbokService,
    action: string | undefined,
    organisation: Organisation
}

interface state {
}

class KnarkbokLanding extends React.Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {}
    }

    render() {
        const kbService: KnarkbokService = this.props.KBService;
        const organisation: Organisation = this.props.organisation;

        let action = this.props.action;
        if (action) {
            switch (action) {
                case "drug-administrator" :
                    return <TempKBControlPanel
                        KBService={kbService}
                        organisation={organisation}
                    />;
                case "drug-journal-overview" :
                    return <KnarkbokOverview
                        KBService={kbService}
                        organisation={organisation}
                    />;
                case "pharmacy-order" :
                    return <PharmacyOrder
                        organisation={organisation}
                    />;
                case 'drug-book-archive' :
                    return <DrugBookArchive
                        organisation={organisation}
                    />;
            }
        }

        return <div>
            Select something from the menu
        </div>;
    }
}

export default KnarkbokLanding;
