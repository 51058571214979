import React, {ReactElement} from 'react';
import "./Replenishment.css"

interface props {
    site: string
}

interface state {
    numberOfItems: string,
    sent: boolean
}

class Replenishment extends React.Component<props, state> {
    constructor(props: Readonly<props>) {
        super(props);

        this.state = {
            numberOfItems: '',
            sent: false
        }
    }

    render(): ReactElement {
        if (this.state.sent) {
            return this.thankYou();
        } else {
            return this.feedbackForm();
        }
    }

    private thankYou(): React.JSX.Element {
        const numberOfItems: string = this.state.numberOfItems;
        return <div className={'container replenishment-container pt-3'}>
            <div className={'row'}>
                <div className={"col"}>
                    <h1>Tack för din beställning!</h1>
                </div>
            </div>
            <div className={'row'}>
                <div className={"col"}>
                    <p>Henrik kommer fylla på förrådet med {numberOfItems} pennor inom några dagar.</p>
                </div>
            </div>
        </div>
    }

    private feedbackForm(): React.JSX.Element {
        const headline: React.JSX.Element = <h2>{this.props.site}</h2>;
        const description: React.JSX.Element = <div>Svarta pennor, det borde finnas minst 5 stycken.</div>;
        const numberOfItems: React.JSX.Element = <input type={'text'}
                                                        className={"replenishment-input"}
                                                        placeholder={'Hur många svarta pennor vill du beställa?'}
                                                        value={this.state.numberOfItems}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({numberOfItems: event.currentTarget.value})}
        />;
        const orderButton: React.JSX.Element = <button placeholder={'Beställ'}
                                                       className={"btn btn-tage-default pt-2 pb-1"}
                                                       onClick={() => this.submit()}>
            Beställ
        </button>;

        return <div className={'container replenishment-container'}>
            <div className={'row pl-3 pt-3'}>
                {headline}
            </div>
            <div className={'row pt-2'}>
                <div className={'col'}>
                    {description}
                </div>
            </div>
            <div className={'row pt-2'}>
                <div className={'col'}>
                    {numberOfItems}
                </div>
            </div>
            <div className={'row pt-3'}>
                <div className={'col text-right '}>
                    {orderButton}
                </div>
            </div>
        </div>
    }

    private submit() {
        this.setState({
            sent: true
        });
    }
}

export default Replenishment;
