import React, {Component} from "react";
import {BackendContext} from "../../infra/BackendContext";
import {EventBackendService} from "../register/v2/EventBackendService";
import {Organisation} from "../model/Organisation";
import TranslationService from "../../infra/TranslationService";
import "./CreateUserModal.css"
import {Role, TageResponse, UAU} from "../../pages/administration/user/UserAdministration";
interface props {
    organisation?: Organisation
    hideModal: () => void
    updateUserList: () => Promise<void>
    user?: UAU
    editing?: boolean
    creating?: boolean
}
interface RoleResponse extends TageResponse {
    data: { roles: Role[] }
}
interface state {
    firstName: string,
    lastName: string,
    email: string,
    userName: string,
    phone: string,
    currentRoles: Role[]
    possibleRoles: Role[],
    missingMandatory: boolean
}
class CreateUserModal extends Component<props, state> {
    static contextType = BackendContext;
    constructor(props: any) {
        super(props);
        if (this.props.editing) {
            this.state = ({
                firstName: this.props.user?.firstName || "",
                lastName: this.props.user?.lastName || "",
                email: this.props.user?.email || "",
                userName: this.props.user?.userName || "",
                phone: this.props.user?.phone || "",
                currentRoles: this.props.user?.roles || [],
                possibleRoles: [],
                missingMandatory: false
            })
        } else {
            this.state = ({
                firstName: "",
                lastName: "",
                email: "",
                userName: "",
                phone: "",
                currentRoles: [],
                possibleRoles: [],
                missingMandatory: false
            })
        }
    }
    render() {
        const {tr} = this;
        const {firstName, lastName,email,  currentRoles, missingMandatory} = this.state;
        const {editing, creating} = this.props;
        const headline = (this.props.editing && tr("Editing user")) ||
            (this.props.creating && tr("Creating user")) ||
            (tr("default"));
        let firstNameClass = "";
        let lastNameClass = "";
        let emailClass = "";
        let roleClass = "";
        if (missingMandatory) {
            firstNameClass = firstName ? "" : "missing-mandatory"
            lastNameClass = lastName ? "" : "missing-mandatory"
            emailClass = email ? "" : "missing-mandatory"
            roleClass = currentRoles.length > 0 ? "" : "missing-mandatory"
        }
        //TODO make sure the creation modal is normal on a phone aswell
        return <div>
            <aside className="create-user-modal-background"
                   onClick={() => this.clear()}/>
            <div className={"create-user-modal justify-content-center"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col justify-content-center"}>
                            <h4>
                                {headline}
                            </h4>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    {tr("firstName")}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <input value={this.state.firstName}
                                           className={firstNameClass}
                                           aria-label={"first name"}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleFirstName(e)}/>
                                </div>
                            </div>
                            <div className={"row pt-1"}>
                                <div className={"col"}>
                                    {tr("lastName")}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <input value={this.state.lastName}
                                           className={lastNameClass}
                                           aria-label={"last name"}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleLastName(e)}/>
                                </div>
                            </div>
                            <div className={"row pt-1"}>
                                <div className={"col"}>
                                    {tr("email")}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <input value={this.state.email}
                                           className={emailClass}
                                           aria-label={"email field"}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleEmail(e)}/>
                                </div>
                            </div>
                            <div className={"row pt-1"}>
                                <div className={"col"}>
                                    {tr("phone")}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <input value={this.state.phone}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePhone(e)}/>
                                </div>
                            </div>
                        </div>
                        <div className={"col-5"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <div className={"row"}>
                                        {tr("Current roles:")}
                                    </div>
                                    <div className={"row border-role "+roleClass}>
                                        <div className={"col"}>
                                            {
                                                this.state.currentRoles.map((role: Role) => {
                                                    return <div key={role.roleId}
                                                                title={role.description}
                                                                className={"row mt-1 p-0"}>
                                                        <div className={"col-10"} key={role.name}>{tr(role.name)}</div>
                                                        <div className={"col-2 text-right"}>
                                                            <button
                                                                aria-label={role.roleId + "_remove"}
                                                                className={"btn btn-tage-small-round btn-r"}
                                                                onClick={() => this.removeRole(role.roleId)}>{"-"}</button>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"row pt-3"}>
                                <div className={"col"}>
                                    <div className={"row"}>
                                        {tr("Available roles:")}
                                    </div>
                                    <div className={"row border-role"}>
                                        <div className={"col"}>
                                            {
                                                this.state.possibleRoles.map((role: Role) => {
                                                    return <div key={role.roleId}
                                                                title={role.description}
                                                                className={"row mt-1 p-0 " + this.deselectIfSelected(role)}>
                                                        <div className={"col-10"}
                                                             key={role.roleId}>{tr(role.name)}</div>
                                                        <div className={"col-2 text-right"}>
                                                            <button
                                                                aria-label={role.roleId + "_add"}
                                                                className={"btn btn-tage-small-round btn-g"}
                                                                onClick={() => this.addRole(role)}>{"+"}</button>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row pt-3"}>
                        <div className={"col-8"}></div>
                        <div className={"col-4 m-0 text-right"}>
                            <button className={"btn btn-tage-default-cancel mr-5"}
                                    aria-label={"cancel button"}
                                    onClick={() => this.clear()}>{tr("Cancel")}
                            </button>
                            <button className={"btn btn-tage-default"}
                                    aria-label={"confirm button"}
                                    onClick={() => this.submit()}>{editing ? tr("Update user") : creating ? tr("Create user") : "OK"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    async componentDidMount() {
        let orgId: string = this.props.organisation?.organisationId || "0";
        const PRPath: string = `/api/v1/administration/roles/${orgId}`
        const PRUrl: string = EventBackendService.getUrl2(PRPath);
        const possibleRolesResponse: any = await this.context.get(PRUrl, {
            success: "",
            failure: ""
        }) as RoleResponse;
        if (possibleRolesResponse && possibleRolesResponse.success) {
            if (possibleRolesResponse.data) {
                this.setState({possibleRoles: possibleRolesResponse.data.roles})
            }
        }
        if (this.props.user && this.props.user.userName && this.props.editing) {
            const CRPath: string = `/api/v1/administration/user/${this.props.user.userName}/${orgId}`
            const CRUrl: string = EventBackendService.getUrl2(CRPath);
            const currentRolesResponse: any = await this.context.get(CRUrl, {
                success: "",
                failure: ""
            }) as RoleResponse;
            if (currentRolesResponse && currentRolesResponse.success) {
                if (currentRolesResponse.data) {
                    this.setState({currentRoles: currentRolesResponse.data.roles})
                }
            }
        }
    }
    private tr(string: string): string {
        return TranslationService.translation(string)
    }
    private handleFirstName(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({firstName: e.currentTarget.value})
    }
    private handlePhone(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({phone: e.currentTarget.value})
    }
    private handleEmail(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({email: e.currentTarget.value})
    }
    private handleLastName(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({lastName: e.currentTarget.value})
    }
    private mandatoryCheck(): boolean {
        const {firstName, lastName, email, currentRoles} = this.state;
        if (!firstName || !lastName || !email || currentRoles.length < 1) {
            this.setState({missingMandatory: true});
            return false;
        }
        return true;
    }
    private async submit() {
        let organisationId: string = this.props.organisation?.organisationId || "0";
        const {editing, creating} = this.props;
        const {firstName, lastName, email, phone, currentRoles, userName} = this.state;
        const path: string = `/api/v1/administration/user`
        const url: string = EventBackendService.getUrl2(path);
        if(!this.mandatoryCheck()){
            return
        }
        let response;
        if (editing) {
            response = await this.context.put(url,
                JSON.stringify({
                    firstName,
                    lastName,
                    email,
                    phone,
                    organisationId,
                    roles: currentRoles.map((role) => role.roleId),
                    userName: userName
                }),
                {
                    success: this.tr("Edited user"),
                    failure: this.tr("Failed to edit user")
                }) as TageResponse;
        }
        if (creating) {
            response = await this.context.post(url,
                JSON.stringify({
                    firstName,
                    lastName,
                    email,
                    phone,
                    organisationId,
                    roles: currentRoles.map((role) => role.roleId)
                }),
                {
                    success: this.tr("Created user"),
                    failure: this.tr("Failed to create user")
                }) as TageResponse;
        }
        if (response && response.success) {
            await this.props.updateUserList()
            this.clear();
        }
    }
    private removeRole(roleId: string) {
        let currentRoles: Role[] = this.state.currentRoles;
        const wanted = currentRoles.find((role) => role.roleId === roleId)
        if (wanted) {
            currentRoles.splice(currentRoles.indexOf(wanted), 1);
            this.setState({currentRoles: currentRoles});
        }
    }
    private addRole(role: Role) {
        const currentRoles: Role[] = this.state.currentRoles;
        if (!currentRoles.includes(role)) {
            currentRoles.push(role)
        }
        this.setState({currentRoles: currentRoles})
    }
    private deselectIfSelected(input: Role) {
        let selected = this.state.currentRoles.find((r: Role) => r.roleId === input.roleId);
        if (selected) {
            return " deselected"
        }
        return "";
    }
    private clear() {
        this.props.hideModal();
    }
}
export default CreateUserModal;