import React, {Component} from "react";
import "./TageButton.css";
import {BackendContext, Message} from "../../infra/BackendContext";

interface state {
    currentlyLoading: boolean
}

interface props {
    buttonClickCallback: (message: Message) => any
    buttonText: string,
    buttonId: string
}

class TageButton extends Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);

        this.state = {
            currentlyLoading: false
        };
    }

    componentDidMount() {
    }

    render() {
        const className = this.state.currentlyLoading ? "btn btn-tage-loading-1" : "btn btn-tage-1";
        const callbackFn = this.state.currentlyLoading ? () => this.nop() : (e: React.MouseEvent<HTMLButtonElement>) => this.handleButtonClick(e);
        const buttonText = this.state.currentlyLoading ? <span className="loader"></span> : this.props.buttonText;

        //This is done to make sure that the button width is not redrawn when the spinning loader is inserted, so the button row doesn't dance around
        const earlierRender = document.getElementById(this.props.buttonId);
        let widthStyle = {}
        if (earlierRender && this.state.currentlyLoading) {
            widthStyle = {width: Math.round(earlierRender.getBoundingClientRect().width) + "px"}
        }

        return <div>
            <button onClick={callbackFn}
                    style={widthStyle}
                    id={this.props.buttonId}
                    className={className}>{buttonText}
            </button>

        </div>
    }

    private async handleButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        this.setState({currentlyLoading: true})
        await this.props.buttonClickCallback({success: "Bra!", failure: "Illa!"});
        this.setState({currentlyLoading: false})

        //TODO need to do things depening on what the result for the fetch is!
    }

    private nop() {
    }
}

export default TageButton;
