import React, {ReactElement} from "react";


interface props {
}

interface state {
}


class TraindriverLiaStudentReport extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {}
    }


    render(): ReactElement {

        return <div>
            Report for student
        </div>;
    }

}


export default TraindriverLiaStudentReport;
