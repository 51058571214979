import React, {ReactElement} from "react";
import {fetchObjectOf} from "../../../infra/BackendService";


interface props {
    action: string,
    version: number
}

interface state {
    events: string | undefined
}

class Export extends React.Component<props, state> {

    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            events: undefined
        }
    }

    render(): ReactElement {
        const events = this.state.events;
        if (events === undefined) {
            return <div/>;
        }

        return <div>
            {events}
        </div>
    }

    componentDidMount() {
        const action: string = this.props.action;
        const version: number = this.props.version;

        this.getEvents(action, version);
    }

    private getEvents(action: string, version: number) {
        const url: string = "/api/v1/backoffice/events/" + action + '/' + version;
        fetchObjectOf(url).then(() => {
            this.setState({events: "Events have been exported and sent to support@logeze.com"});
        });
    }
}

export default Export;
