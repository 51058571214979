import React, {ReactElement, ReactInstance} from 'react';
import {FieldComponent, FieldError} from "./FieldComponent";
import "./DuplicableField.css";
import Value from "../../register/v2/Value";
import {Condition, Conditional, Field} from "../../register/v2/Action";
import Form from "../../register/v2/Form";
import {DESKTOP} from "../../../infra/Constants";
import DeleteLogo from "../../../images/delete-512.png";
import {
    compactIndex,
    containsMarkers,
    DUPLICATION_INDEX_MARKER,
    DUPLICATION_MARKER_START,
    DUPLICATION_MARKER_STOP,
    getDuplicationIndex,
    getDuplicationMarkers,
    removeDuplicationMarker
} from "./DuplicationUtility";
import {ConditionalProps, ConditionalState} from "./ConditionalField";

interface props extends ConditionalProps {
}

interface state extends ConditionalState {
    currentCopies: ReactElement[]
}

class DuplicableField extends React.Component<props, state> implements FieldComponent {
    myCopies: Map<string, React.ReactInstance> = new Map<string, React.ReactInstance>();
    duplicationMarkers: string[] = [];

    constructor(props: Readonly<props>) {
        super(props);
        let field = props.field;
        field.name = removeDuplicationMarker(field.name);
        const initialField = this.createField(field, false);
        const currentFields: ReactElement[] = [];
        currentFields.push(initialField);

        this.state = {
            ...this.state,
            currentCopies: currentFields
        }
    }

    render(): ReactElement {
        const field: Field = this.props.field;
        const isConditional: boolean = DuplicableField.isConditionalOn(field);
        const visible: boolean = this.state.visible;
        if (isConditional) {
            if (!visible) {
                return <div/>
            }
        }

        const currentFields: ReactElement [] = this.state.currentCopies;

        return <div>
            {currentFields}
        </div>
    }

    createField(field: Field, createDeleteButton: boolean): ReactElement {
        const device: string = DESKTOP;
        const dateFormat = "yyyy-MM-dd";
        const userEmail = "foo@bar.com";
        const shareMyValue = this.shareMyValue.bind(this);
        const showPopoverModal = false;
        const prefilledValues: Value[] = [];
        let flipPopover = () => {
        };

        const uuid = require("uuid");
        let currentIndex = this.duplicationMarkers.length;
        const currentUniqueName: string = DUPLICATION_MARKER_START + uuid.v4() + DUPLICATION_INDEX_MARKER + currentIndex + "-" + DUPLICATION_MARKER_STOP;
        if (field.conditionalOn !== undefined) {
            field = JSON.parse(JSON.stringify(this.props.field));
            field.conditionalOn = undefined;
        }
        const uniqueField = this.enrichWithUniqueName(field, currentUniqueName);

        let label: React.ReactFragment;
        let fieldName = field.name;
        uniqueField.duplicable = false;
        const indexNumber: number = this.duplicationMarkers.indexOf(currentUniqueName) + 1;
        label = Form.getLabel(fieldName, indexNumber);

        const fieldContent = Form.drawColumn(uniqueField, this.myCopies, device, dateFormat, userEmail, shareMyValue, showPopoverModal, prefilledValues, flipPopover);
        const duplicableField = <div>
            {fieldContent}
        </div>;

        const duplicationButton = <button id={currentUniqueName + "duplicate"}
                                          className={"btn btnDuplication ml-1"}
                                          aria-label={currentUniqueName + ".duplicationButton"}
                                          onClick={() => this.duplicateField()}>
            +
        </button>;

        let deleteButton;
        if (createDeleteButton) {
            deleteButton = <button id={currentUniqueName + "delete"}
                                   className={"btn btnDuplication ml-1"}
                                   aria-label={currentUniqueName + ".deleteButton"}
                                   onClick={() => this.deleteDuplication(currentUniqueName)}>
                <img className={"pb-1"}
                     alt={"Delete"}
                     height={18}
                     aria-label={"delete copy"}
                     src={DeleteLogo}/>
            </button>;
        } else {
            deleteButton = <div/>;
        }

        return <div key={currentUniqueName}>
            {label}
            {duplicableField}
            {deleteButton}
            {duplicationButton}
        </div>
    }

    private enrichWithUniqueName(field: Field, currentUniqueName: string): Field {
        const uniqueField: Field = {...field};
        uniqueField.fields = [];
        const currentName = uniqueField.name;

        if (!this.duplicationMarkers.includes(currentUniqueName)) {
            this.duplicationMarkers.push(currentUniqueName);
        }
        uniqueField.name = currentName + currentUniqueName;
        this.enrichWithConditionalOn(uniqueField, currentUniqueName);

        field.fields?.forEach((f: Field) => {
            if (uniqueField.fields !== undefined) {
                const copyOf = {...f};
                const enriched = this.enrichWithUniqueName(copyOf, currentUniqueName);
                enriched.options = [];

                f.options?.forEach((option: string | Field) => {
                        if (typeof option === 'string') {
                            if (enriched.options === undefined) {
                                enriched.options = [];
                            }
                            enriched.options.push(option);
                        } else {
                            const enrichedOption = this.enrichWithUniqueName(option, currentUniqueName);

                            if (enriched.options === undefined) {
                                enriched.options = [];
                            }
                            enriched.options.push(enrichedOption);
                        }
                    }
                );

                uniqueField.fields.push(enriched);
            }
        });

        return uniqueField;
    }

    private enrichWithConditionalOn(uniqueField: Field, currentUniqueName: string) {
        if (uniqueField.conditionalOn !== undefined) {
            const copyOfConditionalOn: Conditional = {
                predicates: [],
                triggerOutsideDuplicableField: false,
                operator: ''
            };

            copyOfConditionalOn.operator = uniqueField.conditionalOn.operator;

            uniqueField.conditionalOn.predicates.forEach((srcPredicate: Value | Condition) => {
                if (!(srcPredicate instanceof Condition)) {
                    let oldName: string = srcPredicate.fieldName;
                oldName = removeDuplicationMarker(oldName);
                const fieldName: string = oldName + currentUniqueName;

                const values: string[] = [];
                srcPredicate.values.forEach((value: string) => {
                    const cleaned = removeDuplicationMarker(value);

                    const headLength: number = oldName.length;
                    const head: string = cleaned.substring(0, headLength);

                    const tail: string = cleaned.substring(headLength);

                    const wantedValue: string = head + currentUniqueName + tail;
                    values.push(wantedValue);
                });

                const targetPredicate: Value = {
                    fieldName: fieldName,
                    values: values
                };

                copyOfConditionalOn.predicates.push(targetPredicate);
                }
            });

            uniqueField.conditionalOn = copyOfConditionalOn;

        }
    }

    private shareMyValue(notifier: string, values: Value[]): void {
        const fieldReferences = this.myCopies;

        this.duplicationMarkers.forEach((duplicationMarker: string) => {
            const currentFields: Map<string, React.ReactInstance> = new Map<string, React.ReactInstance>();
            const currentValues: Value[] = [];
            fieldReferences.forEach((value: ReactInstance, key: string) => {
                if (key.includes(duplicationMarker)) {
                    currentFields.set(key, value);
                }
            });

            values.forEach((value: Value) => {
                if (value.fieldName.includes(duplicationMarker)) {
                    currentValues.push(value);
                }
            });

            if (currentValues.length > 0) {
                const filtered = new Map(currentFields);
                filtered.delete(notifier);
                Form.notifyConditionalOn(filtered, currentValues);
            }
        });
    }

    clear(): void {
        this.resetHelpers();

        const field = this.props.field;
        const initialField = this.createField(field, false);
        const currentFields: ReactElement[] = [];
        currentFields.push(initialField);

        const conditional = DuplicableField.isConditionalOn(field);
        let visible: boolean = true;
        if (conditional) {
            visible = false;
        }

        this.setState({
            visible: visible,
            currentCopies: currentFields
        });
    }

    private static isConditionalOn(field: Field) {
        return field.conditionalOn !== undefined;
    }

    isValid(): FieldError {
        this.myCopies.forEach((reference: any) => {
            const field: FieldComponent = reference.current;
            if (field !== null) {
                const fieldError: FieldError = field.isValid();
                if (!fieldError.valid) {
                    return fieldError;
                }
            }
        });

        return {
            name: this.props.field.name,
            valid: true,
            error: ""
        };
    }

    set(values: Value[]): void {
        this.resetHelpers();

        const receivers: ReactElement [] = this.createReceivers(values);
        const enriched: Value[] = this.enrichWithUniqueNames(values);

        this.setState({
            currentCopies: receivers
        }, () => {
            setTimeout(() => {
                this.myCopies.forEach((reference: any) => {
                    const field: FieldComponent = reference.current;
                    if (field !== null) {
                        field.set(enriched);
                    }
                });
            }, 10);
        });
    }

    private createReceivers(values: Value[]): ReactElement [] {
        const receivers: ReactElement [] = [];
        const field: Field = this.props.field;

        if (values.length === 0) {
            const copies: number = this.state.currentCopies.length;
            for (let index = 0; index < copies - 1; index++) {
                const createDeleteButton = index !== 0;

                const duplicatedField: ReactElement = this.createField(field, createDeleteButton);
                receivers.push(duplicatedField);
            }

            return receivers;
        }

        const duplications: number = this.countDuplications(values);
        for (let index = 0; index < duplications; index++) {
            const createDeleteButton = index !== 0;

            const duplicatedField: ReactElement = this.createField(field, createDeleteButton);
            receivers.push(duplicatedField);
        }

        return receivers;
    }

    private countDuplications(values: Value[]): number {
        const duplications: Set<string> = new Set();
        values.forEach((value: Value) => {
            let duplicationIndexStr: string | undefined = value.duplicationIndex;
            if (duplicationIndexStr !== undefined) {
                duplications.add(duplicationIndexStr);
            }
        });

        return duplications.size;
    }

    values(): Value[] {
        const allValues: Value[] = [];
        this.myCopies.forEach((ref: any) => {
            if (ref.current !== null) {
                const field: any = ref.current;
                if (field.props.field.duplicable !== undefined) {
                    if (field.props.field.duplicable) {
                        if (field.isValid()) {
                            let values = field.values();
                            allValues.push(...values);
                        }
                    } else {
                        if (field.isValid()) {
                            const nonDuplicableValues = field.values();

                            nonDuplicableValues.forEach((value: Value) => {
                                const cleanedValue = this.getCleaned(value);
                                allValues.push(cleanedValue);
                            });
                        }
                    }
                }
            }
        });

        return allValues;
    }

    private getCleaned(value: Value) {
        const fieldName = value.fieldName;

        const cleanedName: string = removeDuplicationMarker(fieldName);
        const duplicationIndex: string = this.getIndex(fieldName);
        const cleanedValues: string[] = [];
        value.values.forEach((v: string) => {
            const cleanedValue: string = removeDuplicationMarker(v);
            cleanedValues.push(cleanedValue);
        });

        const cleanedValue: Value = {
            fieldName: cleanedName,
            duplicationIndex: duplicationIndex,
            values: cleanedValues
        };

        return cleanedValue;
    }

    enrichWithUniqueNames(values: Value[]): Value[] {
        const result: Value[] = [];
        let uniqueName: string = '';
        values.forEach((value: Value) => {
            this.myCopies.forEach((v: any, key: string) => {
                let duplicationIndex = getDuplicationIndex(key);
                if (duplicationIndex === value.duplicationIndex) {
                    let duplicationMarkers = getDuplicationMarkers(key);
                    uniqueName = duplicationMarkers.join("");
                }
            });
            const fieldName = value.fieldName;
            const enrichedName: string = fieldName + uniqueName;
            const enrichedValues: string[] = [];
            value.values.forEach((v) => {
                if (v.includes(fieldName)) {
                    const head: string = v.substring(0, fieldName.length);
                    const tail: string = v.substring(fieldName.length);
                    const enrichedValue: string = head + uniqueName + tail;
                    enrichedValues.push(enrichedValue);
                } else {
                    enrichedValues.push(v);
                }
            });

            const enriched: Value = {
                fieldName: enrichedName,
                duplicationIndex: value.duplicationIndex,
                values: enrichedValues
            };

            result.push(enriched);
        });

        return result;
    }

    private getIndex(fieldName: string): string {
        if (!containsMarkers(fieldName)) {
            return '0';
        }

        return getDuplicationIndex(fieldName);
    }

    getUniqueNameFromIndex(wantedDuplication: number): string {
        return this.duplicationMarkers[wantedDuplication];
    }

    private duplicateField() {
        const field: Field = this.props.field;
        const currentFields: ReactElement [] = this.state.currentCopies;

        const duplicatedField: ReactElement = this.createField(field, true);
        currentFields.push(duplicatedField);

        this.setState({
            currentCopies: currentFields
        });
    }

    private deleteDuplication(currentUniqueName: string) {
        const candidates: string[] = [];
        this.myCopies.forEach((value, key) => {
            if (key.includes(currentUniqueName)) {
                candidates.push(key);
                this.myCopies.delete(key);
                return;
            }
        });

        let longestSoFar: string = '';
        candidates.forEach((value: string) => {
            if (value.length > longestSoFar.length) {
                longestSoFar = value;
            }
        });

        const toBeDeleted: string = getDuplicationIndex(longestSoFar);

        const values: Value[] = this.values();
        values.forEach((v: Value) => {
            if (v.duplicationIndex !== undefined) {
                const current: string = v.duplicationIndex;
                v.duplicationIndex = compactIndex(toBeDeleted, current);
            }
        });

        this.set(values);
    }

    private resetHelpers() {
        this.myCopies = new Map<string, React.ReactInstance>();
        this.duplicationMarkers = [];
    }
}

export default DuplicableField;
