import {logout} from "./Logout";

const minutes = 15;
const securityTokenKey = "d99e1405-bb87-4b1d-9894-c692983cd26d";

let nextRefreshTime: number = 0;

export function refreshSecurityToken(): void {
    const now = new Date();

    if (now.valueOf() > nextRefreshTime && getSecurityToken().length !== 0) {
        securityToken.validateToken();
        nextRefreshTime = now.valueOf() + (minutes * 60 * 1000);
    }
}


export function getRefreshTime(): number {
    return nextRefreshTime;
}

export function getSecurityToken(): string {
    const securityToken = sessionStorage.getItem(securityTokenKey);

    if (securityToken !== null) {
        return securityToken;
    } else {
        logout();
        return '';
    }
}

export function setSecurityToken(newSecurityToken: string): void {
    sessionStorage.setItem(securityTokenKey, newSecurityToken);
}


function validateToken(): void {
    let host: string;
    if (process.env.REACT_APP_LOCALHOST === undefined) {
        host = '';
    } else {
        host = process.env.REACT_APP_LOCALHOST;
    }

    const REFRESH_API = '/api/v2/refresh';
    const url: string = host + REFRESH_API;

    fetch(url, {
        headers: {
            'X-Custom-header': getSecurityToken()
        },
        credentials: 'include',
        method: "GET"
    })
        .then((res) => {
            if (res.status !== 200) {
                throw new Error('not OK');
            }

            return res.json();
        })
        .then((json) => {
            const securityToken = json.token + ";" + json.validTo;
            setSecurityToken(securityToken);
            console.log("Validation of security token succeeded");
        })
        .catch((error) => {
            console.log("Validation of security token failed", error);

            logout();
            sessionStorage.setItem("inactivityLogout", "true");
        });
}

export const securityToken = {
    validateToken
};