import React from 'react';
import {KnarkbokService} from "./Knarkbokservice";
import "./DrugEventFocus.css"
import {BookId, DrugEvent} from "./models/Models";
import TranslationService from "../../infra/TranslationService";
import {jsPDF} from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";

interface props {
    KBService: KnarkbokService,
    closeFocus: () => void,
    book: BookId | undefined
    drugName: string,
    locationName: string
}

interface state {
    events: DrugEvent[]
}

class DrugEventFocus extends React.Component<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            events: []
        }
    }

    async componentDidMount() {
        if (this.props.book) {
            let events = await this.props.KBService.getEventLogForDrug(this.props.book) as any; //TODO type
            this.setState({events: events.details.reverse()})
        }
    }

    async componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        if (this.props.book && prevProps.book?.id !== this.props.book?.id) {
            let events = await this.props.KBService.getEventLogForDrug(this.props.book) as any;
            this.setState({events: events.details.reverse()});
        }
    }

    render() {
        let data: any[] = []
        if (this.state.events) {
            data.push(
                <tr key="header-thing">
                    <th>Datum</th>
                    <th>Användare</th>
                    <th>Händelse</th>
                    <th>Specifikation</th>
                    <th>Kommentar</th>
                    <th>Antal</th>
                    <th>Räknat saldo</th>
                    <th>Beräknat saldo</th>
                    <th>Avvikelse</th>
                    <th>Epost skickat</th>
                </tr>
            )

            this.state.events.forEach((ev: DrugEvent, index) => {
                const redWarning = ev.warning ? " redwarning" : ""
                const comment: string | undefined = ev.comment;

                let commentStart: string = "";
                if (comment) {
                    const length: number = Math.min(7, comment.length);
                    const commentText: string = comment.substr(0, length);
                    let more: string = '';
                    if (comment.length > length) {
                        more = ' ...';
                    }
                    commentStart = commentText + more;
                }

                data.push(
                    <tr key={"drug-event" + index} className={redWarning}>
                        <td>{ev.time}</td>
                        <td>{ev.userFullName}</td>
                        <td>{TranslationService.translation(ev.event.toString())}</td>
                        <td>{ev.additionalData ? TranslationService.translation(ev.additionalData) : ""}</td>
                        <td>
                            {comment ? (
                                <div title={comment} className={"hover-pointer"}
                                     onClick={(e) => DrugEventFocus.popup(e, comment)}>
                                    {commentStart}
                                </div>
                            ) : ""}
                        </td>
                        <td>{ev.amount}</td>
                        <td>{ev.reportedTotal}</td>
                        <td>{ev.trueTotal}</td>
                        <td>{ev.balance}</td>
                        <td>{ev.warning ? "✓" : ""}</td>
                    </tr>
                )
            })
        }

        const grid = (
            <div aria-label={"Drug Book Modal"} className={"container2 m-0 pl-0 pr-0"}>
                <div className={"col m-0 pr-1 justify-content-end text-right"}>
                    <button aria-label={'close'} className={"btn btn-close-focus btn-tage-default-cancel"}
                            onClick={() => this.props.closeFocus()}>
                        X
                    </button>
                </div>
                <div className={"row m-0 pt-2 pb-2"}>
                    <div><h5 aria-label={'headline'}
                             className={"mt-0"}>{this.props.drugName + ", " + this.props.locationName}</h5></div>
                </div>
                <div className={"container2 m-0 pt-3 pl-0 kb-modal-scrollable"}>
                    <table className={"drug-event-table"}>
                        <thead>
                        {data[0]}
                        </thead>
                        <tbody>
                        {data.slice(1)}
                        </tbody>
                    </table>
                </div>
                <div className={"row m-0 pt-4 pr-3"}>
                    <div className={"col m-0 p-0 justify-content-end text-right"}>
                        <button aria-label={'print'} onClick={() => this.createKbReport()}
                                className={"btn btn-tage-default-cancel"}>
                            Skriv ut
                        </button>
                    </div>
                </div>
            </div>
        )

        return (
            <div>
                <aside className="knarkbok-modal-background2"
                       data-testid="modal-background"
                       onClick={() => this.props.closeFocus()}/>
                <div className={"knarkbok-modal2 pr-3 justify-content-center pb-5"}>
                    {grid}
                </div>
            </div>
        )
    }

    public createKbReport() {
        const doc = new jsPDF('l');

        let drugName = this.props.drugName;
        doc.text(`Förbrukningsjournal för ${drugName}, ${this.props.locationName}`, 14, 10);

        const body: string[][] = [];
        this.state.events.map((ev) => {
            body.push([ev.time,
                ev.userFullName,
                TranslationService.translation(ev.event.toString()),
                ev.additionalData ? TranslationService.translation(ev.additionalData) : "",
                ev.comment ? ev.comment + '' : '',
                ev.amount,
                ev.reportedTotal + '',
                ev.trueTotal + '',
                ev.balance,
                ev.warning ? "X" : ""])

            return null;
        })

        autoTable(doc, {
            headStyles: {fillColor: [82, 206, 255]},
            head: [['Datum', 'Användare', 'Händelse', 'Specifikation', 'Kommentar', 'Antal', 'Räknat Saldo', 'Beräknat Saldo', 'Avvikelse', 'Epost skickat']],
            body: body,
        })

        const date = new Date().toISOString();

        doc.save(`${drugName}-${this.props.locationName}-${date}.pdf`);
    }

    private static popup(e: React.MouseEvent<HTMLDivElement>, comment: string | undefined) {
        //TODO actual popup
        e.preventDefault()
        alert(comment);
    }
}

export default DrugEventFocus;
