interface TsCrypto extends Crypto {
    randomUUID?() : string;
}

function generateUUID() : string {
    let cryptoRef: TsCrypto;
    let r: string | undefined = "";

    if (typeof crypto !== "undefined") {
        cryptoRef = crypto;
        r = cryptoRef.randomUUID?.();
    }

    return r ? r : "";
}

export {generateUUID};

