import React, {Component} from 'react';
import StackTrace from "stacktrace-js";
import ErrorPage from "./ErrorPage";

export const ERRORAPI = process.env.REACT_APP_LOCALHOST + "/api/v1/phoneHome";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, errorInfo) {
        let errorMsg = error.message;

        errorMsg += "errorInfo: <" + JSON.stringify(errorInfo) + ">"
        try {
            let userAgent = navigator.userAgent;
            let platform = navigator.platform;
            let userName = "";
            let path = "";
            if (!!this.props.userData) {
                if (!!this.props.userData.userName) {
                    userName = this.props.userData.userName;
                }
            }
            if (this.props.path) {
                path = this.props.path;
            }
            errorMsg += ", UserAgent: <" + userAgent + ">";
            errorMsg += ", Platform: <" + platform + ">";
            errorMsg += ", UserName: <" + userName + ">";
            errorMsg += ", Path: <" + path + ">";
        } catch (e) {
            console.log(e);
        }
        StackTrace.fromError(error)
            .then((er) => {
                StackTrace.report(er, ERRORAPI, errorMsg)
            })
            .catch(console.log);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage/>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
