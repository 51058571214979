import React from 'react';
import EmailInputField from "../fields/v1/EmailInputField";
import "./SendNewPassword.css";
import './RecoverNewPassword.css'
import {getDomain} from "../../Public";
import TranslationService from "../../infra/TranslationService";

export const FORGOT_PASSWORD_API = process.env.REACT_APP_LOCALHOST + "/api/v1/request-new-password";

const heading: string = "Återställ ditt lösenord";
const label: string = "Ange din e-postadress";
const button: string = "Byt lösenord";
const incorrectMail: string = "Inte en mailaddress";

interface props {
}

interface state {
    email: string,
    message: string,
    formVisible: boolean
    domain: string
}

class RecoverNewPassword extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);

        const domain = getDomain(window.location.hostname); //TODO use props instead, use render instead of component

        this.state = {
            email: "",
            message: "",
            formVisible: true,
            domain: domain
        };
    }

    render() {

        const form: React.JSX.Element | string = this.getForm();
        const message: string = this.state.message;

        return (
            <div className={"recoverPasswordContainer"}>
                <div className={"row"}>
                    <div className={"col text-center"}>
                        <h2 aria-label={'Recover password'}>
                            {TranslationService.translatePerDomain(heading, this.state.domain)}
                        </h2>
                        {form}
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col text-center"}>
                        <p id={"requestNewPasswordMessage"}>{message}</p>
                    </div>
                </div>
            </div>
        );
    }

    private getForm(): React.JSX.Element | string {
        const showForm: boolean = this.state.formVisible;

        if (showForm) {
            return <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => this.onSendNewPassword(e)}>
                <p>{TranslationService.translatePerDomain(label, this.state.domain)}</p>
                <EmailInputField onChangeEmail={this.onChangeEmail}/>
                <button id={"requestNewPasswordButton"}
                        className={"btn btn-newPassword"}>{TranslationService.translatePerDomain(button, this.state.domain)}</button>
            </form>;
        }

        return '';
    }

    onChangeEmail(email: string) {
        this.setState({message: ""});
        this.setState({email: email})
    }

    onSendNewPassword(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const email = this.state.email;
        if (email !== "") {
            let payload = {
                "email": email
            };

            fetch(FORGOT_PASSWORD_API, {
                method: "POST",
                body: JSON.stringify(payload)
            })
                .then(res => res.json())
                .then(() => {
                    let message = "Ett mail med instruktioner har skickats till " + email;
                    this.setState({message: message});
                    this.setState({formVisible: false});
                }).catch(console.log);
        } else {
            this.setState({message:TranslationService.translatePerDomain(incorrectMail, this.state.domain)});
        }
    }
}

export default RecoverNewPassword;
