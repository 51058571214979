import React, {ChangeEvent, createRef, ReactElement} from 'react';
import {Field} from "../../register/v2/Action";
import Period from "../../fields/v2/Period";
import {DESKTOP, REPORT} from "../../../infra/Constants";
import TranslationService from "../../../infra/TranslationService";
import {UserOld} from "../../model/UserOld";
import {fetchObjectOf} from "../../../infra/BackendService";
import {SharingServiceBackend} from "../../sharing/SharingService";
import {jsPDF} from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";

export interface Report {
    name: string,
    hours: Hour[],
    sum: number
}

interface Hour {
    date: string,
    numberOfHours: number,
    comment?: string
}

interface props {
    actionName: string,
}

interface state {
    report: Report | undefined,
    selectedReporter: string | undefined,
    reporters: UserOld[] | undefined

}

class FitymiTimeReport extends React.Component<props, state> {
    periodRef: any;

    constructor(props: props) {
        super(props);

        this.state = {
            report: undefined,
            selectedReporter: undefined,
            reporters: undefined
        }
    }

    render(): ReactElement {
        const selection = this.getSelection();
        let report = this.getReport();

        return <div>
            {selection}
            <hr/>
            {report}
        </div>;
    }

    componentDidMount() {
        const sharingService: SharingServiceBackend = new SharingServiceBackend();
        const actionName = this.props.actionName;
        const sharers = sharingService.getSharers(actionName, REPORT);

        sharers.then((reporters: UserOld[]) => {
            const selectedReporter = reporters.length > 0 ? reporters[0].userName : undefined
            this.setState({
                reporters: reporters,
                selectedReporter: selectedReporter
            });
        });
    }

    private getSelection(): ReactElement {
        let reporterSelect: ReactElement;
        const reporters = this.state.reporters;
        if (reporters === undefined) {
            return <div
                className={"spinner"}
                aria-label={"waiting for reporters"}
            />
        }
        if (reporters.length === 0) {
            return <div>No reports to display right now</div>;
        }

        if (reporters.length < 2) {
            reporterSelect = <div/>;
        } else {
            const selectedReporter = this.state.selectedReporter;
            const name: string = "reporter.select";

            const labelText = TranslationService.translation("fitymi-time-report.select.reporter");
            const label: ReactElement = <div className={"row"}>
                <div className={"col"}>
                    <label aria-label={name + ".label"}
                           htmlFor={name}>{labelText}</label>
                </div>
            </div>;

            const options: ReactElement[] = reporters.map((u: UserOld) => {
                return <option key={u.userName}
                               value={u.userName}
                               data-testid={"select-option"}>
                    {u.firstName} {u.lastName}
                </option>;
            });

            const onChange = (e: ChangeEvent<HTMLSelectElement>) => this.onChange(e);
            const field: ReactElement = <div className={"row"}>
                <div className={"col"}>
                    <select aria-label={"reporter.selection"}
                            name={name}
                            value={selectedReporter}
                            onChange={onChange}>
                        {options}
                    </select>
                </div>
            </div>;

            reporterSelect = <div>
                {label}
                {field}
            </div>;
        }

        const reportPeriod: Field = {
            name: "reportPeriod",
            fieldLabel: true,
            mandatory: true,
            defaultValue: "firstDayOfMonth",
            duplicationIndex: 0,
            showChildLabel: false
        };
        this.periodRef = createRef();
        const periodField = <div>
            <Period ref={this.periodRef} field={reportPeriod} device={DESKTOP} dateFormat={"yyyy-MM-dd"}/>
        </div>;

        const view: string = TranslationService.translation("view");
        const viewButton = <div>
            <button id={"viewButton"}
                    aria-label={"view"}
                    className={"btn"}
                    onClick={() => this.view()}>

                {view}
            </button>
        </div>;

        let pdfReport = <div/>;
        if (this.state.report) {
            pdfReport = this.getPdfReportButton();
        }

        return <div>
            {reporterSelect}
            {periodField}
            <div className={"row pt-3"}>
                {viewButton}
                {pdfReport}
            </div>
        </div>;
    }

    private getReport(): ReactElement {
        if (this.state.report === undefined) {
            return <div/>;
        } else {
            const name = this.state.report.name;
            const nameHeader = TranslationService.translation("fitymi-time-report.reporter.name");
            let reporter = <div className={"row"} aria-label={"name"}>
                <div className={"col"}>{nameHeader}: {name}</div>
            </div>;

            const emptyLine = <div className={"row mb-3"}/>;

            const headlineDateTranslated = TranslationService.translation("fitymi-time-report.dateTime.date");
            const numberOfHoursTranslated = TranslationService.translation("fitymi-time-report.dateTime.hours");
            const commentTranslated = TranslationService.translation("fitymi-time-report.comment");
            const headline = <div className={"row"} aria-label={"headlineDate"}>
                <div className={"col"}>{headlineDateTranslated}</div>
                <div className={"col"}>{numberOfHoursTranslated}</div>
                <div className={"col"}>{commentTranslated}</div>
            </div>;

            const hours: Hour[] = this.state.report.hours;
            const reportDetails = hours.map((h: Hour, i) => {
                const date = h.date;
                const numberOfHours = h.numberOfHours;
                let comment: string;
                if (h.comment !== undefined) {
                    comment = h.comment;
                } else {
                    comment = "";
                }

                return <div className={"row"} aria-label={date} key={date + i}>
                    <div className={"col"}>{date}</div>
                    <div className={"col"}>{numberOfHours}</div>
                    <div className={"col"}>{comment}</div>
                </div>;
            });

            const totalTranslated = TranslationService.translation("fitymi-time-report.total");
            const sum = this.state.report.sum;
            const summary = <div className={"row"} aria-label={"sum"}>
                <div className={"col"}>{totalTranslated}</div>
                <div className={"col"}>{sum}</div>
                <div className={"col"}/>
            </div>;

            return <div key={"asdgf"}>
                {reporter}
                {emptyLine}
                {headline}
                {reportDetails}
                {emptyLine}
                {summary}
            </div>;
        }
    }

    private view() {
        let currentPeriod: Period = this.periodRef.current;
        let from: string | undefined = currentPeriod.getFrom();
        if (from === undefined) {
            //todo: set state to error message & return
            throw new Error("The from field is mandatory!");
        }
        let to: string | undefined = currentPeriod.getTo();
        if (to === undefined) {
            to = "9999-12-31";
        }
        let reporter = this.state.selectedReporter;

        let url: string = "/api/v1/reports/fitymi-time-report/" + reporter + "/" + from + "/" + to;
        fetchObjectOf<Report>(url).then((report) => {
                this.setState({report: report});
            }
        );
    }

    private onChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const selectedReporter = e.target.value;
        this.setState({selectedReporter: selectedReporter});
    }

    private getPdfReportButton() {
        return <button className={"btn ml-4"} onClick={() => this.generatePdfReport()}>Som pdf</button>
    }

    private generatePdfReport() {
        const doc = new jsPDF();

        if (this.state.report) {
            const name = this.state.report.name;
            //TODO dates -
            let reporter = `Rapport för: ${name}`
            doc.text(reporter, 14, 10);

            const headlineDateTranslated = TranslationService.translation("fitymi-time-report.dateTime.date");
            const numberOfHoursTranslated = TranslationService.translation("fitymi-time-report.dateTime.hours");
            const commentTranslated = TranslationService.translation("fitymi-time-report.comment");

            const totalTranslated = TranslationService.translation("fitymi-time-report.total");
            const sum = this.state.report.sum;

            const body: string[][] = [];
            const hours: Hour[] = this.state.report.hours;

            hours.map((h: Hour) => {
                const date = h.date;
                const numberOfHours = h.numberOfHours;
                let comment: string;
                if (h.comment !== undefined) {
                    comment = h.comment;
                } else {
                    comment = "";
                }

                body.push([date, '' + numberOfHours, comment])
                return null;
            });

            autoTable(doc, {
                headStyles: {fillColor: [82, 206, 255]},
                head: [[headlineDateTranslated, numberOfHoursTranslated, commentTranslated]],
                body: body,
            })

            autoTable(doc, {
                styles: {
                    halign: 'right'
                },
                headStyles: {fillColor: [82, 206, 255]},
                head: [[totalTranslated]],
                body: [[sum]],
            })

            const date = new Date().toISOString();

            doc.save(`${reporter}-${date}.pdf`);
        }
    }
}

export default FitymiTimeReport;
