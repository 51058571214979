import React, {ChangeEvent} from 'react';
import TranslationService from "../../../infra/TranslationService";
import {getDomain} from "../../../Public";

export const EMAIL = "E-post";

interface Props {
    size: number;
    tooltip: string;
    onChangeEmail: (email: string) => void
}

interface State {
    email: string
}

export const valid = (email: string) => {
    if (email.includes("@")) {
        let splitArray = email.split("@");
        return (splitArray.length === 2 && splitArray[1].includes(".") && !splitArray[1].endsWith("."));
    }
    return false;
};

export default class EmailInputField extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            email: ""
        }
    }

    static defaultProps = {
        size: 52,
        tooltip: "",
        onChangeEmail: (email: string) => {
            console.log("A parent has not set the callback method for onChangeEmail(email: string) " +
                "so " + email + " will not be shared properly.")
        }
    };

    onChange(currentEmail: string) {
        this.setState({email: currentEmail})
    }

    onEmailChange(email: string) {
        if (valid(email)) {
            this.props.onChangeEmail(email);
        } else {
            this.props.onChangeEmail('');
        }
    }

    render() {
        const email = this.state.email;
        const tooltip = this.props.tooltip;
        const size = this.props.size;
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
            let email: string = event.target.value;
            this.onChange(email);
            this.onEmailChange(email);
        };

        const domain = getDomain(window.location.hostname);
        const placeholder = TranslationService.translatePerDomain(EMAIL, domain)

        return (
            <input id={"emailInputField"}
                   placeholder={placeholder}
                   title={tooltip}
                   size={size}
                   onChange={onChange}
                   onBlur={onChange}
                   value={email}/>
        );
    }
}