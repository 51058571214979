import Value from "./Value";

export interface Action {
    name: string;
    version: number;
    frontendVersion: string;
    groupBy?: string;
    type: string;
    fields: Field[];
}

export class Condition {
    operator: string | undefined;
    predicates: (Condition | Value)[] | undefined;
    duplicationIndex?: string
}

export interface Field {
    name: string;
    fieldLabel: boolean;
    type?: string;
    graphType?: string;
    layout?: Layout;
    showLabel?: boolean;
    showChildLabel?: boolean;
    hidden?: boolean;
    mandatory?: boolean;
    multiple?: boolean;
    defaultValue?: string;
    min?: number;
    max?: number;
    decimalPlaces?: number;
    fieldSize?: number;
    options?: (string | Field) [];
    showDefaultOption?: boolean;
    optionsLayout?: OptionsLayout;
    associates?: Associate[];
    dependsOn?: string;
    dependingOptions?: DependingOption[];
    dependingDescriptions?: DependingDescriptionOption[];
    paragraphs?: Paragraph[];
    fields?: Field[];
    duplicable?: boolean;
    parent?: Field;
    duplicationIndex: number;
    notifyChange?: boolean;
    conditionalOn?: Conditional;
    searchedItem?: string;
    optionsSource?: string;
    dependsOnField?: string;
}

export interface Layout {
    desktop: {
        rows: FieldCount[]
    };
    mobile: {
        rows: FieldCount[]
    };
}

export interface FieldCount {
    fields: number;
}

export interface DependingOption {
    dependsOn: string;
    options: string[];
}

export interface DependingDescriptionOption {
    dependsOn: string;
    paragraphs: Paragraph[];
}

export interface OptionsLayout {
    desktop: {
        columns: number;
    };
    mobile: {
        columns: number;
    };
}

export interface Error {
    reason: string;
    message: string;
}

export interface Associate {
    userId: string;
    displayName: string;
}

export interface Paragraph {
    paragraph: string;
}

export interface Conditional {
    operator: string;
    triggerOutsideDuplicableField: boolean,
    predicates: (Value | Condition)[];
    duplicationIndex?: string
}

export function instanceOfAction(object: any): object is Action {
    return 'name' in object;
}
